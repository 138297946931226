import { Component, OnInit, HostListener } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactService } from '../service/contact.service';
import { Contact } from '../entities/contact';
import { UtilsService } from '../service/utils.service';
import { Page } from '../entities/page';
import { Card } from '../entities/card';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent extends MetadataService implements OnInit {

  image: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Contact%20Us/1ContactUs.webp";
  public innerWidth: any;
  mindate: string;

  destinations: string[] = [
    "Botswana", "South Africa", "Zimbabwe", "Zambia", "Namibia", "Tanzania", "Kenya", "Rwanda",
    "Malawi", "Uganda", "Mauritius", "Mozambique", "Seychelles", "Maldives", "Zanzibar", "Madagascar",
    "Not sure"
  ];
  ids: string[] = [
    "botswana", "southafrica", "zimbabwe", "zambia", "namibia", "tanzania", "kenya", "rwanda",
    "malawi", "uganda", "mauritius", "mozambique", "seychelles", "maldives", "zanzibar", "madagascar",
    "notsure"
  ];
  values: boolean[] = [
    false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false,
    false
  ];

  model = new Contact();
  submitted = false;
  error: {};
  verificationimg: string;
  num: number;

  section: number;

  adults: number;
  children: number;
  notsurepeople: boolean = false;

  arrival: string;
  departure: string;
  unsuretravel: boolean = false;

  travelstyle: string = "stylish";

  userClicked: boolean = false;

  result: string = "";

  page: Page = new Page;

  constructor(meta: Meta, title: Title, private router: Router, private contactService: ContactService, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "contact us";
    this.page.card = new Card();
    this.page.card.h1 = "Contact Us";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    this.adults = 2;
    this.children = 0;
    this.section = 1;

    this.innerWidth = window.innerWidth;

    //Block out previous dates on date picker
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.mindate = yyyy + '-' + mm + '-' + dd;

    /*
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.arrival = yyyy + '/' + mm + '/' + dd;
    this.departure = yyyy + '/' + mm + '/' + dd;
    */

    this.model.id = null;
    var max = 9999;
    var min = 1000;
    this.num = Math.floor(Math.random() * (max - min + 1) + min);
    this.verificationimg = 'https://services.africaunwind.com/verificationimage.php?num=' + this.num + '&t=' + new Date().getTime();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  forcelower(strInput): void {
    if (strInput == "email")
      this.model.email = this.model.email.toLowerCase();
    else
      this.model.emailconfirm = this.model.emailconfirm.toLowerCase();
  }

  onSubmit() {
    this.userClicked = true;

    if (!this.isFormValid()) return;

    //TODO: MR: Work out the values to send through to the email
    // Format both emails on the other side

    this.model.destinations = "";
    for (let i = 0; i < this.values.length; i++) {
      const val = this.values[i];
      if (val) {
        if (this.model.destinations.length > 0) {
          this.model.destinations = this.model.destinations + ", " + this.destinations[i];
        } else {
          this.model.destinations = this.destinations[i];
        }
      }
    }
    this.model.adults = this.adults;
    this.model.children = this.children;
    this.model.notsurepeople = this.titleCaseWord(this.notsurepeople.toString());
    this.model.arrival = this.titleCaseWord(this.arrival);
    this.model.departure = this.titleCaseWord(this.departure);
    this.model.unsuretravel = this.titleCaseWord(this.unsuretravel.toString());
    this.model.travelstyle = this.titleCaseWord(this.travelstyle);

    this.submitted = true;

    return this.contactService.contact2Form(this.model, this.num).subscribe(
      data => {
        console.log(data);
        if (data.indexOf('Thank you') >= 0) {
          this.model.id = 1;
          this.result = data;
        } else {
          this.error = data;
          this.error = {
            errorTitle: 'The contact form was not submitted.',
            errorDesc: data
          };
          this.result = this.addLineBreaks(data);
          //Go to new page
          this.router.navigate(
            ['/thank-you-enquiry'],
            { queryParams: { name: this.model.name, 'email': this.model.email } });
        }
      },
      error => this.error = error
    );
  }

  goHome() {
    this.router.navigate(['/']);
  }

  isValidLength(name: string, length: number): boolean {
    if (name === undefined || name === '') return;
    return name.length >= length;
  }
  isValidEmail(email: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  isConfirmedEmail(): boolean {
    return this.model?.emailconfirm == this.model?.email;
  }

  lessAdults(): void {
    if (this.adults > 1) this.adults--;
  }
  moreAdults(): void {
    if (this.adults < 1000) this.adults++;
  }

  lessChildren(): void {
    if (this.children > 0) this.children--;
  }
  moreChildren(): void {
    if (this.children < 1000) this.children++;
  }

  prevSection(): void {
    if (this.section > 1)
      this.section--;
  }
  nextSection(): void {
    this.section++;
    console.log(this.travelstyle);
  }

  isFormValid(): boolean {
    var result = false;
    var name = this.isValidLength(this.model?.name, 2);
    var surname = this.isValidLength(this.model?.surname, 2);
    var email = this.isValidEmail(this.model?.email);
    var confirmEmail = this.isValidEmail(this.model?.emailconfirm) && this.isConfirmedEmail();
    var verify = this.model?.verify == this.num.toString();
    result = name && surname && email && confirmEmail && verify;
    return result;
  }

  addLineBreaks(line: string): string {
    return line.split('\n\n').join('<br/><br/>');
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getimage(): string {
    var result = this.image.replace('.jpg', '-20w.webp');
    result = result.replace('.jpeg', '-20w.webp');
    result = result.replace('.png', '-20w.webp');
    result = result.replace('.webp', '-20w.webp'); //TODO: MR: Ensure this is correct

    result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?!

    let re = /\`/gi;
    result = result.replace(re, "\'");

    return result;
  }
}
