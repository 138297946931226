<div id="hiddenOS1" class="hiddenH3div">{{ "OUR STORY" | titlecase }}</div>
<div id="hiddenOS2" class="hiddenH3div">
  {{ "RANDOM FACTS ABOUT ME" | titlecase }}
</div>
<div id="hiddenOS3" class="hiddenH3div">
  {{ "MY FAVOURITE DESTINATIONS FOR..." | titlecase }}
</div>
<div>
  <app-header
    [title]="'Our Story'"
    [subtitle]="'Unwind With Us'"
    [image]="headerimage"
    [altText]="'Our Story'"
  ></app-header>

  <div class="btncontainer t-b20">
    <a href="/contact-us">
      <button aria-label="Get in touch" class="btn">Get in Touch</button>
    </a>
  </div>
  <div class="btncontainer t-b10">
    <button
      aria-label="Down arrow"
      class="btn downarrow"
      onClick="document.getElementById('article').scrollIntoView();"
    ></button>
  </div>

  <!-- Article -->
  <span class="container">
    <span id="article" name="article" class="p1280 abouttext">
      <div class="p100 flexmt mt35 mb-70">
        <div id="divOS1" class="left">
          <article>
            <span class="abouttext left dark">
              <h3 id="ourStoryH3" class="center black">
                {{ "OUR STORY" | titlecase }}
              </h3>

              <p class="first-paragraph">
                <span
                  class="dark"
                  [innerHTML]="'HI, I’M LISELLE RAATH.'"
                ></span>
              </p>

              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'Like many people, I knew what I wanted to do with my life when I was just 10 years old.  A Hollywood actress.  And like many of those people, life turned out a little different…But ‘different’ is what I have learned to embrace.  See, ‘different’ also means ‘out of the ordinary’ - & this is exactly what I have found on my first-ever trip to another country.'
                  "
                ></span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'At the age of 19, I hopped on a plane, left my world & my ways behind & stepped into a different world where people spoke a different language, ate different food & showed me that ‘different’ is pretty special.'
                  "
                ></span>
              </p>

              <p>
                <span
                  class="dark"
                  [innerHTML]="'In that moment, I felt at home.'"
                ></span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'It is those moments that I still seek (& find) in Africa’s thrilling wildlife encounters, her natural beauty & interesting people.  And today, more than 20 years later I embrace ‘different’ in my travels.  See, travel broadens our horizons, awakens the adventurer in us & overwhelms us – all at the same time.'
                  "
                ></span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'A few years after my first plane ride, I met someone who shared the same healthy obsession with travel & shortly after, we said “I do.”  Together, we chase the next country, the next destination, the next adventure.  So of course, it didn’t come as a surprise to us when our “double the fun” motto brought us twins.  ‘Out of the ordinary’ – this is our lives.  Today, the twins share our passion for travel & by their 5th birthday, they have already been to 5 countries.'
                  "
                ></span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'As a family, we continue to share our love for travel, because as Ibn Battuta said “Traveling – it leaves you speechless, then turns you into a storyteller.”'
                  "
                ></span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'Like many people, I knew what I wanted to do with my life when I was just 10 years old…To make it extraordinary!'
                  "
                ></span>
              </p>
              <p>
                <span
                  class="dark signature"
                  [innerHTML]="'Liselle Raath'"
                ></span>
              </p>
              <p class="ceo">
                <span class="dark" [innerHTML]="'CEO'"></span>
              </p>
            </span>
          </article>
        </div>
      </div>
    </span>
  </span>

  <span class="container white-back">
    <span id="article" name="article" class="p1280 abouttext white-back">
      <div class="p100 flexmt mt35 mb-70">
        <div id="divOS2" class="left">
          <article>
            <span class="abouttext left dark">
              <div id="randomFacts" class="h3class center black">
                {{ "RANDOM FACTS ABOUT ME" | titlecase }}
              </div>
              <p></p>

              <p>
                <span class="header black"
                  >{{ "PASSIONS‌ ‌&‌ ‌INTERESTS‌" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    '‌People‌ ‌&‌ ‌living‌ ‌out‌ ‌of‌ ‌a‌ ‌suitcase.'
                  "
                ></span>
              </p>
              <p>
                <span class="header black"
                  >{{ "TRAVEL ESSENTIAL" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="'My camera.'"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "BUSH OR BEACH?" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="'Beach in summer, bush in winter.'"
                ></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "MUST EAT SAFARI FOOD" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="'All of it.'"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "FUNNIEST TRAVEL STORY" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'That day a hippo blocked the entrance to my chalet door…'
                  "
                ></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "FAVOURITE SAFARI ACTIVITY" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'Where there is action!  Lions hunting, a leopard climbing, predators eating or elephants splashing.'
                  "
                ></span>
              </p>

              <p>
                <span class="header black">{{ "BAD HABIT" | titlecase }} </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="
                    'I take way too many pictures & expect my family to sit still for the perfect shot.'
                  "
                ></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "WHAT TO DO IN AFRICA" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="'Explore.  Eat.  Nap.  Repeat.'"
                ></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "WHAT NOT TO DO IN AFRICA" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="'Whatever you do, don’t run!'"
                ></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "TRAVEL ADVICE" | titlecase }}
                </span>
              </p>
              <p>
                <span
                  class="dark"
                  [innerHTML]="'Do one adventurous thing every day!'"
                ></span>
              </p>
            </span>
          </article>
        </div>
      </div>
    </span>
  </span>

  <span class="container">
    <span id="article" name="article" class="p1280 abouttext">
      <div class="p100 flexmt mt35 mb-70">
        <div id="divOS3" class="left">
          <article>
            <span class="abouttext left dark">
              <div id="myFavourite" class="h3class center black">
                {{ "MY FAVOURITE DESTINATIONS FOR..." | titlecase }}
              </div>
              <p></p>
              <p>
                <span class="header black">{{ "WILDLIFE" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="wildlife"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "WALKING SAFARIS" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="walking"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "GORILLA-TREKKING" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="gorilla"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "SCUBA DIVING" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="scuba"></span>
              </p>

              <p>
                <span class="header black">{{ "BIG 5" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="big"></span>
              </p>

              <p>
                <span class="header black">{{ "FAMILIES" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="families"></span>
              </p>

              <p>
                <span class="header black">{{ "ADVENTURE" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="adventure"></span>
              </p>

              <p>
                <span class="header black"
                  >{{ "PHOTOGRAPHY" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="photography"></span>
              </p>

              <p>
                <span class="header black">{{ "NATURE" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="nature"></span>
              </p>

              <p>
                <span class="header black">{{ "BEACHES" | titlecase }} </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="beaches"></span>
              </p>
              <p>
                <span class="header black"
                  >{{ "OFF-THE-BEATEN-TRACK" | titlecase }}
                </span>
              </p>
              <p>
                <span class="dark" [innerHTML]="off"></span>
              </p>
            </span>
          </article>
        </div>
      </div>
    </span>
  </span>

  <app-images [heading]="'Gallery'" [images]="images" [altArray]="altArray"></app-images>

  <app-steps id="steps"></app-steps>
  <app-instagram id="instagram"></app-instagram>

  <app-intersection></app-intersection>
</div>
