<div id="hiddenSteps" class="hiddenH3div">Ready, Steady, Go!</div>
<div class="areacontainer">
  <div id="divSteps" class="area">
    <div class="grid-container">
      <div class="grid-item item1">
        <div class="coverheading">
          <div class="h3class center">Ready, Steady, Go!</div>
        </div>
      </div>

      <div class="gridcontainer">
        <div class="grid">
          <div class="griditem itemHead1">
            <img src="https://raw.githubusercontent.com/aumraath/africaunwind/main/images/steps/stepmouse-200w.webp" />
          </div>
          <div class="griditem itemText1 textmargin">
            1. Click on Let's Plan.
          </div>
          <div class="griditem itemHead2">
            <img
              src="https://raw.githubusercontent.com/aumraath/africaunwind/main/images/steps/stepnotebook-200w.webp"
            />
          </div>
          <div class="griditem itemText2 textmargin">
            2. A travel expert will curate a bespoke holiday.
          </div>
          <div class="griditem itemHead3">
            <img
              src="https://raw.githubusercontent.com/aumraath/africaunwind/main/images/steps/stepsuitecase-200w.webp"
            />
          </div>
          <div class="griditem itemText3 textmargin">
            3. Book & start packing.
          </div>
        </div>
      </div>

      <div class="btncontainer">
        <a href="/contact-us"
          ><button aria-label="Lets plan" class="btn pink round">
            Let's Plan
          </button></a
        >
      </div>
    </div>
  </div>
</div>
