<div id="hiddenAreas" class="hiddenH3div">Travel Experiences</div>
<div id="divAreas" class="areacontainer">
  <div class="h3class center">Travel Experiences</div>
  <div class="gridcontainer">
    <div class="grid griditem2">
      <div
        *ngFor="let img of images; let i = index"
        [ngClass]="{ bigImg: isBig(i) }"
        class="grid-item grid__item"
        (mouseover)="scaleImage($event, i)"
        (mouseout)="scaleImage($event, i)"
      >
        <a [href]="getUrl(i)">
          <div class="content coverimg">
            <picture class="lazy">
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 900px), (min-height: 510px)"
              />
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 450px), (min-height: 255px)"
              />
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 300px), (min-height: 170px)"
              />
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="getImgUrl(i)"
                [ngClass]="{ scaleup: item[i] }"
                [alt]="getItemAltNameFromImage(i)"
              />
            </picture>
          </div>
          <div class="blockimage"></div>
          <div class="h4class">
            {{ getItemAltNameFromImage(i) }}
          </div>
        </a>
      </div>
    </div>
    <app-intersection></app-intersection>
  </div>
</div>
