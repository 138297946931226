import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intersection',
  templateUrl: './intersection.component.html',
  styleUrls: ['./intersection.component.css']
})
export class IntersectionComponent implements OnInit {

  observer: IntersectionObserver | undefined;

  constructor() { }

  ngOnInit(): void {
    this.InitIntersectionObserver();
    //this.DocumentLazyLoad();
  }

  DocumentLazyLoad() {
    var lazyloadImages;

    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll(".lazy");

      var options = {
        root: document.querySelector('#au'),
        rootMargin: '100px', // where in relation to the edge of the viewport, we are observing
        threshold: 1.0      // how much of the element needs to have intersected in order to fire our loading function
      };

      this.observer = new IntersectionObserver(this.LazyLoad.bind(this), options);

      var imageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var image = entry.target;

            //Set image and remove
            //image.src = image.dataset.src;
            image.classList.remove("lazy");
            imageObserver.unobserve(image);
          }
        });
      });

      lazyloadImages.forEach(function (image) {
        imageObserver.observe(image);
      });
    } else {
      var lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll(".lazy");

      function lazyload() {
        if (lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function () {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function (img) {
            if (img.offsetTop < (window.innerHeight + scrollTop)) {
              //Set Image and remove class
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
          });
          if (lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }

      document.addEventListener("scroll", lazyload);
      window.addEventListener("resize", lazyload);
      window.addEventListener("orientationChange", lazyload);
    }
  }

  InitIntersectionObserver() {
    var options = {
      rootMargin: '50px 20px 75px 30px', // '100px', // '50px 20px 75px 30px',
      threshold: [0, 0.25, 0.75, 1] //1.0 //[0, 0.25, 0.75, 1]
      //root: document.querySelector('#au'),
      //rootMargin: '100px', // where in relation to the edge of the viewport, we are observing
      //threshold: 1.0      // how much of the element needs to have intersected in order to fire our loading function
    };

    this.observer = new IntersectionObserver(this.LazyLoad.bind(this), options);

    // Tell our observer to observe all img elements with a "lazy" class
    var lazyImages = document.querySelectorAll('picture.lazy');
    lazyImages.forEach(img => {
      this.observer.observe(img);
    });
  }

  LazyLoad(elements) {
    elements.forEach(picture => {
      if (picture.intersectionRatio > 0) {

        // gather all the image and source elements in this picture
        var sources = picture.target.children;

        for (var s = 0; s < sources.length; s++) {
          var source = sources[s];

          // set a new srcset on the source elements
          if (source.hasAttribute("srcset")) {
            var size = "-20w";
            if (source.attributes.media.nodeValue.indexOf("900px") > 0) size = "-lg"; //1920 - 1200px
            if (source.attributes.media.nodeValue.indexOf("450px") > 0) size = "-md"; //720 - 700px
            if (source.attributes.media.nodeValue.indexOf("300px") > 0) size = "-sm"; //480 - 300px
            if (source.attributes.media.nodeValue.indexOf("1px") > 0) size = "-sm"; //480 - 1px
            source.setAttribute("srcset", source.srcset.replace("-20w", size)); //20
          }
          // or a new src on the img element
          else {
            source.setAttribute("src", source.src.replace("-20w", "-md"));
          }
        }

        // stop observing this element. Our work here is done!
        this.observer.unobserve(picture.target); //(item.target);

        // remove the lazy class when the full image is loaded to unblur
        source.addEventListener('load', image => {
          image.target.closest("picture").classList.remove("lazy")
        }, false);

      };
    });
  }

}
