import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  searchOpen: boolean;
  hamburgerOpen: boolean;
  find: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.searchOpen = false;
    this.hamburgerOpen = false;
  }

  toggleHamburger(): void {
    this.hamburgerOpen = !this.hamburgerOpen;
    //this.searchOpen = this.hamburgerOpen;
  }
}
