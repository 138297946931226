<div class="footer-dark">
  <footer>
    <div class="container">
      <div class="row">
        <div class="menu">
          <div class="explore item">
            <div class="h2class">Explore</div>
            <ul>
              <li><a href="/safaris">Safaris</a></li>
              <li><a href="/islands">Islands</a></li>
              <li><a href="/cities">Cities</a></li>
              <li>
                <a href="/top-destinations">Top Destinations</a>
              </li>
              <li><a href="/destinations">All Destinations</a></li>
            </ul>
          </div>
          <div class="experience item">
            <div class="h2class">Experience</div>
            <ul>
              <li><a href="/bucket-list">The Bucket List</a></li>
              <li><a href="/honeymoons">Honeymoons</a></li>
              <li><a href="/family-travel">Family Travel</a></li>
              <li><a href="/trips">Our Favourite Trips</a></li>
              <li><a href="/what-to-expect">What To Expect</a></li>
            </ul>
          </div>
          <div class="contact item">
            <div class="h2class">Contact</div>
            <ul>
              <a href="/"><li class="normaltext">Africa Unwind</li></a>
              <a href="/"><li class="normaltext">+27 21 300 1579</li></a>
              <a href="mailto:info@africaunwind.com"
                ><li class="normaltext" [innerHTML]="email"></li
              ></a>
              <li><a href="/our-story">Our Story</a></li>
              <li><a href="/contact-us">Let's Plan</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!--<div class="row gap"></div>-->

      <div class="row">
        <div class="copyright">
          <a
            href="https://www.satsa.com/"
            target="_blank"
            rel="nofollow"
            >
            <img alt="SATSA" style="" copyright="" src="https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SATSA-200w.webp">
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col item social">
          <a
            href="https://www.instagram.com/africaunwind/"
            target="_blank"
            rel="noopener"
            ><i class="icon ion-social-instagram"></i
          ></a>
          <a
            href="https://twitter.com/africaunwind"
            target="_blank"
            rel="noopener"
            ><i class="icon ion-social-twitter"></i
          ></a>
          <a
            href="https://www.facebook.com/africaunwind"
            target="_blank"
            rel="noopener"
            ><i class="icon ion-social-facebook"></i
          ></a>
          <!-- <a href="#" target="_blank" rel="noopener"
            ><i class="icon ion-social-youtube"></i
          ></a> -->
        </div>
      </div>

      <div class="row">
        <p class="copyright">Africa Unwind © 2021</p>
      </div>
    </div>
  </footer>
</div>
