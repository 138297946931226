<div id="hiddenImages" class="hiddenH3div">Gallery</div>
<div class="modalcontainer" [ngClass]="{ showmodal: modalImage !== '' }">
  <div class="closemodal" (click)="clearModalImage()">x</div>
  <div class="prevmodal" (click)="setModalImage(selecteditem - 1)"><</div>
  <div class="nextmodal" (click)="setModalImage(selecteditem + 1)">></div>
  <div class="modalimg">
    <div class="image">
      <img
        (click)="clearModalImage()"
        [src]="modalImage"
        [alt]="altArray[selecteditem]"
      />
    </div>
  </div>
</div>

<div id="divImages" class="areacontainer">
  <div id="imagesH3" class="h3class center black">Gallery</div>
  <div class="area">
    <div class="grid-container">
      <div
        class="grid-item item1"
        (mouseover)="scaleImage($event, 0)"
        (mouseout)="scaleImage($event, 0)"
        (click)="setModalImage(0)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(0)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(0)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(0)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(0)"
              [ngClass]="{ scaleup: item[0] }"
              [alt]="altArray[0]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item2"
        (mouseover)="scaleImage($event, 1)"
        (mouseout)="scaleImage($event, 1)"
        (click)="setModalImage(1)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(1)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(1)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(1)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(1)"
              [ngClass]="{ scaleup: item[1] }"
              [alt]="altArray[1]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item3"
        (mouseover)="scaleImage($event, 2)"
        (mouseout)="scaleImage($event, 2)"
        (click)="setModalImage(2)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(2)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(2)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(2)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(2)"
              [ngClass]="{ scaleup: item[2] }"
              [alt]="altArray[2]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item4"
        (mouseover)="scaleImage($event, 3)"
        (mouseout)="scaleImage($event, 3)"
        (click)="setModalImage(3)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(3)"
              media="(min-width: 900px), (min-height: 510px)"
            />
            <source
              [srcset]="getImgUrl(3)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(3)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(3)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(3)"
              [ngClass]="{ scaleup: item[3] }"
              [alt]="altArray[3]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item5"
        (mouseover)="scaleImage($event, 4)"
        (mouseout)="scaleImage($event, 4)"
        (click)="setModalImage(4)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(4)"
              media="(min-width: 900px), (min-height: 510px)"
            />
            <source
              [srcset]="getImgUrl(4)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(4)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(4)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(4)"
              [ngClass]="{ scaleup: item[4] }"
              [alt]="altArray[4]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item6"
        (mouseover)="scaleImage($event, 5)"
        (mouseout)="scaleImage($event, 5)"
        (click)="setModalImage(5)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(5)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(5)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(5)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(5)"
              [ngClass]="{ scaleup: item[5] }"
              [alt]="altArray[5]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item7"
        (mouseover)="scaleImage($event, 6)"
        (mouseout)="scaleImage($event, 6)"
        (click)="setModalImage(6)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(6)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(6)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(6)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(6)"
              [ngClass]="{ scaleup: item[6] }"
              [alt]="altArray[6]"
            />
          </picture>
        </div>
      </div>

      <div
        class="grid-item item8"
        (mouseover)="scaleImage($event, 7)"
        (mouseout)="scaleImage($event, 7)"
        (click)="setModalImage(7)"
      >
        <div class="coverimg">
          <picture class="lazy">
            <source
              [srcset]="getImgUrl(7)"
              media="(min-width: 450px), (min-height: 255px)"
            />
            <source
              [srcset]="getImgUrl(7)"
              media="(min-width: 300px), (min-height: 170px)"
            />
            <source
              [srcset]="getImgUrl(7)"
              media="(min-width: 1px), (min-height: 1px)"
            />
            <img
              [src]="getImgUrl(7)"
              [ngClass]="{ scaleup: item[7] }"
              [alt]="altArray[7]"
            /> <!--getItemAltNameFromImage(getImgUrl(7))-->
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
