<div>
  <app-header *ngIf="page.card?.images"
    [title]="page.card?.h1"
    [logo]="'yes'"
    [subtitle]="page.card?.h2"
    [image]="getImageForHome()"
    [altText]="page.card?.alt1"
  ></app-header> <!--card.images[0]-->

  <div class="btncontainer t-b20">
    <a href="/#places">
      <button aria-label="Top destinations" class="btn">
        Top Destinations
      </button>
    </a>
  </div>

  <div class="btncontainer t-b10">
    <a href="#areas">
      <button aria-label="Down arrow" class="btn downarrow"></button>
    </a>
  </div>

  <app-areas id="areas"></app-areas>

  <app-aboutus
    *ngIf="page.card && (page.reason?.length > 0 || page.card?.html?.length > 0)"
    id="about"
    [image]=""
    [showtext]="'true'"
    [title]="page.card.h3"
    [reason]="page.reason"
    [html]="page.card.html"
  ></app-aboutus>

  <app-places id="places" name="places"></app-places>

  <app-whyus id="whyus"></app-whyus>

  <app-our-favourites
    id="ourfavourites"
  ></app-our-favourites>

  <app-steps id="steps"></app-steps>

  <app-instagram id="instagram"></app-instagram>

  <app-intersection></app-intersection>
</div>
