import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divWhyus").width() - 4; //25px padding on both sides

    while ($("#hiddenWhyus").width() > mainwidth) {
      var hiddenWidth = $("#hiddenWhyus").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenWhyus").css("font-size"));
      var h3Size = $("#divWhyus").find(".h3class").css("font-size");
      $("#hiddenWhyus").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenWhyus").css("font-size");
    $("#divWhyus").find(".h3class").css("font-size", finalfontsize);
  });
}

@Component({
  selector: 'app-whyus',
  templateUrl: './whyus.component.html',
  styleUrls: ['./whyus.component.css']
})
export class WhyusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //ResizeHeaders();
  }
}
