<div id="hiddenDestinations" class="hiddenH3div">
  {{ "All Destinations" | titlecase }}
</div>
<div>
  <app-header
      [title]="page.card.h1"
      [subtitle]="page.card.h2"
      [image]="page.card?.images[0]"
      [altText]="page.card?.alt1"
    ></app-header>

  <div class="btncontainer t-b20">
    <a href="/contact-us">
      <button aria-label="Get in touch" class="btn">Get in Touch</button>
    </a>
  </div>
  <div class="btncontainer t-b10">
    <button
      aria-label="Down arrow"
      class="btn downarrow"
      onClick="document.getElementById('article').scrollIntoView();"
    ></button>
  </div>

  <span class="container">
    <!-- Article -->
    <span id="article" name="article" class="p1280 abouttext">
      <div class="p100 flexmt mt35 mb-70">
        <div id="divDestinations" class="left">
          <article>
            <span class="abouttext left dark">
              <h3 *ngIf="page.card.h3" class="center black">
                {{ (page.card.h3 | titlecase) }} <!--fixTitle(cleanTitle(page.cardtitle) | titlecase)-->
              </h3>
<p *ngFor="let line of page.reason; let i = index">
                <!--Experience-->
                <span *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])" class="header black"
                  >{{ utils.getWhyTitle(page.reason[i]) | titlecase }}
                </span>
                <span
                  *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])"
                  class="dark"
                  [innerHTML]="page.reason[i + 1]"
                ></span>

                <!--Experience-->
                <span
                  *ngIf="
                    i == 0 ||
                    (!utils.isAllUppercase(page.reason[i]) &&
                    !utils.startWithTime(page.reason[i]) &&
                      !utils.isAllUppercase(page.reason[i - 1]) &&
                      !utils.startWithTime(page.reason[i - 1]))
                  "
                  class="dark"
                  [innerHTML]="page.reason[i]"
                ></span>
              </p>
            </span>
          </article>
        </div>
      </div>
    </span>
  </span>

  <div class="gridcontainer">
    <div class="grid griditem2">
      <div
        *ngFor="let img of images; let i = index"
        class="grid-item grid__item"
        (mouseover)="scaleImage($event, i)"
        (mouseout)="scaleImage($event, i)"
      >
        <a [href]="getUrl(i)">
          <div class="content coverimg">
            <picture class="lazy">
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 450px), (min-height: 255px)"
              />
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 300px), (min-height: 170px)"
              />
              <source
                [srcset]="getImgUrl(i)"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="getImgUrl(i)"
                [ngClass]="{ scaleup: item[i] }"
                [alt]="getItemAltNameFromImage(i)"
              />
            </picture>
          </div>
          <div class="h4class">
            {{ getItemAltNameFromImage(i) }}
          </div>
        </a>
      </div>
    </div>
  </div>

  <app-steps id="steps"></app-steps>
  <app-instagram id="instagram"></app-instagram>

  <app-intersection></app-intersection>
</div>
