<div class="fullheightimg picturediv" id="headerapp">
  <img
    id="mainimg"
    class="fullheightimg"
    [ngClass]="{ 'img-bottom': isMainPage, 'img-right': isBlogPage }"
    [src]="getimage()"
    [alt]="altText"
  />

  <!-- <picture class="lazy">
    <source
      [srcset]="getimage()"
      media="(min-width: 900px), (min-height: 510px)"
    />
    <source
      [srcset]="getimage()"
      media="(min-width: 450px), (min-height: 255px)"
    />
    <source
      [srcset]="getimage()"
      media="(min-width: 300px), (min-height: 170px)"
    />
    <source [srcset]="getimage()" media="(min-width: 1px), (min-height: 1px)" />
    <img
      [ngClass]="{ 'img-bottom': isMainPage }"
      [src]="getimage()"
      [alt]="getItemAltNameFromImage(image)"
    />
  </picture> -->

  <div *ngIf="title" class="hiddendiv">
    {{ fixTitle(title | titlecase) }}
  </div>
  <!-- <div *ngIf="subtitle" class="sub hiddenH2 hiddensub subtitle h2class">
    {{ subtitle | titlecase }}
  </div> -->

  <div class="grid">
    <div>
      <div *ngIf="logo" class="logocontainer"><div class="logo"></div></div>
    </div>
    <div>
      <div class="main"> <!--*ngIf="!containsZero()"-->
        <!-- <span> -->
        <h1 class="mainheader" *ngIf="title"> <!--highlight [highlight]="'0'" [className]="'highlight'"-->
          {{ fixTitle(title | titlecase) }}
        </h1>
        <!-- </span> -->
      </div>

    </div>
    <div>
      <div class="mainspacer"></div>
    </div>
    <div>
      <div class="preSub">
        <div *ngIf="presubtitle" class="presubtitle h2class">
          {{ presubtitle | titlecase }}
        </div>
      </div>
    </div>
    <div>
      <div class="sub">
        <h2
          *ngIf="subtitle"
          class="subtitle h2class"
          [ngClass]="{ subtitlemove: presubtitle }"
        >
          {{ fixSubtitle(subtitle) | titlecase }}
        </h2>
      </div>
    </div>
  </div>
</div>
