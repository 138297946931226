import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UtilsService } from '../service/utils.service';

import { Page } from '../entities/page';
import { Sibling } from '../entities/sibling';

import * as $ from "jquery";
import { HighlightDirective } from '../service/highlight.service';
import { Card } from '../entities/card';

function ResizeHeaders() {
  console.log('Resize H3');

  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divCard").width() - 4; //25px padding on both sides

    while ($("#hiddenCard").width() > mainwidth) {
      var hiddenWidth = $("#hiddenCard").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenCard").css("font-size"));
      var h3Size = $("#divCard").find("h3").css("font-size");
      $("#hiddenCard").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenCard").css("font-size");
    $("#divCard").find("h3").css("font-size", finalfontsize);
    $("#divCard").find("h3").css("line-height", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#cardapp").addClass('safari');
      $("#divCard").find("h3").css("padding-top", 5);
      $("#divCard").find("h3").css("padding-bottom", 0);

      $("#cardBread").find("h3").css("padding-top", 0);
      $("#cardBread").find("h3").css("padding-bottom", 0);
      $("#cardBread").find("h3").css("margin-top", -20);
      $("#cardBread").find("h3").css("margin-bottom", -20);
      console.log("Safari Hack");
      $(".why").find(".h5class").css("padding-top", 15);
    }
  });
}

function FixYouTubeDiv() {
  $(document).ready(function () {

    const div = document.getElementById("htmlYouTube");
    div.style.height = div.offsetWidth * 9 / 16 + "px";

  });
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  page: Page = new Page;
  loadedChildren: boolean = false;


  constructor(private router: Router, private actRoute: ActivatedRoute, private utils: UtilsService) { //private transferStateService: TransferStateService
    this.page.routerUrl = this.router.url;

    this.page.experience = this.cleanText(this.actRoute.snapshot.params.experience);
    this.page.country = this.cleanText(this.actRoute.snapshot.params.country);
    this.page.area = this.cleanText(this.actRoute.snapshot.params.area);
    this.page.lodge = this.cleanText(this.actRoute.snapshot.params.lodge);

    //Serengeti has specific styling
    if (this.page.area && this.page.area.toLowerCase() == "serengeti national park")
      this.page.isSerengeti = true;

    //This is to handle LONG folder structures
    //  This wasnt planned but happened eg. wineroute
    //handling this here...
    this.page.folder1 = this.cleanText(this.actRoute.snapshot.params.folder1);
    this.page.folder2 = this.cleanText(this.actRoute.snapshot.params.folder2);
    this.page.folder3 = this.cleanText(this.actRoute.snapshot.params.folder3);

    //TODO: MR: ONly use this to get the list of locations to convert into Lat Long
    //var vals = ds.consoleWriteGeolocationNames();

    //Some defaults before getting the actual card
    //this.page.card = new Card();
    //this.page.card.images = new Array();
    //this.page.card.images.push("https://raw.githubusercontent.com/aumraath/africaunwind/main/images/spacer.webp"); //Default image

    //Get the actual card
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
  }

  getPageValid(): boolean {
    if (this.page.card.images.length > 0 && !this.loadedChildren) {
      //If there are images, then the card is loaded, surely
      this.utils.findChildren(this.page);
      this.loadedChildren = true;
    }
    return this.loadedChildren;
  }

  getIFrameSource(): string {
    var url = "https://www.youtube.com/embed/" + this.page.card?.youtube;
    return url;
  }

  hasCalledResize(): boolean {
    if (!this.page.resizedH3) {
      ResizeHeaders();
      ApplySafariHack();
      //FixYouTubeDiv();
      this.page.resizedH3 = true;
    }
    return true;
  }

  cleanText(text: string): string { //To clean eg. Experience, Country, etc
    if (!text) return;
    return text
      .toLowerCase()
      .split("-").join(" ")
      .split("_").join(" ");
  }

  isSpecialHeading(str: string): boolean {
    var specialHeadings = ["MIGRATION EXPLAINED", "ACTIVITTIES"];
    return specialHeadings.indexOf(str.toUpperCase()) > -1;
  }

  getPreSubtitle() {
    return "";
  }

  getTitle1(text: string): string { //Used to display H3 with 0s better
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.utils.containsZero(this.page.card.h3)) {
      var result = text.substr(0, text.indexOf("0"));
      return result;
    }
    else return text;
  }

  getTitle2(text: string): string { //Used to display H3 with 0s better
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.utils.containsZero(this.page.card.h3)) {
      return "0";
    }
    else return "";
  }

  getTitle3(text: string): string { //Used to display H3 with 0s better
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.utils.containsZero(this.page.card.h3)) {
      return text.substr(text.indexOf("0") + 1);
    }
    else return "";
  }

  containsLatitude(): boolean {
    if (!this.page.card.h1) return;
    return (this.page.card.h1.toLowerCase().indexOf("latitude") > -1);
  }

  startsWithNumbers(text: string): boolean {
    if ((text.indexOf("1") == 0)
      || (text.indexOf("2") == 0)
      || (text.indexOf("3") == 0)
      || (text.indexOf("4") == 0)
      || (text.indexOf("5") == 0)
      || (text.indexOf("6") == 0)
      || (text.indexOf("7") == 0)
      || (text.indexOf("8") == 0)
      || (text.indexOf("9") == 0)
      || (text.indexOf("0") == 0))
      return true;

    return false;
  }

  blacklisted() {
    //console.log("Experience " + this.page.experience);
    if (this.page.experience == "what to expect") {
      return true;
    }
    return false;
  }

  getBreadCrumbParent() {
    if (this.utils.isTopExperiences(this.page.experience)) {
      if (!this.page.card.parent.siblings[0]?.title) {
        this.utils.fixCardTitle(this.page.card.parent.siblings[0], this.page);
        console.log("Fixed? : " + this.page.card.parent.siblings[0]?.title);
      }
      return this.page.card.parent.siblings[0];
    }
    return this.page.parent;
  }

  getBreadCrumbUncles() {
    if (this.utils.isTopExperiences(this.page.experience)) {
      return this.getOtherSiblings();
    }
    return this.utils.fixUncles(this.page.depth, this.page.experience, this.page.uncles);
  }

  getOtherSiblings() {
    var otherSiblings = <Sibling[]>[];
    for (let index = 1; index < this.page.parent.siblings.length; index++) {
      const sibling = this.page.parent.siblings[index];
      otherSiblings.push(sibling);
    }
    return otherSiblings;
  }

  getBreadCrumbHeading() {
    console.log('Back links');
    if (this.utils.isTopExperiences(this.page.experience)) {
      return "Other Experiences";
    }
    return "Back";
  }

  getHighlightsTitle() {
    if (this.page.experience == "what to expect") return "What To Expect?";
    return "What We Love";
  }


  isPageBlogAllThingsAfrica() {
    if (this.page?.experience?.toLowerCase() == "blog"
      && this.page?.country?.toLowerCase() == "all things africa"
      && this.page?.card?.level == 3) {
      return true;
    }
    return false;
  }

  /*
  backCards() {
    this.itemCounter = this.itemCounter - this.itemsToGet;
    if (this.itemCounter < 0)
      this.itemCounter = 0;
    this.getFolderSubs(this.thisFolder.special, this.itemCounter, this.itemsToGet);
  }

  nextCards() {
    this.itemCounter = this.itemCounter + this.itemsToGet;
    this.getFolderSubs(this.thisFolder.special, this.itemCounter, this.itemsToGet);
  }
  */
}
