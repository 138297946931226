<div>
  <app-header
    *ngIf="innerWidth > 550"
    [title]=""
    [logo]=""
    [subtitle]=""
    [image]="image"
    [altText]="'Contact Us'"
  ></app-header>

  <div class="container">
    <div *ngIf="!submitted" class="area">
      <div *ngIf="section == 1" class="destinations section">
        <div class="h4class choose">Choose...&nbsp;Your travel destination(s).</div>
        <div class="row">
          <div class="row flexrow noselect">
            <div *ngFor="let dest of destinations; let i = index">
              <input
                type="checkbox"
                [name]="ids[i]"
                [value]="ids[i]"
                [id]="ids[i]"
                [(ngModel)]="values[i]"
                hidden
              />
              <label [for]="ids[i]">{{ destinations[i] }}</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="section == 2" class="number section">
        <div class="h4class choose">Choose...&nbsp;The number of adults & children.</div>
        <div class="row">
          <div class="colleft">Adults</div>
          <div class="colright">
            <div class="btnsmall noselect noleftmargin" (click)="lessAdults()">
              -
            </div>
            <div class="numberwidth">{{ adults }}</div>
            <div class="btnsmall btnmore noselect" (click)="moreAdults()">
              +
            </div>
          </div>
        </div>
        <div class="row">
          <div class="colleft">Children under 12</div>
          <div class="colright">
            <div
              class="btnsmall noselect noleftmargin"
              (click)="lessChildren()"
            >
              -
            </div>
            <div class="numberwidth">{{ children }}</div>
            <div class="btnsmall btnmore noselect" (click)="moreChildren()">
              +
            </div>
          </div>
        </div>
        <div class="destinations row">
          <div class="colleft"></div>
          <div class="colright">
            <div>
              <input
                type="checkbox"
                [name]="'notsurepeople'"
                [value]="'notsurepeople'"
                [id]="'notsurepeople'"
                [(ngModel)]="notsurepeople"
                hidden
              />
              <label class="peoplelabel" [for]="'notsurepeople'"
                >Not sure</label
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="section == 3" class="dates section">
        <div class="h4class choose">Choose...&nbsp;Your travel dates.</div>
        <div class="row daterow">
          <div class="colleft">Arrival</div>
          <div class="colright">
            <div>
              <input
                type="date"
                id="arrival"
                name="arrival"
                [min]="mindate"
                [(ngModel)]="arrival"
              />
            </div>
          </div>
        </div>
        <div class="row daterow">
          <div class="colleft">Departure</div>
          <div class="colright">
            <div>
              <input
                type="date"
                id="departure"
                name="departure"
                [min]="mindate"
                [(ngModel)]="departure"
              />
            </div>
          </div>
        </div>
        <div class="row daterow">
          <div class="colleft"></div>
          <div class="destinations colright">
            <div>
              <input
                type="checkbox"
                [name]="'unsuretravel'"
                [value]="'unsuretravel'"
                [id]="'unsuretravel'"
                [(ngModel)]="unsuretravel"
                hidden
              />
              <label class="travellabel" [for]="'unsuretravel'">Not sure</label>
            </div>
            <!--
            <div>
              <label class="checkcontainer">
                <input type="checkbox" [(ngModel)]="unsuretravel" />
                <span class="checkmark"></span>
              </label>
            </div>
            -->
          </div>
        </div>
      </div>
      <div *ngIf="section == 4" class="style section">
        <div class="h4class choose">Choose...&nbsp;Your travel style.</div>
        <div class="row stylerow">
          <div class="colleft">
            <div class="styleHeading">Charming</div>
            <div class="stylePrice">USD $3,000 - USD $6,000</div>
            <div class="stylePP">per person</div>
          </div>
          <div class="colright">
            <div>
              <label class="radiocontainer">
                <input
                  type="radio"
                  id="charming"
                  name="style"
                  value="charming"
                  [(ngModel)]="travelstyle"
                />
                <span class="radiocheckmark"></span>
              </label>
            </div>
            <div>
              I appreciate good value, but don't want to compromise on
              experience.
            </div>
          </div>
        </div>
        <div class="row stylerow">
          <div class="colleft">
            <div class="styleHeading">Stylish</div>
            <div class="stylePrice">USD $6,000 - USD $12,000</div>
            <div class="stylePP">per person</div>
          </div>
          <div class="colright">
            <div>
              <label class="radiocontainer">
                <input
                  checked
                  type="radio"
                  id="stylish"
                  name="style"
                  value="stylish"
                  [(ngModel)]="travelstyle"
                />
                <span class="radiocheckmark"></span>
              </label>
            </div>
            <div>I love stylish interiors & amazing experiences.</div>
          </div>
        </div>
        <div class="row stylerow">
          <div class="colleft">
            <div class="styleHeading">Exclusive</div>
            <div class="stylePrice">USD $12,000 +</div>
            <div class="stylePP">per person</div>
          </div>
          <div class="colright">
            <div>
              <label class="radiocontainer">
                <input
                  type="radio"
                  id="exclusive"
                  name="style"
                  value="exclusive"
                  [(ngModel)]="travelstyle"
                />
                <span class="radiocheckmark"></span>
              </label>
            </div>
            <div>
              I prefer accommodation of the highest standard & usually opt for
              exclusive experiences.
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="section == 5"
        class="contact section"
        [ngClass]="{
          sectionlast: section == 5
        }"
      >
        <div class="h4class choose">Choose...&nbsp;How we can reach you.</div>
        <div class="row contactrow">
          <div class="colleft colleftrow">
            Name<span
              class="required"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.name) && !isValidLength(model?.name, 2)
              }"
              >*</span
            >
          </div>
          <div class="colright">
            <input
              type="text"
              name="name"
              autocomplete="off"
              id="name"
              [(ngModel)]="model.name"
              [ngClass]="{
                errorline:
                  (userClicked || model?.name) && !isValidLength(model?.name, 2)
              }"
              required
              placeholder="Mary"
            />
          </div>
        </div>
        <div class="row contactrow">
          <div class="colleft colleftrow">
            Surname<span
              class="required"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.surname) &&
                  !isValidLength(model?.surname, 2)
              }"
              >*</span
            >
          </div>
          <div class="colright">
            <input
              type="text"
              surname="surname"
              autocomplete="off"
              id="surname"
              [(ngModel)]="model.surname"
              [ngClass]="{
                errorline:
                  (userClicked || model?.surname) &&
                  !isValidLength(model?.surname, 2)
              }"
              required
              placeholder="Jones"
            />
          </div>
        </div>
        <div class="row contactrow">
          <div class="colleft colleftrow">
            Email<span
              class="required"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.email) && !isValidEmail(model?.email)
              }"
              >*</span
            >
          </div>
          <div class="colright">
            <input
              type="text"
              name="email"
              autocomplete="off"
              id="email"
              [(ngModel)]="model.email"
              [ngClass]="{
                errorline:
                  (userClicked || model?.email) && !isValidEmail(model?.email)
              }"
              required
              email
              (keyup)="forcelower('email')"
              placeholder="mary@example.com"
            />
          </div>
        </div>
        <div class="row contactrow">
          <div class="colleft colleftrow">
            Confirm Email<span
              class="required"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.emailconfirm) &&
                  (!isValidEmail(model?.emailconfirm) || !isConfirmedEmail())
              }"
              >*</span
            >
          </div>
          <div class="colright">
            <input
              type="text"
              name="emailconfirm"
              autocomplete="off"
              id="email"
              [(ngModel)]="model.emailconfirm"
              [ngClass]="{
                errorline:
                  (userClicked || model?.emailconfirm) &&
                  (!isValidEmail(model?.emailconfirm) || !isConfirmedEmail())
              }"
              required
              emailconfirm
              (keyup)="forcelower('confirmemail')"
              placeholder="mary@example.com"
            />
          </div>
        </div>
        <div class="row contactrow">
          <div class="colleft colleftrow">Phone</div>
          <div class="colright">
            <input
              type="text"
              name="phone"
              autocomplete="off"
              id="phone"
              required
              [(ngModel)]="model.phone"
              placeholder="+27 21 300 1579"
            />
          </div>
        </div>
        <div class="row contactrow">
          <div class="colleft colleftrow">
            Verification Code<span
              class="required"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.verify) && model?.verify != num
              }"
              >*</span
            >
          </div>
          <div class="colright">
            <div class="verify">
              <input
                type="text"
                name="verify"
                autocomplete="off"
                id="verify"
                [(ngModel)]="model.verify"
                [ngClass]="{
                  errorline:
                    (userClicked || model?.verify) && model?.verify != num
                }"
                required
                verify
                [placeholder]=""
              />
            </div>

            <!-- <div class="row"> -->
            <div class="typecode">Type code:</div>

            <img
              id="verification_img"
              [src]="verificationimg"
              alt="verification image"
              class="verification_img"
            />
            <!-- </div> -->
          </div>
        </div>
        <div class="row contactrow tellmore">
          <div class="colleft colleftrow">Tell us more</div>
          <div class="colright">
            <textarea
              name="message"
              autocomplete="off"
              id="message"
              [(ngModel)]="model.message"
              rows="3"
              required
              placeholder=""
            ></textarea>
          </div>
        </div>

        <div class="row contactrow compact">
          <div class="colleft colleftrow"></div>
          <div class="colright">
            <div
              class="required requiredMessage"
              [ngClass]="{
                errorRequired:
                  (userClicked || model?.verify) && model?.verify != num
              }"
            >
              * Please fill in all the required fields
            </div>
          </div>
        </div>

        <div class="rowcenter">
          <button
            aria-label="Send Details"
            class="btn"
            (click)="onSubmit()"
            [ngClass]="{ invalidform: !isFormValid() }"
          >
            Send Details
          </button>
        </div>
      </div>
      <div
        class="rowspace"
        [ngClass]="{
          lastleft: section == 5
        }"
      >
        <div class="prev btnsmall noselect" (click)="prevSection()"><</div>
        <div
          *ngIf="section < 5"
          class="next btnsmall btnmore noselect"
          (click)="nextSection()"
        >
          >
        </div>
      </div>
      <div *ngIf="section < 5" class="rowend">
        <div class="progress">{{ section }} of 5</div>
      </div>
    </div>
    <div *ngIf="submitted" class="area">
      <div class="section sent">
        <div class="h4class">Enquiry Sent</div>
        <div class="row">
          <div class="row flexrow noselect">
            <span [innerHTML]="result"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-intersection></app-intersection>
</div>
