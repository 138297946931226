<div id="hiddenInstagram" class="hiddenH3div">Instagram</div>
<div id="divInstagram" class="areacontainer">
  <div class="h3class center">Instagram</div>
  <div class="area">
    <a
      href="https://www.instagram.com/africaunwind/"
      target="_blank"
      rel="noopener"
    >
      <div class="grid-container">
        <div class="grid-item item1">
          <div class="coverimg">
            <img [src]="images1" [alt]="title[0]" />
          </div>
        </div>

        <div class="grid-item item2">
          <div class="coverimg">
            <img [src]="images2" [alt]="title[1]" />
          </div>
        </div>

        <div class="grid-item item3">
          <div class="coverimg">
            <img [src]="images3" [alt]="title[2]" />
          </div>
        </div>

        <div class="grid-item item4">
          <div class="coverimg">
            <img [src]="images4" [alt]="title[3]" />
          </div>
        </div>

        <div class="grid-item item5">
          <div class="coverimg">
            <img [src]="images5" [alt]="title[4]" />
          </div>
        </div>

        <div class="grid-item item6">
          <div class="coverimg">
            <img [src]="images6" [alt]="title[5]" />
          </div>
        </div>

        <div class="grid-item item7">
          <div class="coverimg">
            <img [src]="images7" [alt]="title[6]" />
          </div>
        </div>

        <div class="grid-item item8">
          <div class="coverimg">
            <img [src]="images8" [alt]="title[7]" />
          </div>
        </div>

        <div class="grid-item item9">
          <div class="coverimg">
            <img [src]="images9" [alt]="title[8]" />
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
