import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function scrollToPlaces() {
  $(function () {

    console.log("Scroll to places");

    //$("#places").scrollIntoView();
    $([document.documentElement, document.body]).animate({
      scrollTop: $("#places").offset().top
    }, 2000);

    /*
    $('html, body').animate({
      scrollTop: $("#div1").offset().top
    }, 2000);
    */
  });
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  email: string;

  constructor() {
    this.email = "info&#64;africaunwind&#46;com";
  }

  ngOnInit(): void {
  }

  scrollToElement(): void {
    scrollToPlaces();
    //$element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
