import { Injectable } from '@angular/core';

import { TransferStateService } from '@scullyio/ng-lib';
import { ItemService } from '../service/item.service';

import { DomSanitizer } from '@angular/platform-browser';

import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';

import { Card } from '../entities/card';
import { Child } from '../entities/child';
import { Uncle } from '../entities/uncle';
import { Page } from '../entities/page';
@Injectable({
  providedIn: 'root'
})
export class UtilsService extends MetadataService {
  constructor(meta: Meta, title: Title, private transferStateService: TransferStateService, private itemService: ItemService, private sanitizer: DomSanitizer) {
    super(meta, title);

  }
  splitByEnter(str: string): string[] {
    if (!str) { return; }
    const result = [] as string[];
    const arr = str.split(/[\r\n]+/);
    for (let index = 0; index < arr.length; index++) {
      const line = arr[index];
      if (line.length > 0) { result.push(line); }
      // Finding issues with lines
      // if (line.length == 0) console.log("!");
      // console.log("^" + line + "^");
    }
    return result;
  }

  defaultImage(): string {
    return '/home.webp';
  }



  setOtherLines(strArray: string[]): string[] {
    const otherlines = [] as string[];
    for (let index = 0; index < strArray?.length; index++) {
      if (index > 0) {
        otherlines.push(strArray[index]);
      }
    }
    return otherlines;
  }

  fiximages(images: string[], foldername: string) {
    if (!images) { return; }

    var siteStatus = this.getSiteStatus();
    foldername = foldername.replace("CONTENT/", "/");

    console.log('Images: ' + images);
    for (let index = 0; index < images.length; index++) {
      if (siteStatus !== "live") {
        //Fix image paths from google (shortened, so add path)`
        images[index] = foldername + "/" + images[index];
      }


      // Add in the content base url
      images[index] = 'https://raw.githubusercontent.com/aumraath/africaunwind/main/images' + images[index];
    }
    console.log('Images: ' + images);
  }

  getArticleTitle(page: Page) {

    const card = page.card;
    const experience = page.experience;
    const country = page.country;

    /*if (lodge || folder1 || folder2 || folder3) {
      return "Why " + lodge;
    }
    else if (area) {
      return "Why " + area;
    }
    else if (country) {
      return "Why " + country;
    }
    else if (experience) {
      return "Why " + experience;
    }*/

    // TODO: MR: Untamed: Get their info here: First try to read this from the untamed info

    // ONly one level (depth)
    if (experience === '') { return 'Welcome to Africa Unwind'; }
    if (experience.toLowerCase() === 'what to expect') { return 'What To Expect On Safari?'; }
    if (experience.toLowerCase() === 'destinations') { return 'All Destinations'; }
    if (page.experience.toLowerCase() === 'trips') { return 'Our Favourite Trips'; }
    if (page.experience.toLowerCase() === 'top destinations') { return 'Top Destinations'; }
    if (page.experience.toLowerCase() === 'our story') { return page.card.h1; }
    if (page.experience.toLowerCase() === 'contact us') { return page.card.h1; }
    if (page.experience.toLowerCase() === 'search site') { return page.card.h1; }

    // More levels possible
    // First level
    if (page.depth === 1) {
      if (page.experience.toLowerCase() === 'bucket list') { return 'What\'s On The Bucket List?'; }
      if (page.experience.toLowerCase() === 'honeymoons') { return 'Why An African Honeymoon?'; }
      if (page.experience.toLowerCase() === 'family travel') { return 'Why Your Family Will Love Africa?'; }
      if (page.experience.toLowerCase() === 'blog') { return 'Another Travel Blog'; }
    }

    // Second level
    if (page.depth === 2) {
      if (page.experience.toLowerCase() === 'bucket list') { return page.card.h1; }
      if (page.experience.toLowerCase() === 'honeymoons') { return page.card.h1; }
      if (page.experience.toLowerCase() === 'family travel') { return page.card.h1; }
      if (page.experience.toLowerCase() === 'favourite') { return page.card.h1; }
    }

    // Third level
    if (page.depth === 3) {
      if (page.experience.toLowerCase() === 'favourite') { return page.card.h1; }
    }

    // Fix empty titles
    if (!card?.title) { // undefined
      // console.log('Experience: ' + experience);
      // Blog doesn't have a title, create it here
      if (experience?.toLowerCase() === 'blog') {
        if (country) {
          card.title = this.removenumbers(country, page);
        } else {
          card.title = 'Another travel blog';
        }

        return card.title;
      }
    }

    if (!card?.title) {
      // Get Title from foldername
      const fwd = card?.foldername?.lastIndexOf('/');
      if (fwd > -1) {
        const newPath = card.foldername.slice(0, fwd);
        card.title = this.removenumbers(newPath, page);
      }
    }

    if (card?.title?.toLowerCase() === 'safaris') {
      return '6 Reasons To Go On Safari';
    }

    if (card?.title?.toLowerCase() === 'islands') {
      return '6 Reasons To Visit An Island';
    }

    if (card?.title?.toLowerCase() === 'cities') {
      return '6 Reasons To Visit A City';
    }

    const title = this.getWhyTitle(card?.title);
    let additional = '';
    /* Handle special cases */
    if (title?.toLowerCase().indexOf('wine route') > -1) {
      additional = 'The ';
    }

    return 'Why ' + additional + title + '?';
  }

  removenumbers(text: string, page: Page): string {

    if (page.experience?.toLowerCase() === 'blog') {
      return text;
    }

    if (text.toLowerCase().indexOf('6 reasons') === 0) { return text; }

    if ((text.indexOf('1') === 0)
      || (text.indexOf('2') === 0)
      || (text.indexOf('3') === 0)
      || (text.indexOf('4') === 0)
      || (text.indexOf('5') === 0)
      || (text.indexOf('6') === 0)
      || (text.indexOf('7') === 0)
      || (text.indexOf('8') === 0)
      || (text.indexOf('9') === 0)
      || (text.indexOf('0') === 0)) {
      return text.substr(1);
    }

    return text;
  }

  getWhyTitle(text: string): string {
    if (!text) { return; }

    // Fix some stuff, eg. spelling - rather to happen in the BE, but here we are :-)
    if (text === 'ACTIVITTIES') { text = 'ACTIVITIES'; }

    return text
      .split('amp;').join('');
  }

  createAltArray(card: Card): string[] {
    const altArray = [] as string[];
    altArray.push(card.alt1);
    altArray.push(card.alt2);
    altArray.push(card.alt3);
    altArray.push(card.alt4);
    altArray.push(card.alt5);
    altArray.push(card.alt6);
    altArray.push(card.alt7);
    altArray.push(card.alt8);
    // console.log("ALT array: " + altArray);
    return altArray;
  }

  isTopExperiences(experience: string): boolean {
    if (experience?.toLowerCase() === 'bucket list' ||
      experience?.toLowerCase() === 'honeymoons' ||
      experience?.toLowerCase() === 'family travel') {
      return true;
    }
    return false;
  }

  fixSiblingTitles(page: Page) {
    const card = page.card;
    for (let index = 1; index < card.parent.siblings.length; index++) {
      const sibling = card.parent.siblings[index];

      if (!sibling || !sibling?.title) {
        // Get Title from foldername
        const fwd = sibling.foldername.lastIndexOf('/');
        if (fwd > -1) {
          const newPath = sibling.foldername.slice(fwd + 1);
          sibling.title = this.removenumbers(newPath, page);
        }
      }

      /*

      if (!sibling?.title) {
        //Get Title from foldername
        var fwd = sibling.foldername.lastIndexOf('/');
        if (fwd > -1) {
          var newPath = sibling.foldername.slice(0, fwd);
          this.parent.siblings[index].title = this.removenumbers(newPath, page);
        }
      }

      */
    }

    // console.log("Experience siblings: " + this.parent.siblings);
    // for (let index = 0; index < this.card.images.length; index++) {
    // Add in the content base url
    // this.card.images[index] = 'https://raw.githubusercontent.com/aumraath/africaunwind/main/images' + '/' + this.card.images[index];
    //  }

  }

  fixUncles(depth: number, experience: string, uncles: Uncle[]): Uncle[] {
    // Used to eg. remove blog, experiences, etc from islands, cities, safaris
    // console.log("depth " + depth + " experience " + experience);

    if (depth === 2) {
      switch (experience) {
        case 'safaris':
        case 'islands':
        case 'cities':
          const trimmedUncles = [] as Uncle[];
          // Remove all but above three
          for (let index = 1; index < uncles.length; index++) {
            const uncle = uncles[index];
            // console.log("uncle " + uncle.title);
            if (uncle.title.toLowerCase() === 'safaris'
              || uncle.title.toLowerCase() === 'islands'
              || uncle.title.toLowerCase() === 'cities') {
              trimmedUncles.push(uncle);
            }
          }
          uncles = trimmedUncles;
          break;
        default:
          uncles = []; // blank for the rest
      }
    }
    return uncles;
  }

  getCardsHeadings(page: Page): string {

    const experience = page.experience;
    const country = page.country;
    const area = page.area;
    const lodge = page.lodge;
    const folder1 = page.folder1;
    const folder2 = page.folder2;
    const folder3 = page.folder3;

    // Level two
    if (page.depth === 2) {
      if (page.experience.toLowerCase() === 'favourite') { return 'Trips'; }
    }

    if (experience?.toLowerCase() === 'blog') {
      return 'Travel Journal';
    }

    if (!lodge && (area === 'eastern cape' || area === 'kruger national park') && country === 'south africa' && experience === 'safaris') {
      return 'Reserves';
    }

    if (experience === 'islands') {
      if (country) {
        return 'Lodges';
      }
      else if (experience) {
        return 'Islands';
      }
    }

    if (experience === 'cities') {
      if (country) {
        return 'Lodges';
      }
      else if (experience) {
        return 'Cities';
      }
    }

    if (lodge || folder1 || folder2 || folder3) {
      return 'Lodges';
    }
    else if (area) {
      return 'Lodges';
    }
    else if (country) {
      if (this.isTopExperiences(experience)) {
        return 'Where Can I Do This?';
      }
      return 'Areas';
    }
    else if (experience) {
      if (this.isTopExperiences(experience)) {
        return 'Experiences';
      }
      return 'Countries';
    }
  }

  getCards(card: Card) {
    if (card?.children?.length > 0) {
      return card.children;
    }
    else {
      return card?.parent?.siblings;
    }
  }

  fixCardsOrder(cards: Child[]): Child[] {
    if (cards) {

      // Replace eg "5 December 2022" created date with "20221205"
      for (let index = 0; index < cards.length; index++) {
        cards[index].created = this.getNewCreatedDate(cards[index].created);
        // console.log(">> " + cards[index].foldername + " | " + cards[index].created);
      }

      // sort by created date descending
      cards.sort((a, b) => (a.created <= b.created ? 1 : -1));

      // Remove the "hide" cards
      cards = cards.filter(item => item.created !== 'hide');
      // Remove the cards not yet beyong BLOG as a title (not done yet)
      // cards = cards.filter(item => item.title);
    }
    return cards;
  }

  removeCardsWithBLOGTitle(cards: Child[]): Child[] {
    if (cards) {
      // Remove the cards not yet beyond BLOG as a title (not done yet)
      cards = cards.filter(item => item.title.toLowerCase().indexOf('blog') <= -1);
    }
    return cards;
  }

  getNewCreatedDate(created: string): string {
    if (created !== '' && created !== undefined && created !== 'hide') {
      const dateparts = created.split(' ');
      let day = dateparts[0];
      if (day.length === 1) { day = '0' + day; } // Add preceding 0 for days
      return dateparts[2] + this.getMonth(dateparts[1]) + day;
    }
    return created;
  }

  getMonth(dateString: string): string {
    switch (dateString?.toLowerCase()) {
      case 'january': return '01';
      case 'february': return '02';
      case 'march': return '03';
      case 'april': return '04';
      case 'may': return '05';
      case 'june': return '06';
      case 'july': return '07';
      case 'august': return '08';
      case 'september': return '09';
      case 'october': return '10';
      case 'november': return '11';
      case 'december': return '12';
    }
  }

  fixCardsTitles(cards: Child[], uncles: Uncle[], parent: Child, page: Page) {
    if (cards) {
      const newcards = [] as Child[];

      for (let index = 0; index < cards.length; index++) {
        if (cards[index].image) {
          this.fixCardTitle(cards[index], page);
          newcards.push(cards[index]);
        }
        // console.log(newcards[index].title);
      }
    }

    if (uncles) {
      for (let index = 0; index < uncles.length; index++) {
        this.fixCardTitle(uncles[index], page);
      }
    }

    if (parent) { this.fixCardTitle(parent, page); }
  }

  fixCardsDefaultImage(cards: Child[]) {
    if (cards) {
      const newcards = [] as Child[];

      for (let index = 0; index < cards.length; index++) {
        if (cards[index].image === '' || cards[index].image === undefined) {
          cards[index].image = this.defaultImage();
          newcards.push(cards[index]);
        }
      }
      cards = newcards;
    }
  }

  fixCardTitle(item: Child, page: Page): void {
    // If no title, get it from the foldername
    if (!item) { return; }
    // Fix title
    if (!item.title) { item.title = item.h1; }
    if (!item.title) { item.title = this.removenumbers(/[^/]*$/.exec(item.foldername)[0], page); }

    if (page.experience === 'blog' && page.country === 'all things africa') {
      if (item.title === 'The Kruger 10 Ways') {
        item.title = 'The Kruger: Ten Ways';
      }
    }
  }

  getPrefixUrl(path): string {
    if (path === '') { return '/'; }

    return '/' + path + '/';
  }

  getAltText(altArray: string[], i: number): string {
    if (altArray && altArray.length < i) {
      return altArray[i];
    }

    return '';
  }

  containsZero(cardtitle: string): boolean {
    if (!cardtitle) { return; }
    return (cardtitle.indexOf('0') > -1);
  }

  isAllUppercase(str: string): boolean {
    return this.getWhyTitle(str)?.toUpperCase() === this.getWhyTitle(str);
  }

  startWithTime(str: string): boolean {
    const re = new RegExp('[0-9][0-9][hH][0-9][0-9]:');
    const found = re.test(str);
    return found;
  }

  startWithUSD(str: string): boolean {
    const found = str.indexOf('USD $');
    return found === 0;
  }

  startWithDay(str: string): boolean {
    const found = str.toLocaleLowerCase().indexOf('day ');
    return found === 0;
  }

  getSiteStatus(): string {
    const fullUrl = window.location.href;
    if (fullUrl.indexOf('www.africaunwind.com') >= 0) {
      return "live";
    } else if (fullUrl.indexOf('test.africaunwind.com') >= 0) {
      return "test";
    }
    return "local";
  }

  findCard(page: Page): void {
    //Set a default image
    //page.card = new Card();
    //page.card.images.push("https://raw.githubusercontent.com/aumraath/africaunwind/main/images/spacer.webp");

    console.log('getting card content');
    const siteStatus = this.getSiteStatus();
    console.log('Site status: ' + siteStatus);

    //http://localhost:8000/findtxtfile.php?basePath=content&partialSearch=safaris

    // Get the path
    page.path = this.getPath(page);
    page.depth = this.getPageDepth(page);

    //let card$ = this.itemService.findContentFromTxtFile(siteStatus, page.path); // TODO: MR: Might need to clean this up like below
    let card$ = this.itemService.findContentFromGoogleDrive(siteStatus, page.path); // TODO: MR: Might need to clean this up like below

    const find = (page.path === '') ? '' : this.removeOther(this.removeApostrophe(page.path));

    // TODO: MR: Maybe don't use this anymore, definitely not for test site
    if (siteStatus == "live") {
      card$ = this.transferStateService.useScullyTransferState(
        'card',
        this.itemService.findAllContent(find)
      );
    }

    card$.subscribe(
      (res: Card) => {
        // UI elements
        page.card = res;

        // console.log("Got card");
        if (res.other) { page.other = this.splitByEnter(res.other); }
        page.otherlines = this.setOtherLines(page.other);

        // TODO: MR: REMOVE THIS!!!!!!!!


        page.why = this.splitByEnter(page.card.why);
        page.reason = this.splitByEnter(page.card.reason);
        page.exp = this.splitByEnter(page.card.experience);
        page.stay = this.splitByEnter(page.card.stay);
        page.act = this.splitByEnter(page.card.activities);
        page.when = this.splitByEnter(page.card.when);

        page.summary = this.splitByEnter(page.card.summary);
        page.overview = this.splitByEnter(page.card.overview);
        page.inclusions = this.splitByEnter(page.card.inclusions);
        page.exclusions = this.splitByEnter(page.card.exclusions);
        // page.lodges = this.splitByEnter(page.card.lodges.replace("?\r\n", ""));

        /*
        // If NO images on card, add a default image
        if (page.card?.images == null || page.card.images.length <= 0) {
          if (page.card?.images === undefined || page.card?.images === null) {
            page.card.images = [this.defaultImage()];
          } else {
            page.card.images.push(this.defaultImage());
          }
        }
        */

        // FIX A FEW THINGS HERE
        this.fiximages(page.card.images, page.card.foldername);

        //If NO image add a spacer for now
        if (page.card.images.length <= 0) {
          page.card.images.push("https://raw.githubusercontent.com/aumraath/africaunwind/main/images/spacer.webp");
        }

        // Write out all CURRENT meta values
        /*
        console.log('Current meta values');
        console.log('h1: ' + page.card.h1);
        console.log('h2: ' + page.card.h2);
        console.log('h3: ' + page.card.h3);
        console.log('pageTitle: ' + page.card.pageTitle);
        console.log('alt1: ' + page.card.alt1);
        console.log('alt2: ' + page.card.alt2);
        console.log('alt3: ' + page.card.alt3);
        console.log('alt4: ' + page.card.alt4);
        console.log('alt5: ' + page.card.alt5);
        console.log('alt6: ' + page.card.alt6);
        console.log('alt7: ' + page.card.alt7);
        console.log('alt8: ' + page.card.alt8);
        console.log('meta: ' + page.card.meta);
        console.log('html: ' + page.card.html);
        console.log('category: ' + page.card.category);
        console.log('youtube: ' + page.card.youtube);
        */


        if (page.card.h1 === 'h1') { page.card.h1 = this.fixTitle(this.cleanTitle(this.fixDefaultPageTitle(page), page)).replace(/(^\w|\s\w)/g, m => m.toUpperCase()); }
        if (page.card.h2 === 'h2') { page.card.h2 = this.cleanTitle(this.getSubtitle(page), page); }
        if (page.card.h3 === 'h3') { page.card.h3 = this.fixTitle(this.cleanTitle(this.getArticleTitle(page), page)); }
        if (page.card.pageTitle === 'pagetitle') { page.card.pageTitle = this.getPageTitle(page); }

        if (page.card.alt1 === 'alt1') { page.card.alt1 = this.getAltTextFromImage(page, 0); }
        if (page.card.alt2 === 'alt2') { page.card.alt2 = this.getAltTextFromImage(page, 1); }
        if (page.card.alt3 === 'alt3') { page.card.alt3 = this.getAltTextFromImage(page, 2); }
        if (page.card.alt4 === 'alt4') { page.card.alt4 = this.getAltTextFromImage(page, 3); }
        if (page.card.alt5 === 'alt5') { page.card.alt5 = this.getAltTextFromImage(page, 4); }
        if (page.card.alt6 === 'alt6') { page.card.alt6 = this.getAltTextFromImage(page, 5); }
        if (page.card.alt7 === 'alt7') { page.card.alt7 = this.getAltTextFromImage(page, 6); }
        if (page.card.alt8 === 'alt8') { page.card.alt8 = this.getAltTextFromImage(page, 7); }

        page.altArray = this.createAltArray(page.card);
        // console.log("ALT array: " + page.altArray);

        if (page.card.meta === 'meta') { page.card.meta = this.shortenText(this.getMetaDescription(page), 159); }

        if (page.card.url === 'url') { page.card.url = page.routerUrl; }
        // if (page.card.related == "related") page.card.related = xxxxxx;
        if (page.card.keyword === 'keyword') { page.card.keyword = 'Big Five Safari'; }
        if (page.card.author === 'author') { page.card.author = 'Africa Unwind'; }
        if (page.card.created === 'date') { page.card.created = new Date().toLocaleDateString('en-ZA'); }
        if (page.card.modified === 'date') { page.card.modified = new Date().toLocaleDateString('en-ZA'); }
        if (page.card.html === 'html') { page.card.html = ''; }

        if (page.card.category === '' || page.card.category === undefined) { page.card.category = ''; }
        if (page.card.youtube === '' || page.card.youtube === undefined) { page.card.youtube = ''; }

        if (!page.image || page.image === 'image') { page.image = this.getPageImage(page); }

        // Write out all NEW meta values
        /*
        console.log('NEW meta values');
        console.log('h1: ' + page.card.h1);
        console.log('h2: ' + page.card.h2);
        console.log('h3: ' + page.card.h3);
        console.log('pageTitle: ' + page.card.pageTitle);
        console.log('alt1: ' + page.card.alt1);
        console.log('alt2: ' + page.card.alt2);
        console.log('alt3: ' + page.card.alt3);
        console.log('alt4: ' + page.card.alt4);
        console.log('alt5: ' + page.card.alt5);
        console.log('alt6: ' + page.card.alt6);
        console.log('alt7: ' + page.card.alt7);
        console.log('alt8: ' + page.card.alt8);
        console.log('meta: ' + page.card.meta);
        console.log('html: ' + page.card.html);
        console.log('category: ' + page.card.category);
        console.log('youtube: ' + page.card.youtube);
        console.log('Children: ' + page.card.children);
        console.log('Parent: ' + page.card.parent.siblings.length + ' siblings');
        */


        console.log('Uncles: ' + page.card?.grandparent?.uncles?.length);


        /*
        //Get latlong
        if (page.card.level > 1) {
          var address = page.country;
          if (page.area) address = page.area + ", " + address;
          if (page.lodge) address = page.lodge + ", " + address;
          if (page.folder1) address = page.folder1 + ", " + address;
          if (page.folder2) address = page.folder2 + ", " + address;
          if (page.folder3) address = page.folder3 + ", " + address;
          var position = -5;
          do {
            if (position != -5) { //Force skip first time
              address = address.substr(position + 2);
            }
            this.latlong = this.itemService.getLatLongValues(address);
            position = address.indexOf(', ');
          } while (this.latlong === undefined && position > -1);
        }
        */

        // TODO: MR: From here we need to fix some things again...
        // For Experiences, fix simbling titles
        if (this.isTopExperiences(page.experience)) {
          this.fixSiblingTitles(page);
        }

        // Breadcrumb stuff
        page.parent = page.card.parent;
        page.uncles = page.card.grandparent?.uncles;
        page.cardsHeadings = this.getCardsHeadings(page);
        page.cards = this.getCards(page.card);
        page.cards = this.fixCardsOrder(page.cards);

        /*
        for (var i = 0; i < page.cards.length; i++) {
          console.log("CARD IMG " + page.cards[i].image);
        }
        */

        this.fixCardsDefaultImage(page.cards);
        this.fixCardsTitles(page.cards, page.uncles, page.parent, page);

        page.cards = this.removeCardsWithBLOGTitle(page.cards);

        page.prefixUrl = this.getPrefixUrl(page.path);

        // Add more tags
        if (page.depth === 1) { // TODO: MR: UNTAMED, if it should be on sub pages remove this if
          if (page.experience.toLowerCase() === 'safaris' || page.experience.toLowerCase() === 'family travel') {
            this.callAddGoogleSafariFamilyTag(page);
          }
        }


        // SEO Meta tags to follow
        this.updateTags(page);
      },
      (err) => {
        page.error = err;
      }
    );
  }

  cleanUrl(url: string): string {
    //removeApostrophe???? as more ideas?
    return url.split(' ').join('%20')
      .split("'").join('%27');
  }

  findChildren(page: Page): void {
    console.log('getting children');
    const siteStatus = this.getSiteStatus();

    if (siteStatus !== "live") {
      //Call the children while the rest is loading
      let children$ = this.itemService.findChildrenFromGoogleDrive(siteStatus, page.card.folderid);
      children$.subscribe(
        (res: Child[]) => {
          page.card.children = res;
          //Alter folder (url) and image
          if (siteStatus !== "live") {
            for (let index = 0; index < page.card.children.length; index++) {
              var foldername = page.card.children[index].foldername.replace("CONTENT/", "");
              //https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Uganda/Murchison%20Falls%20National%20Park/Baker's%20Lodge/1Baker'sLodge.webp"

              var image = foldername + "/" + page.card.children[index].image;
              page.card.children[index].image = this.cleanUrl(image);
              foldername = "./" + foldername;
              page.card.children[index].foldername = foldername;
            }
          }
          page.cards = this.getCards(page.card);
        },
        (err) => {
          page.error = err;
        });
    }
  }

  isSpecialPages(page: Page): boolean {
    // First level
    if (page.depth === 1) {
      if (page.experience.toLowerCase() === 'safaris' ||
        page.experience.toLowerCase() === 'islands' ||
        page.experience.toLowerCase() === 'cities' ||
        page.experience.toLowerCase() === 'honeymoons' ||
        page.experience.toLowerCase() === 'family travel') { return true; }

      return false;
    }
  }

  addGoogleConvertionTag() {
    // this.addMetaGoogleConvertionTag();
  }

  callAddGoogleSafariFamilyTag(page: Page) {
    this.addGoogleSafariFamilyTag(page);
  }

  getPageTitle(page: Page): string {
    if (page.card.h1.toLowerCase() === 'africa unwind') { return page.card.h1; }
    return page.card.h1 + ' | Africa Unwind';
  }

  shortenText(text: string, n: number): string {
    if (text.length > n) {
      console.log('Text shortened');
      return text.substring(0, n - 1);
    }
    return text;
  }

  removeApostrophe(text: string): string { // Used for cleanUrl
    if (!text) { return; }
    return text
      .toLowerCase()
      .split('\'').join('')
      .split('’').join('')
      .split('%27').join('')
      .split('\'').join('')
      .split('`').join('');
  }

  removeOther(text: string): string { // Used for cleanUrl
    if (!text) { return; }
    return text
      .toLowerCase()
      .split('& ').join('')
      .split('+ ').join('')
      .split('(').join('')
      .split(')').join('');
  }

  cleanTitle(text: string, page: Page): string { // Used for H1 and H3
    if (!text) { return; }
    text = text
      .toLowerCase()
      .split(':').join(' -')
      // .split("0").join("o")
      .split(' water based').join(' Water-Based')
      .split('malaria free').join('malaria-free')
      .split('family friendly').join('family-friendly')
      .split('amp;').join('');
    text = this.removenumbers(text, page);
    text = text.replace('è', 'e');
    text = text.replace('  ', ' '); // remove double space
    return text;
  }

  fixTitle(text: string): string { // Used for H1 and H3
    if (!text) { return; }
    return text.split('1920’S').join('1920’s');
    // return text;
  }

  fixDefaultPageTitle(page: Page): string {
    // Those with only one (flat level)
    if (page.experience.toLowerCase() === 'trips') { return 'Our Favourite Trips'; }

    // Those with more...
    // First level
    if (page.depth === 1) {
      if (page.experience.toLowerCase() === 'bucket list') { return 'The Bucket List'; }
      if (page.experience.toLowerCase() === 'blog') { return 'Another Travel Blog'; }
      if (page.experience.toLowerCase() === 'our story') { return 'Our Story'; }
      if (page.experience.toLowerCase() === 'contact us') { return 'Contact Us'; }
      if (page.experience.toLowerCase() === 'search site') { return 'Search Site'; }
      if (page.experience.toLowerCase() === 'destinations') { return 'All Destinations'; }
    }

    if (page.card.title) {
      return page.card.title; // TODO: MR: Might need more stuff here to make it look better
    }

    if (page.folder3) { return page.folder3; }
    if (page.folder2) { return page.folder2; }
    if (page.folder1) { return page.folder1; }
    if (page.lodge) { return page.lodge; }
    if (page.area) { return page.area; }
    if (page.country) { return page.country; }
    if (page.experience) { return page.experience; }
    return 'Africa Unwind';
  }

  getSubtitle(page: Page): string {
    // console.log("Page Experience: " + page.experience);

    // One level only (depth)
    if (page.experience === '') { return 'Your African Safari Experts'; }
    if (page.experience.toLowerCase() === 'our story') { return 'UNWIND WITH US'; }
    if (page.experience.toLowerCase() === 'contact us') { return 'UNWIND WITH US'; }
    if (page.experience.toLowerCase() === 'search site') { return 'UNWIND WITH US'; }

    // If more than one level
    // First level
    if (page.depth === 2) {
      if (page.experience.toLowerCase() === 'bucket list') { return 'BUCKET LIST EXPERIENCES'; }
      if (page.experience.toLowerCase() === 'honeymoons') { return 'HONEYMOON EXPERIENCES'; }
      if (page.experience.toLowerCase() === 'family travel') { return 'FAMILY TRAVEL EXPERIENCES'; }
      if (page.experience.toLowerCase() === 'favourite') { return 'OUR FAVOURITE TRIPS'; }
      if (page.experience.toLowerCase() === 'blog') { return 'BECAUSE TRAVEL IS EVERYTHING'; }
    }

    // TODO: MR: Untamed: Get their info here: Maybe also add this to Untamed doc and read from there

    // Fix "entebbe kampala"
    if (page.country && page.country.toLowerCase() === 'entebbe kampala') {
      page.country = 'entebbe & kampala';
    }

    if (page.folder1 || page.folder2 || page.folder3) {
      return page.lodge + ', ' + page.country;
    }
    else if (page.lodge) {
      return page.area + ', ' + page.country;
    }
    else if (page.area) {
      // From cities, if we go to a lodge, we don't know the country...
      // herewith some some logic to fix that

      let computedCountry = '';
      const southafrica = ['cape town', 'durban', 'johannesburg', 'wine route', 'whale route', 'garden route', 'panorama route'];
      if (southafrica.indexOf(page.country.toLowerCase()) > -1) {
        computedCountry = 'south africa';
      }
      else if (page.country.toLowerCase() === 'arusha') {
        computedCountry = 'tanzania';
      }
      else if (page.country.toLowerCase() === 'entebbe & kampala') {
        computedCountry = 'uganda';
      }
      else if (page.country.toLowerCase() === 'kigali') {
        computedCountry = 'rwanda';
      }
      else if (page.country.toLowerCase() === 'nairobi') {
        computedCountry = 'kenya';
      }
      else if (page.country.toLowerCase() === 'lilongwe') {
        computedCountry = 'malawi';
      }
      else if (page.country.toLowerCase() === 'victoria falls') {
        computedCountry = 'zimbabwe';
      }
      else if (page.country.toLowerCase() === 'livingstone') {
        computedCountry = 'zambia';
      }
      else if (page.country.toLowerCase() === 'windhoek') {
        computedCountry = 'namibia';
      }

      if (computedCountry) {
        return page.country + ', ' + computedCountry;
      }
      else {
        return page.country;
      }
    }
    else if (page.country) {
      return page.experience;
    }
    else if (page.experience) {
      if (page.experience.toLowerCase() === 'blog') {
        return 'Because Travel Is Everything';
      }
      else {
        return 'UNWIND WITH US';
      }
    }
  }

  getAltTextFromImage(page: Page, i: number): string {
    const image = page.card.images[i];
    let altname = '';

    if (image) {
      const lastSlash = image.lastIndexOf('/');
      if (lastSlash <= 0) {
        altname = image;
      } else {
        altname = image.substr(lastSlash + 1);
      }
    }

    // remove some strings
    altname = altname.replace('.webp', '');
    altname = altname.replace('-20w', '');
    altname = altname.replace('-200w', '');
    altname = altname.replace('-sm', '');
    altname = altname.replace('-md', '');
    altname = altname.replace('-lg', '');
    altname = altname.replace('-200w.webp', '');

    return altname;
  }

  getMetaDescription(page: Page): string {
    if (page.card?.why) {
      return page.card?.why?.split('’').join('\'').split('&amp;').join('and').split('amp;').join('').replace(/\r?\n|\r/g, '. ');
    } // .replace(/[^a-zA-Z0-9 ]/g, "")

    if (page.card?.reason) {
      return page.card?.reason?.split('’').join('\'').split('&amp;').join('and').split('amp;').join('').replace(/\r?\n|\r/g, '. ');
    } // .replace(/[^a-zA-Z0-9 ]/g, "")

    if (page.card?.other) {
      return page.card?.other?.split('’').join('\'').split('&amp;').join('and').split('amp;').join('').replace(/\r?\n|\r/g, '. ');
    } // .replace(/[^a-zA-Z0-9 ]/g, "")

    return 'Tailor-made luxury safaris for families, couples and wildlife enthusiasts. We offer inclusive safari experiences ranging from secluded luxury lodges to those located in the heart of the action. Check availability.';

  }

  getPageImage(page: Page): string {
    if (page?.card?.images) { return page?.card?.images[0]; }
    return 'https://raw.githubusercontent.com/aumraath/africaunwind/main/images/logo-200w.webp'; // return the logo if nothing else
  }

  getPath(page: Page): string {
    if (page.folder3) {
      return page.experience + '/' + page.country + '/' + page.area + '/' + page.lodge + '/' + page.folder1 + '/' + page.folder2 + '/' + page.folder3;
    }
    else if (page.folder2) {
      return page.experience + '/' + page.country + '/' + page.area + '/' + page.lodge + '/' + page.folder1 + '/' + page.folder2;
    }
    else if (page.folder1) {
      return page.experience + '/' + page.country + '/' + page.area + '/' + page.lodge + '/' + page.folder1;
    }
    else if (page.lodge) {
      return page.experience + '/' + page.country + '/' + page.area + '/' + page.lodge;
    }
    else if (page.area) {
      return page.experience + '/' + page.country + '/' + page.area;
    }
    else if (page.country) {
      return page.experience + '/' + page.country;
    }
    else if (page.experience) {
      return page.experience;
    }
    else {
      return '';
    }
  }

  getPageDepth(page: Page): number {
    if (page.folder3) { return 7; }
    if (page.folder2) { return 6; }
    if (page.folder1) { return 5; }
    if (page.lodge) { return 4; }
    if (page.area) { return 3; }
    if (page.country) { return 2; }
    if (page.experience) { return 1; }
    return 0;
  }

}
