import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divSteps").width() - 4; //25px padding on both sides

    while ($("#hiddenSteps").width() > mainwidth) {
      var hiddenWidth = $("#hiddenSteps").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenSteps").css("font-size"));
      var h3Size = $("#divSteps").find(".h3class").css("font-size");
      $("#hiddenSteps").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenSteps").css("font-size");
    $("#divSteps").find(".h3class").css("font-size", finalfontsize);
  });
}

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //ResizeHeaders();
  }

}
