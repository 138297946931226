import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[highlight]'
})
export class HighlightDirective implements AfterViewInit {
  @Input('highlight') str: string;
  @Input() className: string = 'highlight';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const regex = new RegExp(this.str, 'gi');
    const text = this.el.nativeElement.innerHTML;
    this.el.nativeElement.innerHTML = text.replace(regex, (match) => {
      return `<span class="${this.className}">${match}</span>`;
    });
  }
}
