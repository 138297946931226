import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divInstagram").width() - 52; //25px padding on both sides

    while ($("#hiddenInstagram").width() > mainwidth) {
      var hiddenWidth = $("#hiddenInstagram").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenInstagram").css("font-size"));
      var h3Size = $("#divInstagram").find(".h3class").css("font-size");
      $("#hiddenInstagram").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenInstagram").css("font-size");
    $("#divInstagram").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divInstagram").find(".h3class").css("padding-top", 15);
    }
  });
}

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})
export class InstagramComponent implements OnInit {

  images1: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/1Insta-sm.webp";
  images2: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/2Insta-sm.webp";
  images3: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/3Insta-sm.webp";
  images4: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/4Insta-sm.webp";
  images5: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/5Insta-sm.webp";
  images6: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/6Insta-sm.webp";
  images7: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/7Insta-sm.webp";
  images8: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/8Insta-sm.webp";
  images9: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/INSTAGRAM/9Insta-sm.webp";

  title: string[] = [
    "tree-house-safari",
    "outside-bath-safari",
    "island",
    "lion-safari",
    "cape-town-south-africa",
    "hot-air-balloon",
    "gorilla-trekking-safari",
    "tented-safari",
    "tented-outdoor-sala-safari"
  ]

  constructor() { }

  ngOnInit(): void {
    //ResizeHeaders();
    ApplySafariHack();
  }

}
