import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

//Instead of using this.domSanitizer.bypassSecurityTrustResourceUrl(url), it is recommended to use this.domSanitizer.sanitize(SecurityContext.URL, url)

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
