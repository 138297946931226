import { Component, OnInit, HostListener } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactService } from '../service/contact.service';
import { ActivatedRoute } from '@angular/router';
import { Contact } from '../entities/contact';
import { UtilsService } from '../service/utils.service';
import { Page } from '../entities/page';
import { Card } from '../entities/card';

@Component({
  selector: 'app-thankyouenquiry',
  templateUrl: './thankyouenquiry.component.html',
  styleUrls: ['./thankyouenquiry.component.css']
})
export class ThankYouEnquiryComponent extends MetadataService implements OnInit {

  image: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Contact%20Us/1ContactUs.webp";
  public innerWidth: any;
  result: string = "";
  page: Page = new Page;
  name: string;
  email: string;

  constructor(meta: Meta, title: Title, private router: Router, private contactService: ContactService, private utils: UtilsService, private route: ActivatedRoute) {
    super(meta, title);


    //this.page.routerUrl = this.router.url;
    //this.page.experience = "contact us";
    //this.page.card = new Card();
    //this.page.card.h1 = "Contact Us";
    //this.page.isSerengeti = false;
    //this.utils.findCard(this.page);

    //this.utils.addGoogleConvertionTag();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.route.queryParams
      .subscribe(params => {
        this.name = params.name;
        this.email = params.email;
      }
      );
    this.result = this.getThanks();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getThanks() {
    return "Hi " + this.name + ",<br><br>Welcome to Africa Unwind &amp; thank you for reaching out!<br><br>We are so excited to hear from you &amp; will revert back with a (human) response as soon as possible. During business hours that’s usually within 24 hours.<br><br>In the meanwhile, please feel free to browse our website at <a href='https://africaunwind.com'>www.africaunwind.com</a> or visit us on <a href='https://www.instagram.com/africaunwind/ '>Instagram</a> or <a href='https://www.facebook.com/africaunwind'>Facebook</a>. You can also reach us on +27 21 300 1579.<br><br>We can’t wait to start planning the holiday of your dreams!<br><br>Come to Africa...&amp; Unwind.<br><br>A copy of this message has also been sent to: " + this.email;
  }

  getimage(): string {
    var result = this.image.replace('.jpg', '-20w.webp');
    result = result.replace('.jpeg', '-20w.webp');
    result = result.replace('.png', '-20w.webp');
    result = result.replace('.webp', '-20w.webp'); //TODO: MR: Ensure this is correct

    result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?!

    let re = /\`/gi;
    result = result.replace(re, "\'");

    return result;
  }
}
