import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Contact } from '../../app/entities/contact';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  ServerUrl = 'https://services.africaunwind.com'; //TODO: MR: FIX!!
  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Referrer-Policy': 'no-referrer' })
  };

  constructor(private http: HttpClient) { }

  contactForm(formdata: Contact) { // Observable<string>  {
    return this.http.post<Contact>(this.ServerUrl + '/contact.php', formdata, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  contact2Form(formdata: Contact, num: number): Observable<string> {
    return this.http.get(`${this.ServerUrl}/contact2.php?name=${formdata.name}&surname=${formdata.surname}&email=${formdata.email}&phone=${formdata.phone}&message=${formdata.message}&verify=${formdata.verify}&num=${num}&destinations=${formdata.destinations}&adults=${formdata.adults}&children=${formdata.children}&notsurepeople=${formdata.notsurepeople}&arrival=${formdata.arrival}&departure=${formdata.departure}&unsuretravel=${formdata.unsuretravel}&travelstyle=${formdata.travelstyle}`).pipe(
      map((res) => {
        return res['data'];
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'The contact form was not submitted.',
      errorDesc: 'Please try again later.'
    };
    return throwError(this.errorData);
  }
}
