<div *ngIf="page.card" class="experiencecontainer" id="cardapp">
  <div *ngIf="hasCalledResize()" id="hiddenCard" class="hiddenH3div" > <!--[ngClass]="{ allThingsAfrica: isPageBlogAllThingsAfrica() }"-->
    {{ page.card.h3 | titlecase }}
  </div>

  <div class="area">
    <app-header
      *ngIf="page.card?.images && page.card?.images.length > 0"
      [title]="page.card.h1"
      [presubtitle]="getPreSubtitle()"
      [subtitle]="page.card.h2"
      [image]="page.card?.images[0]"
      [altText]="page.card?.alt1"
    ></app-header>
    <!--fixTitle(this.cleanTitle(card?.title))-->
    <!--getSubtitle()-->

    <div class="btncontainer t-b20">
      <a href="/contact-us">
        <button aria-label="Get in touch" class="btn">Get in Touch</button>
      </a>
    </div>
    <div class="btncontainer t-b10">
      <button
        aria-label="Down arrow"
        class="btn downarrow"
        onClick="document.getElementById('article').scrollIntoView();"
      ></button>
    </div>

    <span class="container">
      <div class="why" *ngIf="page.why">
        <div class="h5class white">{{getHighlightsTitle()}}</div>
        <ol class="ol">
          <li *ngIf="page.why[0]">
            <span class="text" [innerHTML]="page.why[0]"></span>
          </li>
          <li *ngIf="page.why[1]">
            <span class="text" [innerHTML]="page.why[1]"></span>
          </li>
          <li *ngIf="page.why[2]">
            <span class="text" [innerHTML]="page.why[2]"></span>
          </li>
          <li *ngIf="page.why[3]">
            <span class="text" [innerHTML]="page.why[3]"></span>
          </li>
          <li *ngIf="page.why[4]">
            <span class="text" [innerHTML]="page.why[4]"></span>
          </li>
          <li *ngIf="page.why[5]">
            <span class="text" [innerHTML]="page.why[5]"></span>
          </li>
          <li *ngIf="page.why[6]">
            <span class="text" [innerHTML]="page.why[6]"></span>
          </li>
          <li *ngIf="page.why[7]">
            <span class="text" [innerHTML]="page.why[7]"></span>
          </li>
          <li *ngIf="page.why[8]">
            <span class="text" [innerHTML]="page.why[8]"></span>
          </li>
          <li *ngIf="page.why[9]">
            <span class="text" [innerHTML]="page.why[9]"></span>
          </li>
        </ol>
      </div>

    <!-- Article -->
      <span class="p1280 abouttext" [ngClass]="{ serengeti: page.isSerengeti }">
        <div id="article" name="article" class="p100 flexmt mb-70">
          <div id="divCard" class="left">
            <article>
              <span class="abouttext left dark">

                <!--Header-->
                <h3 class="center black"> <!--[ngClass]="{ allThingsAfrica: isPageBlogAllThingsAfrica() }"--> <!--*ngIf="!utils.containsZero(page.card.h3)"--> <!--highlight [highlight]="'0'" [className]="'highlight'"-->
                  {{ (page.card.h3 | titlecase) }} <!--fixTitle(cleanTitle(page.cardtitle) | titlecase)-->
                </h3>

                <!--Rate-->
                <p *ngIf="page.card?.rate"><!--Add DIV to hide first letter stretching two lines-->
                </p>
                <p *ngIf="page.card?.rate">
                  <span class="header black">RATE </span>
                  <span class="dark makebold" [innerHTML]="page.card.rate"></span>
                </p>

                <!--Areas-->
                <p *ngIf="page.card?.areas">
                  <span class="header black">AREAS </span>
                  <span class="dark" [innerHTML]="page.card.areas"></span>
                </p>

                <!--Summary-->
                <p *ngIf="page.card?.summary">
                  <span class="header black">TRIP SUMMARY </span>
                </p>
                <span *ngIf="page.card?.summary">
                  <ol class="summary">
                    <li *ngFor="let line of page.summary; let i = index">
                      <span class="text" [innerHTML]="line"></span>
                    </li>
                  </ol>
                </span>

                <!--Overview-->
                <p *ngIf="page.card?.overview">
                  <span class="header black">TRIP OVERVIEW </span>
                </p>
                <p *ngFor="let line of page.overview; let i = index">
                  <span *ngIf="utils.startWithDay(page.overview[i])" class="header dark uppercase"
                    >{{ utils.getWhyTitle(page.overview[i]) | titlecase }}
                  </span>
                  <span
                    *ngIf="utils.startWithDay(page.overview[i])"
                    class="dark"
                    [innerHTML]="page.overview[i + 1]"
                  ></span>
                  <span
                    *ngIf="
                      i == 0 ||
                      (!utils.startWithDay(page.overview[i]) &&
                        !utils.startWithDay(page.overview[i - 1]))
                    "
                    class="dark"
                    [innerHTML]="page.overview[i]"
                  ></span>
                </p>

                <!--Inclusions-->
                <p *ngIf="page.card?.inclusions">
                  <span class="header black">INCLUSIONS</span>
                </p>
                <div *ngFor="let line of page.inclusions; let i = index">
                  <span class="dark" [innerHTML]="line"></span>
                </div>

                <!--Exclusions-->
                <p *ngIf="page.card?.exclusions">
                  <span class="header black">EXCLUSIONS</span>
                </p>
                <div *ngFor="let line of page.exclusions; let i = index">
                  <span class="dark" [innerHTML]="line"></span>
                </div>


                <p *ngFor="let line of page.reason; let i = index">
                  <!--Experience-->
                  <span *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])" class="header black"
                    >{{ utils.getWhyTitle(page.reason[i]) | titlecase }}
                  </span>
                  <span
                    *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])"
                    class="dark"
                    [ngClass]="{ makebold: utils.startWithUSD(page.reason[i + 1]) }"
                    [innerHTML]="page.reason[i + 1]"
                  ></span>

                  <!--Experience-->
                  <span
                    *ngIf="
                      i == 0 ||
                      (!utils.isAllUppercase(page.reason[i]) &&
                      !utils.startWithTime(page.reason[i]) &&
                        !utils.isAllUppercase(page.reason[i - 1]) &&
                        !utils.startWithTime(page.reason[i - 1]))
                    "
                    class="dark"
                    [ngClass]="{ makebold: utils.startWithUSD(page.reason[i]) }"
                    [innerHTML]="page.reason[i]"
                  ></span>
                </p>

                <p *ngFor="let line of page.exp; let i = index">
                  <span *ngIf="i == 1" class="header black">Experience </span>
                  <span class="dark" [innerHTML]="page.exp[i]"></span>
                </p>

                <p *ngFor="let line of page.stay; let i = index">
                  <span *ngIf="i == 0" class="header black">Stay </span>
                  <!-- ACTIVITTIES -->
                  <span *ngIf="isSpecialHeading(page.stay[i])" class="header black"
                    >{{ utils.getWhyTitle(page.stay[i]) | titlecase }}
                  </span>
                  <span
                    *ngIf="!isSpecialHeading(page.stay[i])"
                    class="dark"
                    [innerHTML]="page.stay[i]"
                  ></span>
                </p>

                <p *ngFor="let line of page.act; let i = index">
                  <span *ngIf="i == 0" class="header black">Activities </span>
                  <span class="dark" [innerHTML]="page.act[i]"></span>
                </p>

                <p *ngFor="let line of page.when; let i = index">
                  <span *ngIf="i == 0" class="header black"
                    >When To Travel
                  </span>
                  <!-- MIGRATION EXPLAINED -->
                  <span *ngIf="isSpecialHeading(page.when[i])" class="header black"
                    >{{ utils.getWhyTitle(page.when[i]) | titlecase }}
                  </span>

                  <!-- <span
                    *ngIf="!isSpecialHeading(when[i])"
                    class="dark"
                    [innerHTML]="when[i]"
                  ></span> -->

                  <!---->

                  <span
                    *ngIf="
                      !isSpecialHeading(page.when[i]) && utils.isAllUppercase(page.when[i])
                    "
                    class="header dark uppercase"
                    >{{ utils.getWhyTitle(page.when[i]) | titlecase }}
                  </span>
                  <span
                    *ngIf="
                      !isSpecialHeading(page.when[i]) && utils.isAllUppercase(page.when[i])
                    "
                    class="dark"
                    [innerHTML]="page.when[i + 1]"
                  ></span>
                  <span
                    *ngIf="
                      i == 0 ||
                      (!isSpecialHeading(page.when[i]) &&
                        !utils.isAllUppercase(page.when[i]) &&
                        !utils.isAllUppercase(page.when[i - 1]))
                    "
                    class="dark"
                    [innerHTML]="page.when[i]"
                  ></span>
                </p>

              <!--Blog info-->
              <p *ngFor="let line of page.otherlines; let i = index">
                <span
                  *ngIf="startsWithNumbers(page.otherlines[i])"
                  class="header black"
                  >{{ utils.getWhyTitle(page.otherlines[i]) | titlecase }}
                </span>
                <span
                  *ngIf="startsWithNumbers(page.otherlines[i])"
                  class="dark"
                  [innerHTML]="page.otherlines[i + 1]"
                ></span>
                <span
                  *ngIf="
                    i == 0 ||
                    (!startsWithNumbers(page.otherlines[i]) &&
                      !startsWithNumbers(page.otherlines[i - 1]))
                  "
                  class="dark"
                  [innerHTML]="page.otherlines[i]"
                ></span>
              </p>

              <!--Mark Down-->
              <!--HTML Injected here via template-->
              <div id="htmlArticle"
                *ngIf="page.card?.html"
                [innerHTML]="page.card?.html"
              ></div>

              <!--Youtube if present-->
              <div id="htmlYouTube"
                *ngIf="page.card?.youtube">


                <iframe width="100%" height="100%" [src]="getIFrameSource() | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <!--
                <iframe width="560" height="315" src="https://www.youtube.com/embed/gM2yYvdpaoM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                -->

                <!--
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/803457251?h=c9bb9fb50e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Discover the Ultimate Luxury Safari with Africa Unwind"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                -->

              </div>

              </span>
            </article>




          </div>
        </div>
      </span>
    </span>

    <app-our-favourites *ngIf="utils.isSpecialPages(page)"
        id="ourfavourites"
        [isSpecialPage]="utils.isSpecialPages(page)"
      ></app-our-favourites>

    <app-images *ngIf="page?.card?.images.length >= 5 && page.altArray"
      [heading]="'Gallery'"
      [images]="page.card.images"
      [altArray]="page.altArray"
    ></app-images>

    <div *ngIf="page?.card?.images.length < 5" class="galleryFiller"></div>

    <div class="flexcenter">
      <!--TODO: MR: Depends on what to show as cards-->
      <div
        class="leftarrow"
        *ngIf="page.itemCounter > 0"
        (click)="backCards()"
      ></div>

      <!--TODO: MR: The children part will depend on the page your on... experience... etc-->
      <app-cards
        *ngIf="getPageValid() && page?.cards && page?.cards.length > 0 && !blacklisted()"
        class="p100 flexcenter"
        [heading]="page.cardsHeadings"
        [cards]="page.cards"
        [prefixurl]="page.prefixUrl"
      ></app-cards>

      <!--TODO: MR: Depends on what to show as cards-->
      <div
        class="rightarrow"
        *ngIf="page.subs && page.subs.length >= page.itemsToGet"
        (click)="nextCards()"
      ></div>
    </div>

    <div class="flexcenter">
      <app-breadcrumb
        id="cardBread"
        *ngIf="(page.depth > 1 && (page.parent || (page.uncles && page.uncles?.length > 0)))"
        class="p100 flexcenter"
        [heading]="getBreadCrumbHeading()"
        [parent]="getBreadCrumbParent()"
        [uncles]="getBreadCrumbUncles()"
        [prefixurl]="prefixUrl"
      ></app-breadcrumb>
    </div>

    <app-steps id="steps"></app-steps>
    <app-instagram id="instagram"></app-instagram>

    <app-intersection></app-intersection>
  </div>
</div>
