import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
//import { SeoService } from '../service/seo.service';
import { Card } from '../entities/card';
import { Page } from '../entities/page';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private meta: Meta, private title: Title) { } //private seoService: SeoService

  updateTags(page: Page): void {
    var prefix = "https://www.africaunwind.com";
    var position = page?.card?.url?.indexOf("africaunwind");
    var url = (position >= 0) ? page.card.url : prefix + page.card.url;
    var image = page.image.replace('.jpg', '-20w.webp');
    image = image.replace('.jpeg', '-20w.webp');
    image = image.replace('.png', '-20w.webp');
    image = image.replace('.webp', '-20w.webp');

    //Console what will happen: Might need to remove this
    console.log('Update SEO tags');
    console.log('Title: ' + page.card.pageTitle);
    console.log('Google FolderId: ' + page.card.folderid);
    console.log('Url: ' + url);
    console.log('Meta: ' + page.card.meta);

    //Write to HEADER
    console.log('Set Title: ' + page.card.pageTitle);
    this.title.setTitle(page.card.pageTitle);

    console.log('Set url: ' + url);
    console.log('Set descr: ' + page.card.meta);

    console.log('Set Other');
    // Other
    this.meta.addTag({ name: 'description', content: page.card.meta });
    this.meta.addTag({ name: 'keywords', content: page.card.keyword });
    this.meta.addTag({ name: 'robots', content: 'index, follow' });
    this.meta.addTag({ name: 'author', content: page.card.author });
    //this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    this.meta.addTag({ charset: 'UTF-8' });
    this.meta.addTag({ name: 'date', content: page.card.created, scheme: 'YYYY-MM-DD' });

    console.log('Set OG');
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:description', content: page.card.meta });
    this.meta.addTag({ property: 'og:title', content: page.card.pageTitle });
    this.meta.addTag({ property: 'og:image', content: image });
    this.meta.addTag({ property: 'og:url', content: url });
    this.meta.addTag({ property: 'og:site_name', content: "Africa Unwind" });

    console.log('Set Twitter');
    //TODO: MR: summary_large_image
    this.meta.addTag({ name: 'twitter:card', content: 'large' });
    this.meta.addTag({ name: 'twitter:description', content: page.card.meta });
    this.meta.addTag({ name: 'twitter:title', content: page.card.pageTitle }); // updateTag?
    this.meta.addTag({ name: 'twitter:url', content: url }); // updateTag?
    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ name: 'twitter:site', content: '@africaunwind' });
    this.meta.addTag({ name: 'twitter:creator', content: '@africaunwind' });

    //console.log('Set Last');
    //this.meta.addTag({ name: 'title', property: 'og:title', content: page.card.pageTitle }); // updateTag?
    //this.meta.addTag({ name: 'image', property: 'og:image', content: image });
    //this.meta.addTag({ name: 'description', property: 'og:description', content: page.card.meta });

    //Schema
    this.loadScript(page.card.schema);

    //Canonical stuff
    this.addCanonicalUrl(page.card.canon, url);

    console.log('SEO Done');
  }

  loadScript(schema: string) {
    this.loadLDJson(schema);
  }

  addMetaGoogleConvertionTag() {
    /*
    let dynamicScript = document.createElement('script');
    dynamicScript.text = "<!-- Event snippet for Submit lead form conversion page --> <script> gtag('event', 'conversion', {'send_to': 'AW-710869978/FnsxCJvjmoIYENqH_NIC'}); </script>";
    document.getElementsByTagName('head')[0].appendChild(dynamicScript);
    */
  }

  addGoogleSafariFamilyTag(page: Page) {

    //CURRENTLY REMOVED....

    var OLDtext = "<!-- Google Tag Manager -->(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MKCBP3S');<!-- End Google Tag Manager -->";

    var text = "<!-- Event snippet page load --> <script> gtag('event', 'page_view', {'send_to': 'GTM-MKCBP3S'}); </script>";

    //page_title: '<Page Title>',
    //page_location: '<Page Location>',
    //page_path: '<Page Path>',

    /*
    let dynamicScript = document.createElement('script');
    dynamicScript.text = text;
    document.getElementsByTagName('head')[0].appendChild(dynamicScript);
    */
  }

  /*
  loadScriptText() {
    let dynamicScript = document.createElement('script'); // creates the script tag
    //dynamicScript.src = ''; // sets the source (insert url in between quotes)
    dynamicScript.type = 'text/javascript'; // set the script type
    dynamicScript.async = true; // makes script run asynchronously
    dynamicScript.text = "//alert('this is inserted dynamically')";

    //dynamicScript.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(dynamicScript);
    //document.body.appendChild(dynamicScript);
    //https://stackoverflow.com/questions/46923628/how-to-dynamically-add-scripts-into-the-head-tag-when-angular-component-has-load
  }
  */

  loadLDJson(schema: string) {
    //Schema generic
    //"{
    //"@context": "https://schema.org"
    ///* Replace this json object with the real one */
    //} "
    var generic = schema.toLowerCase().indexOf("replace this json object with the real one") > -1;
    if (generic) return;

    console.log('SCHEMA add');

    //console.log("🚀 ~ file: metadata.service.ts:121 ~ MetadataService ~ loadLDJson ~ schema", schema)

    //this.scriptService.setJsonLd(this.renderer, JSON_LD_DATA);
    //console.log('JSON_LD_DATA Script loaded');


    let script = document.createElement('script');
    script.type = 'application/ld+json';
    script.id = 'ld+json';
    script.text = schema;

    var found = document.getElementById('ld+json');
    console.log('Found schema? ' + found);
    if (!found) {
      //this._renderer2.appendChild(this._document.body, script);
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    //https://jsonworld.com/blog/how-to-add-script-dynamically-in-angular-application
    //https://www.htmlgoodies.com/javascript/loading-external-scripts-dynamically-in-angular/
    //https://pankaj-kumar.medium.com/how-to-add-script-dynamically-in-angular-application-ce32e421d390
  }

  /*
  createLinkForCanonicalURL(canon: string, url: string): void {
    if (canon == "true") {
      this.seoService.createLinkForCanonicalURL(url);
    }
  }
  */

  addCanonicalUrl(canon: string, url: string): void {

    console.log('Canonical add');

    let link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.getElementsByTagName('head')[0].appendChild(link);
    link.setAttribute('href', url);
  }

}
