import { Component, OnInit, Input } from '@angular/core';
import * as $ from "jquery";
import { Card } from '../entities/card';

import { UtilsService } from '../service/utils.service';

function ResizeHeaders() {
  console.log('Resize H3');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divAboutus").width() - 52; //25px padding on both sides

    while ($("#hiddenAboutus").width() > mainwidth) {
      var hiddenWidth = $("#hiddenAboutus").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenAboutus").css("font-size"));
      var h3Size = $("#divAboutus").find("h3").css("font-size");
      $("#hiddenAboutus").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenAboutus").css("font-size");
    $("#divAboutus").find("h3").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#expect").css("margin-bottom", 50);
    }
  });
}

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  @Input() image: string;
  @Input() showtext: string;
  @Input() title: string;
  @Input() reason: string[];
  @Input() html: string;


  constructor(private utils: UtilsService) {

  }

  ngOnInit(): void {
    //ResizeHeaders();
    ApplySafariHack();
  }

}
