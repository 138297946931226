import { Component, OnInit, Input } from '@angular/core';
import { FolderItem } from '../entities/folderitem';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divAboutus").width() - 52; //25px padding on both sides

    while ($("#hiddenAboutus").width() > mainwidth) {
      var hiddenWidth = $("#hiddenAboutus").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenAboutus").css("font-size"));
      var h3Size = $("#divAboutus").find(".h3class").css("font-size");
      $("#hiddenAboutus").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenAboutus").css("font-size");
    $("#divAboutus").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#imagesH3").css("padding-top", 17);
      $("#imagesH3").css("padding-bottom", 30);
    }
  });
}

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {

  @Input() images: string[];
  @Input() heading: FolderItem[];
  @Input() altArray: string[];

  item: boolean[] = [false, false, false, false, false, false, false, false]
  modalImage: string = "";
  selecteditem: number = -1;
  private styleTag: HTMLStyleElement;
  //TODO: MR: Find alternative

  constructor() {
    //TODO: MR: Find an alternative to showing the modal images... and hiding them - this doesn't work with SSR
    this.styleTag = this.buildStyleElement();
  }

  ngOnInit(): void {
    console.log('Images init');
    //console.log('altArray ' + this.altArray);
    ResizeHeaders();
    ApplySafariHack();
  }

  getImgUrl(i: number): string {
    if (this.images[i]) {

      var result = this.images[i];

      if (result.toLowerCase().indexOf('.webp') > -1) {
        result = result.replace('.webp', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.jpg') > -1) {
        result = result.replace('.jpg', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.jpeg') > -1) {
        result = result.replace('.jpeg', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.png') > -1) {
        result = result.replace('.png', '-20w.webp');
      };

      //result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?! '
      let re = /\`/gi;
      result = result.replace(re, "\'");

      return result;
    } else
      return '';
  }

  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }



  setModalImage(i: number) {
    if (i < 0) i = this.images.length - 1;
    if (i > this.images.length - 1) i = 0;

    var result = this.images[i].replace('.jpg', '-lg.webp');
    result = result.replace('.jpeg', '-lg.webp');
    result = result.replace('.png', '-lg.webp');
    result = result.replace('.webp', '-lg.webp');

    let re = /\`/gi;
    result = result.replace(re, "\'");

    //$('body').addClass('hidebodyscroll');
    this.selecteditem = i;
    document.body.appendChild(this.styleTag);
    //TODO: MR: Find alternative
    this.modalImage = result;
  }

  clearModalImage() {
    //$('body').removeClass('hidebodyscroll');
    this.selecteditem = -1;
    document.body.removeChild(this.styleTag);
    //TODO: MR: Find alternative
    this.modalImage = "";
  }

  private buildStyleElement(): HTMLStyleElement {
    var style = document.createElement("style");
    style.type = "text/css";
    style.setAttribute("data-debug", "Injected by Modal.");
    style.textContent = `
			body {
				overflow: hidden !important ;
			}
		`;

    return (style);
  }

}
