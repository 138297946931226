import { Injectable } from '@angular/core';
import { ContentFolder } from '../entities/contentfolder';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor() { }

  //Make PHP call to get the info
  getFolderContent(): ContentFolder[] {
    return [];
  }

  consoleWriteGeolocationNames(): string[] {
    var allLatLong = [];
    var allPlain = this.getFolderContent();
    allPlain.forEach(item => {

      var arr = item.plain.split("/");
      if (arr.length > 1) {
        var value = "";
        for (var ii = arr.length - 1; ii >= 1; ii--) {
          value = value + arr[ii] + ",";
        }
        allLatLong.push(value.substr(0, value.length - 1));
      }

    })
    return allLatLong;
  }

  splitByEnter(str: string): string[] {
    if (!str) return;
    var arr = str.split(/[\r\n]+/);
    return arr;
  }


}
