import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { DataService } from '../service/data.service';
import { ItemService } from '../service/item.service';

import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilsService } from '../service/utils.service';

import { Sibling } from '../entities/sibling';
import * as $ from "jquery";
import { Page } from '../entities/page';
import { Card } from '../entities/card';

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#searchtext").css("margin-top", 10);
      $("#searchtext").css("margin-bottom", 25);
      $("#searchbutton").css("margin-bottom", 35);
    }
  });
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends MetadataService implements OnInit {

  cards: Sibling[];
  error: string;
  find: string;

  page: Page = new Page();

  constructor(meta: Meta, title: Title, private router: Router, private actRoute: ActivatedRoute, private ds: DataService, private itemService: ItemService, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "search";
    this.page.card = new Card();
    this.page.card.h1 = "Search";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    this.cards = [];
    this.find = "";
    ApplySafariHack();
  }

  cleanText(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("-").join(" ")
      //.split(":").join(" -")
      .split("_").join(" ")
      .split("& ").join("")
      .split("\+ ").join("")
      .split("+ ").join("")
      .split("'").join("") //remove apostraphe
      .split("’").join("")
      .split('\'').join('')
      .split('`').join('')
      .split("(").join("")
      .split(")").join("");
  }

  getPrefixUrl(): string {
    return "/";
  }

  search() {
    if (!this.find) return;
    this.findFolders(this.find);
  }

  findFolders(find: string) {
    console.log('Searching: ' + find);

    //Set default card - showing searching or something... like a spinner
    var searchingCardHolder = new Sibling();
    searchingCardHolder.title = "Searching...";
    searchingCardHolder.foldername = this.router.url;
    searchingCardHolder.why = "Please be patient.";
    searchingCardHolder.image = "/Search/Binocs.webp";
    this.cards = [searchingCardHolder];

    this.itemService.findFolders(this.cleanText(find)).subscribe(
      (res: Sibling[]) => {
        this.cards = res;

        //TODO: MR: If nothing found below change title to NO RESULTS and image something like that...
        if (res.length <= 0) {
          searchingCardHolder.title = "No results found...";
          searchingCardHolder.why = "Please try again.";
          searchingCardHolder.image = "/Search/NoResults.webp";
          this.cards = [searchingCardHolder];
        }
      },
      (err) => {
        this.error = err;
      }
    );
  }
}
