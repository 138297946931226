<div *ngIf="cards" class="experiencecontainer">
  <div class="area">
    <div class="flexcenter">
      <div class="searchcontainer">
        <div class="textfield">
          <input
            id="searchtext"
            class="textsearch"
            type="text"
            placeholder="Search for, eg. Cheetah"
            [(ngModel)]="find"
            (keyup.enter)="search()"
          />
        </div>
        <div class="searchbutton">
          <button id="searchbutton" class="btn" (click)="search()">
            Search
          </button>
        </div>
      </div>

      <!--TODO: MR: Depends on what to show as cards-->
      <div
        class="leftarrow"
        *ngIf="itemCounter > 0"
        (click)="backCards()"
      ></div>

      <!--TODO: MR: The children part will depend on the page your on... experience... etc-->
      <div *ngIf="cards.length > 0" class="searchcards">
        <app-cards
          class="p100 flexcenter mt mb pt50"
          [heading]="'Search Results'"
          [cards]="cards"
          [prefixurl]="getPrefixUrl()"
        ></app-cards>
      </div>

      <!--TODO: MR: Depends on what to show as cards-->
      <div
        class="rightarrow"
        *ngIf="subs && subs.length >= itemsToGet"
        (click)="nextCards()"
      ></div>
    </div>

    <app-steps id="steps"></app-steps>
    <app-instagram id="instagram"></app-instagram>

    <app-intersection></app-intersection>
  </div>
</div>
