<div id="hiddenWhyus" class="hiddenH3div">Why Us?</div>
<div class="areacontainer">
  <div id="divWhyus" class="area">
    <div class="coverimg">
      <div class="coverheading">
        <div class="h3class center">Why Us?</div>
      </div>
    </div>

    <div class="grid-container">
      <!-- <div class="grid-item item2 coverimg2">
      </div>
      <div class="grid-item item3 coverimg2">
      </div>
      <div class="grid-item item4 coverimg2">
      </div>
      <div class="grid-item item5 coverimg2">
      </div> -->

      <div class="grid-item item6 steps">
        <div class="">
          <div class="gridHeader">Price guarantee</div>
        </div>
      </div>
      <div class="grid-item item7 steps">
        <div class="">
          <div class="gridHeader">Expert advice</div>
        </div>
      </div>
      <div class="grid-item item8 steps">
        <div class="">
          <div class="gridHeader">Tailor-made</div>
        </div>
      </div>
      <div class="grid-item item9 steps">
        <div class="">
          <div class="gridHeader">On Call 24hrs</div>
        </div>
      </div>

      <div class="grid-item item11 whyus">
        <div class="">
          <div class="">
            Our clients are guaranteed to pay a lower rate than making a direct
            booking.
          </div>
        </div>
      </div>
      <div class="grid-item item12 whyus">
        <div class="">
          <div class="">
            Our well travelled safari experts provide clients with honest and
            reliable advice.
          </div>
        </div>
      </div>
      <div class="grid-item item13 whyus">
        <div class="">
          <div class="">
            Our holidays are uniquely designed and tailored to each client's
            requirements.
          </div>
        </div>
      </div>
      <div class="grid-item item14 whyus">
        <div class="">
          <div class="">
            Our support line is always open - 24 hours a day. So we are always
            here to assist!
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
