import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ItemService } from '../service/item.service';
import { UtilsService } from '../service/utils.service';
import { TransferStateService } from '@scullyio/ng-lib';

import { Page } from '../entities/page';
import { Card } from '../entities/card';

import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize H3');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    //-------------------------------------------
    //MT1
    var mainwidth = $("#divMT1").width() - 4; //25px padding on both sides

    while ($("#hiddenMT1").width() > mainwidth) {
      var hiddenWidth = $("#hiddenMT1").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenMT1").css("font-size"));
      var h3Size = $("#divMT1").css("font-size");
      $("#hiddenMT1").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenMT1").css("font-size");
    $("#divMT1").find("h3").css("font-size", finalfontsize);

    //-------------------------------------------
    //MT2
    var mainwidth = $("#divMT2").width() - 4; //25px padding on both sides

    while ($("#hiddenMT2").width() > mainwidth) {
      var hiddenWidth = $("#hiddenMT2").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenMT2").css("font-size"));
      var h3Size = $("#divMT2").css("font-size");
      $("#hiddenMT2").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenMT2").css("font-size");
    $("#divMT2").css("font-size", finalfontsize);

    //-------------------------------------------
    //MT3
    var mainwidth = $("#divMT3").width() - 4; //25px padding on both sides

    while ($("#hiddenMT3").width() > mainwidth) {
      var hiddenWidth = $("#hiddenMT3").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenMT3").css("font-size"));
      var h3Size = $("#divMT3").css("font-size");
      $("#hiddenMT3").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenMT3").css("font-size");
    $("#divMT3").css("font-size", finalfontsize);

    //-------------------------------------------
    //MT4
    var mainwidth = $("#divMT4").width() - 4; //25px padding on both sides

    while ($("#hiddenMT4").width() > mainwidth) {
      var hiddenWidth = $("#hiddenMT4").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenMT4").css("font-size"));
      var h3Size = $("#divMT4").css("font-size");
      $("#hiddenMT4").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenMT4").css("font-size");
    $("#divMT4").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divMT1").find("h3").css("padding-top", 10);
      $("#divMT1").find("h3").css("padding-bottom", 0);

      $("#divMT2").css("padding-top", 20);
      $("#divMT2").css("padding-bottom", 10);
      $("#divMT2").css("margin-top", 0);


      $("#divMT3").css("padding-top", 0);
      $("#divMT3").css("padding-bottom", 10);
      $("#divMT3").css("margin-top", -15);

      $("#divMT4").css("padding-top", 0);
      $("#divMT4").css("padding-bottom", 10);
      $("#divMT4").css("margin-top", -15);

      $("#ourToMore").css("margin-top", 25);
      $("#ourToMore").css("margin-bottom", 5);
    }
  });
}

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent extends MetadataService implements OnInit {

  page: Page = new Page;
  bigItems: number[] = [];

  image: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Trips/1MoreTrips.webp";

  charmingItem: boolean[] = [false, false, false, false, false, false];
  charmingTitle: string[] = [
    "Beloved Africa",
    "South Africa -<br\>Center Stage",
    "Beautiful Botswana",
    "Namibia's Best Road Trip",
    "Zambia Adventures",
    "Kenya On A Shoestring"
  ];
  charmingImages: string[] = [
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/1Beloved%20Africa/1BelovedAfrica.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/2South%20Africa%20-%20Center%20Stage/1South%20Africa%20-%20Center%20Stage.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/3Beautiful%20Botswana/1Beautiful%20Botswana.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/4Namibia%27s%20Best%20Road%20Trip/1Namibia%27s%20Best%20Road%20Trip.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/5Zambia%20Adventures/1Zambia%20Adventures.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/6Kenya%20On%20A%20Shoestring/1Kenya%20On%20A%20Shoestring.webp",
  ];
  charmingUrl: string[] = [
    "/favourite/1charming/1beloved-africa",
    "/favourite/1charming/2south-africa---center-stage",
    "/favourite/1charming/3beautiful-botswana",
    "/favourite/1charming/4namibias-best-road-trip",
    "/favourite/1charming/5zambia-adventures",
    "/favourite/1charming/6kenya-on-a-shoestring",
  ];

  stylishItem: boolean[] = [false, false, false, false, false, false];
  stylishTitle: string[] = [
    "Botswana Bliss",
    "Limelight On Africa",
    "Swanky Zimbabwe",
    "South Africa In Style",
    "Tempting Tanzania",
    "Fun For Families"
  ];
  stylishImages: string[] = [
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/1Botswana%20Bliss/1Beautiful%20Botswana.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/2Limelight%20On%20Africa/1Limelight%20On%20Africa.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/3Swanky%20Zimbabwe/1Swanky%20Zimbabwe.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/4South%20Africa%20In%20Style/1South%20Africa%20In%20Style.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/5Tempting%20Tanzania/1Tempting%20Tanzania.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/6Fun%20For%20Families/1Fun%20For%20Families.webp"
  ];
  stylishUrl: string[] = [
    "/favourite/2stylish/1botswana-bliss",
    "/favourite/2stylish/2limelight-on-africa",
    "/favourite/2stylish/3swanky-zimbabwe",
    "/favourite/2stylish/4south-africa-in-style",
    "/favourite/2stylish/5tempting-tanzania",
    "/favourite/2stylish/6fun-for-families"
  ];

  exclusiveItem: boolean[] = [false, false, false, false, false, false];
  exclusiveTitle: string[] = [
    "Creme De La Creme",
    "Pride Of Africa",
    "Royal Rwanda",
    "Tanzania Bush Beach",
    "Namibia With Wings",
    "Elite Bush Beach Fushion",
  ];
  exclusiveImages: string[] = [
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/1Creme%20De%20La%20Creme/1Cremde%20De%20La%20Creme.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/2Pride%20Of%20Africa/1Pride%20Of%20Africa.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/3Royal%20Rwanda/1Royal%20Rwanda.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/4Tanzania%20Bush%20%26%20Beach/1Tanzania%20Bush%20%26%20Beach.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/5Namibia%20With%20Wings/1Namibia%20With%20Wings.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/6%20Elite%20Bush%20%26%20Beach%20Fushion/1Elite%20Bush%20%26%20Beach%20Fushion.webp",
  ];
  exclusiveUrl: string[] = [
    "/favourite/3exclusive/1creme-de-la-creme",
    "/favourite/3exclusive/2pride-of-africa",
    "/favourite/3exclusive/3royal-rwanda",
    "/favourite/3exclusive/4tanzania-bush-beach",
    "/favourite/3exclusive/5namibia-with-wings",
    "/favourite/3exclusive/6-elite-bush-beach-fushion",
  ];

  constructor(meta: Meta, title: Title, private router: Router, private itemService: ItemService, private transferStateService: TransferStateService, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "trips";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    ResizeHeaders();
    ApplySafariHack();
  }

  getImgUrl(i: number, section: number): string {
    var result = "";

    if (section == 1)
      result = this.charmingImages[i];
    if (section == 2)
      result = this.stylishImages[i];
    if (section == 3)
      result = this.exclusiveImages[i]

    if (result.toLowerCase().indexOf('.webp') > -1) {
      result = result.replace('.webp', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.jpg') > -1) {
      result = result.replace('.jpg', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.jpeg') > -1) {
      result = result.replace('.jpeg', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.png') > -1) {
      result = result.replace('.png', '-20w.webp');
    };

    //TODO: MR: Ensure this is correct
    //result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?! '
    let re = /\`/gi;
    result = result.replace(re, "\'");

    return result;
  }

  scaleImage($event, item: number, section: number) {
    if (section == 1)
      this.charmingItem[item] = $event.type == 'mouseover' ? true : false;
    if (section == 2)
      this.stylishItem[item] = $event.type == 'mouseover' ? true : false;
    if (section == 3)
      this.exclusiveItem[item] = $event.type == 'mouseover' ? true : false;
  }

  getItemAltNameFromImage(int: number, section: number): string {
    if (section == 1)
      return this.charmingTitle[int];
    if (section == 2)
      return this.stylishTitle[int];
    if (section == 3)
      return this.exclusiveTitle[int];
  }

  getUrl(int: number, section: number): string {
    if (section == 1)
      return this.charmingUrl[int];
    if (section == 2)
      return this.stylishUrl[int];
    if (section == 3)
      return this.exclusiveUrl[int];
  }

  isBig(int: number): boolean {
    return this.bigItems.indexOf(int) > -1;
  }

}
