import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divDestinations").width() - 4; //25px padding on both sides

    while ($("#hiddenDestinations").width() > mainwidth) {
      var hiddenWidth = $("#hiddenDestinations").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenDestinations").css("font-size"));
      var h3Size = $("#divDestinations").find(".h3class").css("font-size");
      $("#hiddenDestinations").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenDestinations").css("font-size");
    $("#divDestinations").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divDestinations").find(".h3class").css("padding-top", 5);
      $("#divDestinations").find(".h3class").css("padding-bottom", 25);
      $("#placesToAll").css("margin-bottom", 5);
    }
  });
}

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css']
})
export class PlacesComponent implements OnInit {

  item: boolean[] = [false, false, false, false, false, false, false, false, false]
  image1: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Botswana/1Botswana-20w.webp";
  image2: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/Kruger%20National%20Park/1KrugerNationalPark-20w.webp";
  image3: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/Kruger%20National%20Park/Sabi%20Sand%20Private%20Game%20Reserve/1SabiSand-20w.webp";
  image4: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Botswana/Okavango%20Delta/1OkavangoDelta-20w.webp";
  image5: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Zimbabwe/Victoria%20Falls/1VictoriaFalls-20w.webp";
  image6: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/CITIES/Cape%20Town/1CapeTown-20w.webp";
  image7: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/1SouthAfrica-20w.webp";
  image8: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Tanzania/Serengeti%20National%20Park/1Serengeti-20w.webp";
  image9: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Namibia/1Namibia-20w.webp";

  constructor() { }

  ngOnInit(): void {
    //ResizeHeaders();
    ApplySafariHack();
  }

  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }

}
