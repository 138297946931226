<div id="hiddenCards" class="hiddenH3div">{{ heading }}</div>
<div class="container">
  <div id="divCards" class="area">
    <div id="cardsH3" class="h3class center">{{ heading }}</div>
    <div
      class="container cards"
      [ngClass]="{ extraspacebottom: scrollbarIsVisible() }"
    >
      <div *ngFor="let card of cards; let i = index" class="card coverimg">
        <a [href]="getUrl(i)">
          <div class="cardImage coverimg">
            <div
              class="coverimg"
              [ngStyle]="{
                'background-image':
                  'url(' + getImgUrl(i).replace('-20w', '-200w') + ')'
              }"
            ></div>
          </div>
          <div class="h2class line-clamp">{{ getCardTitle(i) | titlecase }}</div>
          <div class="descr">
            <p><span [innerHTML]="getWhy(i)"></span></p>
            <p class="read-more read-more-background"></p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
