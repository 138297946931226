<div id="hiddenAboutus" class="hiddenH3div">{{title}}</div>
<div class="aboutstrip">
  <div id="divAboutus" class="about">
    <article>
      <span *ngIf="reason || html" class="abouttext left dark">
        <h3 *ngIf="title" class="center black">
          {{ (title | titlecase) }} <!--fixTitle(cleanTitle(cardtitle) | titlecase)-->
        </h3>

        <p *ngFor="let line of reason; let i = index"
          [ngClass]="{ 'collapse': i > 0 &&  utils.isAllUppercase(reason[i - 1])}">
          <span
            *ngIf="utils.isAllUppercase(reason[i])"
            class="header black"
            [innerHTML]="reason[i]"
          ></span>
          <span
            *ngIf="utils.isAllUppercase(reason[i])"
            class="dark"
            [innerHTML]="reason[i + 1]"
          ></span>

          <span
            *ngIf="
              i == 0 ||
              (!utils.isAllUppercase(reason[i]) &&
                !utils.isAllUppercase(reason[i - 1]))
            "
            class="dark"
            [innerHTML]="reason[i]"
          ></span>

        </p>

        <!--Mark Down-->
        <!--HTML Injected here via template-->
        <div id="htmlArticle"
          *ngIf="!reason && html"
          [innerHTML]="html"
        ></div>
      </span>
    </article>
  </div>
  <div class="btncontainer">
    <a href="/what-to-expect">
      <button id="expect" aria-label="Our Story" class="btn">
        What To Expect
      </button>
    </a>
  </div>
</div>
