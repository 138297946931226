export class Contact {
  id: number;
  name: string;
  surname: string;
  email: string;
  emailconfirm: string;
  phone?: string;
  message: string;
  verify: string;

  destinations: string;
  adults: number;
  children: number;
  notsurepeople: string;
  arrival: string;
  departure: string;
  unsuretravel: string;
  travelstyle: string;

}
