import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CardComponent } from './card/card.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ThankYouEnquiryComponent } from './thankyouenquiry/thankyouenquiry.component';
import { SearchComponent } from './search/search.component';

import { DestinationsComponent } from './destinations/destinations.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { TopdestinationsComponent } from './topdestinations/topdestinations.component';
import { TripsComponent } from './trips/trips.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'our-story', component: OurStoryComponent },
  { path: 'destinations', component: DestinationsComponent },
  { path: 'top-destinations', component: TopdestinationsComponent },
  { path: 'trips', component: TripsComponent },

  { path: 'contact-us', component: ContactusComponent },
  { path: 'thank-you-enquiry', component: ThankYouEnquiryComponent },
  { path: 'search-site', component: SearchComponent },

  { path: ':experience', component: CardComponent },
  { path: ':experience/:country', component: CardComponent },
  { path: ':experience/:country/:area', component: CardComponent },
  { path: ':experience/:country/:area/:lodge', component: CardComponent },
  { path: ':experience/:country/:area/:lodge/:folder1', component: CardComponent },
  { path: ':experience/:country/:area/:lodge/:folder1/:folder2', component: CardComponent },
  { path: ':experience/:country/:area/:lodge/:folder1/:folder2/:folder3', component: CardComponent },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload',
    useHash: false,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
