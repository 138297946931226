import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divAreas").width() - 52; //25px padding on both sides

    while ($("#hiddenAreas").width() > mainwidth) {
      var hiddenWidth = $("#hiddenAreas").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenAreas").css("font-size"));
      var h3Size = $("#divAreas").find(".h3class").css("font-size");
      $("#hiddenAreas").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenAreas").css("font-size");
    $("#divAreas").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divAreas").find(".h3class").css("padding-top", 15);
    }
  });
}

//();
//
@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss']
})
export class AreasComponent implements OnInit {
  bigItems: number[] = [0, 3];
  item: boolean[] = [false, false, false, false, false, false]
  images: string[] = [
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/1Safaris-20w.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/1Islands-20w.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/CITIES/1Cities-20w.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Bucket%20List/1TheBucketList-20w.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Honeymoons/1Honeymoons-20w.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Family%20Travel/1FamilyTravel-20w.webp"
  ];
  title: string[] = [
    "Safaris",
    "Islands",
    "Cities",
    "The Bucket List",
    "Honeymoons",
    "Family Travel"
  ];
  url: string[] = [
    "/safaris",
    "/islands",
    "/cities",
    "/bucket-list",
    "/honeymoons",
    "/family-travel"
  ];

  constructor() { }

  ngOnInit(): void {
    //ResizeHeaders();
    ApplySafariHack();
  }

  getImgUrl(i: number): string {
    if (this.images[i]) {
      var result = this.images[i];
      let re = /\`/gi;
      result = result.replace(re, "\'");

      return result;
    } else
      return '';
  }

  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }

  getItemAltNameFromImage(int: number): string {
    return this.title[int];
  }

  getUrl(int: number): string {
    return this.url[int];
  }

  isBig(int: number): boolean {
    return this.bigItems.indexOf(int) > -1;
  }

}
