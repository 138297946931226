<div>
  <app-header
    *ngIf="innerWidth > 550"
    [title]=""
    [logo]=""
    [subtitle]=""
    [image]="image"
    [altText]="'Contact Us'"
  ></app-header>

  <div class="container">
    <div class="area">
      <div class="section sent">
        <div class="h4class">Enquiry Sent</div>
        <div class="row">
          <div class="row flexrow noselect">
            <span [innerHTML]="result"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-intersection></app-intersection>
</div>
