import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ItemService } from '../service/item.service';
import { UtilsService } from '../service/utils.service';
import { TransferStateService } from '@scullyio/ng-lib';

import { Page } from '../entities/page';
import { Card } from '../entities/card';

import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize H3');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divDestinations").width() - 4; //25px padding on both sides

    while ($("#hiddenDestinations").width() > mainwidth) {
      var hiddenWidth = $("#hiddenDestinations").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenDestinations").css("font-size"));
      var h3Size = $("#divDestinations").find("h3").css("font-size");
      $("#hiddenDestinations").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenDestinations").css("font-size");
    $("#divDestinations").find("h3").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divDestinations").find("h3").css("padding-top", 0);
      $("#divDestinations").find("h3").css("padding-bottom", 0);
      $("#topToAll").css("margin-top", 10);
      $("#topToAll").css("margin-bottom", 0);
    }
  });
}

@Component({
  selector: 'app-topdestinations',
  templateUrl: './topdestinations.component.html',
  styleUrls: ['./topdestinations.component.scss']
})
export class TopdestinationsComponent extends MetadataService implements OnInit {

  page: Page = new Page;

  imageHeader: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Destinations/1.1.webp";

  bigItems: number[] = [];
  item: boolean[] = [false, false, false, false, false, false, false, false, false];

  image1: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Botswana/1Botswana-20w.webp";
  image2: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/Kruger%20National%20Park/1KrugerNationalPark-20w.webp";
  image3: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/Kruger%20National%20Park/Sabi%20Sand%20Private%20Game%20Reserve/1SabiSand-20w.webp";
  image4: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Botswana/Okavango%20Delta/1OkavangoDelta-20w.webp";
  image5: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Zimbabwe/Victoria%20Falls/1VictoriaFalls-20w.webp";
  image6: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/CITIES/Cape%20Town/1CapeTown-20w.webp";
  image7: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/1SouthAfrica-20w.webp";
  image8: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Tanzania/Serengeti%20National%20Park/1Serengeti-20w.webp";
  image9: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Namibia/1Namibia-20w.webp";

  constructor(meta: Meta, title: Title, private router: Router, private itemService: ItemService, private transferStateService: TransferStateService, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "top destinations";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    ResizeHeaders();
    ApplySafariHack();
  }



  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }

}
