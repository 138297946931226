import { Uncle } from '../entities/uncle';
import { Card } from '../entities/card';
import { Parent } from '../entities/parent';
import { Child } from '../entities/child';

import { LatLong } from '../entities/latlong';
import { SafeHtml } from '@angular/platform-browser';

export class Page {
  experience: string;
  country: string;
  area: string;
  lodge: string;
  folder1: string;
  folder2: string;
  folder3: string;

  isSerengeti: boolean;
  path: string;
  prefixUrl: string;
  depth: number;

  card: Card;
  parent: Parent;
  uncles: Uncle[];
  other: string[];
  otherlines: string[] = [];

  why: string[];
  reason: string[];
  exp: string[];
  stay: string[];
  act: string[];
  when: string[];
  summary: string[];
  overview: string[];
  inclusions: string[];
  exclusions: string[];
  altArray: string[];

  cardsHeadings: string;
  cards: Child[];
  lodges: string[];

  html: SafeHtml;

  error: string;
  latlong: LatLong;
  resizedH3: boolean;

  //TODO: MR: UNTAMED add other meta objects here
  url: string; //Figure out in code
  routerUrl: string; //From router object
  image: string;

  constructor() { }
}
