<div id="hiddenBreadcrumb" class="hiddenH3div">{{ heading }}</div>
<div class="container" *ngIf="parentUrl">
  <div id="divBreadcrumb" class="area">
    <div class="coverheading">
      <div class="h3class center">{{ heading }}</div>
    </div>

    <div class="container cards">
      <div class="card coverimg">
        <a [href]="parentUrl">
          <div class="cardImage coverimg">
            <div
              class="coverimg"
              [ngStyle]="{
                'background-image':
                  'url(' + parentImgUrl.replace('20w', '200w') + ')'
              }"
            ></div>
          </div>
          <div class="h2class">{{ parentTitle | titlecase }}</div>
        </a>
      </div>

      <div *ngFor="let uncle of uncles; let i = index" class="card coverimg">
        <a [href]="getUrl(i)">
          <div class="cardImage coverimg">
            <div
              class="coverimg"
              [ngStyle]="{
                'background-image':
                  'url(' + getImgUrl(i).replace('20w', '200w') + ')'
              }"
            ></div>
          </div>
          <div class="h2class">{{ getCardTitle(i) | titlecase }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
