import { Component, OnInit, Input } from '@angular/core';
import { Favourite } from '../entities/favourite';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divCards").width() - 4; //25px padding on both sides

    while ($("#hiddenCards").width() > mainwidth) {
      var hiddenWidth = $("#hiddenCards").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenCards").css("font-size"));
      var h3Size = $("#divCards").find(".h3class").css("font-size");
      $("#hiddenCards").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenCards").css("font-size");
    $("#divCards").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      var viewportWidth = $(window).width();
      var viewportHeight = $(window).height();

      $("#cardsH3").css("margin-top", -15);

      if (viewportWidth <= 749 || viewportHeight <= 450)
        $("#cardsH3").css("padding-bottom", 15);
      else
        $("#cardsH3").css("padding-bottom", 25);
    }
  });
}

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  @Input() cards: Favourite[];
  @Input() prefixurl: string;
  @Input() heading: string;

  constructor() { }

  ngOnInit(): void {
    console.log('Cards init');
    ResizeHeaders();
    ApplySafariHack();
    /*
    for (let card of this.cards) {
      console.log("Card: " + card);
    }
    */
  }

  getCards(): Favourite[] {
    return this.cards;
  }

  cardExists(i: number): boolean {
    if (!this.cards) return false;
    var top = this.cards.length;
    return (i + 1 <= top);
  }

  card(i: number): Favourite {
    if (!this.cards) return null;
    var top = this.cards.length;
    if (i + 1 > top) //ensuring it doesnt call a non existing image
      i = (i + 1) - top - 1;
    return this.cards[i];
  }

  getUrl(i: number): string {
    if (this.cards[i]?.foldername) {
      //var lastSlash = this.cards[i].foldername.lastIndexOf("%2F");
      //var name = this.cards[i].foldername.substr(lastSlash + 3);

      var name = this.cards[i].foldername;
      name = name.split('%20').join('-');
      name = this.removeApostrophe(name);
      name = this.removeOther(name);
      name = "." + name.toLowerCase().split(' ').join('-');
      return name;
    } else
      return this.prefixurl;
  }

  getImgUrl(i: number): string {
    if (this.cards[i]?.image) {
      var result = 'https://raw.githubusercontent.com/aumraath/africaunwind/main/images/' + this.cards[i].image;

      //console.log("Ïmage 1 " + result);

      if (result.toLowerCase().indexOf('.webp') > -1) {
        result = result.replace('.webp', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.jpg') > -1) {
        result = result.replace('.jpg', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.jpeg') > -1) {
        result = result.replace('.jpeg', '-20w.webp');
      } else if (result.toLowerCase().indexOf('.png') > -1) {
        result = result.replace('.png', '-20w.webp');
      };

      result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?!

      //result = result.replace('%200ways', '%20ways'); //NEW ISSUE found - can't find cause - hard ugly fix //TODO: MR: Replace this with actual fix

      //console.log("Ïmage 3 " + result);

      return result;
    } else
      return '';
  }

  getCardTitle(i: number): string {
    if (this.cards[i]?.title)
      return this.cleanTitle(this.cleanText(this.cards[i].title));
    else if (this.cards[i]?.h1)
      return this.cards[i].h1;
    else
      return 'Not found';
  }

  getWhy(i: number): string {
    //console.log("WHY " + this.cards[i]?.why);
    //console.log("OTHER " + this.cards[i]?.other);
    //console.log("FOLDER " + this.cards[i]?.foldername);

    if (this.cards[i]?.why)
      return this.cards[i].why;
    else if (this.cards[i]?.other)
      return this.getOtherWithoutAllUppercase(this.cards[i].other);
    else
      return this.cards[i]?.foldername?.substr(this.cards[i]?.foldername?.length - 25);
  }

  getOtherWithoutAllUppercase(other: string): string {
    if (!other) return;
    var result = "";
    var arr = other.split(/[\r\n]+/);
    for (let index = 0; index < arr.length; index++) {
      const line = arr[index];
      if (line.length > 0 && line.split('&amp;').join('&').toUpperCase() !== line.split('&amp;').join('&')) {
        if (result.length > 0) {
          result += "\r\n" + line;
        } else {
          result = line;
        }
      }
    }
    return result;
  }

  cleanText(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("-").join(" ")
      .split("_").join(" ");
  }

  cleanTitle(text: string): string {
    if (!text) return;
    //Skip special cases
    if (text.toLowerCase() === "the kruger: ten ways")
      return text;
    //Replace others
    return text
      .split(":").join(" -")
      .split(" water based").join(" Water-Based")
      .split("malaria free").join("malaria-free")
      .split("family friendly").join("family-friendly")
      .split("amp;").join("");
  }

  removeApostrophe(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("'").join("")
      .split("’").join("")
      .split('%27').join('')
      .split('\'').join('')
      .split('`').join('');
  }

  removeOther(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("& ").join("")
      .split("+ ").join("")
      .split("(").join("")
      .split(")").join("");
  }

  scrollbarIsVisible(): boolean {
    //check if scrollbar is visible
    if ($(".cards").prop("scrollWidth") > $(".cards").prop("offsetWidth")) {
      return true;
    }
    return false;
  }

}
