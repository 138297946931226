import { Component, OnInit, Input } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UtilsService } from '../service/utils.service';
import { Page } from '../entities/page';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends MetadataService implements OnInit {

  page: Page = new Page;

  constructor(meta: Meta, title: Title, private router: Router, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
  }

  getImageForHome(): string {
    return "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/home.webp"; //Force this image!

    /*
    if (this.page.card.images.length > 1) {
      return this.page.card?.images[1]; // TODO: MR: Maybe just make this home.webp!
    }
    return this.page.card?.images[0];
    */
  }

}
