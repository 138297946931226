import { Component, OnInit, Input } from '@angular/core';
import { FolderPeer } from '../entities/folderpeer';
import { Child } from '../entities/child';
import { Parent } from '../entities/parent';
import { Uncle } from '../entities/uncle';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divBreadcrumb").width() - 52; //25px padding on both sides

    while ($("#hiddenBreadcrumb").width() > mainwidth) {
      var hiddenWidth = $("#hiddenBreadcrumb").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenBreadcrumb").css("font-size"));
      var h3Size = $("#divBreadcrumb").find(".h3class").css("font-size");
      $("#hiddenBreadcrumb").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenBreadcrumb").css("font-size");
    $("#divBreadcrumb").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divBreadcrumb").find(".h3class").css("padding-top", 0);
      $("#divBreadcrumb").find(".h3class").css("padding-bottom", 25);
    }
  });
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() parent: Parent;
  @Input() uncles: Uncle[];
  @Input() prefixurl: string;
  @Input() heading: string;

  parentUrl: string;
  parentImgUrl: string;
  parentTitle: string;

  constructor() { }

  ngOnInit(): void {
    console.log('Breadcrumb init');
    if (this.parent.image) {
      this.parentUrl = this.fixName(this.parent.foldername);
      this.parentImgUrl = this.fixUrl(this.parent.image);
      this.parentTitle = this.cleanTitle(this.cleanText(this.parent.title));
    }
    ResizeHeaders();
    ApplySafariHack();

    //TEST ALL INPUT
    /*
    console.log('Parent: ' + this.parent.title);
    console.log('Uncles lenth: ' + this.uncles.length);
    console.log('Parent url:' + this.parentUrl);
    console.log('prefixurl:' + this.prefixurl);
    console.log('heading:' + this.heading);
    console.log('parentImgUrl:' + this.parentImgUrl);
    console.log('parentTitle:' + this.parentTitle);
    */
  }

  getUrl(i: number): string {
    if (this.uncles[i]?.foldername) {
      var name = this.uncles[i].foldername;
      return this.fixName(name);
    } else
      return this.prefixurl;
  }

  fixName(name: string): string {
    name = name.split('%20').join('-');
    name = this.removeApostrophe(name);
    name = this.removeOther(name);
    name = "." + name.toLowerCase().split(' ').join('-');
    return name;
  }

  getImgUrl(i: number): string {
    if (this.uncles[i]?.image) {
      var result = this.uncles[i].image;
      return this.fixUrl(result);
    } else
      return '';
  }

  fixUrl(result: string): string {
    result = 'https://raw.githubusercontent.com/aumraath/africaunwind/main/images/' + result;
    result = result.replace('.jpg', '-20w.webp');
    result = result.replace('.jpeg', '-20w.webp');
    result = result.replace('.png', '-20w.webp');
    result = result.replace('.webp', '-20w.webp');
    result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?!
    return result;
  }

  getCardTitle(i: number): string {
    if (this.uncles[i]?.title)
      return this.cleanTitle(this.cleanText(this.uncles[i].title));
    else
      return 'Not found';
  }

  cleanText(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("-").join(" ")
      .split("_").join(" ");
  }

  cleanTitle(text: string): string {
    if (!text) return;
    return text
      .split(":").join(" -")
      .split(" water based").join(" Water-Based")
      .split("amp;").join("");
  }

  removeApostrophe(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("'").join("")
      .split("’").join("")
      .split('%27').join('')
      .split('\'').join('')
      .split('`').join('');
  }

  removeOther(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .split("& ").join("")
      .split("+ ").join("")
      .split("(").join("")
      .split(")").join("");
  }

}
