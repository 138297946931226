import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilsService } from '../service/utils.service';
import { Page } from '../entities/page';
import { Card } from '../entities/card';

import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize H3');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    // part 1
    var mainwidth = $("#divOS1").width() - 4; //25px padding on both sides

    while ($("#hiddenOS1").width() > mainwidth) {
      var hiddenWidth = $("#hiddenOS1").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenOS1").css("font-size"));
      var h3Size = $("#divOS1").find("h3").css("font-size");
      $("#hiddenOS1").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenOS1").css("font-size");
    $("#divOS1").find("h3").css("font-size", finalfontsize);

    //-------------------------------------------------------
    // part 2
    var mainwidth = $("#divOS2").width() - 4; //25px padding on both sides

    while ($("#hiddenOS2").width() > mainwidth) {
      var hiddenWidth = $("#hiddenOS2").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenOS2").css("font-size"));
      var h3Size = $("#divOS2").find(".h3class").css("font-size");
      $("#hiddenOS2").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenOS2").css("font-size");
    $("#divOS2").find(".h3class").css("font-size", finalfontsize);

    //-------------------------------------------------------
    // part 3
    var mainwidth = $("#divOS3").width() - 4; //25px padding on both sides

    while ($("#hiddenOS3").width() > mainwidth) {
      var hiddenWidth = $("#hiddenOS3").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenOS3").css("font-size"));
      var h3Size = $("#divOS3").find(".h3class").css("font-size");
      $("#hiddenOS3").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenOS3").css("font-size");
    $("#divOS3").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#ourStoryH3").css("padding-top", 0);
      $("#ourStoryH3").css("padding-bottom", 0);

      $("#randomFacts").css("padding-top", 0);
      $("#randomFacts").css("padding-bottom", 0);

      $("#myFavourite").css("padding-top", 0);
      $("#myFavourite").css("padding-bottom", 0);
    }
  });
}

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.css']
})
export class OurStoryComponent extends MetadataService implements OnInit {

  wildlife: string = "<a href='/safaris/botswana'>Botswana</a> · ‌<a href='/safaris/south-africa'>South Africa</a> · ‌<a href='/safaris/zimbabwe'>Zimbabwe</a> · ‌<a href='/safaris/zambia'>Zambia</a> · ‌<a href='/safaris/namibia'>Namibia</a> · ‌<a href='/safaris/tanzania'>Tanzania</a> · ‌<a href='/safaris/kenya'>Kenya</a>";
  walking: string = "<a href='/safaris/zambia/south-luangwa-national-park'>South‌ ‌Luangwa</a> · ‌<a href='/safaris/zambia/north-luangwa-national-park'>North‌ ‌Luangwa</a> · ‌<a href='/safaris/south-africa/kruger-national-park'>Kruger‌ ‌Private‌ ‌Reserves</a> · ‌<a href='/safaris/botswana/okavango-delta'>Okavango‌ ‌Delta</a> · ‌<a href='/safaris/tanzania/serengeti-national-park'>Serengeti‌ ‌National‌ Park</a> · ‌<a href='/safaris/tanzania/tarangire-national-park'>Tarangire‌ ‌National‌ Park</a> · ‌<a href='/safaris/tanzania/nyerere-national-park-selous-game-reserve'>Nyerere‌ ‌National‌ Park</a> · ‌<a href='/safaris/tanzania/ruaha-national-park'>Ruaha‌ ‌National‌ Park</a> · ‌<a href='/safaris/kenya/masai-mara'>Masai‌ Mara</a> · ‌<a href='/safaris/kenya/samburu-national-reserve'>Samburu‌ National‌ Reserve</a>";
  gorilla: string = "<a href='/safaris/rwanda/volcanoes-national-park'>Volcanoes‌ ‌National‌ Park</a> · <a href='/safaris/uganda/bwindi-impenetrable-national-park'>Bwindi‌ ‌Impenetrable‌ ‌National‌ Park</a>";
  scuba: string = "<a href='/islands/maldives'>Maldives</a> · <a href='/islands/seychelles'>Seychelles</a> · <a href='/islands/mozambique/anantara-bazaruto-island-resort-spa'>Bazaruto‌ ‌Archipelago</a> · <a href='/islands/mozambique/azura-quilalea-private-island'>Quirimbas‌ ‌Archipelago</a>";
  big: string = "<a href='/safaris/south-africa/kruger-national-park'>Kruger‌ Private‌ Reserves</a> · <a href='/safaris/south-africa/eastern-cape'>Eastern‌ ‌Cape</a> · <a href='/safaris/south-africa/madikwe-game-reserve/madikwe-safari-lodge'>Madikwe Game Reserve</a> · <a href='/safaris/botswana/chobe-national-park'>Chobe‌ ‌National‌ ‌Park</a> · <a href='/safaris/botswana/okavango-delta'>Okavango‌ ‌Delta</a> · <a href='/safaris/botswana/moremi-game-reserve'>Moremi‌ Game‌ ‌Reserve</a> · <a href='/safaris/kenya/masai-mara'>Masai‌ Mara</a> · <a href='/safaris/tanzania/serengeti-national-park'>Serengeti‌ ‌National‌ Park</a> · <a href='/safaris/tanzania/ngorongoro-crater'>Ngorongoro‌ ‌Crater</a> · <a href='/safaris/namibia/etosha-national-park'>Etosha‌ National‌ ‌Park</a> · <a href='/safaris/zimbabwe/hwange-national-park'>Hwange‌ National‌ ‌Park</a> · <a href='/safaris/zimbabwe/mana-pools-national-park'>Mana‌ ‌Pools‌ National‌ ‌Park</a>";
  families: string = "<a href='/cities/cape-town'>Cape‌ Town</a> · <a href='/safaris/south-africa/madikwe-game-reserve'>Madikwe Game Reserve</a> · <a href='/safaris/south-africa/eastern-cape'>Eastern‌ ‌Cape</a> · <a href='/cities/garden-route'>Garden‌ Route</a> · <a href='/cities/whale-route'>Whale‌ Route</a> · <a href='/islands/mauritius'>Mauritius</a> · <a href='/safaris/namibia'>Namibia</a> · <a href='/safaris/botswana'>Botswana</a> · <a href='/islands/mozambique'>Mozambique</a> · <a href='/safaris/tanzania'>Tanzania</a> · <a href='/safaris/kenya'>Kenya</a>";
  adventure: string = "<a href='/cities/cape-town'>Cape‌ Town</a> · <a href='/cities/victoria-falls'>Victoria‌ Falls</a> · <a href='/islands/mauritius'>Mauritius</a>";
  photography: string = "<a href='/safaris/south-africa'>South‌ ‌Africa</a> · <a href='/safaris/botswana'>Botswana</a> · <a href='/safaris/namibia'>Namibia</a>";
  nature: string = "<a href='/cities/cape-town'>Cape‌ Town</a> · ‌<a href='/cities/wine-route'>Wine‌ ‌Route</a> · ‌<a href='/cities/panorama-route'>Panorama‌ ‌Route</a> · ‌<a href='/safaris/botswana/okavango-delta'>Okavango‌ ‌Delta</a> · ‌<a href='/safaris/botswana/makgadikgadi-pans'>Makgadikgadi‌ ‌Pans</a> · ‌<a href='/cities/victoria-falls'>Victoria‌ Falls</a> · ‌<a href='/safaris/namibia/sossusvlei'>Sossusvlei</a> · ‌<a href='/safaris/namibia/skeleton-coast'>‌Skeleton‌ ‌Coast</a> · ‌<a href='/safaris/tanzania/ngorongoro-crater'>Ngorongoro‌ ‌Crater</a> · ‌<a href='/safaris/tanzania/serengeti-national-park'>Serengeti‌ ‌National‌ Park</a> · ‌<a href='/islands/zanzibar'>‌Zanzibar</a> · ‌<a href='/safaris/rwanda/volcanoes-national-park'>Volcanoes‌ ‌National‌ Park</a> · ‌<a href='/safaris/uganda/bwindi-impenetrable-national-park'>Bwindi‌ ‌Impenetrable‌ ‌National‌ Park</a> · ‌<a href='/safaris/malawi/lake-malawi'>‌Lake‌ ‌Malawi</a> · ‌<a href='/islands/mozambique/anantara-bazaruto-island-resort-spa'>Bazaruto‌ ‌Archipelago</a> · ‌<a href='/islands/mozambique/azura-quilalea-private-island'>Quirimbas‌ ‌Archipelago</a> · ‌<a href='/islands/maldives'>Maldives</a> · ‌<a href='/islands/seychelles'>Seychelles</a>";
  beaches: string = "<a href='/islands/mozambique/anantara-bazaruto-island-resort-spa'>Bazaruto‌ ‌Archipelago</a> · ‌<a href='/islands/mozambique/azura-quilalea-private-island'>Quirimbas‌ ‌Archipelago</a> · ‌<a href='/islands/maldives'>Maldives</a> · ‌<a href='/islands/seychelles'>Seychelles</a>";
  off: string = "<a href='/islands/madagascar'>Madagascar</a> · ‌<a href='/safaris/malawi'>‌Malawi</a>";

  headerimage: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/9OurStory.webp";

  images: string[] = ["https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/1OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/2OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/3OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/4OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/5OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/6OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/7OurStory.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Our%20Story/8OurStory.webp"];

  altArray: string[] = ["Our Story Cape Town", "Our Story Cerest", "Our Story Houtbay", "Our Story Mozambique", "Our Story Maldives", "Our Story Kruger", "Our Story Addo Elephant Park", "Our Story Safari"];

  page: Page = new Page();

  constructor(meta: Meta, title: Title, private router: Router, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "our story";
    this.page.card = new Card();
    this.page.card.h1 = "Our Story";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    ResizeHeaders();
    ApplySafariHack();
  }
}
