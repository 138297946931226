import { Component, OnInit, Input } from '@angular/core';
import * as $ from "jquery";

function ResizeHeaders() {
  console.log('Resize h3class');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divOurfavourites").width() - 4; //25px padding on both sides

    while ($("#hiddenOurfavourites").width() > mainwidth) {
      var hiddenWidth = $("#hiddenOurfavourites").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenOurfavourites").css("font-size"));
      var h3Size = $("#divOurfavourites").find(".h3class").css("font-size");
      $("#hiddenOurfavourites").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenOurfavourites").css("font-size");
    $("#divOurfavourites").find(".h3class").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divOurfavourites").find(".h3class").css("padding-top", 20);
      $("#divOurfavourites").find(".h3class").css("padding-bottom", 25);
      $("#ourToMore").css("margin-top", 25);
      $("#ourToMore").css("margin-bottom", 5);
    }
  });
}

@Component({
  selector: 'app-our-favourites',
  templateUrl: './our-favourites.component.html',
  styleUrls: ['./our-favourites.component.css']
})
export class OurFavouritesComponent implements OnInit {

  @Input() isSpecialPage: boolean;

  item: boolean[] = [false, false, false]
  image1: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/1Charming/1Charming-20w.webp";
  image2: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/2Stylish/1Stylish-20w.webp";
  image3: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Favourite/3Exclusive/1Exclusive-20w.webp";


  constructor() { }

  ngOnInit(): void {
    ResizeHeaders();
    ApplySafariHack();
  }

  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }

}
