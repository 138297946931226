<div id="hiddenMT1" class="hiddenH3div">
  {{ "Our Favourite Trips" | titlecase }}
</div>
<div id="hiddenMT2" class="hiddenH3div">Charming</div>
<div id="hiddenMT3" class="hiddenH3div">Stylish</div>
<div id="hiddenMT4" class="hiddenH3div">Exclusive</div>
<app-header
  [title]="page.card.h1"
  [subtitle]="page.card.h2"
  [image]="page.card?.images[0]"
  [altText]="page.card?.alt1"
></app-header>

<div class="btncontainer t-b20">
  <a href="/contact-us">
    <button aria-label="Get in touch" class="btn">Get in Touch</button>
  </a>
</div>
<div class="btncontainer t-b10">
  <button
    aria-label="Down arrow"
    class="btn downarrow"
    onClick="document.getElementById('article').scrollIntoView();"
  ></button>
</div>

<span class="container">
  <span id="article" name="article" class="p1280 abouttext">
    <div class="p100 flexmt mt35 mb-70">
      <div id="divMT1" class="left">
        <article>

          <span class="abouttext left dark">
            <h3 *ngIf="page.card.h3" class="center black">
              {{ (page.card.h3 | titlecase) }} <!--fixTitle(cleanTitle(page.cardtitle) | titlecase)-->
            </h3>

            <p *ngFor="let line of page.reason; let i = index">
              <!--Experience-->
              <span *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])" class="header black"
                >{{ utils.getWhyTitle(page.reason[i]) | titlecase }}
              </span>
              <span
                *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])"
                class="dark"
                [ngClass]="{ makebold: utils.startWithUSD(page.reason[i + 1]) }"
                [innerHTML]="page.reason[i + 1]"
              ></span>

              <!--Experience-->
              <span
                *ngIf="
                  i == 0 ||
                  (!utils.isAllUppercase(page.reason[i]) &&
                  !utils.startWithTime(page.reason[i]) &&
                    !utils.isAllUppercase(page.reason[i - 1]) &&
                    !utils.startWithTime(page.reason[i - 1]))
                "
                class="dark"
                [ngClass]="{ makebold: utils.startWithUSD(page.reason[i]) }"
                [innerHTML]="page.reason[i]"
              ></span>
            </p>
          </span>
        </article>
      </div>
    </div>
  </span>
</span>

<div class="areacontainer">
  <div id="divMT2" class="h3class center black charmingtop">Charming</div>
  <div class="gridcontainer">
    <div class="grid griditem2">
      <div
        *ngFor="let img of charmingImages; let i = index"
        class="grid-item grid__item"
        (mouseover)="scaleImage($event, i, 1)"
        (mouseout)="scaleImage($event, i, 1)"
      >
        <a [href]="getUrl(i, 1)">
          <div class="content coverimg">
            <picture class="lazy">
              <source
                [srcset]="getImgUrl(i, 1)"
                media="(min-width: 450px), (min-height: 255px)"
              />
              <source
                [srcset]="getImgUrl(i, 1)"
                media="(min-width: 300px), (min-height: 170px)"
              />
              <source
                [srcset]="getImgUrl(i, 1)"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="getImgUrl(i, 1)"
                [ngClass]="{ scaleup: charmingItem[i] }"
                [alt]="getItemAltNameFromImage(i, 1)"
              />
            </picture>
          </div>
          <div class="h4class">
            <span
              class="dark"
              [innerHTML]="getItemAltNameFromImage(i, 1)"
            ></span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div id="divMT3" class="h3class center black">Stylish</div>
  <div class="gridcontainer">
    <div class="grid griditem2">
      <div
        *ngFor="let img of stylishImages; let i = index"
        class="grid-item grid__item"
        (mouseover)="scaleImage($event, i, 2)"
        (mouseout)="scaleImage($event, i, 2)"
      >
        <a [href]="getUrl(i, 2)">
          <div class="content coverimg">
            <picture class="lazy">
              <source
                [srcset]="getImgUrl(i, 2)"
                media="(min-width: 450px), (min-height: 255px)"
              />
              <source
                [srcset]="getImgUrl(i, 2)"
                media="(min-width: 300px), (min-height: 170px)"
              />
              <source
                [srcset]="getImgUrl(i, 2)"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="getImgUrl(i, 2)"
                [ngClass]="{ scaleup: stylishItem[i] }"
                [alt]="getItemAltNameFromImage(i, 2)"
              />
            </picture>
          </div>
          <div class="h4class">
            <span
              class="dark"
              [innerHTML]="getItemAltNameFromImage(i, 2)"
            ></span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div id="divMT4" class="h3class center black">Exclusive</div>
  <div class="gridcontainer">
    <div class="grid griditem2">
      <div
        *ngFor="let img of exclusiveImages; let i = index"
        class="grid-item grid__item"
        (mouseover)="scaleImage($event, i, 3)"
        (mouseout)="scaleImage($event, i, 3)"
      >
        <a [href]="getUrl(i, 3)">
          <div class="content coverimg">
            <picture class="lazy">
              <source
                [srcset]="getImgUrl(i, 3)"
                media="(min-width: 450px), (min-height: 255px)"
              />
              <source
                [srcset]="getImgUrl(i, 3)"
                media="(min-width: 300px), (min-height: 170px)"
              />
              <source
                [srcset]="getImgUrl(i, 3)"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="getImgUrl(i, 3)"
                [ngClass]="{ scaleup: exclusiveItem[i] }"
                [alt]="getItemAltNameFromImage(i, 3)"
              />
            </picture>
          </div>
          <div class="h4class">
            <span
              class="dark"
              [innerHTML]="getItemAltNameFromImage(i, 3)"
            ></span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<app-intersection></app-intersection>
