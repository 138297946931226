export class Sibling {
  foldername: string;
  title: string;
  why: string;
  experience: string;
  stay: string;
  activities: string;
  reason: string;
  when: string;
  level: number;
  image: string;
  created: string;
  other: string;
  h1: string;
  constructor() { }
}
