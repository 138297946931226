import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../service/metadata.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from "jquery";

import { ItemService } from '../service/item.service';
import { UtilsService } from '../service/utils.service';
import { TransferStateService } from '@scullyio/ng-lib'; // TODO: MR: Add back

import { Page } from '../entities/page';
import { Card } from '../entities/card';

function ResizeHeaders() {
  console.log('Resize H3');
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $("#divDestinations").width() - 4; //25px padding on both sides

    while ($("#hiddenDestinations").width() > mainwidth) {
      var hiddenWidth = $("#hiddenDestinations").width();
      var factor = hiddenWidth / mainwidth;
      var currentFontSize = parseInt($("#hiddenDestinations").css("font-size"));
      var h3Size = $("#divDestinations").find("h3").css("font-size");
      $("#hiddenDestinations").css("font-size", currentFontSize / factor);
    };

    var finalfontsize = $("#hiddenDestinations").css("font-size");
    $("#divDestinations").find("h3").css("font-size", finalfontsize);
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#divDestinations").find("h3").css("padding-top", 0);
      $("#divDestinations").find("h3").css("padding-bottom", 0);
      $(".gridcontainer").css("padding-top", 5);
      $(".gridcontainer").css("padding-bottom", 30);
    }
  });
}

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent extends MetadataService implements OnInit {

  page: Page = new Page;

  imageHeader: string = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/Top%20Destinations/1.1.webp";

  bigItems: number[] = [0, 1, 5, 6];
  item: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  titleDest: string[] = [
    "Botswana",
    "South Africa",
    "Zimbabwe",
    "Zambia",
    "Namibia",
    "Tanzania",
    "Kenya",
    "Rwanda",
    "Malawi",
    "Uganda",
    "Mauritius",
    "Mozambique",
    "Seychelles",
    "Maldives",
    "Zanzibar",
    "Madagascar"
  ];
  images: string[] = [
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Botswana/1Botswana.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/South%20Africa/1SouthAfrica.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Zimbabwe/1Zimbabwe.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Zambia/1Zambia.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Namibia/1Namibia.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Tanzania/1Tanzania.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Kenya/1Kenya.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Rwanda/1Rwanda.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Malawi/1Malawi.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/SAFARIS/Uganda/1Uganda.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Mauritius/1Mauritius.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Mozambique/1Mozambique.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Seychelles/1Seychelles.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Maldives/1Maldives.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Zanzibar/1Zanzibar.webp",
    "https://raw.githubusercontent.com/aumraath/africaunwind/main/images/ISLANDS/Madagascar/1Madagascar.webp"
  ];
  urlDest: string[] = [
    "/safaris/botswana",
    "/safaris/south-africa",
    "/safaris/zimbabwe",
    "/safaris/zambia",
    "/safaris/namibia",
    "/safaris/tanzania",
    "/safaris/kenya",
    "/safaris/rwanda",
    "/safaris/malawi",
    "/safaris/uganda",
    "/islands/mauritius",
    "/islands/mozambique",
    "/islands/seychelles",
    "/islands/maldives",
    "/islands/zanzibar",
    "/islands/madagascar"
  ];

  constructor(meta: Meta, title: Title, private router: Router, private itemService: ItemService, private transferStateService: TransferStateService, private utils: UtilsService) {
    super(meta, title);

    this.page.routerUrl = this.router.url;
    this.page.experience = "destinations";
    this.page.isSerengeti = false;
    this.utils.findCard(this.page);
  }

  ngOnInit(): void {
    ResizeHeaders();
    ApplySafariHack();
  }

  getImgUrl(i: number): string {
    if (this.images[i]) {
      var result = this.images[i].replace('.webp', '-20w.webp');
      result = result.replace('.jpeg', '-20w.webp'); //jpeg was converted to webp when resizing
      result = result.replace('.png', '-20w.png');  //TODO: MR: Ensure this is correct
      //result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?! '
      let re = /\`/gi;
      result = result.replace(re, "\'");

      return result;
    } else
      return '';
  }

  scaleImage($event, item: number) {
    this.item[item] = $event.type == 'mouseover' ? true : false;
  }

  getItemAltNameFromImage(int: number): string {
    return this.titleDest[int];
  }

  getUrl(int: number): string {
    return this.urlDest[int];
  }

  isBig(int: number): boolean {
    return this.bigItems.indexOf(int) > -1;
  }

}
