import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Card } from '../entities/card';
import { Child } from '../entities/child';
import { Sibling } from '../entities/sibling';

import { LatLong } from '../entities/latlong';
import { Favourite } from '../entities/favourite';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  //TODO: MR: Change this on live site - maybe put in config file? //services.africaunwind.com
  //LIVE

  baseUrl = 'https://services.africaunwind.com';

  //Below doesnt work, set to * the .htaccess while testing... but change back!!
  //Local:
  //baseUrl = 'http://localhost:8080/services'
  // Online:
  //baseUrl = 'https://services.africaunwind.com'

  constructor(private http: HttpClient) { }

  findAllContent(foldername: string): Observable<Card> {
    //findcard: Finds a specific folder, with children, parent, siblings, grandparent and uncles.
    //<!-- https://services.africaunwind.com/findcard.php?find=moremi -->
    //console.log("folder");
    return this.http.get(`${this.baseUrl}/findallcontent.php?find=${foldername}`).pipe(
      map((res) => {
        console.log("findCard " + foldername);
        return res['data'];
      }),
      catchError(this.handleError));
  }

  findContentFromTxtFile(siteStatus: string, foldername: string): Observable<Card> {
    //NA: This used to read from a local folder, however, I have now added logic to read from Google Drive for quick editing fixed by Untamed... so rather use that

    // findcard: Finds a specific folder's first txt file
    // TODO: MR: Will later get... children, parent, siblings, grandparent and uncles.

    let baseUrl = this.baseUrl;
    let content = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images";
    if (siteStatus === "local") {
      baseUrl = "http://localhost:8000";
      content = "content";
    }

    //In this case foldername needs to be \ not /
    foldername = foldername.split("/").join("\\");

    return this.http.get(`${baseUrl}/findtxtfile.php?basePath=${content}&partialSearch=${foldername}`).pipe(
      map((res) => {
        console.log("findCard from txt " + foldername);
        return res['data'];
      }),
      catchError(this.handleError));
  }

  findContentFromGoogleDrive(siteStatus: string, foldername: string): Observable<Card> {
    let baseUrl = this.baseUrl;
    let content = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images";
    if (siteStatus === "local") {
      baseUrl = "http://localhost:8000";
      content = "content";
    }

    //In this case foldername needs to be \ not /
    //foldername = foldername.split("/").join("\\");

    return this.http.get(`${baseUrl}/googlecard.php?partialSearch=${foldername}`).pipe(
      map((res) => {
        console.log("Card from Google " + foldername);
        return res['data'];
      }),
      catchError(this.handleError));
  }

  findChildrenFromGoogleDrive(siteStatus: string, folderId: string): Observable<Child[]> {
    let baseUrl = this.baseUrl;
    let content = "https://raw.githubusercontent.com/aumraath/africaunwind/main/images";
    if (siteStatus === "local") {
      baseUrl = "http://localhost:8000";
      content = "content";
    }

    return this.http.get(`${baseUrl}/googlechildren.php?folderid=${folderId}`).pipe(
      map((res) => {
        console.log("Children from Google " + folderId);
        return res['data'];
      }),
      catchError(this.handleError));
  }

  /*
  findCard(foldername: string): Observable<Card> {
    //findcard: Finds a specific folder, with children, parent, siblings, grandparent and uncles.
    //<!-- https://services.africaunwind.com/findcard.php?find=moremi -->
    //console.log("folder");
    return this.http.get(`${this.baseUrl}/findcard.php?find=${foldername}`).pipe(
      map((res) => {
        //console.log("findCard");
        return res['data'];
      }),
      catchError(this.handleError));
  }
  */

  /*
  findFavourite(foldername: string): Observable<Favourite> {
    //findfavourite: Finds a specific folder, with children, parent, siblings, grandparent and uncles.
    //<!-- https://services.africaunwind.com/findfavourite.php?find=moremi -->
    //console.log("folder");
    return this.http.get(`${this.baseUrl}/findfavourite.php?find=${foldername}`).pipe(
      map((res) => {
        //console.log("findfavourite");
        return res['data'];
      }),
      catchError(this.handleError));
  }
  */

  findExperience(foldername: string, file: string): Observable<Favourite> {
    //console.log("folder");
    return this.http.get(`${this.baseUrl}/findexperience.php?find=${foldername}&file=${file}`).pipe(
      map((res) => {
        //console.log("findfavourite");
        return res['data'];
      }),
      catchError(this.handleError));
  }

  findFolders(find: string): Observable<Sibling[]> {
    // findfolders: Does a search and return all items with that string...
    //<!-- https://services.africaunwind.com/findallfolders.php?find=moremi -->
    //console.log("folder");
    return this.http.get(`${this.baseUrl}/findallfolders.php?find=${find}`).pipe(
      map((res) => { //TODO: MR: Cache
        //console.log("findFolders");
        return res['data'];
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);

    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }

  getLatLongValues(address: string): LatLong {
    //This comes from the excel spreadsheet
    //https://docs.google.com/spreadsheets/d/19PWkexBWI7d0s0tZHIYqWPkbiIfH5bfy9ZAVV8cVPik/edit?usp=sharing
    //Get foldercontent...
    //Copy to...https://www.jsonquerytool.com/
    //filter $.array[?(@.plain!="")].plain
    //copy to google
    //split by ,
    //concatenate
    //online convert to json
    var obj: LatLong[];
    obj = [
      {
        "Address": "Botswana",
        "Lat": -22.328474,
        "Long": 24.684866,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "ABC,Botswana",
        "Lat": 35.641961,
        "Long": -88.8084183,
        "Descr": "346 Muse St, Jackson, TN 38301, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "An African Anthology,Botswana",
        "Lat": -18.8887617,
        "Long": 22.5898516,
        "Descr": "Okavango Delta, Botswana Gunotsoga, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "And Beyond,Botswana",
        "Lat": -19.453596,
        "Long": 23.302649,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond,Botswana",
        "Lat": -18.5693078,
        "Long": 24.0543554,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe,Botswana",
        "Lat": -18.4256281,
        "Long": 24.7142918,
        "Descr": "Chobe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Game Lodge,Chobe,Botswana",
        "Lat": -17.84083,
        "Long": 25.076058,
        "Descr": "Chobe National Park, Serondela, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Savanna Lodge,Chobe,Botswana",
        "Lat": -17.8306483,
        "Long": 25.0533611,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Under Canvas,Chobe,Botswana",
        "Lat": -18.666616,
        "Long": 24.3905926,
        "Descr": "Chobe National Park, 267, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Valley Lodge,Chobe,Botswana",
        "Lat": -17.852453,
        "Long": 25.24392,
        "Descr": "Lesoma Valley, Chobe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngoma Safari Lodge,Chobe,Botswana",
        "Lat": -17.941432,
        "Long": 24.7092848,
        "Descr": "Ngoma Safari Lodge - Machaba Safaris™, 17.941398, 24.709321 Chobe Forest Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chobe Chilwero,Chobe,Botswana",
        "Lat": -17.827767,
        "Long": 25.137583,
        "Descr": "Chobe National Park, Kasane, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "DDS,Botswana",
        "Lat": 36.0692031,
        "Long": -95.920267,
        "Descr": "6565 S Yale Ave Suite 1104, Tulsa, OK 74136, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Footsteps,Botswana",
        "Lat": -19.978924,
        "Long": 23.429794,
        "Descr": "Airport Rd, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Great Plains,Botswana",
        "Lat": 26.889801,
        "Long": -80.1026569,
        "Descr": "165 Cat Rock Ln, Jupiter, FL 33458, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kalahari,Botswana",
        "Lat": -22.328474,
        "Long": 24.684866,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Deception Valley Lodge,Kalahari,Botswana",
        "Lat": -20.953001,
        "Long": 23.649818,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dinaka Camp,Kalahari,Botswana",
        "Lat": -20.9463391,
        "Long": 23.3433755,
        "Descr": "Haina veld, Ngamiland, North West Botswana, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kalahari Plains Camp,Kalahari,Botswana",
        "Lat": -21.3654813,
        "Long": 23.8341122,
        "Descr": "Central Kalahari, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tau Pan Camp,Kalahari,Botswana",
        "Lat": -21.4502727,
        "Long": 23.4209741,
        "Descr": "Tau Pan Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ker and Downey,Botswana",
        "Lat": -19.978924,
        "Long": 23.429794,
        "Descr": "Airport Rd, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwando,Botswana",
        "Lat": -17.8762924,
        "Long": 24.7950423,
        "Descr": "Chobe River",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Linyanti,Linyanti,Botswana",
        "Lat": -18.295555,
        "Long": 23.907756,
        "Descr": "Linyanti Campsite 1, 18°17'44.0\"S 23°54'27.9\"E, Botswana, Selebi Phikwe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Duma Tau,Linyanti,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "King's Pool,Linyanti,Botswana",
        "Lat": -18.4380621,
        "Long": 23.7069809,
        "Descr": "Ngamiland West Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lagoon Camp,Linyanti,Botswana",
        "Lat": -18.2148804,
        "Long": 23.4140753,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lebala Camp,Linyanti,Botswana",
        "Lat": -18.4143541,
        "Long": 23.5425958,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Bush Camp,Linyanti,Botswana",
        "Lat": -18.2468505,
        "Long": 23.9592905,
        "Descr": "Sedudu Gate Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Ebony Camp,Linyanti,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Tented Camp,Linyanti,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Duma Tau,Linyanti,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zarafa Camp,Linyanti,Botswana",
        "Lat": -18.5894098,
        "Long": 23.5309216,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machaba,Botswana",
        "Lat": -19.1227858,
        "Long": 23.8132141,
        "Descr": "19.122785, 23.813216 NG/19 Khwai Development Trust, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makgadikgadi Pans,Botswana",
        "Lat": -20.7913528,
        "Long": 25.4733554,
        "Descr": "Makadikadi Basin, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Kalahari,Makgadikgadi Pans,Botswana",
        "Lat": -20.4681289,
        "Long": 25.1329958,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jack's Camp,Makgadikgadi Pans,Botswana",
        "Lat": -20.4956474,
        "Long": 25.1844749,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leroo La Tau,Makgadikgadi Pans,Botswana",
        "Lat": -20.4206221,
        "Long": 24.5217782,
        "Descr": "Boteti River, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Meno A Kwena,Makgadikgadi Pans,Botswana",
        "Lat": -20.7913528,
        "Long": 25.4733554,
        "Descr": "Makadikadi Basin, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nxai Pan Camp,Makgadikgadi Pans,Botswana",
        "Lat": -19.9362573,
        "Long": 24.7764659,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "San Camp,Makgadikgadi Pans,Botswana",
        "Lat": -20.4986315,
        "Long": 25.131425,
        "Descr": "Makgadikgadi-Pans-Nationalpark Botswana Makgadikgadi-Pans-Nationalpark, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Moremi,Botswana",
        "Lat": -19.2395708,
        "Long": 23.3544449,
        "Descr": "Moremi, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Khwai River Lodge,Moremi,Botswana",
        "Lat": -19.1485425,
        "Long": 23.8001226,
        "Descr": "-19.148322, 23.800381, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Moremi,Moremi,Botswana",
        "Lat": -19.188326,
        "Long": 23.408492,
        "Descr": "Plot 244 Airport Road, Xakanaxa, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Okuti,Moremi,Botswana",
        "Lat": -19.1806824,
        "Long": 23.4104272,
        "Descr": "Okavango Delta, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Xakanaxa,Moremi,Botswana",
        "Lat": -19.1823661,
        "Long": 23.4088182,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hyena Pan Camp,Moremi,Botswana",
        "Lat": -18.9985985,
        "Long": 23.7689546,
        "Descr": "Ngamiland North, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Khwai Bush Camp,Moremi,Botswana",
        "Lat": -19.152142,
        "Long": 23.7707102,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Khwai Tented Camp,Moremi,Botswana",
        "Lat": -19.152142,
        "Long": 23.7707102,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Machaba,Moremi,Botswana",
        "Lat": -19.1227858,
        "Long": 23.8132141,
        "Descr": "19.122785, 23.813216 NG/19 Khwai Development Trust, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Mombo,Moremi,Botswana",
        "Lat": -19.2223568,
        "Long": 22.7779066,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machaba Camp,Moremi,Botswana",
        "Lat": -19.1227858,
        "Long": 23.8132141,
        "Descr": "19.122785, 23.813216 NG/19 Khwai Development Trust, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mma Dinare,Moremi,Botswana",
        "Lat": -21.88106,
        "Long": 27.751381,
        "Descr": "Mmadinare, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mombo Camp,Moremi,Botswana",
        "Lat": -19.2179449,
        "Long": 22.7850008,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Moremi Crossing,Moremi,Botswana",
        "Lat": -19.5271168,
        "Long": 23.1495068,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pelo Camp,Moremi,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Qorokwe Camp,Moremi,Botswana",
        "Lat": -19.5774866,
        "Long": 23.3817664,
        "Descr": "Qorokwe Camp, Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rra Dinare,Moremi,Botswana",
        "Lat": -19.6510095,
        "Long": 22.9058802,
        "Descr": "South-Eastern Okavango, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sable Alley Camp,Moremi,Botswana",
        "Lat": -19.1280182,
        "Long": 23.6613429,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saguni Safari Lodge,Moremi,Botswana",
        "Lat": -19.1342196,
        "Long": 23.7636917,
        "Descr": "Ngamiland North, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chief's Camp,Moremi,Botswana",
        "Lat": -19.293294,
        "Long": 22.909051,
        "Descr": "Chiefs Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Jackal and Hide,Moremi,Botswana",
        "Lat": -19.1520906,
        "Long": 23.6953918,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tuludi Camp,Moremi,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Natural Selection,Botswana",
        "Lat": -19.1280182,
        "Long": 23.6613429,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "New folder,Botswana",
        "Lat": -24.6449297,
        "Long": 25.9056619,
        "Descr": "Plot 54374, 2nd Floor, Block A, Unit 5A-11, Grand Union Building New, Gaborone, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Okavango Delta,Botswana",
        "Lat": -19.6510095,
        "Long": 22.9058802,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Abu Camp,Okavango Delta,Botswana",
        "Lat": -19.4031505,
        "Long": 22.608906,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Eagle Island Lodge,Okavango Delta,Botswana",
        "Lat": -19.5449468,
        "Long": 23.0501841,
        "Descr": "Okavango Delta, Okavango, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Okavango,Okavango Delta,Botswana",
        "Lat": -18.8887617,
        "Long": 22.5898516,
        "Descr": "Okavango Delta, Botswana Gunotsoga, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chitabe Camp,Okavango Delta,Botswana",
        "Lat": -19.5268283,
        "Long": 23.377046,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chitabe Lediba,Okavango Delta,Botswana",
        "Lat": -19.5284623,
        "Long": 23.3811247,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delta Camp,Okavango Delta,Botswana",
        "Lat": -19.532383,
        "Long": 23.105484,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Duba Explorers Camp,Okavango Delta,Botswana",
        "Lat": -18.9578312,
        "Long": 22.6743085,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Duba Plains Camp,Okavango Delta,Botswana",
        "Lat": -19.020638,
        "Long": 22.6978397,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gomoti Plains Camp,Okavango Delta,Botswana",
        "Lat": -19.5820845,
        "Long": 23.5442579,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gomoti Private Camp,Okavango Delta,Botswana",
        "Lat": -19.5024175,
        "Long": 23.5153663,
        "Descr": "Gomoti River, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gunn's Camp,Okavango Delta,Botswana",
        "Lat": -19.5274813,
        "Long": 23.1393582,
        "Descr": "Gunn's Main Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jacana Camp,Okavango Delta,Botswana",
        "Lat": -19.2814941,
        "Long": 22.6080608,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jao Camp,Okavango Delta,Botswana",
        "Lat": -19.3037063,
        "Long": 22.6020396,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kadizora Camp,Okavango Delta,Botswana",
        "Lat": -18.8887617,
        "Long": 22.5898516,
        "Descr": "Okavango Delta, Botswana Gunotsoga, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanana Camp,Okavango Delta,Botswana",
        "Lat": -19.5424545,
        "Long": 22.8623017,
        "Descr": "NG 27 B, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kiri Camp,Okavango Delta,Botswana",
        "Lat": -19.6118414,
        "Long": 23.0383833,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwara Camp,Okavango Delta,Botswana",
        "Lat": -19.19792,
        "Long": 23.416506,
        "Descr": "Western Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwetsani Camp,Okavango Delta,Botswana",
        "Lat": -19.2443237,
        "Long": 22.5362206,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Tubu,Okavango Delta,Botswana",
        "Lat": -19.276477,
        "Long": 22.4752858,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Vumbura,Okavango Delta,Botswana",
        "Lat": -19.0016228,
        "Long": 22.8613895,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mapula Lodge,Okavango Delta,Botswana",
        "Lat": -18.8548891,
        "Long": 22.7488607,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nxabega Okavango Tented Camp,Okavango Delta,Botswana",
        "Lat": -19.486001,
        "Long": 22.796095,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oddballs Camp,Okavango Delta,Botswana",
        "Lat": -19.356976,
        "Long": 22.973672,
        "Descr": "On The Edge of Chief's Island, Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oddballs Enclave,Okavango Delta,Botswana",
        "Lat": -19.5308722,
        "Long": 23.0924556,
        "Descr": "Okavango Delta, Moremi National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pom Pom Camp,Okavango Delta,Botswana",
        "Lat": -19.5844269,
        "Long": 22.842667,
        "Descr": "Pom Pom Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Baines' Camp,Okavango Delta,Botswana",
        "Lat": -19.582238,
        "Long": 23.21749,
        "Descr": "19.582238, 23.217490 NG/32 Okavango Kopano Mokoro Community Trust Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Stanley's Camp,Okavango Delta,Botswana",
        "Lat": -19.594582,
        "Long": 23.249607,
        "Descr": "NG/42 Okavango Kopano Mokoro Community Trust, Botswana, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sandibe Okavango Safari Lodge,Okavango Delta,Botswana",
        "Lat": -19.453596,
        "Long": 23.302649,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Seba Camp,Okavango Delta,Botswana",
        "Lat": -19.4048555,
        "Long": 22.5624418,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shinde Camp,Okavango Delta,Botswana",
        "Lat": -19.1200181,
        "Long": 23.1547383,
        "Descr": "Moremi, Okavango/Moremi, Botswana, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Renovating - New images to follow,Shinde Camp,Okavango Delta,Botswana",
        "Lat": -19.1200181,
        "Long": 23.1547383,
        "Descr": "Moremi, Okavango/Moremi, Botswana, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Splash Camp,Okavango Delta,Botswana",
        "Lat": -19.0736667,
        "Long": 23.3762778,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tubu Tree Camp,Okavango Delta,Botswana",
        "Lat": -19.2872664,
        "Long": 22.4643159,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Vumbura Plains,Okavango Delta,Botswana",
        "Lat": -18.9795636,
        "Long": 22.8935874,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Xudum Okavango Delta Lodge,Okavango Delta,Botswana",
        "Lat": -19.714054,
        "Long": 22.886667,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Xugana Island Lodge,Okavango Delta,Botswana",
        "Lat": -19.0677557,
        "Long": 23.1001581,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary,Botswana",
        "Lat": -19.293294,
        "Long": 22.909051,
        "Descr": "Chiefs Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savuti,Botswana",
        "Lat": -18.5682622,
        "Long": 24.0670116,
        "Descr": "Savuti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Savute Elephant  Lodge,Savuti,Botswana",
        "Lat": -18.5693078,
        "Long": 24.0543554,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Savuti,Savuti,Botswana",
        "Lat": -18.564352,
        "Long": 24.061858,
        "Descr": "Paradise Campsite, Savuti, 18°33'51.7\"S 24°03'42.7\"E, Botswana, Selebi Phikwe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savute Safari Lodge,Savuti,Botswana",
        "Lat": -18.564308,
        "Long": 24.057397,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savute Under Canvas,Savuti,Botswana",
        "Lat": -18.569435,
        "Long": 24.071571,
        "Descr": "Ngoma Gate Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savuti Camp,Savuti,Botswana",
        "Lat": -18.5969336,
        "Long": 23.6739327,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Town",
        "Lat": -33.9248685,
        "Long": 18.4240553,
        "Descr": "Cape Town, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "21 Nettleton,Cape Town",
        "Lat": -33.9351959,
        "Long": 18.3807601,
        "Descr": "19 - 23 Nettleton Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "African Elite,Cape Town",
        "Lat": -33.915771,
        "Long": 18.41816,
        "Descr": "Shop A21, Cape Quarter, De Waterkant, Dixon St, De Waterkant, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Mount Nelson Hotel,Cape Town",
        "Lat": -33.9320145,
        "Long": 18.4116669,
        "Descr": "76 Orange St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bungalow 52,Cape Town",
        "Lat": -33.9406711,
        "Long": 18.3759546,
        "Descr": "52 Fourth Beach Promenade, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camissa House,Cape Town",
        "Lat": -33.94727,
        "Long": 18.41707,
        "Descr": "28 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camps Bay Retreat,Cape Town",
        "Lat": -33.9463,
        "Long": 18.3807,
        "Descr": "Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Cadogan,Cape Town",
        "Lat": -33.9318158,
        "Long": 18.4082055,
        "Descr": "5 Upper Union St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Grace,Cape Town",
        "Lat": -33.908713,
        "Long": 18.4205508,
        "Descr": "W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8002, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape View Clifton,Cape Town",
        "Lat": -33.9323918,
        "Long": 18.3780387,
        "Descr": "232 Kloof Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cloud Nine Boutique Hotel,Cape Town",
        "Lat": -33.949412,
        "Long": 18.379279,
        "Descr": "502 Sonnekus, Argyle Road, Camps Bay, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Compass House,Cape Town",
        "Lat": -33.9278789,
        "Long": 18.3794975,
        "Descr": "154 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dock House,Cape Town",
        "Lat": -33.9058798,
        "Long": 18.418972,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ellerman House,Cape Town",
        "Lat": -33.92903,
        "Long": 18.37756,
        "Descr": "180 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorgeous George Boutique Hotel,Cape Town",
        "Lat": -33.9236057,
        "Long": 18.4200855,
        "Descr": "118 St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kensington Place,Cape Town",
        "Lat": -33.9394599,
        "Long": 18.40511,
        "Descr": "38 Kensington Cres, Higgovale Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manna Bay,Cape Town",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "More Quarters,Cape Town",
        "Lat": -33.9322299,
        "Long": 18.40814,
        "Descr": "2 Nicol St, Gardens, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Beach Lodge,Cape Town",
        "Lat": -34.4756401,
        "Long": 20.5086937,
        "Descr": "De Hoop Avenue, De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nox Apartments & Villas,Cape Town",
        "Lat": -33.9631894,
        "Long": 18.3777178,
        "Descr": "1st Floor, Suite A, 13 Totnes Ave, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "O On Kloof Boutique Hotel,Cape Town",
        "Lat": -33.9247948,
        "Long": 18.3828384,
        "Descr": "92 Kloof Rd, Sea Point, Cape Town, 8060, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Cape Town,Cape Town",
        "Lat": -33.9086313,
        "Long": 18.4162905,
        "Descr": "One&Only Cape Town, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "POD Boutique Hotel,Cape Town",
        "Lat": -33.9491694,
        "Long": 18.3797583,
        "Descr": "3 Argyle St, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Queen Victoria Hotel,Cape Town",
        "Lat": -33.906174,
        "Long": 18.4187658,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Waterfront,Cape Town",
        "Lat": -33.8996146,
        "Long": 18.4120393,
        "Descr": "100 Beach Road, Granger Bay Blvd, Mouille Point, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sea Five,Cape Town",
        "Lat": -33.9513297,
        "Long": 18.381319,
        "Descr": "5 Central Dr, Camps Bay, Cape Town, 8040, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Table Bay Hotel,Cape Town",
        "Lat": -33.9028648,
        "Long": 18.4221961,
        "Descr": "Quay, 6 W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taj Cape Town,Cape Town",
        "Lat": -33.9244744,
        "Long": 18.4197152,
        "Descr": "1 Waal Straat, corner St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Bay Hotel,Cape Town",
        "Lat": -33.95254,
        "Long": 18.3782,
        "Descr": "69 Victoria Rd, Camps Bay, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Cape Milner,Cape Town",
        "Lat": -33.927595,
        "Long": 18.409109,
        "Descr": "2 Milner Rd, Tamboerskloof, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Clarendon Bantry Bay,Cape Town",
        "Lat": -33.9280643,
        "Long": 18.3792721,
        "Descr": "158 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Constantia,Cape Town",
        "Lat": -34.0297494,
        "Long": 18.4398087,
        "Descr": "34 Spaanschemat River Rd, Constantia, Cape Town, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Long Beach,Cape Town",
        "Lat": -34.13759,
        "Long": 18.32947,
        "Descr": "1 Kirsten Ave, Kommetjie, Cape Town, 7976, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marly Boutique Hotel,Cape Town",
        "Lat": -33.9531211,
        "Long": 18.3775315,
        "Descr": "The Promenade, 201 Victoria Rd, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The One Above,Cape Town",
        "Lat": -33.911232,
        "Long": 18.420313,
        "Descr": "1 Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Silo,Cape Town",
        "Lat": -33.9084084,
        "Long": 18.4225592,
        "Descr": "Silo Square, Victoria & Alfred Waterfront, Cape Town, 8801, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spa House,Cape Town",
        "Lat": -34.0459263,
        "Long": 18.3662281,
        "Descr": "18 Ave Suzanne, Hout Bay, Cape Town, 7872, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Three Boutique Hotel,Cape Town",
        "Lat": -33.9376864,
        "Long": 18.4176354,
        "Descr": "3 Flower St, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Atlantic,Cape Town",
        "Lat": -34.065147,
        "Long": 18.36796,
        "Descr": "Chapmans Peak Dr, Hout Bay, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Twelve Apostles Hotel & Spa,Cape Town",
        "Lat": -33.9835262,
        "Long": 18.3589679,
        "Descr": "Victoria Street, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria & Alfred Hotel,Cape Town",
        "Lat": -33.90597,
        "Long": 18.4201001,
        "Descr": "V&A Complex, PierHead, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Villa Olivier,Cape Town",
        "Lat": -33.95792,
        "Long": 18.3818899,
        "Descr": "27 Ingleside Rd, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Garden Route",
        "Lat": -33.704229,
        "Long": 22.047637,
        "Descr": "South Cape DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna,Garden Route",
        "Lat": -34.0350856,
        "Long": 23.0464693,
        "Descr": "Knysna, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanonkop House,Knysna,Garden Route",
        "Lat": -34.0362538,
        "Long": 23.0220235,
        "Descr": "6 Cuthbert St, Kanonkop, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna Hollow Country Estate,Knysna,Garden Route",
        "Lat": -34.036308,
        "Long": 23.017231,
        "Descr": "5 Welbedacht Ln, Kanonkop, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leisure Isle Lodge,Knysna,Garden Route",
        "Lat": -34.066256,
        "Long": 23.055721,
        "Descr": "87 Bayswater Rd, Leisure Isle, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pezula Resort Hotel & Spa,Knysna,Garden Route",
        "Lat": -34.0693174,
        "Long": 23.0890876,
        "Descr": "Lagoon View Dr, Sparrebosch, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Rex Hotel,Knysna,Garden Route",
        "Lat": -34.0391937,
        "Long": 23.045213,
        "Descr": "8 Gray St, Knysna Central, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Turbine Boutique Hotel,Knysna,Garden Route",
        "Lat": -34.048774,
        "Long": 23.049058,
        "Descr": "36 Sawtooth Lane, Thesen Island, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Plettenberg Bay,Garden Route",
        "Lat": -34.0574892,
        "Long": 23.3644925,
        "Descr": "Plettenberg Bay, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Christiana Lodge,Plettenberg Bay,Garden Route",
        "Lat": -34.088019,
        "Long": 23.3686895,
        "Descr": "Aquarius Cl, SolarBeach, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emily Moon River Lodge,Plettenberg Bay,Garden Route",
        "Lat": -34.01012,
        "Long": 23.3729843,
        "Descr": "1 Rietvlei Rd, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hog Hollow Country Lodge,Plettenberg Bay,Garden Route",
        "Lat": -33.9731395,
        "Long": 23.4736603,
        "Descr": "Askop Road, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hunters Country House,Plettenberg Bay,Garden Route",
        "Lat": -34.052641,
        "Long": 23.275351,
        "Descr": "Off N2, 10km west of Plettenberg Bay, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurland,Plettenberg Bay,Garden Route",
        "Lat": -33.9408084,
        "Long": 23.4865442,
        "Descr": "KURLAND ESTATE N2 THE CRAGS, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tamodi Lodge & Stables,Plettenberg Bay,Garden Route",
        "Lat": -33.986913,
        "Long": 23.45518,
        "Descr": "Pinehaven rd, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Plettenberg,Plettenberg Bay,Garden Route",
        "Lat": -34.0574892,
        "Long": 23.3644925,
        "Descr": "Plettenberg Bay, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Trogon House,Plettenberg Bay,Garden Route",
        "Lat": -33.9692268,
        "Long": 23.4798545,
        "Descr": "Animal Alley, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tsala Treetop Lodge,Plettenberg Bay,Garden Route",
        "Lat": -34.0521202,
        "Long": 23.2705612,
        "Descr": "Off N2 10km W Of Plettenberg, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Port Elizabeth,Garden Route",
        "Lat": -33.98232,
        "Long": 25.54756,
        "Descr": "30 Springfield Rd, Springfield, Port Elizabeth, 6070, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "No 5 Boutique Art Hotel,Port Elizabeth,Garden Route",
        "Lat": -33.9847202,
        "Long": 25.6619275,
        "Descr": "5 Brighton Dr, Summerstrand, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Port Elizabeth,Port Elizabeth,Garden Route",
        "Lat": -33.987245,
        "Long": 25.6711184,
        "Descr": "Marine Dr & 9th Ave Summerstrand Nelson Mandela Bay, Summerstrand, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Beach Hotel,Port Elizabeth,Garden Route",
        "Lat": -33.982086,
        "Long": 25.658955,
        "Descr": "Marine Dr, Summerstrand, Port Elizabeth, 6000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Windermere,Port Elizabeth,Garden Route",
        "Lat": -33.9759451,
        "Long": 25.6447799,
        "Descr": "35 Humewood Rd, Humewood, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madagascar",
        "Lat": -18.766947,
        "Long": 46.869107,
        "Descr": "Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Antananarivo,Madagascar",
        "Lat": -18.8791902,
        "Long": 47.5079055,
        "Descr": "Antananarivo, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lokanga Boutique Hotel,Antananarivo,Madagascar",
        "Lat": -18.9257261,
        "Long": 47.5306729,
        "Descr": "lot VW 115 Ambohimitsimbina, Antananarivo 101, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Maison Gallieni,Antananarivo,Madagascar",
        "Lat": -18.9084039,
        "Long": 47.5292373,
        "Descr": "Rue Rainandriamampandry, Tananarive 182, BP, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nosy Be,Madagascar",
        "Lat": -13.3150186,
        "Long": 48.2592606,
        "Descr": "Nosy Be, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anjajavy le Lodge,Nosy Be,Madagascar",
        "Lat": -14.9914277,
        "Long": 47.2297279,
        "Descr": "Anjajavy L Hotel, Péninsule d'Anjajavy, Anjajavy Village 515, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Tsarabanjina,Nosy Be,Madagascar",
        "Lat": -13.028615,
        "Long": 48.54632,
        "Descr": "Tsarabanjina island B.P 380 Helville 2007, 501, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Miavana,Nosy Be,Madagascar",
        "Lat": -13.3150186,
        "Long": 48.2592606,
        "Descr": "Nosy Be, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Maldives",
        "Lat": 3.202778,
        "Long": 73.22068,
        "Descr": "Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Kihavah Villas,Maldives",
        "Lat": 5.2951617,
        "Long": 73.056221,
        "Descr": "Kihavah Huravalhi Island, 20215, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bandos Island Resort,Maldives",
        "Lat": 4.2695115,
        "Long": 73.4909429,
        "Descr": "North Male atoll, 08480, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Main Page",
        "Lat": 37.1359329,
        "Long": -94.5123901,
        "Descr": "5557 N Main St, Joplin, MO 64801, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "DumaTau,Linyanti,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Building - Images to follow,Little Duma Tau,Linyanti,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savuti Camp,Linyanti,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Khwai Leadwood Camp,Moremi,Botswana",
        "Lat": -19.152142,
        "Long": 23.7707102,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Jackal & Hide,Moremi,Botswana",
        "Lat": -19.1520906,
        "Long": 23.6953918,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Kwara,Okavango Delta,Botswana",
        "Lat": -19.1056353,
        "Long": 23.2656312,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Xaranna Okavango Delta Camp,Okavango Delta,Botswana",
        "Lat": -19.631978,
        "Long": 22.89855,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Savute Elephant Lodge,Savuti,Botswana",
        "Lat": -18.5693078,
        "Long": 24.0543554,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda,Botswana",
        "Lat": -18.5328932,
        "Long": 23.5220696,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda Camp,Selinda,Botswana",
        "Lat": -18.5328932,
        "Long": 23.5220696,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda Explorers Camp,Selinda,Botswana",
        "Lat": -18.5365932,
        "Long": 23.4857007,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zarafa Camp,Selinda,Botswana",
        "Lat": -18.5894098,
        "Long": 23.5309216,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambezi Queen,Botswana",
        "Lat": -17.7850204,
        "Long": 25.155695,
        "Descr": "Kasika Island Zambezi Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Princess,Zambezi Queen,Botswana",
        "Lat": -17.7850204,
        "Long": 25.155695,
        "Descr": "Kasika Island Zambezi Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambezi Queen,Zambezi Queen,Botswana",
        "Lat": -17.7850204,
        "Long": 25.155695,
        "Descr": "Kasika Island Zambezi Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kenya",
        "Lat": -0.023559,
        "Long": 37.906193,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Amboseli National Park,Kenya",
        "Lat": -2.652687,
        "Long": 37.260565,
        "Descr": "Amboseli National Park, Loitoktok District, Rift Valley, Entonet, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Amboseli Sopa Lodge,Amboseli National Park,Kenya",
        "Lat": -2.652687,
        "Long": 37.260565,
        "Descr": "Amboseli National Park, Loitoktok District, Rift Valley, Entonet, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Campi Ya Kanzi,Amboseli National Park,Kenya",
        "Lat": -2.7759331,
        "Long": 37.8917251,
        "Descr": "Mtito Andei, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Finch Hattons (Tsavo),Amboseli National Park,Kenya",
        "Lat": -2.2495147,
        "Long": 38.3376639,
        "Descr": "Tsavo East National Park, Tsavo national park, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ol Donyo Lodge (Chyulu Hills),Amboseli National Park,Kenya",
        "Lat": -2.5021328,
        "Long": 37.7535829,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Porini Amboseli Camp,Amboseli National Park,Kenya",
        "Lat": -2.652687,
        "Long": 37.260565,
        "Descr": "Amboseli National Park, Loitoktok District, Rift Valley, Entonet, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Satao Elerai Camp,Amboseli National Park,Kenya",
        "Lat": -2.652016,
        "Long": 37.26077,
        "Descr": "Elerai Conservation Area, Amboseli, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tortilis Camp,Amboseli National Park,Kenya",
        "Lat": -2.6830399,
        "Long": 37.1824302,
        "Descr": "Amboseli, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Angama Mara,Masai Mara,Kenya",
        "Lat": -1.2740832,
        "Long": 34.9707145,
        "Descr": "Suswa Mara Triangle, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Basecamp Masai Mara,Masai Mara,Kenya",
        "Lat": -1.4553795,
        "Long": 35.2115791,
        "Descr": "Suswa, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bateleur Camp,Masai Mara,Kenya",
        "Lat": -1.2484949,
        "Long": 35.0127983,
        "Descr": "Masai Mara National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cottars 1920's Camp,Masai Mara,Kenya",
        "Lat": -1.718973,
        "Long": 35.340095,
        "Descr": "Ol Derkesi Community Conservancy, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Pepper Camp,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Encounter Mara,Masai Mara,Kenya",
        "Lat": -1.419991,
        "Long": 35.329349,
        "Descr": "Mara Naboisho Conservancy, Naboisho, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entim Mara,Masai Mara,Kenya",
        "Lat": -1.4506011,
        "Long": 35.0603252,
        "Descr": "Entim camp ,serena, Narok, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Governors' Il Moran Camp,Masai Mara,Kenya",
        "Lat": -1.279692,
        "Long": 35.039384,
        "Descr": "Governors Il Moran Road, Mara Rianta, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Karen Blixen Camp,Masai Mara,Kenya",
        "Lat": -1.187604,
        "Long": 35.0575186,
        "Descr": "C13, Mara North Conservancy, Mara Rianta, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kicheche Mara Camp,Masai Mara,Kenya",
        "Lat": -1.2146551,
        "Long": 35.1651798,
        "Descr": "overlooking Olare Orok stream Mara North Conservancy, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kichwa Tembo Tented Camp,Masai Mara,Kenya",
        "Lat": -1.251391,
        "Long": 35.0110967,
        "Descr": "Masai Mara National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lerai Safari Camp,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Governors' Camp,Masai Mara,Kenya",
        "Lat": -1.2814664,
        "Long": 35.0359264,
        "Descr": "Little Governors Road, Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mahali Mzuri,Masai Mara,Kenya",
        "Lat": -1.2736407,
        "Long": 35.2228584,
        "Descr": "Motorogi Conservancy, Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Bushtops Camp,Masai Mara,Kenya",
        "Lat": -1.5039006,
        "Long": 35.3786896,
        "Descr": "Land Parcel No. 4 Siana, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Expedition Camp,Masai Mara,Kenya",
        "Lat": -1.3522336,
        "Long": 35.1627721,
        "Descr": "olare orok river, Narok, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Intrepids Camp,Masai Mara,Kenya",
        "Lat": -1.414426,
        "Long": 35.110462,
        "Descr": "Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Nyika,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Plains Camp,Masai Mara,Kenya",
        "Lat": -1.3435997,
        "Long": 35.1637327,
        "Descr": "Narok, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Naboisho Camp,Masai Mara,Kenya",
        "Lat": -1.374833,
        "Long": 35.23426,
        "Descr": "Naboisho / Mara Conservancy, Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Naibor Camp,Masai Mara,Kenya",
        "Lat": -1.370838,
        "Long": 36.759736,
        "Descr": "69 Kisembe Rd, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngare Serian,Masai Mara,Kenya",
        "Lat": -1.173912,
        "Long": 35.074698,
        "Descr": "New African Territories, Marula Manor, Marula Lane, Karen, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Offbeat Mara Camp,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ol Seki Hemingways Mara,Masai Mara,Kenya",
        "Lat": -1.3929807,
        "Long": 35.2935791,
        "Descr": "Naboisho Conservancy, Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Porini Lion Camp,Masai Mara,Kenya",
        "Lat": -1.326946,
        "Long": 35.174039,
        "Descr": "Olare-Motorogi Conservancy, Maasai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Porini Mara Camp,Masai Mara,Kenya",
        "Lat": -1.3761183,
        "Long": 35.4232828,
        "Descr": "Ol Kinyei Conservancy, Maasai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rekero Camp,Masai Mara,Kenya",
        "Lat": -1.4224117,
        "Long": 35.0850105,
        "Descr": "Rekero Camp | Asilia Africa, Talek river 2, Keekorok, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Richard's River Camp,Masai Mara,Kenya",
        "Lat": -1.2891004,
        "Long": 35.2073622,
        "Descr": "Olare Orok Conservancy, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sala's Camp,Masai Mara,Kenya",
        "Lat": -1.599788,
        "Long": 35.131775,
        "Descr": "Maasai Mara Game Reserve, Narok, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Olonana,Masai Mara,Kenya",
        "Lat": -1.2343919,
        "Long": 35.0362299,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sand River Masai Mara,Masai Mara,Kenya",
        "Lat": -1.619325,
        "Long": 35.1614861,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sarova Mara Game Camp,Masai Mara,Kenya",
        "Lat": -1.5300566,
        "Long": 35.3188861,
        "Descr": "Off C12 Road, Maasai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saruni Mara,Masai Mara,Kenya",
        "Lat": -1.2399468,
        "Long": 35.1602061,
        "Descr": "Mara North Conservancy Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serian Camp,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Speke's Camp,Masai Mara,Kenya",
        "Lat": -1.3695619,
        "Long": 35.2365104,
        "Descr": "Olare Orok River, Masai Mara, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Topi Mara Bush House,Masai Mara,Kenya",
        "Lat": -1.371912,
        "Long": 34.9381095,
        "Descr": "Maasai Mara National Reserve, Ngiro-are Road, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi,Kenya",
        "Lat": -1.2920659,
        "Long": 36.8219462,
        "Descr": "Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Eka Hotel,Nairobi,Kenya",
        "Lat": -1.3241578,
        "Long": 36.8445673,
        "Descr": "Eka Hotel, Mombasa Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hemingways Nairobi,Nairobi,Kenya",
        "Lat": -1.3427104,
        "Long": 36.7053197,
        "Descr": "Karen Mbagathi Ridge, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi Tented Camp,Nairobi,Kenya",
        "Lat": -1.3632752,
        "Long": 36.7764992,
        "Descr": "Nairobi Tented camp, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ololo Safari Lodge,Nairobi,Kenya",
        "Lat": -1.3883495,
        "Long": 36.8328425,
        "Descr": "Southern Border of the Nairobi National Park, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Emakoko,Nairobi,Kenya",
        "Lat": -1.406924,
        "Long": 36.876973,
        "Descr": "The Emakoko, Nairobi National Park, Uhuru Gardens, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Giraffe Manor,Nairobi,Kenya",
        "Lat": -1.375487,
        "Long": 36.744676,
        "Descr": "Gogo Falls Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu,Kenya",
        "Lat": 1.2154506,
        "Long": 36.954107,
        "Descr": "Samburu County, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Bedroom Camp,Samburu,Kenya",
        "Lat": 0.5895286,
        "Long": 37.6033554,
        "Descr": "Samburu National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Larsen's Tented Camp,Samburu,Kenya",
        "Lat": 0.6392573,
        "Long": 37.6743451,
        "Descr": "Larsens Tented Camp, along ewaso ngiro river, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "SaSaab Camp,Samburu,Kenya",
        "Lat": 0.6311773,
        "Long": 37.35051,
        "Descr": "Westgate, Samburu National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu Intrepids,Samburu,Kenya",
        "Lat": 0.577665,
        "Long": 37.492733,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu Sopa Lodge,Samburu,Kenya",
        "Lat": 0.6094372,
        "Long": 37.5237687,
        "Descr": "Unnamed street, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sarara Camp,Samburu,Kenya",
        "Lat": 1.0093928,
        "Long": 37.3856163,
        "Descr": "295P+Q6 Wamba, Samburu, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saruni Rhino,Samburu,Kenya",
        "Lat": 1.0197064,
        "Long": 37.7237678,
        "Descr": "Saruni Rhino, Sera Conservancy, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saruni Samburu,Samburu,Kenya",
        "Lat": 0.72,
        "Long": 37.5291667,
        "Descr": "Kalama Community Conservancy, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Malawi",
        "Lat": -13.254308,
        "Long": 34.301525,
        "Descr": "Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Malawi,Malawi",
        "Lat": -11.6701147,
        "Long": 34.6856509,
        "Descr": "Lake Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kaya Mawa,Lake Malawi,Malawi",
        "Lat": -12.0916906,
        "Long": 34.7171035,
        "Descr": "Likoma island Nkwazi Village, Likoma, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mumbo Island Camp,Lake Malawi,Malawi",
        "Lat": -13.9905196,
        "Long": 34.8323958,
        "Descr": "Lake Pass Rd, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nkwichi Lodge,Lake Malawi,Malawi",
        "Lat": -12.1920185,
        "Long": 34.7015411,
        "Descr": "Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pumulani Lodge,Lake Malawi,Malawi",
        "Lat": -14.3654393,
        "Long": 34.8241338,
        "Descr": "Nankumba, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Makokola Retreat,Lake Malawi,Malawi",
        "Lat": -14.305215,
        "Long": 35.133353,
        "Descr": "The Makokola Retreat, Mangochi, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lilongwe,Malawi",
        "Lat": -13.9626121,
        "Long": 33.7741195,
        "Descr": "Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 13 Degrees,Lilongwe,Malawi",
        "Lat": -13.9297681,
        "Long": 33.7966419,
        "Descr": "43/2/58, Mphonongo, Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Liwonde National Park,Malawi",
        "Lat": -14.844135,
        "Long": 35.3466253,
        "Descr": "Liwonde National Park, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuthengo Camp,Liwonde National Park,Malawi",
        "Lat": -14.844135,
        "Long": 35.3466253,
        "Descr": "Liwonde National Park, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mvuu Lodge,Liwonde National Park,Malawi",
        "Lat": -14.8494156,
        "Long": 35.2945432,
        "Descr": "Mvuu Camp, Liwonde National Park Liwonde, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majete Wildlife Reserve,Malawi",
        "Lat": -15.9364179,
        "Long": 34.6414028,
        "Descr": "Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mkulumadzi Lodge,Majete Wildlife Reserve,Malawi",
        "Lat": -15.8160531,
        "Long": 34.7333751,
        "Descr": "Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Namibia",
        "Lat": -22.95764,
        "Long": 18.49041,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Damaraland,Namibia",
        "Lat": -20.4262301,
        "Long": 14.0072348,
        "Descr": "Torra Conservancy, Damaraland, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Kipwe,Damaraland,Namibia",
        "Lat": -20.5375707,
        "Long": 14.429587,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Damaraland Camp,Damaraland,Namibia",
        "Lat": -20.4262301,
        "Long": 14.0072348,
        "Descr": "Torra Conservancy, Damaraland, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Desert Rhino Camp,Damaraland,Namibia",
        "Lat": -20.0245647,
        "Long": 13.8457346,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Doro Nawas Camp,Damaraland,Namibia",
        "Lat": -20.4492893,
        "Long": 14.3025694,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Etendeka Mountain Camp,Damaraland,Namibia",
        "Lat": -19.9014123,
        "Long": 13.973336,
        "Descr": "C43, Kamanjab, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hoanib Valley Camp,Damaraland,Namibia",
        "Lat": -19.374142,
        "Long": 13.1502414,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mowani Mountain Camp,Damaraland,Namibia",
        "Lat": -20.504635,
        "Long": 14.42271,
        "Descr": "Twyfelfontein, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Etosha,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Andersson's At Ongava,Etosha,Namibia",
        "Lat": -19.355867,
        "Long": 15.904069,
        "Descr": "C38 | Andersson Gate, Etosha, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Ongava,Etosha,Namibia",
        "Lat": -19.333683,
        "Long": 15.872314,
        "Descr": "C38 | Andersson Gate, Etosha, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Bush Camp,Etosha,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Lodge,Etosha,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Outpost,Etosha,Namibia",
        "Lat": -19.058018,
        "Long": 17.190761,
        "Descr": "Etosha National Park, Etosha, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ongava Lodge,Etosha,Namibia",
        "Lat": -19.333316,
        "Long": 15.871599,
        "Descr": "C38 | Andersson Gate, Etosha, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ongava Tented Camp,Etosha,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Bush Camp,Etosha,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Etosha Aoba Camp,Etosha,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Tented Camp,Etosha,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma The Fort,Etosha,Namibia",
        "Lat": -18.7360687,
        "Long": 17.0427703,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Tree Top Camp,Etosha,Namibia",
        "Lat": -18.7313095,
        "Long": 17.0466392,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Safarihoek Lodge,Etosha,Namibia",
        "Lat": -19.2426476,
        "Long": 15.2853701,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Villa Mushara,Etosha,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kunene,Namibia",
        "Lat": -19.4086317,
        "Long": 13.914399,
        "Descr": "Kunene Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nambwa Tented Lodge,Kunene,Namibia",
        "Lat": -17.87537,
        "Long": 23.3170719,
        "Descr": "M125, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Okahirongo River Camp,Kunene,Namibia",
        "Lat": -17.2143659,
        "Long": 12.4260776,
        "Descr": "North-western Namibia Marienfluss region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serra Cafema,Kunene,Namibia",
        "Lat": -17.2127074,
        "Long": 12.2018141,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Skeleton Coast,Namibia",
        "Lat": -19.9873292,
        "Long": 13.2605112,
        "Descr": "Skeleton Coast Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hoanib Skeleton Coast Camp,Skeleton Coast,Namibia",
        "Lat": -19.374142,
        "Long": 13.1502414,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Okahirongo Elephant Lodge,Skeleton Coast,Namibia",
        "Lat": -18.775276,
        "Long": 12.930922,
        "Descr": "Purros, Purros Conservancy in the Kunene Streek, Purros, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shipwreck Lodge,Skeleton Coast,Namibia",
        "Lat": -19.0660114,
        "Long": 12.5971988,
        "Descr": "Skeleton Coast Park Mowe Bay, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hoodia Desert Lodge,Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kulala Desert Lodge,Sossusvlei,Namibia",
        "Lat": -24.6148915,
        "Long": 15.703433,
        "Descr": "5F7Q9PP3+29 Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Kulala,Sossusvlei,Namibia",
        "Lat": -24.6189495,
        "Long": 15.7627201,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sossus Dune Lodge,Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sossusvlei Desert Lodge,Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sossusvlei Lodge,Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wolwedans Boulders Camp,Sossusvlei,Namibia",
        "Lat": -25.096584,
        "Long": 15.969458,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wolwedans Dune Camp,Sossusvlei,Namibia",
        "Lat": -24.7273906,
        "Long": 15.3423909,
        "Descr": "Sossusvlei, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wolwedans Dunes Lodge,Sossusvlei,Namibia",
        "Lat": -25.096584,
        "Long": 15.969458,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wolwedans Private Camp,Sossusvlei,Namibia",
        "Lat": -25.096584,
        "Long": 15.969458,
        "Descr": "Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Windhoek,Namibia",
        "Lat": -22.5608807,
        "Long": 17.0657549,
        "Descr": "Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Windhoek,Windhoek,Namibia",
        "Lat": -22.570407,
        "Long": 17.085683,
        "Descr": "Rev Michael Scott St, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Omaanda,Windhoek,Namibia",
        "Lat": -22.4255023,
        "Long": 17.436387,
        "Descr": "Khomas, Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Olive Exclusive,Windhoek,Namibia",
        "Lat": -22.5534297,
        "Long": 17.0902601,
        "Descr": "22 Promenaden Rd, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rwanda",
        "Lat": -1.940278,
        "Long": 29.873888,
        "Descr": "Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Akagera National Park,Rwanda",
        "Lat": -1.879612,
        "Long": 30.7040199,
        "Descr": "Akagera National Park, Akagera, Kayonza, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Magashi Camp,Akagera National Park,Rwanda",
        "Lat": -1.432074,
        "Long": 29.566613,
        "Descr": "Volcanoes National Park, kinigi, Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ruzizi Tented Lodge,Akagera National Park,Rwanda",
        "Lat": -1.920451,
        "Long": 30.716565,
        "Descr": "Akagera National Park, Akagera Road, Kayonza District, Eastern Province, Kayonza District, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali,Rwanda",
        "Lat": -1.9578755,
        "Long": 30.112735,
        "Descr": "Kigali City, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Marriott,Kigali,Rwanda",
        "Lat": -1.9536025,
        "Long": 30.0620968,
        "Descr": "KN 3 Avenue, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Serena Hotel,Kigali,Rwanda",
        "Lat": -1.9563339,
        "Long": 30.0627322,
        "Descr": "KN 3 Ave, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Retreat by Heaven,Kigali,Rwanda",
        "Lat": -1.9472729,
        "Long": 30.0651165,
        "Descr": "No 5, KN 29 St, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Kivu,Rwanda",
        "Lat": -2.0448431,
        "Long": 29.1855785,
        "Descr": "Lake Kivu",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Kivu Serena Hotel,Lake Kivu,Rwanda",
        "Lat": -1.7041852,
        "Long": 29.2602825,
        "Descr": "Ave de la Cooperation, Gisenyi, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nyungwe Forest National Park,Rwanda",
        "Lat": -2.48125,
        "Long": 29.215052,
        "Descr": "Nyungwe Forest National Park, KN6, Rusizi, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Nyungwe House,Nyungwe Forest National Park,Rwanda",
        "Lat": -2.446958,
        "Long": 29.103924,
        "Descr": "Gisakura, Nyamasheke Nyungwe Forest Reserve, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Volcanoes National Park,Rwanda",
        "Lat": -1.432074,
        "Long": 29.566613,
        "Descr": "Volcanoes National Park, kinigi, Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bisate Lodge,Volcanoes National Park,Rwanda",
        "Lat": -1.4442954,
        "Long": 29.5255647,
        "Descr": "Volcanoes National Park Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mountain Gorilla View Lodge,Volcanoes National Park,Rwanda",
        "Lat": -1.4284123,
        "Long": 29.554036,
        "Descr": "Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Gorilla's Nest,Volcanoes National Park,Rwanda",
        "Lat": -1.442093,
        "Long": 29.5783818,
        "Descr": "Nyonirima, Kinigi, Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabyinyo Silverback Lodge,Volcanoes National Park,Rwanda",
        "Lat": -1.4164698,
        "Long": 29.5703546,
        "Descr": "Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Kataza House,Volcanoes National Park,Rwanda",
        "Lat": -1.432074,
        "Long": 29.566613,
        "Descr": "Volcanoes National Park, kinigi, Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Kwitonda Lodge,Volcanoes National Park,Rwanda",
        "Lat": -1.4231154,
        "Long": 29.6189166,
        "Descr": "Ruhengeri, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Virunga Lodge,Volcanoes National Park,Rwanda",
        "Lat": -1.4470588,
        "Long": 29.7412992,
        "Descr": "Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "South Africa",
        "Lat": -30.559482,
        "Long": 22.937506,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Eastern Cape,South Africa",
        "Lat": -32.2968402,
        "Long": 26.419389,
        "Descr": "Eastern Cape, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Addo Elephant Park,Eastern Cape,South Africa",
        "Lat": -33.4834289,
        "Long": 25.7505798,
        "Descr": "Addo Elephant National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorah Elephant Camp,Addo Elephant Park,Eastern Cape,South Africa",
        "Lat": -33.508578,
        "Long": 25.80108,
        "Descr": "ADDO HEIGHTS RD, Addo, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Long Hope Villa,Addo Elephant Park,Eastern Cape,South Africa",
        "Lat": -33.4834289,
        "Long": 25.7505798,
        "Descr": "Addo Elephant National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Riverbend Lodge,Addo Elephant Park,Eastern Cape,South Africa",
        "Lat": -33.426058,
        "Long": 25.7533717,
        "Descr": "Elephant National Park, Zuurberg Road, Addo, 6105, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Amakhala Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.5344746,
        "Long": 26.0862196,
        "Descr": "Amakhala Game Reserve, Amakhala Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bukela Lodge,Amakhala Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.5355712,
        "Long": 26.0867324,
        "Descr": "Amakhala Game Reserve Greater, Addo, Paterson, 6015, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hillsnek Safari Camp,Amakhala Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.511417,
        "Long": 26.141302,
        "Descr": "HillsNek Safari Camp – Amakhala Game Reserve, Amakhala Game Reserve, Greater Addo, Buyskloof, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hlosi Game Lodge,Amakhala Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.550443,
        "Long": 26.1052051,
        "Descr": "Amakhala Game Reserve, Paterson, 6015, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kariega Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.575856,
        "Long": 26.603718,
        "Descr": "Kariega Game Reserve Eastern Cape, R343, Kenton-on-Sea, 6191, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kariega River Lodge,Kariega Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.661538,
        "Long": 26.560473,
        "Descr": "R72, Kenton-on-Sea, 6169, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Settlers Drift,Kariega Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.648401,
        "Long": 26.516859,
        "Descr": "Kariega Game Reserve - Settlers Drift, Settlers Drift Entrance, R72, Kenton-on-Sea, 6191, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Ecca Lodge,Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.1462395,
        "Long": 26.5250324,
        "Descr": "Kwandwe Private Game Reserve, 6170, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Fort House,Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Great Fish River Lodge,Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Melton Manor,Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.146984,
        "Long": 26.524956,
        "Descr": "R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Uplands Homestead,Kwandwe Private Reserve,Eastern Cape,South Africa",
        "Lat": -33.146984,
        "Long": 26.524956,
        "Descr": "R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4096371,
        "Long": 26.2990686,
        "Descr": "Assegaai Bush, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Kichaka Lodge,Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4096371,
        "Long": 26.2990686,
        "Descr": "Assegaai Bush, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Lentaba Lodge,Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4691545,
        "Long": 26.2521636,
        "Descr": "Within Lalibela Game Reserve, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Mark's Camp,Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4692903,
        "Long": 26.2519329,
        "Descr": "Within Lalibela Game Reserve, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Mills Manor,Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4691545,
        "Long": 26.2521636,
        "Descr": "Within Lalibela Game Reserve, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lalibela Tree Tops Lodge,Lalibela Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4691545,
        "Long": 26.2521636,
        "Descr": "Within Lalibela Game Reserve, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samara Game Reserve,Eastern Cape,South Africa",
        "Lat": -32.4404437,
        "Long": 24.7347673,
        "Descr": "Petersburg Road Off the R63 to Pearston, Graaff-Reinet, 6280, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samara Karoo Lodge,Samara Game Reserve,Eastern Cape,South Africa",
        "Lat": -32.4404437,
        "Long": 24.7347673,
        "Descr": "Petersburg Road Off the R63 to Pearston, Graaff-Reinet, 6280, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Manor at Samara,Samara Game Reserve,Eastern Cape,South Africa",
        "Lat": -32.4404437,
        "Long": 24.7347673,
        "Descr": "Petersburg Road Off the R63 to Pearston, Graaff-Reinet, 6280, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Bayethe Tented Lodge,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Eagles Crag,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4158603,
        "Long": 26.0916266,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Lobengula,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Long Lee Manor,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4668045,
        "Long": 26.0503559,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Riverdene Family Lodge,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Sarili,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Sindile,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sharmwari Explorer Camp,Shamwari Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama,Kruger,South Africa",
        "Lat": -24.4261553,
        "Long": 31.0288083,
        "Descr": "Kapama River Lodge, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Buffalo Camp,Kapama,Kruger,South Africa",
        "Lat": -24.4666743,
        "Long": 31.0509923,
        "Descr": "Kapama Buffalo Camp, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Karula,Kapama,Kruger,South Africa",
        "Lat": -24.459084,
        "Long": 31.106221,
        "Descr": "Kapama Karula, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama River Lodge,Kapama,Kruger,South Africa",
        "Lat": -24.4261553,
        "Long": 31.0288083,
        "Descr": "Kapama River Lodge, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Southern Camp,Kapama,Kruger,South Africa",
        "Lat": -24.4666743,
        "Long": 31.0509923,
        "Descr": "Kapama Buffalo Camp, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Klaserie,Kruger,South Africa",
        "Lat": -24.2564029,
        "Long": 31.2168466,
        "Descr": "The Klaserie Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Africa on Foot Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.2569911,
        "Long": 31.216675,
        "Descr": "Ross Farm, Klaserie Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Boabab Ridge,Klaserie,Kruger,South Africa",
        "Lat": -24.318845,
        "Long": 31.228981,
        "Descr": "Klaserie Private Nature Reserve Hoedspruit, PO Box 1199, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Klaserie Sands River Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.1899507,
        "Long": 31.2609757,
        "Descr": "Greater Kruger Park, Klaserie Private Nature Reserve Greater Kruger, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makumu Game Lodge,Klaserie,Kruger,South Africa",
        "Lat": -24.4045799,
        "Long": 31.3506581,
        "Descr": "Makumu Private Game Lodge, Farm Dundee 32KU Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Misava Safari Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.160441,
        "Long": 31.1725338,
        "Descr": "Klaserie Private Nature Reserve Limpopo Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nthambo Tree Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.288344,
        "Long": 31.231355,
        "Descr": "Ross Farm, Enkhulu Gate, Argyle Road, Klaserie Private Nature Reserve, Hoedspruit, 1360, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Amani,Klaserie,Kruger,South Africa",
        "Lat": -24.1726637,
        "Long": 31.1989811,
        "Descr": "Northumberland Farm Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Camp George,Klaserie,Kruger,South Africa",
        "Lat": -24.1661317,
        "Long": 31.2024748,
        "Descr": "Kruger Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Rishile Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.241788,
        "Long": 31.2233999,
        "Descr": "Tanda Tula Rishilé Camp, Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Under Construction,Tanda Tula Rishile Camp,Klaserie,Kruger,South Africa",
        "Lat": -24.241788,
        "Long": 31.2233999,
        "Descr": "Tanda Tula Rishilé Camp, Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kruger,Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamiltons Tented Camp,Kruger,Kruger,South Africa",
        "Lat": -24.6237334,
        "Long": 31.6725986,
        "Descr": "Kruger National Park Hamiltons Tented Rd Kruger Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hoyo Hoyo Safari Lodge,Kruger,Kruger,South Africa",
        "Lat": -24.5998315,
        "Long": 31.5973381,
        "Descr": "Hoyo Hoyo Safari Lodge, Kruger National Park P O Box 196, Skukuza, 1350, South Africa, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Imbali Safari Lodge,Kruger,Kruger,South Africa",
        "Lat": -24.6010456,
        "Long": 31.6298179,
        "Descr": "S145, Kruger National Park, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Narina Lodge,Kruger,Kruger,South Africa",
        "Lat": -24.96209,
        "Long": 31.563108,
        "Descr": "Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Tinga Lodge,Kruger,Kruger,South Africa",
        "Lat": -24.9714803,
        "Long": 31.5833087,
        "Descr": "Kruger Park, Tinga Legends Lodge Wy, Kruger Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pafuri Luxury Tented Camp,Kruger,Kruger,South Africa",
        "Lat": -22.4205608,
        "Long": 31.2295341,
        "Descr": "Makuleke Contract Park Northern Kruger National Park, Pafuri, 0973, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Lebombo,Kruger,Kruger,South Africa",
        "Lat": -24.4522416,
        "Long": 31.978066,
        "Descr": "N'wanetsi River, Kruger National Park Mpumalanga, Kruger Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Sweni,Kruger,Kruger,South Africa",
        "Lat": -24.454006,
        "Long": 31.98051,
        "Descr": "Sweni River, Kruger National Park, Mpumalanga, Kruger Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Outpost,Kruger,Kruger,South Africa",
        "Lat": -22.44029,
        "Long": 31.08357,
        "Descr": "The Outpost Lodge, in Kruger National Park, Pafuri/Makuleke Region Kruger National Park, 2021, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manyeleti,Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Honeyguide Khoka Moya Camp,Manyeleti,Kruger,South Africa",
        "Lat": -24.5385477,
        "Long": 31.4005605,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Honeyguide Mantobeni Camp,Manyeleti,Kruger,South Africa",
        "Lat": -24.548927,
        "Long": 31.4133426,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Manor House,Manyeleti,Kruger,South Africa",
        "Lat": -23.988384,
        "Long": 31.55474,
        "Descr": "Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Safari Lodge,Manyeleti,Kruger,South Africa",
        "Lat": -24.58871,
        "Long": 31.393812,
        "Descr": "1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arathusa Safari Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chitwa Chitwa,Sabi Sand,Kruger,South Africa",
        "Lat": -24.733491,
        "Long": 31.558849,
        "Descr": "Chitwa Chitwa Game Lodge, Gowrie Main, Sabi Sands Game Reserve, 1200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini Leadwood Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini River Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Plains,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7434619,
        "Long": 31.478254,
        "Descr": "Sabi Sands Game Reserve, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kirkman's Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.934691,
        "Long": 31.559564,
        "Descr": "Sabi Sand Private Game Reserve, Sabie Park, Sabi Sand, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leopard Hills,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Fish Eagle Villa (Ivory Lodge),Sabi Sand,Kruger,South Africa",
        "Lat": 49.2849885,
        "Long": -123.1107974,
        "Descr": "375 Water St, Vancouver, BC V6B 5C6, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Hi'Nkweni Villa (Tinga Lodge),Sabi Sand,Kruger,South Africa",
        "Lat": -24.9714803,
        "Long": 31.5833087,
        "Descr": "Kruger Park, Tinga Legends Lodge Wy, Kruger Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Ivory Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.932053,
        "Long": 31.514162,
        "Descr": "Unnamed Road, Sabie Park, Mpumalanga, South Africa, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands River Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Founders Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Pioneer Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Private Granite Suites,Sabi Sand,Kruger,South Africa",
        "Lat": -24.797097,
        "Long": 31.499004,
        "Descr": "Londolozi Game Reserve, Londolozi, Sabi Sands Game Reserve, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Tree Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.797097,
        "Long": 31.499004,
        "Descr": "Londolozi Game Reserve, Londolozi, Sabi Sands Game Reserve, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Varty Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.8003764,
        "Long": 31.5408156,
        "Descr": "Mala Mala Game Reserve, Ehlanzeni, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Rattray's Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Sable Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.8003764,
        "Long": 31.5408156,
        "Descr": "Mala Mala Game Reserve, Ehlanzeni, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nottens Bush Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.8948416,
        "Long": 31.5380542,
        "Descr": "Sabi Sand Reserve, Hazyview, Sabie Park, Mpumalanga, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Bush Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.9210498,
        "Long": 31.5215339,
        "Descr": "Sabie Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Earth Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Little Bush Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Selati Camp,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savanna Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Silvan Safari Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbambili Game Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7272657,
        "Long": 31.4765256,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Boulders Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Castleton,Sabi Sand,Kruger,South Africa",
        "Lat": -24.831821,
        "Long": 31.443259,
        "Descr": "Sabi Sand Game Reserve, Kruger National Park, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Ebony Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7865144,
        "Long": 31.4258293,
        "Descr": "Sabi Sands Game Reserve, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tengile River Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Cliff Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.7958054,
        "Long": 31.3434155,
        "Descr": "Sabi Sand Reserve, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Rock Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Safari Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Umkumbe Safari Lodge,Sabi Sand,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chapungu Tented Camp - Closing Temporarily,Thornybush,Kruger,South Africa",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jackalberry Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.5062061,
        "Long": 31.1147099,
        "Descr": "The Thornybush Game Reserve, Guernsey Rd, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kubili House,Thornybush,Kruger,South Africa",
        "Lat": -24.4701298,
        "Long": 31.1177162,
        "Descr": "Thornybush Private Game Reserve, Greater Kruger National Park, Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Monwana Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.447527,
        "Long": 31.169226,
        "Descr": "Thornybush Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "N'Kaya Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.467775,
        "Long": 31.159973,
        "Descr": "Thornybush Game Reserve, Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Malewane,Thornybush,Kruger,South Africa",
        "Lat": -24.517525,
        "Long": 31.163921,
        "Descr": "Avoca Road, Thornybush Private Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saseka Tented Camp,Thornybush,Kruger,South Africa",
        "Lat": -24.4087027,
        "Long": 31.1749392,
        "Descr": "Saseka Tented Camp, Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serondella Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.4533237,
        "Long": 31.1972123,
        "Descr": "Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shumbalala Game Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.451839,
        "Long": 31.169809,
        "Descr": "7 Koedoe St, Hoedspruit, Thornybush Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush Game Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush The River Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush Waterside Lodge - Closing Temporarily,Thornybush,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Waterbuck Lodge,Thornybush,Kruger,South Africa",
        "Lat": -24.5409989,
        "Long": 31.103568,
        "Descr": "Thornybush Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Timbavati,Kruger,South Africa",
        "Lat": -24.407347,
        "Long": 31.314404,
        "Descr": "Timbavati Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kings Camp,Timbavati,Kruger,South Africa",
        "Lat": -24.276071,
        "Long": 31.307427,
        "Descr": "Timbavati Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makanyi Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.471096,
        "Long": 31.255318,
        "Descr": "Makanyi Private Game Lodge | Luxury Safari South Africa, Kruger Park, Middle Road, Timbavati Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Motswari Safari Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.1840301,
        "Long": 31.3899329,
        "Descr": "Motswari Private Game Reserve Timbavati Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngala Safari Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.4799709,
        "Long": 31.3793106,
        "Descr": "andBeyond Ngala Private Game Reserve, Timbavati, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngala Tented Camp,Timbavati,Kruger,South Africa",
        "Lat": -24.4799709,
        "Long": 31.3793106,
        "Descr": "andBeyond Ngala Private Game Reserve, Timbavati, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rock Fig Safari Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.3067454,
        "Long": 31.2910515,
        "Descr": "Timbavati Private Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Hilltop Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.2246851,
        "Long": 31.356172,
        "Descr": "Simbavati Hilltop Lodge, Timbavati Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati River Lodge,Timbavati,Kruger,South Africa",
        "Lat": -24.2155653,
        "Long": 31.353416,
        "Descr": "Simbavati River Lodge, 4, Timbavati Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Field Camp,Timbavati,Kruger,South Africa",
        "Lat": -24.3052416,
        "Long": 31.3232445,
        "Descr": "Tanda Tula, Greater Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Safari Camp,Timbavati,Kruger,South Africa",
        "Lat": -24.3052416,
        "Long": 31.3232445,
        "Descr": "Tanda Tula, Greater Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwazulu-Natal,South Africa",
        "Lat": -28.5305539,
        "Long": 30.8958242,
        "Descr": "KwaZulu-Natal, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cleopatra Mountain Farmhouse,Kwazulu-Natal,South Africa",
        "Lat": -29.3242931,
        "Long": 29.6715869,
        "Descr": "Highmoor Road Kamberg Valley, 3300, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ghost Mountain Inn,Kwazulu-Natal,South Africa",
        "Lat": -27.6145724,
        "Long": 32.0344873,
        "Descr": "Fish Eagle Road, Mkuze, 3965, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makakatana Bay Lodge,Kwazulu-Natal,South Africa",
        "Lat": -28.2369909,
        "Long": 32.4194917,
        "Descr": "iSimangaliso Wetland Park Western Shores, Lake St, Lucia, St Lucia, 3935, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Phinda Forest Lodge,Kwazulu-Natal,South Africa",
        "Lat": -27.777501,
        "Long": 32.34935,
        "Descr": "andBeyond Phinda Private Game Reserve, KwaZulu Natal, 3936, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Phinda Mountain Lodge,Kwazulu-Natal,South Africa",
        "Lat": -27.8840147,
        "Long": 32.2839527,
        "Descr": "Phinda Mountain Lodge Rd, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Phinda Rock Lodge,Kwazulu-Natal,South Africa",
        "Lat": -27.87945,
        "Long": 32.279109,
        "Descr": "andBeyond Phinda Private Game Reserve, KwaZulu Natal, 3936, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Phinda Vlei Lodge,Kwazulu-Natal,South Africa",
        "Lat": -27.780472,
        "Long": 32.353019,
        "Descr": "andBeyond Phinda Private Game Reserve, KwaZulu Natal, 3936, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rhino Ridge Safari Lodge,Kwazulu-Natal,South Africa",
        "Lat": -28.1536333,
        "Long": 31.9547083,
        "Descr": "Hluhluwe iMfolozi Park, Mpembheni, 3937, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thanda Tented Camp,Kwazulu-Natal,South Africa",
        "Lat": -27.8472064,
        "Long": 32.1253327,
        "Descr": "Mkuze, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thonga Beach Lodge,Kwazulu-Natal,South Africa",
        "Lat": -27.3295917,
        "Long": 32.7499046,
        "Descr": "Manzengwenya, 3985, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Three Tree Hill Lodge,Kwazulu-Natal,South Africa",
        "Lat": -28.661642,
        "Long": 29.487047,
        "Descr": "Three Tree Hill Lodge, Rhenosterfontein Farm, Bergville, 3370, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe,South Africa",
        "Lat": -25.3957311,
        "Long": 26.5727815,
        "Descr": "Madikwe, 2840, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jaci's Tree Lodge,Madikwe,South Africa",
        "Lat": -24.8000714,
        "Long": 26.4605878,
        "Descr": "Unnamed Road, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jamala Madikwe Royal Safari Lodge,Madikwe,South Africa",
        "Lat": -24.8042109,
        "Long": 26.4049743,
        "Descr": "Jamala Madikwe, Madikwe Game Reserve, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe Hills Game Lodge,Madikwe,South Africa",
        "Lat": -24.724721,
        "Long": 26.347052,
        "Descr": "Madikwe Hills Private Game Lodge Madikwe Game Reserve, 2865, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe Safari Lodge,Madikwe,South Africa",
        "Lat": -24.7231893,
        "Long": 26.3877945,
        "Descr": "Madikwe Game Reserve, Madikwe, 0116, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Farm House,Madikwe,South Africa",
        "Lat": -24.7857564,
        "Long": 26.4413753,
        "Descr": "Middlepoort Farm KP93 Portion 1 - 5 Madikwe Game Reserve, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Owner's House,Madikwe,South Africa",
        "Lat": -24.7857564,
        "Long": 26.4413753,
        "Descr": "Middlepoort Farm KP93 Portion 1 - 5 Madikwe Game Reserve, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru River House,Madikwe,South Africa",
        "Lat": -24.7857564,
        "Long": 26.4413753,
        "Descr": "Middlepoort Farm KP93 Portion 1 - 5 Madikwe Game Reserve, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Madikwe,Madikwe,South Africa",
        "Lat": -24.7775898,
        "Long": 26.1854077,
        "Descr": "The Royal Madikwe Safari Lodge, Wonderboom gate Madikwe Game Reserve Madikwe, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tuningi Safari Lodge,Madikwe,South Africa",
        "Lat": -24.7744814,
        "Long": 26.2103132,
        "Descr": "Madikwe Game Reserve, Madikwe, 2840, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pilanesberg,South Africa",
        "Lat": -25.240278,
        "Long": 27.075833,
        "Descr": "Pilanesberg, Pilanesberg National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Black Rhino Game Lodge,Pilanesberg,South Africa",
        "Lat": -25.1648526,
        "Long": 26.9816554,
        "Descr": "Black Rhino Game Reserve R565 Pilanesberg, 7130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ivory Tree Game Lodge,Pilanesberg,South Africa",
        "Lat": -25.1859503,
        "Long": 27.1446755,
        "Descr": "Kgabo Drive, Bagatla Gate, Pilanesberg National Park, 0316, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sheperd's Tree Game Lodge,Pilanesberg,South Africa",
        "Lat": -25.3017762,
        "Long": 26.9811726,
        "Descr": "R565 Pilanesberg National Park Bojanala Sun City, Pilanesberg National Park, 0316, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tshukudu Bush House,Pilanesberg,South Africa",
        "Lat": -25.2999262,
        "Long": 27.0286712,
        "Descr": "Pilanesberg National Park, 2000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "South African Cities & Surrounds,South Africa",
        "Lat": -30.559482,
        "Long": 22.937506,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9248685,
        "Long": 18.4240553,
        "Descr": "Cape Town, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "21 Nettleton,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9351959,
        "Long": 18.3807601,
        "Descr": "19 - 23 Nettleton Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "African Elite,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.915771,
        "Long": 18.41816,
        "Descr": "Shop A21, Cape Quarter, De Waterkant, Dixon St, De Waterkant, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Mount Nelson Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9320145,
        "Long": 18.4116669,
        "Descr": "76 Orange St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bungalow 52,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9406711,
        "Long": 18.3759546,
        "Descr": "52 Fourth Beach Promenade, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camissa House,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.94727,
        "Long": 18.41707,
        "Descr": "28 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camps Bay Retreat,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9463,
        "Long": 18.3807,
        "Descr": "Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Cadogan,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9318158,
        "Long": 18.4082055,
        "Descr": "5 Upper Union St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Grace,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.908713,
        "Long": 18.4205508,
        "Descr": "W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8002, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape View Clifton,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9323918,
        "Long": 18.3780387,
        "Descr": "232 Kloof Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cloud Nine Boutique Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.929816,
        "Long": 18.4084645,
        "Descr": "12 Kloof Nek Rd, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Compass House,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9278789,
        "Long": 18.3794975,
        "Descr": "154 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dock House,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9058798,
        "Long": 18.418972,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ellerman House,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.92903,
        "Long": 18.37756,
        "Descr": "180 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorgeous George Boutique Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9236057,
        "Long": 18.4200855,
        "Descr": "118 St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kensington Place,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9394599,
        "Long": 18.40511,
        "Descr": "38 Kensington Cres, Higgovale Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manna Bay,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "More Quarters,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9322299,
        "Long": 18.40814,
        "Descr": "2 Nicol St, Gardens, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Beach Lodge,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -34.4756401,
        "Long": 20.5086937,
        "Descr": "De Hoop Avenue, De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nox Apartments & Villas,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9631894,
        "Long": 18.3777178,
        "Descr": "1st Floor, Suite A, 13 Totnes Ave, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "O On Kloof Boutique Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9247948,
        "Long": 18.3828384,
        "Descr": "92 Kloof Rd, Sea Point, Cape Town, 8060, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Cape Town,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9086313,
        "Long": 18.4162905,
        "Descr": "One&Only Cape Town, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "POD Boutique Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9491694,
        "Long": 18.3797583,
        "Descr": "3 Argyle St, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Queen Victoria Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.906174,
        "Long": 18.4187658,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Waterfront,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.8996146,
        "Long": 18.4120393,
        "Descr": "100 Beach Road, Granger Bay Blvd, Mouille Point, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Table Bay Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9028648,
        "Long": 18.4221961,
        "Descr": "Quay, 6 W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taj Cape Town,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9244744,
        "Long": 18.4197152,
        "Descr": "1 Waal Straat, corner St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Bay Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9028648,
        "Long": 18.4221961,
        "Descr": "Quay, 6 W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Cape Milner,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.927595,
        "Long": 18.409109,
        "Descr": "2 Milner Rd, Tamboerskloof, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Clarendon Bantry Bay,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9280643,
        "Long": 18.3792721,
        "Descr": "158 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Constantia,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -34.0297494,
        "Long": 18.4398087,
        "Descr": "34 Spaanschemat River Rd, Constantia, Cape Town, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Long Beach,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -34.13759,
        "Long": 18.32947,
        "Descr": "1 Kirsten Ave, Kommetjie, Cape Town, 7976, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marly Boutique Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9531211,
        "Long": 18.3775315,
        "Descr": "The Promenade, 201 Victoria Rd, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The One Above,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": 37.7904356,
        "Long": -122.4012663,
        "Descr": "1 Sansome St #3500, San Francisco, CA 94104, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Silo,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9084084,
        "Long": 18.4225592,
        "Descr": "Silo Square, Victoria & Alfred Waterfront, Cape Town, 8801, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spa House,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -34.0459263,
        "Long": 18.3662281,
        "Descr": "18 Ave Suzanne, Hout Bay, Cape Town, 7872, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Atlantic,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -34.065147,
        "Long": 18.36796,
        "Descr": "Chapmans Peak Dr, Hout Bay, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Twelve Apostles Hotel & Spa,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9835262,
        "Long": 18.3589679,
        "Descr": "Victoria Street, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria & Alfred Hotel,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.90363,
        "Long": 18.420529,
        "Descr": "19 Dock Rd, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Durban,South African Cities & Surrounds,South Africa",
        "Lat": -29.8586804,
        "Long": 31.0218404,
        "Descr": "Durban, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Canelands Beach Club & Spa,Durban,South African Cities & Surrounds,South Africa",
        "Lat": -29.5025691,
        "Long": 31.2389144,
        "Descr": "Salt Rock, Dolphin Coast, 4391, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Teremok Marine Lodge,Durban,South African Cities & Surrounds,South Africa",
        "Lat": -29.735195,
        "Long": 31.0807851,
        "Descr": "49 Marine Dr, Umhlanga Rocks, Durban, 4320, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oyster Box,Durban,South African Cities & Surrounds,South Africa",
        "Lat": -29.7284034,
        "Long": 31.0872415,
        "Descr": "2 Lighthouse Rd, Umhlanga, 4319, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbali Resort,Durban,South African Cities & Surrounds,South Africa",
        "Lat": -29.8590713,
        "Long": 31.0220286,
        "Descr": "66 Joe Slovo St, Durban Central, Durban, 4001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.8341255,
        "Long": 23.4537973,
        "Descr": "Garden Route National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0400155,
        "Long": 23.0441819,
        "Descr": "21 Waterfront Dr, Knysna Central, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanonkop House,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0362538,
        "Long": 23.0220235,
        "Descr": "6 Cuthbert St, Kanonkop, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna Hollow Country Estate,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.036308,
        "Long": 23.017231,
        "Descr": "5 Welbedacht Ln, Kanonkop, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leisure Isle Lodge,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.066256,
        "Long": 23.055721,
        "Descr": "87 Bayswater Rd, Leisure Isle, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pezula Resort Hotel & Spa,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0693174,
        "Long": 23.0890876,
        "Descr": "Lagoon View Dr, Sparrebosch, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Rex Hotel,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0391937,
        "Long": 23.045213,
        "Descr": "8 Gray St, Knysna Central, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Turbine Boutique Hotel,Knysna,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.048774,
        "Long": 23.049058,
        "Descr": "36 Sawtooth Lane, Thesen Island, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0574892,
        "Long": 23.3644925,
        "Descr": "Plettenberg Bay, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Christiana Lodge,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.088019,
        "Long": 23.3686895,
        "Descr": "Aquarius Cl, SolarBeach, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emily Moon River Lodge,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.01012,
        "Long": 23.3729843,
        "Descr": "1 Rietvlei Rd, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hog Hollow Country Lodge,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9731395,
        "Long": 23.4736603,
        "Descr": "Askop Road, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hunters Country House,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.052641,
        "Long": 23.275351,
        "Descr": "Off N2, 10km west of Plettenberg Bay, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurland,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9408084,
        "Long": 23.4865442,
        "Descr": "KURLAND ESTATE N2 THE CRAGS, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tamodi Lodge & Stables,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.986913,
        "Long": 23.45518,
        "Descr": "Pinehaven rd, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Plettenberg,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": 40.7274145,
        "Long": -74.0059792,
        "Descr": "175 Varick St, New York, NY 10014, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Trogon House,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9692268,
        "Long": 23.4798545,
        "Descr": "Animal Alley, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tsala Treetop Lodge,Plettenberg Bay,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.0521202,
        "Long": 23.2705612,
        "Descr": "Off N2 10km W Of Plettenberg, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Port Elizabeth,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9608369,
        "Long": 25.6022423,
        "Descr": "Port Elizabeth, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "No 5 Boutique Art Hotel,Port Elizabeth,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9847202,
        "Long": 25.6619275,
        "Descr": "5 Brighton Dr, Summerstrand, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Port Elizabeth,Port Elizabeth,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.987245,
        "Long": 25.6711184,
        "Descr": "Marine Dr & 9th Ave Summerstrand Nelson Mandela Bay, Summerstrand, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Windermere,Port Elizabeth,Garden Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9759451,
        "Long": 25.6447799,
        "Descr": "35 Humewood Rd, Humewood, Port Elizabeth, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.2041028,
        "Long": 28.0473051,
        "Descr": "Johannesburg, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "AtholPlace House & Villa,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.11175,
        "Long": 28.06404,
        "Descr": "90 Pretoria Ave, Atholl, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "D'Oreale Grande,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1443844,
        "Long": 28.2213325,
        "Descr": "64 Jones Rd, O.R. Tambo, Kempton Park, 1620, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fairlawns Boutique Hotel & Spa,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.0795011,
        "Long": 28.0749836,
        "Descr": "1 Alma Rd, Morningside, Johannesburg, 2052, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Hotel The Westcliff,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1710223,
        "Long": 28.0324839,
        "Descr": "67 Jan Smuts Ave, Westcliff, Johannesburg, 2132, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Intercontinental Hotel,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1068045,
        "Long": 28.0525693,
        "Descr": "Corner of Maude &, 5th St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Michelangelo Towers,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1063997,
        "Long": 28.0542906,
        "Descr": "8 Maude St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mount Grace Hotel & Spa,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1336131,
        "Long": 28.0676215,
        "Descr": "1 Melrose Blvd, Melrose Arch, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Sandton,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1041779,
        "Long": 28.0614167,
        "Descr": "Rivonia Rd &, Daisy St, Sandown, Sandton, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Maslow,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.0990371,
        "Long": 28.0588493,
        "Descr": "Rivonia Rd &, Grayston Dr, Gauteng, Sandton, 2031, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Michelangelo Hotel,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1069699,
        "Long": 28.0566358,
        "Descr": "135 West St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Peech Hotel,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.1365329,
        "Long": 28.0588454,
        "Descr": "61 North St, Melrose North, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Johannesburg,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.16454,
        "Long": 28.05939,
        "Descr": "17 4th Ave, Houghton Estate, Johannesburg, 2198, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Saxon Hotel,Johannesburg,South African Cities & Surrounds,South Africa",
        "Lat": -26.11212,
        "Long": 28.03561,
        "Descr": "36 Saxon Rd, Sandhurst, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Birkenhead House,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.409963,
        "Long": 19.280555,
        "Descr": "119 11th St, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Farm 215,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.567574,
        "Long": 19.5137787,
        "Descr": "Farm 215 - Hartebeeskloof & Koudeberg, Baardskeerdersbos, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Forest Lodge,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.549873,
        "Long": 19.413024,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Garden Lodge,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.539996,
        "Long": 19.412938,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Private Villas,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lekkerwater Beach Lodge,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.4502064,
        "Long": 20.6345114,
        "Descr": "De Hoop Marine Protected Area, South Africa, Overberg DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Ocean House,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.473385,
        "Long": 20.5163438,
        "Descr": "De Hoop Avenue De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One Marine Drive Boutique Hotel,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.4221363,
        "Long": 19.234668,
        "Descr": "1 Marine Dr, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Schulphoek House,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.4269554,
        "Long": 19.2004925,
        "Descr": "181 Piet Retief Cres, Sand Bay, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marine Hotel,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.418038,
        "Long": 19.24481,
        "Descr": "Main Road, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Thatch House,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Babylonstoren,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.822505,
        "Long": 18.929985,
        "Descr": "Babylonstoren, Klapmuts - Simondium Rd, Simondium, 7670, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Frannschhoek Country House,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Cle des Montagnes,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.912665,
        "Long": 19.116629,
        "Descr": "13 Daniel Hugo St, Franschhoek, Cape Town, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Residence,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": 37.7904356,
        "Long": -122.4012663,
        "Descr": "1 Sansome St #3500, San Francisco, CA 94104, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Franschhoek Hotel & Spa,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.935809,
        "Long": 19.110409,
        "Descr": "16 Minor Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Quartier Francais,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": 49.2849885,
        "Long": -123.1107974,
        "Descr": "375 Water St, Vancouver, BC V6B 5C6, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leeu House,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.913255,
        "Long": 19.121227,
        "Descr": "12 Huguenot Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mont Rochelle,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.912819,
        "Long": 19.1110279,
        "Descr": "1499 Dassenberg Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Franschhoek,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9083276,
        "Long": 19.1170728,
        "Descr": "68 Huguenot Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.8987481,
        "Long": 19.1036181,
        "Descr": "Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paarl,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.7639,
        "Long": 18.9189,
        "Descr": "Suid-Agter-Paarl Rd, Southern Paarl, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grand Dedale Country House,Paarl,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.6269933,
        "Long": 19.0830857,
        "Descr": "Bovlei Rd, Wellington, 7654, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grande Roche,Paarl,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Light House Boutique Suites,Paarl,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.77236,
        "Long": 18.954887,
        "Descr": "2 Lille, Courtrai, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bonne Esperance,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9369809,
        "Long": 18.8664217,
        "Descr": "Van Riebeeck St &, Neethling St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delaire Graff Estate,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9202951,
        "Long": 18.9218332,
        "Descr": "Helshoogte Rd, Stellenbosch, 7602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lanzerac Hotel & Spa,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.938299,
        "Long": 18.894264,
        "Descr": "1 Lanzerac Rd, Jonkershoek, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majeka House,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9680216,
        "Long": 18.860642,
        "Descr": "26 Houtkapper St, Paradyskloof, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oude Werf Hotel,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9378177,
        "Long": 18.8619977,
        "Descr": "30 Church St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spier Hotel,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9735508,
        "Long": 18.7821798,
        "Descr": "R310 Baden Powell Dr, Stellenbosch, 7603, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Welgevonden,South Africa",
        "Lat": -33.9007534,
        "Long": 18.853663,
        "Descr": "Welgevonden Estate, Cloetesville, Stellenbosch, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fifty Seven Waterberg,Welgevonden,South Africa",
        "Lat": -24.24129,
        "Long": 27.745907,
        "Descr": "Welgevonden Private Game Reserve, R33, Vaalwater, 0530, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mhondoro Safari Lodge,Welgevonden,South Africa",
        "Lat": -24.3389189,
        "Long": 27.8069534,
        "Descr": "Mhondoro Safari Lodge & Villa, Boekenhout Kloof, Site 40 Welgevonden Private Game Reserve, Vaalwater, 0530, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanzania",
        "Lat": -6.369028,
        "Long": 34.888822,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha,Tanzania",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha Coffee Lodge,Arusha,Tanzania",
        "Lat": -3.3745016,
        "Long": 36.6439397,
        "Descr": "Old Dodoma Road, Burka Coffee Estate, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamerkop House by Lemala,Arusha,Tanzania",
        "Lat": -3.3950495,
        "Long": 36.7359786,
        "Descr": "Excel E Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hatari Lodge,Arusha,Tanzania",
        "Lat": -3.2265396,
        "Long": 36.8572379,
        "Descr": "Hatari Lodge (Inside Arusha National Park), Momella Road Arousha National Park TZ, 23100, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kili Villa,Arusha,Tanzania",
        "Lat": -3.3711784,
        "Long": 36.6873993,
        "Descr": "Azimio St, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Legendary Lodge,Arusha,Tanzania",
        "Lat": -3.3407072,
        "Long": 36.640244,
        "Descr": "Selian Coffee Estate, Namanga Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machweo Wellness Retreat,Arusha,Tanzania",
        "Lat": -3.3807342,
        "Long": 36.7450619,
        "Descr": "Onsea Moivaro Road / Baraa Road Arusha TZ, 1259, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shu'Mata Camp,Arusha,Tanzania",
        "Lat": -3.22,
        "Long": 36.909443,
        "Descr": "Momella Rd, ARUSHA, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Tanganyika & Mahale Mountains National Park,Tanzania",
        "Lat": -6.266667,
        "Long": 29.933332,
        "Descr": "Mahale Mountain National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gombe Lodge,Lake Tanganyika & Mahale Mountains National Park,Tanzania",
        "Lat": -6.129117,
        "Long": 29.7318569,
        "Descr": "Mbali Mbali Mahale Lodge, Mahale National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Greystroke Mahale,Lake Tanganyika & Mahale Mountains National Park,Tanzania",
        "Lat": -6.266667,
        "Long": 29.933332,
        "Descr": "Mahale Mountain National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lupita Island,Lake Tanganyika & Mahale Mountains National Park,Tanzania",
        "Lat": -6.266667,
        "Long": 29.933332,
        "Descr": "Mahale Mountain National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mahale Lodge,Lake Tanganyika & Mahale Mountains National Park,Tanzania",
        "Lat": -6.266667,
        "Long": 29.933332,
        "Descr": "Mahale Mountain National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngorongoro Crater,Tanzania",
        "Lat": -3.1617522,
        "Long": 35.5876697,
        "Descr": "Ngorongoro Crater, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entamanu Ngorongoro Camp,Ngorongoro Crater,Tanzania",
        "Lat": -3.1010309,
        "Long": 35.5172358,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entamanu Private Camp,Ngorongoro Crater,Tanzania",
        "Lat": -3.240128,
        "Long": 35.49691,
        "Descr": "Ngorongoro Conservation Area, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gibb's Farm,Ngorongoro Crater,Tanzania",
        "Lat": -3.2981479,
        "Long": 35.6646589,
        "Descr": "Gibb's Farm, Karatu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kitela Lodge,Ngorongoro Crater,Tanzania",
        "Lat": -3.3039762,
        "Long": 35.6667927,
        "Descr": "Karatu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Ndutu,Ngorongoro Crater,Tanzania",
        "Lat": -2.9807949,
        "Long": 34.962502,
        "Descr": "P.O.Box 14529, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Ngorongoro,Ngorongoro Crater,Tanzania",
        "Lat": -3.1291746,
        "Long": 35.669699,
        "Descr": "Ngorongoro Conservation Area, Ngorongoro, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Neptune Ngorongoro Luxury Lodge,Ngorongoro Crater,Tanzania",
        "Lat": -3.304915,
        "Long": 35.5907734,
        "Descr": "Ngorongoro Crater, Ngorongoro Conservation Area, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngorongoro Crater Lodge,Ngorongoro Crater,Tanzania",
        "Lat": -3.240128,
        "Long": 35.49691,
        "Descr": "Ngorongoro Conservation Area, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngorongoro Farm House,Ngorongoro Crater,Tanzania",
        "Lat": -3.338896,
        "Long": 35.595703,
        "Descr": "Oldeani Road, Karatu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngorongoro Serena Safari Lodge,Ngorongoro Crater,Tanzania",
        "Lat": -3.1740035,
        "Long": 35.5638921,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngorongoro Sopa Lodge,Ngorongoro Crater,Tanzania",
        "Lat": -3.154006,
        "Long": 35.676595,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Ngorongoro Crater Camp,Ngorongoro Crater,Tanzania",
        "Lat": -3.1241332,
        "Long": 35.6639308,
        "Descr": "Ngorongoro Crater, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Highlands Ngorongoro,Ngorongoro Crater,Tanzania",
        "Lat": -2.9642076,
        "Long": 35.6616395,
        "Descr": "2°58'09.7\"S 35°39'36 Calle Tanzania, Ciudad Juárez 32697, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Manor at Ngorongoro,Ngorongoro Crater,Tanzania",
        "Lat": -3.199014,
        "Long": 35.679315,
        "Descr": "Ngorongoro forest, Ngorongoro Conservation Area, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ruaha National Park,Tanzania",
        "Lat": -7.7246775,
        "Long": 35.6188924,
        "Descr": "Visit Ruaha National Park, Iringa, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ikuka Safari Camp,Ruaha National Park,Tanzania",
        "Lat": -7.5422039,
        "Long": 34.8799312,
        "Descr": "Ruaha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jabali Private House,Ruaha National Park,Tanzania",
        "Lat": -7.3883852,
        "Long": 34.9560941,
        "Descr": "Ruaha Ave, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jabali Ridge,Ruaha National Park,Tanzania",
        "Lat": -7.6156344,
        "Long": 34.8377218,
        "Descr": "Ruaha National Park 01, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jongomero Camp,Ruaha National Park,Tanzania",
        "Lat": -6.8276914,
        "Long": 39.2765065,
        "Descr": "Gerezani St, Dar es Salaam, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigelia Ruaha,Ruaha National Park,Tanzania",
        "Lat": -7.5582275,
        "Long": 34.9585819,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwihala Camp,Ruaha National Park,Tanzania",
        "Lat": -7.510892,
        "Long": 34.790359,
        "Descr": "Ruaha Ruaha National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rubondo Island,Tanzania",
        "Lat": -2.4102809,
        "Long": 31.8790306,
        "Descr": "Rubondo Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rubondo Island Camp,Rubondo Island,Tanzania",
        "Lat": -2.301424,
        "Long": 31.860767,
        "Descr": "Rubondo Island Camp, Rubondo Island National Park, Rubondo Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selous Game Reserve,Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Beho Beho Camp,Selous Game Reserve,Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kiba Point Selous,Selous Game Reserve,Tanzania",
        "Lat": -7.7789106,
        "Long": 38.0098052,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Roho ya Selous,Selous Game Reserve,Tanzania",
        "Lat": -8.1759457,
        "Long": 37.4001886,
        "Descr": "Ngorongoro Road, Dar es Salaam, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sand Rivers Selous,Selous Game Reserve,Tanzania",
        "Lat": -7.7793062,
        "Long": 38.0087575,
        "Descr": "Rufiji, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selous Serena Camp,Selous Game Reserve,Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Selous Game Reserve Morogoro Region, Mkalinzu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serena Mivumo River Lodge,Selous Game Reserve,Tanzania",
        "Lat": -7.8095001,
        "Long": 37.9002127,
        "Descr": "Rufiji River, Selous Game Reserve Pwani Region, Matambwe, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Siwandu Camp,Selous Game Reserve,Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Asanja Moru,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bologonya Under Canvas,Serengeti National Park,Tanzania",
        "Lat": -1.84284,
        "Long": 35.118269,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dunia Camp,Serengeti National Park,Tanzania",
        "Lat": -2.6556774,
        "Long": 34.7438161,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ehlane Plains Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grumeti Serengeti Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -2.15978,
        "Long": 34.2208709,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "KasKaz Mara Camp,Serengeti National Park,Tanzania",
        "Lat": -1.56879,
        "Long": 34.789758,
        "Descr": "Singita Grumeti, Mara River, Serengeti, Serengeti 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kimondo Migration Camp,Serengeti National Park,Tanzania",
        "Lat": -1.5578865,
        "Long": 34.9281677,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Klein's Camp,Serengeti National Park,Tanzania",
        "Lat": -1.835334,
        "Long": 35.246006,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kubu Kubu Tented Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.3280488,
        "Long": 34.7694965,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kusini Kopjes Kambi,Serengeti National Park,Tanzania",
        "Lat": -2.6181593,
        "Long": 34.7349969,
        "Descr": "Ole Serai Luxury Camps - Moru Kopjes, Ole Serai Luxury Camps - Moru Kopjes Head Office: Wellworth Collection, Box 361 DSM Tanzania Dar es Salaam, 00361, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lahia Tented Lodge,Serengeti National Park,Tanzania",
        "Lat": -1.8936005,
        "Long": 34.6856509,
        "Descr": "Serengeti, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Masek Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -3.0291505,
        "Long": 35.0472142,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lamai Serengeti Camp,Serengeti National Park,Tanzania",
        "Lat": -1.6457766,
        "Long": 34.9218789,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Legendary Serengeti Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Ewanjan,Serengeti National Park,Tanzania",
        "Lat": -2.4967961,
        "Long": 34.7587407,
        "Descr": "Central, Serengeti National Park, Seronera, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Kuria Hills,Serengeti National Park,Tanzania",
        "Lat": -1.6495559,
        "Long": 34.8891664,
        "Descr": "Lemala Kuria Hills Camp, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Mara,Serengeti National Park,Tanzania",
        "Lat": -1.5686143,
        "Long": 34.8734264,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Nanyukie,Serengeti National Park,Tanzania",
        "Lat": -1.5686143,
        "Long": 34.8734264,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mara Under Canvas,Serengeti National Park,Tanzania",
        "Lat": -1.838591,
        "Long": 35.113485,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mbono Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mila Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mkombe's House Lamai,Serengeti National Park,Tanzania",
        "Lat": -1.6457766,
        "Long": 34.9218789,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mwiba Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.086524,
        "Long": 34.493095,
        "Descr": "Singita Grumeti, Serengeti National Park, 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Namiri Plains,Serengeti National Park,Tanzania",
        "Lat": -2.547485,
        "Long": 35.0990313,
        "Descr": "Bariadi, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ndutu Under Canvas,Serengeti National Park,Tanzania",
        "Lat": -1.838591,
        "Long": 35.113485,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Olakira Migration Camp,Serengeti National Park,Tanzania",
        "Lat": -2.4145616,
        "Long": 34.8920946,
        "Descr": "Seronera, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One Nature Nyaruswiga,Serengeti National Park,Tanzania",
        "Lat": -2.3883125,
        "Long": 34.7578125,
        "Descr": "JQ65+M4, Seronera, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Kichakani,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Kusini,Serengeti National Park,Tanzania",
        "Lat": -3.02294,
        "Long": 34.71424,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sayari Camp,Serengeti National Park,Tanzania",
        "Lat": -1.5850706,
        "Long": 34.9266635,
        "Descr": "Serengeti Kogatende, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Bushtops Camp,Serengeti National Park,Tanzania",
        "Lat": -1.7235432,
        "Long": 34.8766243,
        "Descr": "Wogakuria Nr. 10, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Kati Kati Camp,Serengeti National Park,Tanzania",
        "Lat": -2.45322,
        "Long": 34.7478219,
        "Descr": "Serengeti, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Migration Camp,Serengeti National Park,Tanzania",
        "Lat": -1.9270693,
        "Long": 35.0197936,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Pioneer Camp,Serengeti National Park,Tanzania",
        "Lat": -2.6680619,
        "Long": 34.7345404,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Serena Safari Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.382955,
        "Long": 34.7029228,
        "Descr": "Serengeti National Park, Mara Region, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Sojourn Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serengeti Under Canvas,Serengeti National Park,Tanzania",
        "Lat": -1.838591,
        "Long": 35.113485,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Explore,Serengeti National Park,Tanzania",
        "Lat": -2.0185136,
        "Long": 34.2199267,
        "Descr": "Grumeti Reserves, Grumeti Game Reserve, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Faru Faru Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.0185136,
        "Long": 34.2199267,
        "Descr": "Grumeti Reserves Serengeti National Park TZ, 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Mara River Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -1.56879,
        "Long": 34.789758,
        "Descr": "Singita Grumeti, Mara River, Serengeti, Serengeti 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Sabora Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -2.1246654,
        "Long": 34.4118617,
        "Descr": "Singita Grumeti Serengeti National Park, Serengeti National Park 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Sasakwa Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.086524,
        "Long": 34.493095,
        "Descr": "Singita Grumeti, Serengeti National Park, 31623, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Serengeti House,Serengeti National Park,Tanzania",
        "Lat": -2.0911634,
        "Long": 34.505584,
        "Descr": "Singita Grumeti, Grumeti Game Reserve, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Soroi Serengeti Lodge,Serengeti National Park,Tanzania",
        "Lat": -2.4072802,
        "Long": 34.6211,
        "Descr": "Soroi Serengeti Lodge, Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Subeti Tented Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ubuntu Migration Camp,Serengeti National Park,Tanzania",
        "Lat": -2.3333333,
        "Long": 34.8333333,
        "Descr": "Serengeti National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tarangire National Park,Tanzania",
        "Lat": -3.7877648,
        "Long": 35.99537,
        "Descr": "Mto Tarangire, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chem Chem Lodge,Tarangire National Park,Tanzania",
        "Lat": -3.794874,
        "Long": 35.9122671,
        "Descr": "Forest Chem Chem, Forest Chem Chem - Tarangire River, Mdori, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuro Tarangire Camp,Tarangire National Park,Tanzania",
        "Lat": -3.9506743,
        "Long": 35.8661255,
        "Descr": "Tarangire National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lemala Mpingo Ridge,Tarangire National Park,Tanzania",
        "Lat": -3.8705786,
        "Long": 36.0279669,
        "Descr": "Tarangire National Park Tarangire, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Oliver's Camp,Tarangire National Park,Tanzania",
        "Lat": -4.132807,
        "Long": 36.0745955,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nimali Tarangire,Tarangire National Park,Tanzania",
        "Lat": -3.7789891,
        "Long": 36.006521,
        "Descr": "Eastern Boundary, Tarangire National Park, Kwa Kuchinia, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oliver's Camp,Tarangire National Park,Tanzania",
        "Lat": -4.132807,
        "Long": 36.0745955,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Swala Camp,Tarangire National Park,Tanzania",
        "Lat": -4.1312103,
        "Long": 35.9541525,
        "Descr": "Tarangire National Park Tarangire, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tarangire Ndovu Camp,Tarangire National Park,Tanzania",
        "Lat": -3.9506743,
        "Long": 35.8661255,
        "Descr": "Tarangire National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tarangire River Camp,Tarangire National Park,Tanzania",
        "Lat": -3.790624,
        "Long": 35.957768,
        "Descr": "Tarangire River Camp, Tarangire National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tarangire Treetops,Tarangire National Park,Tanzania",
        "Lat": -3.7877648,
        "Long": 35.99537,
        "Descr": "Mto Tarangire, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Uganda",
        "Lat": 1.373333,
        "Long": 32.290275,
        "Descr": "Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bwindi Impenetrable National Park,Uganda",
        "Lat": -1.0337306,
        "Long": 29.6931655,
        "Descr": "Bwindi Impenetrable National Park, Buhoma, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Buhoma Lodge,Bwindi Impenetrable National Park,Uganda",
        "Lat": -0.9882119,
        "Long": 29.6150731,
        "Descr": "Bwindi Impenetrable Forest Bwindi, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bwindi Lodge,Bwindi Impenetrable National Park,Uganda",
        "Lat": -0.9835446,
        "Long": 29.6173918,
        "Descr": "Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chameleon Hill Lodge,Bwindi Impenetrable National Park,Uganda",
        "Lat": -1.171735,
        "Long": 29.673448,
        "Descr": "137 Kizungu Lane, 28903 Kampala, Kampala, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Mountain Gorilla Lodge,Bwindi Impenetrable National Park,Uganda",
        "Lat": -1.0814931,
        "Long": 29.6245341,
        "Descr": "Nyalutembe, Bwindi, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Gorilla Forest Camp,Bwindi Impenetrable National Park,Uganda",
        "Lat": -0.9892911,
        "Long": 29.6138927,
        "Descr": "0°59’20.98”S 29°36’48.54”E, Bwindi National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entebbe & Kampala,Uganda",
        "Lat": 0.3104037,
        "Long": 32.5803039,
        "Descr": "Entebbe Road, Kampala, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Victoria Serena Resort,Entebbe & Kampala,Uganda",
        "Lat": 0.2074689,
        "Long": 32.5829674,
        "Descr": "Kigo Rd, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 0 Degrees,Entebbe & Kampala,Uganda",
        "Lat": 0.2752666,
        "Long": 32.591189,
        "Descr": "64 & 66 Kyodondo, Mobutu Road, Kampala, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Boma Entebbe,Entebbe & Kampala,Uganda",
        "Lat": 0.05329,
        "Long": 32.465018,
        "Descr": "Plot 20A Gowers Road, Entebbe, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kibale Forest National Park,Uganda",
        "Lat": 0.4382416,
        "Long": 30.3939874,
        "Descr": "Kibale, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ndali Lodge,Kibale Forest National Park,Uganda",
        "Lat": 0.486176,
        "Long": 30.3896656,
        "Descr": "Kibale National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Papaya Lake Lodge,Kibale Forest National Park,Uganda",
        "Lat": 0.486176,
        "Long": 30.3896656,
        "Descr": "Kibale National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Turaco Treetops Lodge,Kibale Forest National Park,Uganda",
        "Lat": 0.486176,
        "Long": 30.3896656,
        "Descr": "Kibale National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mgahinga National Park,Uganda",
        "Lat": -1.3640669,
        "Long": 29.638326,
        "Descr": "Mgahinga National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mount Gahinga Lodge,Mgahinga National Park,Uganda",
        "Lat": -1.3640669,
        "Long": 29.638326,
        "Descr": "Mgahinga Gorilla National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Murchison Falls National Park,Uganda",
        "Lat": 1.9288921,
        "Long": 31.6644073,
        "Descr": "Murchison Falls National Park Uganda, Lolim, Masindi, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Baker's Lodge,Murchison Falls National Park,Uganda",
        "Lat": 2.259553,
        "Long": 31.530706,
        "Descr": "Bulisa, N 2 15′ 34.614 E 31 31′ 50.394, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nile Safari Lodge,Murchison Falls National Park,Uganda",
        "Lat": 2.2528119,
        "Long": 31.5200114,
        "Descr": "Murchison falls, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paraa Safari Lodge,Murchison Falls National Park,Uganda",
        "Lat": 2.2899215,
        "Long": 31.5730011,
        "Descr": "Amuru, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Queen Elizabeth National Park,Uganda",
        "Lat": -0.1640716,
        "Long": 30.0202535,
        "Descr": "Queen Elizabeth National Park, Fort Portal-Mpondwe Rd, Rubirizi, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ishasha Wilderness Camp,Queen Elizabeth National Park,Uganda",
        "Lat": -0.164072,
        "Long": 30.020253,
        "Descr": "Lat: S 0 37′ 14.035” Lon: E 29 42′ 43.002” Lat: S 0 37′ 14.035” Lon: E 29 42′ 43.002”, Queen Elizabeth National Park, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kyambura Gorge Lodge,Queen Elizabeth National Park,Uganda",
        "Lat": -0.1640716,
        "Long": 30.0202535,
        "Descr": "Queen Elizabeth National Park, Fort Portal-Mpondwe Rd, Rubirizi, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kyaninga Lodge,Queen Elizabeth National Park,Uganda",
        "Lat": 0.7026709,
        "Long": 30.2989063,
        "Descr": "Fort Portal Box 98, Fort Portal, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mweya Safari Lodge,Queen Elizabeth National Park,Uganda",
        "Lat": -0.1889343,
        "Long": 29.8999895,
        "Descr": "Mweya, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambia",
        "Lat": -13.133897,
        "Long": 27.849332,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kafue,Zambia",
        "Lat": -15.7643589,
        "Long": 28.1766294,
        "Descr": "Kafue, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Busanga Bush Camp,Kafue,Zambia",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shumba Camp,Kafue,Zambia",
        "Lat": -14.219251,
        "Long": 25.8729744,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Livingstone,Zambia",
        "Lat": -17.8519791,
        "Long": 25.8285153,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": ".well-known,",
        "Lat": 45.3337026,
        "Long": -79.2225484,
        "Descr": "68 West Rd. Unit 5, Huntsville, ON P1H 1L8, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "acme-challenge,.well-known,",
        "Lat": 37.8718992,
        "Long": -122.2585399,
        "Descr": "Berkeley, CA, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "CITIES,",
        "Lat": 34.1532002,
        "Long": -118.2553103,
        "Descr": "505 N Brand Blvd # 810, Glendale, CA 91203, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha",
        "Lat": 41.3163244,
        "Long": -72.9223431,
        "Descr": "New Haven, CT 06520, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha Coffee Lodge,Arusha",
        "Lat": -3.3745016,
        "Long": 36.6439397,
        "Descr": "Old Dodoma Road, Burka Coffee Estate, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hatari Lodge,Arusha",
        "Lat": -3.2265396,
        "Long": 36.8572379,
        "Descr": "Hatari Lodge (Inside Arusha National Park), Momella Road Arousha National Park TZ, 23100, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Legendary Lodge,Arusha",
        "Lat": -3.3407072,
        "Long": 36.640244,
        "Descr": "Selian Coffee Estate, Namanga Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shu'Mata Camp,Arusha",
        "Lat": -3.3781719,
        "Long": 36.7045699,
        "Descr": "Leopard Tours LTD, Nyerere Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha (1)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha (2)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "African View Lodge,Arusha (2)",
        "Lat": -3.37088,
        "Long": 36.70121,
        "Descr": "Momella Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha,Arusha (2)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha Coffee Lodge,Arusha (2)",
        "Lat": -3.3745016,
        "Long": 36.6439397,
        "Descr": "Old Dodoma Road, Burka Coffee Estate, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha Serena Hotel,Arusha (2)",
        "Lat": -3.3808569,
        "Long": 36.7924842,
        "Descr": "Tengeru Rd, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamerkop House by Lemala,Arusha (2)",
        "Lat": -3.3950495,
        "Long": 36.7359786,
        "Descr": "Excel E Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamerkop House by Lemala (1),Arusha (2)",
        "Lat": -3.3950495,
        "Long": 36.7359786,
        "Descr": "Excel E Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hatari Lodge,Arusha (2)",
        "Lat": -3.2265396,
        "Long": 36.8572379,
        "Descr": "Hatari Lodge (Inside Arusha National Park), Momella Road Arousha National Park TZ, 23100, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kambi Ya Tembo,Arusha (2)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kili Villa,Arusha (2)",
        "Lat": -3.4244124,
        "Long": 36.8780421,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kili Villa (1),Arusha (2)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Legendary Lodge,Arusha (2)",
        "Lat": -3.3407072,
        "Long": 36.640244,
        "Descr": "Selian Coffee Estate, Namanga Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machweo Wellness Retreat,Arusha (2)",
        "Lat": -3.3807342,
        "Long": 36.7450619,
        "Descr": "Onsea Moivaro Road / Baraa Road Arusha TZ, 1259, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machweo Wellness Retreat (1),Arusha (2)",
        "Lat": -3.3807342,
        "Long": 36.7450619,
        "Descr": "Onsea Moivaro Road / Baraa Road Arusha TZ, 1259, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onsea House,Arusha (2)",
        "Lat": -3.3817381,
        "Long": 36.7448258,
        "Descr": "Onsea Moivaro Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rivertrees Country Inn,Arusha (2)",
        "Lat": -3.3735709,
        "Long": 36.8643875,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shu'Mata Camp,Arusha (2)",
        "Lat": -3.3869254,
        "Long": 36.6829927,
        "Descr": "Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Closed to Oct 2022,Manna Bay,Cape Town",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Durban",
        "Lat": 42.8860753,
        "Long": -72.565999,
        "Descr": "1 Kipling Rd, Brattleboro, VT 05301, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Canelands Beach Club & Spa,Durban",
        "Lat": -29.5025691,
        "Long": 31.2389144,
        "Descr": "Salt Rock, Dolphin Coast, 4391, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Teremok Marine Lodge,Durban",
        "Lat": -29.735195,
        "Long": 31.0807851,
        "Descr": "49 Marine Dr, Umhlanga Rocks, Durban, 4320, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oyster Box,Durban",
        "Lat": -29.7284034,
        "Long": 31.0872415,
        "Descr": "2 Lighthouse Rd, Umhlanga, 4319, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbali Resort,Durban",
        "Lat": -29.5625977,
        "Long": 31.1903212,
        "Descr": "Fairmont Estate Port Zimbali, ZIMBALI, Dolphin Coast, 4422, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entebbe & Kampala (1)",
        "Lat": 0.0511839,
        "Long": 32.463708,
        "Descr": "Entebbe, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Victoria Serena Resort,Entebbe & Kampala (1)",
        "Lat": 0.2068168,
        "Long": 32.5828475,
        "Descr": "Kigo Rd, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 0 Degrees,Entebbe & Kampala (1)",
        "Lat": 0.2752666,
        "Long": 32.591189,
        "Descr": "64 & 66 Kyodondo, Mobutu Road, Kampala, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Boma Entebbe,Entebbe & Kampala (1)",
        "Lat": 0.05329,
        "Long": 32.465018,
        "Descr": "Plot 20A Gowers Road, Entebbe, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Johannesburg",
        "Lat": -26.1461438,
        "Long": 28.0404502,
        "Descr": "37 Bath Ave, Rosebank, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "AtholPlace House & Villa,Johannesburg",
        "Lat": -26.111784,
        "Long": 28.0637131,
        "Descr": "90 Pretoria Ave, Atholl, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "D'Oreale Grande,Johannesburg",
        "Lat": -26.1443844,
        "Long": 28.2213325,
        "Descr": "64 Jones Rd, O.R. Tambo, Kempton Park, 1620, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fairlawns Boutique Hotel & Spa,Johannesburg",
        "Lat": -26.0795011,
        "Long": 28.0749836,
        "Descr": "1 Alma Rd, Morningside, Johannesburg, 2052, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Hotel The Westcliff,Johannesburg",
        "Lat": -26.1710223,
        "Long": 28.0324839,
        "Descr": "67 Jan Smuts Ave, Westcliff, Johannesburg, 2132, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Intercontinental Hotel,Johannesburg",
        "Lat": -26.1068045,
        "Long": 28.0525693,
        "Descr": "Corner of Maude &, 5th St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Michelangelo Towers,Johannesburg",
        "Lat": -26.1063997,
        "Long": 28.0542906,
        "Descr": "8 Maude St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mount Grace Hotel & Spa,Johannesburg",
        "Lat": -26.1336131,
        "Long": 28.0676215,
        "Descr": "1 Melrose Blvd, Melrose Arch, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Sandton,Johannesburg",
        "Lat": -26.1041779,
        "Long": 28.0614167,
        "Descr": "Rivonia Rd &, Daisy St, Sandown, Sandton, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Maslow,Johannesburg",
        "Lat": -26.0990371,
        "Long": 28.0588493,
        "Descr": "Rivonia Rd &, Grayston Dr, Gauteng, Sandton, 2031, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Michelangelo Hotel,Johannesburg",
        "Lat": -26.1069699,
        "Long": 28.0566358,
        "Descr": "135 West St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Peech Hotel,Johannesburg",
        "Lat": -26.1365329,
        "Long": 28.0588454,
        "Descr": "61 North St, Melrose North, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Johannesburg,Johannesburg",
        "Lat": -26.16454,
        "Long": 28.05939,
        "Descr": "17 4th Ave, Houghton Estate, Johannesburg, 2198, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Saxon Hotel,Johannesburg",
        "Lat": -26.11212,
        "Long": 28.03561,
        "Descr": "36 Saxon Rd, Sandhurst, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali",
        "Lat": 40.4432027,
        "Long": -79.9428499,
        "Descr": "5000 Forbes Ave, Pittsburgh, PA 15213, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali (1)",
        "Lat": -1.9440727,
        "Long": 30.0618851,
        "Descr": "Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Marriott,Kigali (1)",
        "Lat": -1.9536025,
        "Long": 30.0620968,
        "Descr": "KN 3 Avenue, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Serena Hotel,Kigali (1)",
        "Lat": -1.9563339,
        "Long": 30.0627322,
        "Descr": "KN 3 Ave, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Retreat by Heaven,Kigali (1)",
        "Lat": -1.9472729,
        "Long": 30.0651165,
        "Descr": "No 5, KN 29 St, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lilongwe",
        "Lat": 35.9049122,
        "Long": -79.0469134,
        "Descr": "Chapel Hill, NC, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 13 Degrees,Lilongwe",
        "Lat": -13.9297681,
        "Long": 33.7966419,
        "Descr": "43/2/58, Mphonongo, Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lilongwe (1)",
        "Lat": -13.9626121,
        "Long": 33.7741195,
        "Descr": "Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 13 Degrees,Lilongwe (1)",
        "Lat": -13.9297681,
        "Long": 33.7966419,
        "Descr": "43/2/58, Mphonongo, Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Livingstone",
        "Lat": 35.6714911,
        "Long": -80.4847366,
        "Descr": "701 W Monroe St, Salisbury, NC 28144, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Livingstone (1)",
        "Lat": -17.8519791,
        "Long": 25.8285153,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Chundu,Livingstone (1)",
        "Lat": -17.8470867,
        "Long": 25.392696,
        "Descr": "Plot 9003, Nakatindi Road, Katambora, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Sussi & Chuma,Livingstone (1)",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sindabezi Island Lodge,Livingstone (1)",
        "Lat": -17.835671,
        "Long": 25.7077228,
        "Descr": "Nakatindi Road, Livingstone 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taita Falcon Lodge,Livingstone (1)",
        "Lat": -17.9690889,
        "Long": 25.9178056,
        "Descr": "11km from the Victoria Falls, Livingstone 60012, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The David Livingstone Lodge,Livingstone (1)",
        "Lat": -17.8879981,
        "Long": 25.8442225,
        "Descr": "Riverside Dr, Off Sichango Rd, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Royal Livingstone,Livingstone (1)",
        "Lat": -17.8519791,
        "Long": 25.8285153,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thorntree River Lodge,Livingstone (1)",
        "Lat": -17.8431091,
        "Long": 25.754919,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Toka Leya,Livingstone (1)",
        "Lat": -17.8555135,
        "Long": 25.7873394,
        "Descr": "Mosi-oa-Tunya National Park, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tongabezi Lodge,Livingstone (1)",
        "Lat": -17.8199844,
        "Long": 25.7047634,
        "Descr": "Livingstone Way, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi (1)",
        "Lat": -1.2920659,
        "Long": 36.8219462,
        "Descr": "Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Eka Hotel,Nairobi (1)",
        "Lat": -1.3241578,
        "Long": 36.8445673,
        "Descr": "Eka Hotel, Mombasa Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hemingways Nairobi,Nairobi (1)",
        "Lat": -1.3427104,
        "Long": 36.7053197,
        "Descr": "Karen Mbagathi Ridge, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi Tented Camp,Nairobi (1)",
        "Lat": -1.3632752,
        "Long": 36.7764992,
        "Descr": "Nairobi Tented camp, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ololo Safari Lodge,Nairobi (1)",
        "Lat": -1.3883495,
        "Long": 36.8328425,
        "Descr": "Southern Border of the Nairobi National Park, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Emakoko,Nairobi (1)",
        "Lat": -1.406924,
        "Long": 36.876973,
        "Descr": "The Emakoko, Nairobi National Park, Uhuru Gardens, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Giraffe Manor,Nairobi (1)",
        "Lat": -1.375487,
        "Long": 36.744676,
        "Descr": "Gogo Falls Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria Falls",
        "Lat": -17.9308041,
        "Long": 25.8423322,
        "Descr": "1 Mallet Dr, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria Falls (1)",
        "Lat": -17.9315803,
        "Long": 25.8301677,
        "Descr": "Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Batonka Lodge,Victoria Falls (1)",
        "Lat": -17.929448,
        "Long": 25.828053,
        "Descr": "Reynard Road, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ilala Lodge,Victoria Falls (1)",
        "Lat": -17.926191,
        "Long": 25.841572,
        "Descr": "A8, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matetsi Victoria Falls,Victoria Falls (1)",
        "Lat": -17.8644008,
        "Long": 25.5003986,
        "Descr": "Matetsi Private Game Reserve, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stanley & Livingstone Boutique Hotel,Victoria Falls (1)",
        "Lat": -17.9995711,
        "Long": 25.8100543,
        "Descr": "Old Ursula’s Road, Stanley & Livingstone Private Game Reserve, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Elephant Camp,Victoria Falls (1)",
        "Lat": -17.9743439,
        "Long": 25.8456366,
        "Descr": "The Elephant Camp, Victoria Falls National Park, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Victoria Falls Hotel,Victoria Falls (1)",
        "Lat": -17.9308041,
        "Long": 25.8423322,
        "Descr": "1 Mallet Dr, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Whale Route",
        "Lat": 36.1937732,
        "Long": -95.7329257,
        "Descr": "2600 U.S. Rte 66, Catoosa, OK 74015, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Birkenhead House,Whale Route",
        "Lat": 33.1080823,
        "Long": -96.8443214,
        "Descr": "3535 Victory Group Way #510, Frisco, TX 75034, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Farm 215,Whale Route",
        "Lat": -33.6149094,
        "Long": 151.3321164,
        "Descr": "Whale Beach Rd, Sydney NSW, Australia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Forest Lodge,Whale Route",
        "Lat": -34.549873,
        "Long": 19.413024,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Garden Lodge,Whale Route",
        "Lat": -34.539996,
        "Long": 19.412938,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Private Villas,Whale Route",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lekkerwater Beach Lodge,Whale Route",
        "Lat": -34.4502064,
        "Long": 20.6345114,
        "Descr": "De Hoop Marine Protected Area, South Africa, Overberg DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Ocean House,Whale Route",
        "Lat": -34.473385,
        "Long": 20.5163438,
        "Descr": "De Hoop Avenue De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ocean Eleven Guest House,Whale Route",
        "Lat": -34.4236111,
        "Long": 19.2344444,
        "Descr": "11 Westcliff Rd, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One Marine Drive Boutique Hotel,Whale Route",
        "Lat": -34.4221363,
        "Long": 19.234668,
        "Descr": "1 Marine Dr, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Schulphoek House,Whale Route",
        "Lat": -34.4269554,
        "Long": 19.2004925,
        "Descr": "181 Piet Retief Cres, Sand Bay, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marine Hotel,Whale Route",
        "Lat": 37.788844,
        "Long": -122.410475,
        "Descr": "609 Sutter St, San Francisco, CA 94102, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Thatch House,Whale Route",
        "Lat": 45.3177627,
        "Long": -85.2645004,
        "Descr": "304 Park Ave, Charlevoix, MI 49720, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Windhoek (1)",
        "Lat": -22.5608807,
        "Long": 17.0657549,
        "Descr": "Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Windhoek,Windhoek (1)",
        "Lat": -22.570407,
        "Long": 17.085683,
        "Descr": "Rev Michael Scott St, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Omaanda,Windhoek (1)",
        "Lat": -22.4255023,
        "Long": 17.436387,
        "Descr": "Khomas, Region, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Olive Exclusive,Windhoek (1)",
        "Lat": -22.5534297,
        "Long": 17.0902601,
        "Descr": "22 Promenaden Rd, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wine Route",
        "Lat": 38.6296488,
        "Long": -122.8769989,
        "Descr": "1437 Grove St, Healdsburg, CA 95448, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek,Wine Route",
        "Lat": -33.909,
        "Long": 19.117792,
        "Descr": "62 Huguenot Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Babylonstoren,Franschhoek,Wine Route",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Frannschhoek Country House,Franschhoek,Wine Route",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek Country House,Franschhoek,Wine Route",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Cle des Montagnes,Franschhoek,Wine Route",
        "Lat": -33.912665,
        "Long": 19.116629,
        "Descr": "13 Daniel Hugo St, Franschhoek, Cape Town, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Residence,Franschhoek,Wine Route",
        "Lat": -33.8992614,
        "Long": 19.1228313,
        "Descr": "Elandskloof Road, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Franschhoek Hotel & Spa,Franschhoek,Wine Route",
        "Lat": -33.935809,
        "Long": 19.110409,
        "Descr": "16 Minor Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Quartier Francais,Franschhoek,Wine Route",
        "Lat": -33.9129333,
        "Long": 19.1202526,
        "Descr": "Corner of Berg and, Wilhelmina St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leeu House,Franschhoek,Wine Route",
        "Lat": -33.913255,
        "Long": 19.121227,
        "Descr": "12 Huguenot Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mont Rochelle,Franschhoek,Wine Route",
        "Lat": -33.8974833,
        "Long": 19.1523292,
        "Descr": "Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Franschhoek,Franschhoek,Wine Route",
        "Lat": -33.9083276,
        "Long": 19.1170728,
        "Descr": "68 Huguenot Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage,Franschhoek,Wine Route",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage at Grande Provence,Franschhoek,Wine Route",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paarl,Wine Route",
        "Lat": -33.7485101,
        "Long": 18.9621122,
        "Descr": "KWV Head Office Main St, Vrykyk, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grand Dedale Country House,Paarl,Wine Route",
        "Lat": -33.6269933,
        "Long": 19.0830857,
        "Descr": "Bovlei Rd, Wellington, 7654, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grande Roche,Paarl,Wine Route",
        "Lat": -33.7513889,
        "Long": 18.9591667,
        "Descr": "1 Plantasie St, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Light House Boutique Suites,Paarl,Wine Route",
        "Lat": -33.77236,
        "Long": 18.954887,
        "Descr": "2 Lille, Courtrai, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stellenbosch,Wine Route",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bonne Esperance,Stellenbosch,Wine Route",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delaire Graff Estate,Stellenbosch,Wine Route",
        "Lat": -33.9202951,
        "Long": 18.9218332,
        "Descr": "Helshoogte Rd, Stellenbosch, 7602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lanzerac Hotel & Spa,Stellenbosch,Wine Route",
        "Lat": -33.9380161,
        "Long": 18.8941341,
        "Descr": "1 Lanzerac Rd, Karindal, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majeka House,Stellenbosch,Wine Route",
        "Lat": -33.9680216,
        "Long": 18.860642,
        "Descr": "26 Houtkapper St, Paradyskloof, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oude Werf Hotel,Stellenbosch,Wine Route",
        "Lat": -33.9378177,
        "Long": 18.8619977,
        "Descr": "30 Church St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spier Hotel,Stellenbosch,Wine Route",
        "Lat": -33.9735508,
        "Long": 18.7821798,
        "Descr": "R310 Baden Powell Dr, Stellenbosch, 7603, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "INSTAGRAM,",
        "Lat": 38.9403808,
        "Long": -92.3277375,
        "Descr": "Columbia, MO 65211, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "ISLANDS,",
        "Lat": 41.8826997,
        "Long": -87.6282649,
        "Descr": "20 N State St, Chicago, IL 60602, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Banyan Tree Vabbinfaru,Maldives",
        "Lat": 4.3096949,
        "Long": 73.4237766,
        "Descr": "Kaafu Atoll, North Male Atoll, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cinnamon Hakuraa Huraa,Maldives",
        "Lat": 2.8536782,
        "Long": 73.539053,
        "Descr": "Atoll 11080, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Coco Bodu Hithi,Maldives",
        "Lat": 4.4304498,
        "Long": 73.384552,
        "Descr": "Coco Bodu Hithi Resort, North Male Atoll, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Coco Palm Dhuni Kolhu,Maldives",
        "Lat": 5.0395987,
        "Long": 72.8817504,
        "Descr": "Coco Palm Dhuni Kolhu Resort, Baa Atoll, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Conrad Maldives Rangali Island,Maldives",
        "Lat": 3.6167957,
        "Long": 72.7228371,
        "Descr": "Rangali Island Alifu Dhaalu Atoll, 20077, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Halaveli,Maldives",
        "Lat": 4.0388294,
        "Long": 72.9192958,
        "Descr": "Halaveli Island, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Moofushi Resort,Maldives",
        "Lat": 3.884293,
        "Long": 72.72777,
        "Descr": "South Atoll, Alifu Atoll 00200, 3.8853495, 72.7275221,18, Moofushi Island, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Resort Maldives at Kuda Huraa,Maldives",
        "Lat": 4.3302248,
        "Long": 73.5980086,
        "Descr": "Kuda Huraa North Malé Atoll, 20097, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanuhura Maldives,Maldives",
        "Lat": 5.53377,
        "Long": 73.506003,
        "Descr": "Kanuhura, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuramathi Island Resort,Maldives",
        "Lat": 4.259362,
        "Long": 72.9846948,
        "Descr": "Kuramathi, Ari Atoll, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuredu Island Resort,Maldives",
        "Lat": 5.5487453,
        "Long": 73.465172,
        "Descr": "Kuredu Island Resort & Spa, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurumba Maldives,Maldives",
        "Lat": 4.2266685,
        "Long": 73.5198584,
        "Descr": "Vihamanaafushi, North Male Atoll 08340, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manafaru Maldives,Maldives",
        "Lat": 6.9958219,
        "Long": 72.9401594,
        "Descr": "Manafaru Private Island, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Meeru Island Resort,Maldives",
        "Lat": 4.4531669,
        "Long": 73.7156448,
        "Descr": "Meeru Island North, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paradise Island,Maldives",
        "Lat": 4.2869396,
        "Long": 73.5535736,
        "Descr": "Paradise Island Resort & Spa Lankanfinolhu North, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sheraton Maldives Full Moon Resort,Maldives",
        "Lat": 4.2505966,
        "Long": 73.5462671,
        "Descr": "Furanafushi Island North Malé Atol, 08240, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Soneva Fushi,Maldives",
        "Lat": 5.1118009,
        "Long": 73.0786623,
        "Descr": "Kunfunadhoo Island, Eydhafushi, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sun Siyam Iru Fushi,Maldives",
        "Lat": 5.744559,
        "Long": 73.323365,
        "Descr": "Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sun Siyam Iru Veli,Maldives",
        "Lat": 2.9332038,
        "Long": 72.9611603,
        "Descr": "atoll, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sun Siyam Olhuveli,Maldives",
        "Lat": 3.849435,
        "Long": 73.4546966,
        "Descr": "South Male Atoll, Olhuveli Male, 08400, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sun Siyam Vilu Reef,Maldives",
        "Lat": 3.0033368,
        "Long": 73.0006248,
        "Descr": "Dh. Meedhuffushi, South Nilandhe Atoll 13100, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Velassaru Maldives,Maldives",
        "Lat": 4.1211163,
        "Long": 73.4370489,
        "Descr": "39 Orchid Magu, Malé 08350, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Veligandu Island Resort,Maldives",
        "Lat": 4.2981175,
        "Long": 73.0119063,
        "Descr": "Veligandu Island, Maldives",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mauritius",
        "Lat": 37.6586897,
        "Long": -122.4001359,
        "Descr": "611 Gateway Blvd Ste 120, South San Francisco, CA 94080, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cannonier Beachcomber Golf Resort & Spa,Mauritius",
        "Lat": -20.000797,
        "Long": 57.5527301,
        "Descr": "Costal Rd, Grand Baie, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Belle Mare Plage,Mauritius",
        "Lat": -20.1674815,
        "Long": 57.7663009,
        "Descr": "Pointe de flacq, Plage, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Prince Maurice,Mauritius",
        "Lat": -20.14973,
        "Long": 57.746152,
        "Descr": "Choisy Road Poste De Flacq, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dinarobin Beachcomber Golf Resort & Spa,Mauritius",
        "Lat": -20.4368993,
        "Long": 57.3206034,
        "Descr": "Le Morne Peninsula, Le Morne, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "LUX Belle Mare,Mauritius",
        "Lat": -20.1990171,
        "Long": 57.7823498,
        "Descr": "Coastal Road, Quatre Cocos, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "LUX Le Morne,Mauritius",
        "Lat": -20.457165,
        "Long": 57.3115016,
        "Descr": "Coastal Road, Le Morne, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mauricia Beachcomber Resort & Spa,Mauritius",
        "Lat": -20.009727,
        "Long": 57.5807484,
        "Descr": "Royal Road, Grand Baie 30512, Grand Baie, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Le Saint Geran,Mauritius",
        "Lat": -20.1605543,
        "Long": 57.7562268,
        "Descr": "Pointe de Flacq, Poste de Flacq 41518, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paradis Beachcomber Golf Resort & Spa,Mauritius",
        "Lat": -20.4368993,
        "Long": 57.3206034,
        "Descr": "Le Morne Peninsula, Le Morne, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paradise Cove Boutique Hotel,Mauritius",
        "Lat": -19.9878899,
        "Long": 57.6326525,
        "Descr": "Royal Road Anse La Raie, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Palm Beachcomber,Mauritius",
        "Lat": -20.0059968,
        "Long": 57.5787491,
        "Descr": "Grand Baie, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shandrani Beachcomber Resort & Spa,Mauritius",
        "Lat": -20.4487013,
        "Long": 57.7066407,
        "Descr": "Mahebourg, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shanti Maurice Resort & Spa,Mauritius",
        "Lat": -20.5122835,
        "Long": 57.4712566,
        "Descr": "Coastal Road, Saint Felix, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oberoi Mauritius,Mauritius",
        "Lat": -20.080998,
        "Long": 57.5133901,
        "Descr": "Turtle Bay, Pointe aux Piments, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The St Regis Mauritius Resort,Mauritius",
        "Lat": -20.461318,
        "Long": 57.3102218,
        "Descr": "Le Morne Peninsula, Coastal Road, Mauritius, Le Morne, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Trou aux Biches Beachcomber Golf Resort & Spa,Mauritius",
        "Lat": -20.0328906,
        "Long": 57.5463251,
        "Descr": "Royal Trou Aux Biches Rd, Triolet, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria Beachcomber Resort & Spa,Mauritius",
        "Lat": -20.0756142,
        "Long": 57.5127443,
        "Descr": "Coastal Road, Pointe Aux Piments 21304, Pointe aux Piments, Mauritius",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mozambique",
        "Lat": -15.0364493,
        "Long": 40.7323311,
        "Descr": "Island of Mozambique, Mozambique Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bazaruto Archipelago,Mozambique",
        "Lat": -21.75763,
        "Long": 35.423339,
        "Descr": "Bazaruto Archipelago, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Bazaruto Island Resort & Spa,Bazaruto Archipelago,Mozambique",
        "Lat": -21.706698,
        "Long": 35.4468,
        "Descr": "Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Benguerra Island,Bazaruto Archipelago,Mozambique",
        "Lat": -21.853401,
        "Long": 35.425307,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Marlin Beach,Bazaruto Archipelago,Mozambique",
        "Lat": -21.853401,
        "Long": 35.425307,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Benguerra Island Lodge,Bazaruto Archipelago,Mozambique",
        "Lat": -21.853401,
        "Long": 35.425307,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mozambique Coastline,Mozambique",
        "Lat": -15.0364493,
        "Long": 40.7323311,
        "Descr": "Island of Mozambique, Mozambique Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anvil Bay,Mozambique Coastline,Mozambique",
        "Lat": -26.3494905,
        "Long": 32.9311351,
        "Descr": "Ponta Chemucane, Machavene 1006, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Coral Lodge,Mozambique Coastline,Mozambique",
        "Lat": -15.007254,
        "Long": 40.770788,
        "Descr": "Varanda 9, Mossuril, Nampula Varanda, Mossuril, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Diamonds Mequfi Beach Resort,Mozambique Coastline,Mozambique",
        "Lat": -13.24512,
        "Long": 40.5559,
        "Descr": "Mecufi Road, Mecufi 3200, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machangulo Beach Lodge,Mozambique Coastline,Mozambique",
        "Lat": -26.0837974,
        "Long": 32.9590911,
        "Descr": "Machangulo Peninsula, Inhaca, Prov, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Massinga Beach Lodge,Mozambique Coastline,Mozambique",
        "Lat": -23.3503135,
        "Long": 35.4760551,
        "Descr": "Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Santorini Mozambique,Mozambique Coastline,Mozambique",
        "Lat": -21.928077,
        "Long": 35.3162073,
        "Descr": "19 cle Outboro, Vilanculos, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "White Pearl Resort,Mozambique Coastline,Mozambique",
        "Lat": -26.7086086,
        "Long": 32.9009284,
        "Descr": "Ponta Mamoli, Zitundo, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Quirimbas Archipelago,Mozambique",
        "Lat": -11.815883,
        "Long": 40.603806,
        "Descr": "Medjumbe Island, Quirimbas Archipelago, Cabo Delgado Province, Medjumbe 2116, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Medjumbe Island Resort & Spa,Quirimbas Archipelago,Mozambique",
        "Lat": -11.815883,
        "Long": 40.603806,
        "Descr": "Medjumbe Island, Quirimbas Archipelago, Cabo Delgado Province, Medjumbe 2116, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Quilalea Private Island,Quirimbas Archipelago,Mozambique",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ibo Island Lodge,Quirimbas Archipelago,Mozambique",
        "Lat": -12.3420704,
        "Long": 40.5828972,
        "Descr": "Rua Bela Vista, Ibo island, Quirimbas Archipelago, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Seychelles",
        "Lat": -4.6384732,
        "Long": 55.4769334,
        "Descr": "Eden Island Marina Eden Island Mahe, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Dique Island,Seychelles",
        "Lat": -4.3590972,
        "Long": 55.8412424,
        "Descr": "La Digue, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Domaine de L'Orangeraie,La Dique Island,Seychelles",
        "Lat": -4.3451166,
        "Long": 55.8302001,
        "Descr": "La Passe, La Digue, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mahe Island,Seychelles",
        "Lat": -4.6826693,
        "Long": 55.480396,
        "Descr": "Mahé, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Maia Luxury Resort & Spa,Mahe Island,Seychelles",
        "Lat": -4.7168018,
        "Long": 55.4793392,
        "Descr": "West Coast Road, Anse Louis, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Banyan Tree Seychelles,Mahe Island,Seychelles",
        "Lat": -4.7819306,
        "Long": 55.4974378,
        "Descr": "Anse Intendance Takamaka Mahe Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Ephelia Resort,Mahe Island,Seychelles",
        "Lat": -4.658432,
        "Long": 55.4053115,
        "Descr": "Port Launay Rd, Mahé, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Resort Seychelles,Mahe Island,Seychelles",
        "Lat": -4.7542939,
        "Long": 55.4660072,
        "Descr": "Petite Anse, Mahe Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Seychelles Northolme Resort & Spa,Mahe Island,Seychelles",
        "Lat": -4.5922623,
        "Long": 55.4317498,
        "Descr": "N Coast Rd, Mahé, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Praslin Island,Seychelles",
        "Lat": -4.3325908,
        "Long": 55.7467259,
        "Descr": "Praslin, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Lemuria Resort,Praslin Island,Seychelles",
        "Lat": -4.299269,
        "Long": 55.681463,
        "Descr": "Anse Kerlan Praslin Seychelles, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Domaine de la Reserve,Praslin Island,Seychelles",
        "Lat": -4.3096638,
        "Long": 55.7356667,
        "Descr": "-4.310214, 55.736266, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Raffles Seychelles,Praslin Island,Seychelles",
        "Lat": -4.3084819,
        "Long": 55.7183626,
        "Descr": "Anse Takamaka Baie Saint Anne Praslin PRASLIN, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Private Islands,Seychelles",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cousine Island,Private Islands,Seychelles",
        "Lat": -4.3487802,
        "Long": 55.6482984,
        "Descr": "Cousine Island, PO Box 977, Victoria, Mahe, Cousine, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Denis Private Island Lodge,Private Islands,Seychelles",
        "Lat": -3.801795,
        "Long": 55.662857,
        "Descr": "Denis Private Island Denis Island, Denis, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Enchanted Island Resort,Private Islands,Seychelles",
        "Lat": -4.6218855,
        "Long": 55.5034253,
        "Descr": "Enchanted Island Resort, Round Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Desroches Island,Private Islands,Seychelles",
        "Lat": -5.6972788,
        "Long": 53.6511778,
        "Descr": "Unnamed Road, Desroches Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Seychelles Labriz Resort & Spa,Private Islands,Seychelles",
        "Lat": -4.4812865,
        "Long": 55.2483398,
        "Descr": "La Passe, Silhouette, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "North Island,Private Islands,Seychelles",
        "Lat": 40.7571572,
        "Long": -73.9826354,
        "Descr": "1177 Avenue of the Americas, 5th Floor, New York, NY 10036, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Six Senses Zil Pasyon,Private Islands,Seychelles",
        "Lat": -4.3179723,
        "Long": 55.8692252,
        "Descr": "Felicite, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zanzibar",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mnemba Island,Zanzibar",
        "Lat": -5.8204983,
        "Long": 39.383505,
        "Descr": "Mnemba Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mnemba Island Lodge,Mnemba Island,Zanzibar",
        "Lat": -5.820408,
        "Long": 39.383625,
        "Descr": "andBeyond Mnemba Island, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pemba Island,Zanzibar",
        "Lat": -5.0319352,
        "Long": 39.7755571,
        "Descr": "Pemba Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fundu Lagoon,Pemba Island,Zanzibar",
        "Lat": -5.2915108,
        "Long": 39.6585569,
        "Descr": "Wambaa, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Manta Resort,Pemba Island,Zanzibar",
        "Lat": -4.882748,
        "Long": 39.679388,
        "Descr": "Psv-zanzibar estate, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stonetown,Zanzibar",
        "Lat": -6.162806,
        "Long": 39.1875763,
        "Descr": "Kenyatta Rd, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emerson Spice Hotel,Stonetown,Zanzibar",
        "Lat": -6.1611602,
        "Long": 39.1920744,
        "Descr": "4044 Tharia St, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Park Hyatt Zanzibar,Stonetown,Zanzibar",
        "Lat": -6.162198,
        "Long": 39.1866153,
        "Descr": "Shangani Street Stone Town Zanzibar TZ, Zanzibar 4255, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zanzibar Serena Hotel,Stonetown,Zanzibar",
        "Lat": -6.1633991,
        "Long": 39.1860502,
        "Descr": "Shangani St, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zanzibar Coast,Zanzibar",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Baraza Resort & Spa,Zanzibar Coast,Zanzibar",
        "Lat": -6.1915383,
        "Long": 39.5351677,
        "Descr": "Bwejuu Beach South East Coast Paje Michamvi Road Dongwe, Zanzibar 2284, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gold Zanzibar Beach House & Spa,Zanzibar Coast,Zanzibar",
        "Lat": -5.7534224,
        "Long": 39.2882085,
        "Descr": "Kendwa, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kilindi Zanzibar,Zanzibar Coast,Zanzibar",
        "Lat": -5.7603948,
        "Long": 39.2906036,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matemwe Retreat,Zanzibar Coast,Zanzibar",
        "Lat": -5.843811,
        "Long": 39.35696,
        "Descr": "Matemwe Beach Matemwe TZ, 3275, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Melia Zanzibar,Zanzibar Coast,Zanzibar",
        "Lat": -5.954186,
        "Long": 39.3679945,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Zanzibar,Zanzibar Coast,Zanzibar",
        "Lat": -6.4105896,
        "Long": 39.4522668,
        "Descr": "Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tui Blue Bahari Zanzibar,Zanzibar Coast,Zanzibar",
        "Lat": -5.9391972,
        "Long": 39.3604166,
        "Descr": "Pwani Mchangani Zanzibar TZ, 0000, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tulia Zanzibar Unique Beach Resort,Zanzibar Coast,Zanzibar",
        "Lat": -6.0347647,
        "Long": 39.4067497,
        "Descr": "Zanzibar, Pongwe, Pongwe, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zawadi Hotel,Zanzibar Coast,Zanzibar",
        "Lat": -6.1674554,
        "Long": 39.5256439,
        "Descr": "South East Coast, Michamvi, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zuri Zanzibar,Zanzibar Coast,Zanzibar",
        "Lat": -5.749608,
        "Long": 39.2902422,
        "Descr": "Kendwa Zanzibar, 73107, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "LANDING PAGES,",
        "Lat": 40.2729792,
        "Long": -76.8956279,
        "Descr": "1705 N Front St, Harrisburg, PA 17102, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cities,LANDING PAGES,",
        "Lat": 30.6350176,
        "Long": -96.3304793,
        "Descr": "424 Tarrow St, College Station, TX 77840, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Islands,LANDING PAGES,",
        "Lat": 30.55494,
        "Long": -81.44484,
        "Descr": "39 Beach Lagoon Rd, Fernandina Beach, FL 32034, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Main Page,LANDING PAGES,",
        "Lat": 44.9498232,
        "Long": -93.296547,
        "Descr": "1330 Lagoon Ave 4th floor, Minneapolis, MN 55408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "1 First Image,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "3 Islands,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "4 Cities,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "5 The Bucket List,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "6 Honeymoons,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "7 Family Travel,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "8 Most Popular Destinations,Main Page,LANDING PAGES,",
        "Lat": -8.783195,
        "Long": 34.508523,
        "Descr": "Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "9 Contact Us,Main Page,LANDING PAGES,",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "ABOUT,Main Page,LANDING PAGES,",
        "Lat": 44.9498232,
        "Long": -93.296547,
        "Descr": "1330 Lagoon Ave 4th floor, Minneapolis, MN 55408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "SAFARIS,",
        "Lat": 29.7058605,
        "Long": -95.4513011,
        "Descr": "Bellaire Blvd, Bellaire, TX 77401, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": 41.8940949,
        "Long": -87.6423033,
        "Descr": "520 W Erie St #440, Chicago, IL 60654, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Beho Beho Camp,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kiba Point Selous,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Roho ya Selous,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sand Rivers Selous,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selous Serena Camp,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Selous Game Reserve Morogoro Region, Mkalinzu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serena Mivumo River Lodge,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Selous Game Reserve Morogoro Region, Mkalinzu, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Siwandu Camp,Nyerere National Park (Selous Game Reserve),Tanzania",
        "Lat": -9,
        "Long": 37.5,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "[",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": ".well-known",
        "Lat": 45.3337026,
        "Long": -79.2225484,
        "Descr": "68 West Rd. Unit 5, Huntsville, ON P1H 1L8, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "acme-challenge,.well-known",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "CITIES",
        "Lat": 37.1095843,
        "Long": -95.7625713,
        "Descr": "Jefferson, Fawn Creek Township, KS 67301, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "INSTAGRAM",
        "Lat": 36.1344109,
        "Long": -95.8492189,
        "Descr": "2001 S 114th E Ave #50, Tulsa, OK 74128, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "ISLANDS",
        "Lat": 36.5889669,
        "Long": -94.9113471,
        "Descr": "Blackberry Island, Oklahoma 74331, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "LANDING PAGES",
        "Lat": 44.9498232,
        "Long": -93.296547,
        "Descr": "1330 Lagoon Ave 4th floor, Minneapolis, MN 55408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cities,LANDING PAGES",
        "Lat": 38.1328618,
        "Long": -121.8708558,
        "Descr": "Birds Landing, CA, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Islands,LANDING PAGES",
        "Lat": 33.5059242,
        "Long": -79.1202239,
        "Descr": "Pawleys Island, SC 29585, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Main Page,LANDING PAGES",
        "Lat": 44.9498232,
        "Long": -93.296547,
        "Descr": "1330 Lagoon Ave 4th floor, Minneapolis, MN 55408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "1 First Image,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "3 Islands,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "4 Cities,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "5 The Bucket List,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "6 Honeymoons,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "7 Family Travel,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "8 Most Popular Destinations,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "9 Contact Us,Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "ABOUT,Main Page,LANDING PAGES",
        "Lat": 44.9498232,
        "Long": -93.296547,
        "Descr": "1330 Lagoon Ave 4th floor, Minneapolis, MN 55408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "SAFARIS",
        "Lat": 36.0822906,
        "Long": -95.6793441,
        "Descr": "4472, 26881 E 58th St S, Broken Arrow, OK 74014, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Chundu,Livingstone,Zambia",
        "Lat": -17.8470867,
        "Long": 25.392696,
        "Descr": "Plot 9003, Nakatindi Road, Katambora, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Sussi & Chuma,Livingstone,Zambia",
        "Lat": -17.840845,
        "Long": 25.771746,
        "Descr": "Mosi-oa-Tunya National Park, Livingstone 00000, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sindabezi Island Lodge,Livingstone,Zambia",
        "Lat": -17.843241,
        "Long": 25.7115,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taita Falcon Lodge,Livingstone,Zambia",
        "Lat": -17.9690889,
        "Long": 25.9178056,
        "Descr": "11km from the Victoria Falls, Livingstone 60012, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The David Livingstone Lodge,Livingstone,Zambia",
        "Lat": -17.8879981,
        "Long": 25.8442225,
        "Descr": "Riverside Dr, Off Sichango Rd, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Royal Livingstone,Livingstone,Zambia",
        "Lat": -17.9149161,
        "Long": 25.8665268,
        "Descr": "Mosi-oa-Tunya Road, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thorntree River Lodge,Livingstone,Zambia",
        "Lat": -17.8431091,
        "Long": 25.754919,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Toka Leya,Livingstone,Zambia",
        "Lat": -17.8554749,
        "Long": 25.7874005,
        "Descr": "Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tongabezi Lodge,Livingstone,Zambia",
        "Lat": -17.8199844,
        "Long": 25.7047634,
        "Descr": "Livingstone Way, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lower Zambezi,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Baines' River Camp,Lower Zambezi,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chiawa Camp,Lower Zambezi,Zambia",
        "Lat": -15.6835762,
        "Long": 29.4135429,
        "Descr": "Lower Zambezi National Park, Chiawa, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Old Mondoro,Lower Zambezi,Zambia",
        "Lat": -15.6296961,
        "Long": 29.6916473,
        "Descr": "Lower Zambezi National Park, Rufunsa, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Zambezi Lodge,Lower Zambezi,Zambia",
        "Lat": -15.727642,
        "Long": 29.314983,
        "Descr": "Royal Zambezi Lodge, Lower Zambezi River <br />Zambia, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sausage Tree Camp,Lower Zambezi,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time & Tide Chongwe Suites,Lower Zambezi,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "North Luangwa,Zambia",
        "Lat": -11.9114239,
        "Long": 32.2653887,
        "Descr": "North Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mwaleshi Camp,North Luangwa,Zambia",
        "Lat": -11.990607,
        "Long": 32.317163,
        "Descr": "North Luangwa National Park, Eastern Province, Zambia, North Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Takwela Camp,North Luangwa,Zambia",
        "Lat": -12.0403312,
        "Long": 32.354631,
        "Descr": "Mwaleshi, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "South Luangwa,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bilimungwe Bushcamp,South Luangwa,Zambia",
        "Lat": -13.3880315,
        "Long": 31.5703743,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chamilandu Bushcamp,South Luangwa,Zambia",
        "Lat": -13.316611,
        "Long": 31.641548,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikoko Tree Camp,South Luangwa,Zambia",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikunto Safari Lodge,South Luangwa,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chindeni Bushcamp,South Luangwa,Zambia",
        "Lat": -13.367288,
        "Long": 31.606818,
        "Descr": "South Luangwa Park, Block 01, South Luangwa Park 51000, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Flatdogs Camp,South Luangwa,Zambia",
        "Lat": -13.1023982,
        "Long": 31.7785433,
        "Descr": "Plot 8755, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Island Bush Camp,South Luangwa,Zambia",
        "Lat": -13.4324436,
        "Long": 31.4742221,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kafunta River Lodge,South Luangwa,Zambia",
        "Lat": -13.1628167,
        "Long": 31.7390257,
        "Descr": "South, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kaingo Camp,South Luangwa,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kalamu Lagoon Camp,South Luangwa,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapamba Bushcamp,South Luangwa,Zambia",
        "Lat": -13.3827058,
        "Long": 31.5260524,
        "Descr": "Mpika, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuyenda Bushcamp,South Luangwa,Zambia",
        "Lat": -13.1897,
        "Long": 31.675825,
        "Descr": "South Luangwa National Park, Block KB24, Zambia, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Camp,South Luangwa,Zambia",
        "Lat": -12.8846195,
        "Long": 31.9670563,
        "Descr": "Lion Camp, South Luangwa National Park, Luangwa National Park, South, Lundazi, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Luangwa River Camp,South Luangwa,Zambia",
        "Lat": -13.074287,
        "Long": 31.8106597,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mfuwe Lodge,South Luangwa,Zambia",
        "Lat": -13.0789813,
        "Long": 31.7904987,
        "Descr": "Mpika, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nkwali Camp,South Luangwa,Zambia",
        "Lat": -13.1162657,
        "Long": 31.7392957,
        "Descr": "Nkwali Camp, Mpika, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nsefu Camp,South Luangwa,Zambia",
        "Lat": -12.9371568,
        "Long": 31.9034699,
        "Descr": "Nsefu Sector Mfuwe ZM Mfuwe ZM, 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Puku Ridge Camp,South Luangwa,Zambia",
        "Lat": -13.1612663,
        "Long": 31.7152779,
        "Descr": "South Luangwa National Park, 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Robin's House,South Luangwa,Zambia",
        "Lat": -13.117285,
        "Long": 31.743361,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chichele Presidential Lodge,South Luangwa,Zambia",
        "Lat": -13.166911,
        "Long": 31.710189,
        "Descr": "Mambwe, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tafika Camp,South Luangwa,Zambia",
        "Lat": -12.854358,
        "Long": 31.997445,
        "Descr": "South Luangwa National Park, Eastern Province, Zambia, Chikwinda, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tena Tena,South Luangwa,Zambia",
        "Lat": -12.9971281,
        "Long": 31.9055727,
        "Descr": "Nsefu, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Chinzombo,South Luangwa,Zambia",
        "Lat": -13.1038261,
        "Long": 31.7620069,
        "Descr": "TIME + TIDE CHINZOMBO, Luangwa River South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Kakuli,South Luangwa,Zambia",
        "Lat": -12.9962835,
        "Long": 31.89581,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Luwi,South Luangwa,Zambia",
        "Lat": -12.8993716,
        "Long": 31.7516685,
        "Descr": "TIME + TIDE LUWI CAMP, South, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Mchenja,South Luangwa,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Nsolo,South Luangwa,Zambia",
        "Lat": -12.9403535,
        "Long": 31.804567,
        "Descr": "South Luangwa National Park, Luwi River, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zungulila Bushcamp,South Luangwa,Zambia",
        "Lat": -13.351853,
        "Long": 31.514774,
        "Descr": "South Luangwa National Park, Zambia, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbabwe",
        "Lat": -19.015438,
        "Long": 29.154857,
        "Descr": "Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gonarezhou & Malilangwe,Zimbabwe",
        "Lat": -21.049439,
        "Long": 31.871865,
        "Descr": "Singita Pamushana, Malilangwe Reserve, Chiredzi, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Pamushana,Gonarezhou & Malilangwe,Zimbabwe",
        "Lat": -21.049439,
        "Long": 31.871865,
        "Descr": "Singita Pamushana, Malilangwe Reserve, Chiredzi, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hwange,Zimbabwe",
        "Lat": -18.3558779,
        "Long": 26.5019976,
        "Descr": "Hwange, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Davison's Camp,Hwange,Zimbabwe",
        "Lat": -19.0136118,
        "Long": 27.3099999,
        "Descr": "Davison's Camp, Hwange, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Makalolo,Hwange,Zimbabwe",
        "Lat": -19.0677965,
        "Long": 27.250514,
        "Descr": "Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Somalisa Acacia,Hwange,Zimbabwe",
        "Lat": -18.910558,
        "Long": 27.121666,
        "Descr": "Along the Kennedy Vlei Line, Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Somalisa Camp,Hwange,Zimbabwe",
        "Lat": -19.0448106,
        "Long": 27.0499452,
        "Descr": "Somalisa Concession Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Hide Safari Camp,Hwange,Zimbabwe",
        "Lat": -18.8729302,
        "Long": 27.1592116,
        "Descr": "90 Coronation Dr, Hwange, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Kariba,Zimbabwe",
        "Lat": -16.9556508,
        "Long": 27.9717606,
        "Descr": "Lake Kariba",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bumi Hills Safari Lodge,Lake Kariba,Zimbabwe",
        "Lat": -16.8091758,
        "Long": 28.3496046,
        "Descr": "Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Changa Safari Camp,Lake Kariba,Zimbabwe",
        "Lat": -16.746124,
        "Long": 28.670422,
        "Descr": "Matusadona National Park, Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Musango Safari Camp,Lake Kariba,Zimbabwe",
        "Lat": -16.8369178,
        "Long": 28.3897561,
        "Descr": "Musango Safari Camp, Island 134 and 135 Lake Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mana Pools,Zimbabwe",
        "Lat": -15.937159,
        "Long": 29.456993,
        "Descr": "Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikwenya Camp,Mana Pools,Zimbabwe",
        "Lat": -15.6745953,
        "Long": 29.512221,
        "Descr": "Chikwenga Island, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "John's Camp,Mana Pools,Zimbabwe",
        "Lat": -15.7450441,
        "Long": 29.3122017,
        "Descr": "Hurungwe, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanga Camp,Mana Pools,Zimbabwe",
        "Lat": -15.908764,
        "Long": 29.263107,
        "Descr": "Kanga Pan, Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Ruckomechi,Mana Pools,Zimbabwe",
        "Lat": -15.8500641,
        "Long": 29.1249441,
        "Descr": "Mana Pools National Park,, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nyamatusi Camp,Mana Pools,Zimbabwe",
        "Lat": -15.6993726,
        "Long": 29.4263824,
        "Descr": "Nyamatusi Camp, Nyamatusi Wilderness Concession Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ruckomechi Camp,Mana Pools,Zimbabwe",
        "Lat": -15.8500641,
        "Long": 29.1249441,
        "Descr": "Mana Pools National Park,, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sapi Explorers Camp,Mana Pools,Zimbabwe",
        "Lat": -15.8370505,
        "Long": 29.6439563,
        "Descr": "Sapi river, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambezi Expeditions,Mana Pools,Zimbabwe",
        "Lat": -15.7729311,
        "Long": 29.2238877,
        "Descr": "Long Zambezi River Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria Falls,Zimbabwe",
        "Lat": -17.9315803,
        "Long": 25.8301677,
        "Descr": "Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Batonka Lodge,Victoria Falls,Zimbabwe",
        "Lat": -17.929448,
        "Long": 25.828053,
        "Descr": "Reynard Road, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ilala Lodge,Victoria Falls,Zimbabwe",
        "Lat": -17.926191,
        "Long": 25.841572,
        "Descr": "A8, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matetsi Victoria Falls,Victoria Falls,Zimbabwe",
        "Lat": -17.9315803,
        "Long": 25.8301677,
        "Descr": "Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stanley & Livingstone Boutique Hotel,Victoria Falls,Zimbabwe",
        "Lat": -17.9995711,
        "Long": 25.8100543,
        "Descr": "Old Ursula’s Road, Stanley & Livingstone Private Game Reserve, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Elephant Camp,Victoria Falls,Zimbabwe",
        "Lat": -17.9743439,
        "Long": 25.8456366,
        "Descr": "The Elephant Camp, Victoria Falls National Park, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Victoria Falls Hotel,Victoria Falls,Zimbabwe",
        "Lat": -17.9308041,
        "Long": 25.8423322,
        "Descr": "1 Mallet Dr, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "icon",
        "Lat": 28.4432814,
        "Long": -81.4683162,
        "Descr": "8375 International Dr, Orlando, FL 32819, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "old,instagram",
        "Lat": 35.249846,
        "Long": -80.7989821,
        "Descr": "3522 N Davidson St, Charlotte, NC 28205, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "nav",
        "Lat": 40.5023071,
        "Long": -111.895405,
        "Descr": "13693 S 200 W Suite 200, Draper, UT 84020, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "separator",
        "Lat": 29.5820321,
        "Long": -90.7092212,
        "Descr": "144 Intracoastal Dr, Houma, LA 70363, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "steps",
        "Lat": 26.116898,
        "Long": -80.254003,
        "Descr": "8000 W Broward Blvd, Plantation, FL 33388, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "whyus,contenxt.africaunwind.com",
        "Lat": 38.8910644,
        "Long": -77.032614,
        "Descr": "1400 Constitution Ave. NW, Washington, D.C., DC 20560, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "]",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arusha Coffee Lodge,Arusha (3)",
        "Lat": -3.3745016,
        "Long": 36.6439397,
        "Descr": "Old Dodoma Road, Burka Coffee Estate, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamerkop House by Lemala,Arusha (3)",
        "Lat": -3.3950495,
        "Long": 36.7359786,
        "Descr": "Excel E Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hatari Lodge,Arusha (3)",
        "Lat": -3.2265396,
        "Long": 36.8572379,
        "Descr": "Hatari Lodge (Inside Arusha National Park), Momella Road Arousha National Park TZ, 23100, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kili Villa,Arusha (3)",
        "Lat": -3.4244124,
        "Long": 36.8780421,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Legendary Lodge,Arusha (3)",
        "Lat": -3.3407072,
        "Long": 36.640244,
        "Descr": "Selian Coffee Estate, Namanga Road, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machweo Wellness Retreat,Arusha (3)",
        "Lat": -3.3807342,
        "Long": 36.7450619,
        "Descr": "Onsea Moivaro Road / Baraa Road Arusha TZ, 1259, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shu'Mata Camp,Arusha (3)",
        "Lat": -2.8423069,
        "Long": 36.9242701,
        "Descr": "Monduli, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Town (1)",
        "Lat": -33.9248685,
        "Long": 18.4240553,
        "Descr": "Cape Town, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "21 Nettleton,Cape Town (1)",
        "Lat": -33.9351959,
        "Long": 18.3807601,
        "Descr": "19 - 23 Nettleton Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "African Elite,Cape Town (1)",
        "Lat": -33.9159549,
        "Long": 18.4178357,
        "Descr": "Shop A21, Cape Quarter, De Waterkant, Dixon St, De Waterkant, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Mount Nelson Hotel,Cape Town (1)",
        "Lat": -33.9320559,
        "Long": 18.4116404,
        "Descr": "76 Orange St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bungalow 52,Cape Town (1)",
        "Lat": -33.9406711,
        "Long": 18.3759546,
        "Descr": "52 Fourth Beach Promenade, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camissa House,Cape Town (1)",
        "Lat": -33.94727,
        "Long": 18.41707,
        "Descr": "28 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camps Bay Retreat,Cape Town (1)",
        "Lat": -33.9463,
        "Long": 18.3807,
        "Descr": "Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Cadogan,Cape Town (1)",
        "Lat": -33.9318158,
        "Long": 18.4082055,
        "Descr": "5 Upper Union St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Grace,Cape Town (1)",
        "Lat": -33.908713,
        "Long": 18.4205508,
        "Descr": "W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8002, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape View Clifton,Cape Town (1)",
        "Lat": -33.9323918,
        "Long": 18.3780387,
        "Descr": "232 Kloof Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cloud Nine Boutique Hotel,Cape Town (1)",
        "Lat": -33.929816,
        "Long": 18.4084645,
        "Descr": "12 Kloof Nek Rd, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Compass House,Cape Town (1)",
        "Lat": -33.9278789,
        "Long": 18.3794975,
        "Descr": "154 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dock House,Cape Town (1)",
        "Lat": -33.9058798,
        "Long": 18.418972,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ellerman House,Cape Town (1)",
        "Lat": -33.9290344,
        "Long": 18.3778647,
        "Descr": "180 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorgeous George Boutique Hotel,Cape Town (1)",
        "Lat": -33.9236057,
        "Long": 18.4200855,
        "Descr": "118 St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kensington Place,Cape Town (1)",
        "Lat": -33.938196,
        "Long": 18.4074486,
        "Descr": "1 Kensington Cres, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manna Bay,Cape Town (1)",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Closed to Oct 2022,Manna Bay,Cape Town (1)",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "More Quarters,Cape Town (1)",
        "Lat": -33.9322299,
        "Long": 18.40814,
        "Descr": "2 Nicol St, Gardens, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Beach Lodge,Cape Town (1)",
        "Lat": -34.4756401,
        "Long": 20.5086937,
        "Descr": "De Hoop Avenue, De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nox Apartments & Villas,Cape Town (1)",
        "Lat": -33.9631894,
        "Long": 18.3777178,
        "Descr": "1st Floor, Suite A, 13 Totnes Ave, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "O On Kloof Boutique Hotel,Cape Town (1)",
        "Lat": -33.9247948,
        "Long": 18.3828384,
        "Descr": "92 Kloof Rd, Sea Point, Cape Town, 8060, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Cape Town,Cape Town (1)",
        "Lat": -33.9086313,
        "Long": 18.4162905,
        "Descr": "One&Only Cape Town, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "POD Boutique Hotel,Cape Town (1)",
        "Lat": -33.9491694,
        "Long": 18.3797583,
        "Descr": "3 Argyle St, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Queen Victoria Hotel,Cape Town (1)",
        "Lat": -33.9053947,
        "Long": 18.4173715,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Waterfront,Cape Town (1)",
        "Lat": -33.8996146,
        "Long": 18.4120393,
        "Descr": "100 Beach Rd, Granger Bay, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Table Bay Hotel,Cape Town (1)",
        "Lat": -33.9028648,
        "Long": 18.4221961,
        "Descr": "Quay, 6 W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taj Cape Town,Cape Town (1)",
        "Lat": -33.9244744,
        "Long": 18.4197152,
        "Descr": "1 Waal Straat, corner St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Bay Hotel,Cape Town (1)",
        "Lat": -33.95254,
        "Long": 18.3782,
        "Descr": "69 Victoria Rd, Camps Bay, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Cape Milner,Cape Town (1)",
        "Lat": -33.927595,
        "Long": 18.409109,
        "Descr": "2 Milner Rd, Tamboerskloof, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Clarendon Bantry Bay,Cape Town (1)",
        "Lat": -33.9280643,
        "Long": 18.3792721,
        "Descr": "158 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Constantia,Cape Town (1)",
        "Lat": -34.0297494,
        "Long": 18.4398087,
        "Descr": "34 Spaanschemat River Rd, Constantia, Cape Town, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Long Beach,Cape Town (1)",
        "Lat": -34.13759,
        "Long": 18.32947,
        "Descr": "1 Kirsten Ave, Kommetjie, Cape Town, 7976, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marly Boutique Hotel,Cape Town (1)",
        "Lat": -33.9531211,
        "Long": 18.3775315,
        "Descr": "The Promenade, 201 Victoria Rd, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The One Above,Cape Town (1)",
        "Lat": -33.911232,
        "Long": 18.420313,
        "Descr": "1 Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Silo,Cape Town (1)",
        "Lat": -33.9084084,
        "Long": 18.4225592,
        "Descr": "Silo Square, Victoria & Alfred Waterfront, Cape Town, 8801, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spa House,Cape Town (1)",
        "Lat": -34.0459263,
        "Long": 18.3662281,
        "Descr": "18 Ave Suzanne, Hout Bay, Cape Town, 7872, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Atlantic,Cape Town (1)",
        "Lat": -34.065147,
        "Long": 18.36796,
        "Descr": "Chapmans Peak Dr, Hout Bay, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Twelve Apostles Hotel & Spa,Cape Town (1)",
        "Lat": -33.9835262,
        "Long": 18.3589679,
        "Descr": "Victoria Street, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria & Alfred Hotel,Cape Town (1)",
        "Lat": -33.90597,
        "Long": 18.4201001,
        "Descr": "V&A Complex, PierHead, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Durban (1)",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Canelands Beach Club & Spa,Durban (1)",
        "Lat": -29.5025691,
        "Long": 31.2389144,
        "Descr": "Salt Rock, Dolphin Coast, 4391, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Teremok Marine Lodge,Durban (1)",
        "Lat": -29.735195,
        "Long": 31.0807851,
        "Descr": "49 Marine Dr, Umhlanga Rocks, Durban, 4320, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oyster Box,Durban (1)",
        "Lat": -29.7284034,
        "Long": 31.0872415,
        "Descr": "2 Lighthouse Rd, Umhlanga, 4319, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbali Resort,Durban (1)",
        "Lat": -29.5628099,
        "Long": 31.1901637,
        "Descr": "Zimbali Drive, Port Zimbali, Dolphin Coast, 4399, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Garden Route (1)",
        "Lat": -33.987864,
        "Long": 22.502253,
        "Descr": "Park Road 1 Strand Street Kraaibosch, Garden Route Mall, George, 6529, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna,Garden Route (1)",
        "Lat": -34.0350856,
        "Long": 23.0464693,
        "Descr": "Knysna, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanonkop House,Knysna,Garden Route (1)",
        "Lat": -34.0362538,
        "Long": 23.0220235,
        "Descr": "6 Cuthbert St, Kanonkop, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna Hollow Country Estate,Knysna,Garden Route (1)",
        "Lat": -34.0355873,
        "Long": 23.017208,
        "Descr": "5 Welbedacht Ln, Kanonkop, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leisure Isle Lodge,Knysna,Garden Route (1)",
        "Lat": -34.066256,
        "Long": 23.055721,
        "Descr": "87 Bayswater Rd, Leisure Isle, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pezula Resort Hotel & Spa,Knysna,Garden Route (1)",
        "Lat": -34.0693174,
        "Long": 23.0890876,
        "Descr": "Lagoon View Dr, Sparrebosch, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Rex Hotel,Knysna,Garden Route (1)",
        "Lat": -34.0391937,
        "Long": 23.045213,
        "Descr": "8 Gray St, Knysna Central, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Turbine Boutique Hotel,Knysna,Garden Route (1)",
        "Lat": -34.048774,
        "Long": 23.049058,
        "Descr": "36 Sawtooth Lane, Thesen Island, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Plettenberg Bay,Garden Route (1)",
        "Lat": -34.0574892,
        "Long": 23.3644925,
        "Descr": "Plettenberg Bay, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Christiana Lodge,Plettenberg Bay,Garden Route (1)",
        "Lat": -34.088019,
        "Long": 23.3686895,
        "Descr": "Aquarius Cl, SolarBeach, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emily Moon River Lodge,Plettenberg Bay,Garden Route (1)",
        "Lat": -34.01012,
        "Long": 23.3729843,
        "Descr": "1 Rietvlei Rd, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hog Hollow Country Lodge,Plettenberg Bay,Garden Route (1)",
        "Lat": -33.9731395,
        "Long": 23.4736603,
        "Descr": "Askop Road, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hunters Country House,Plettenberg Bay,Garden Route (1)",
        "Lat": -34.052641,
        "Long": 23.275351,
        "Descr": "Off N2, 10km west of Plettenberg Bay, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurland,Plettenberg Bay,Garden Route (1)",
        "Lat": -33.9408084,
        "Long": 23.4865442,
        "Descr": "KURLAND ESTATE N2 THE CRAGS, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tamodi Lodge & Stables,Plettenberg Bay,Garden Route (1)",
        "Lat": -33.986913,
        "Long": 23.45518,
        "Descr": "Pinehaven rd, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Plettenberg,Plettenberg Bay,Garden Route (1)",
        "Lat": -34.05381,
        "Long": 23.37756,
        "Descr": "40 Church St, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tsala Treetop Lodge,Plettenberg Bay,Garden Route (1)",
        "Lat": -34.0521202,
        "Long": 23.2705612,
        "Descr": "Off N2 10km W Of Plettenberg, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Port Elizabeth,Garden Route (1)",
        "Lat": -33.9608369,
        "Long": 25.6022423,
        "Descr": "Gqeberha, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "No 5 Boutique Art Hotel,Port Elizabeth,Garden Route (1)",
        "Lat": -33.9847202,
        "Long": 25.6619275,
        "Descr": "5 Brighton Dr, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Port Elizabeth,Port Elizabeth,Garden Route (1)",
        "Lat": -33.987245,
        "Long": 25.6711184,
        "Descr": "Marine Dr & 9th Ave Summerstrand Nelson Mandela Bay, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Windermere,Port Elizabeth,Garden Route (1)",
        "Lat": -33.9759451,
        "Long": 25.6447799,
        "Descr": "35 Humewood Rd, Humewood, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Johannesburg (1)",
        "Lat": -26.2041028,
        "Long": 28.0473051,
        "Descr": "Johannesburg, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "AtholPlace House & Villa,Johannesburg (1)",
        "Lat": -26.111817,
        "Long": 28.0637115,
        "Descr": "90 Pretoria Ave, Atholl, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "D'Oreale Grande,Johannesburg (1)",
        "Lat": -26.1443844,
        "Long": 28.2213325,
        "Descr": "64 Jones Rd, O.R. Tambo, Kempton Park, 1620, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fairlawns Boutique Hotel & Spa,Johannesburg (1)",
        "Lat": -26.0795011,
        "Long": 28.0749836,
        "Descr": "1 Alma Rd, Morningside, Johannesburg, 2052, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Hotel The Westcliff,Johannesburg (1)",
        "Lat": -26.1710223,
        "Long": 28.0324839,
        "Descr": "67 Jan Smuts Ave, Westcliff, Johannesburg, 2132, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Intercontinental Hotel,Johannesburg (1)",
        "Lat": 44.879663,
        "Long": -93.2013581,
        "Descr": "5005 Glumack Dr, Minneapolis, MN 55450, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Michelangelo Towers,Johannesburg (1)",
        "Lat": -26.1063997,
        "Long": 28.0542906,
        "Descr": "8 Maude St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mount Grace Hotel & Spa,Johannesburg (1)",
        "Lat": -26.1336131,
        "Long": 28.0676215,
        "Descr": "1 Melrose Blvd, Melrose Arch, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Sandton,Johannesburg (1)",
        "Lat": -26.1041779,
        "Long": 28.0614167,
        "Descr": "Rivonia Rd &, Daisy St, Sandown, Sandton, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Maslow,Johannesburg (1)",
        "Lat": -26.0990371,
        "Long": 28.0588493,
        "Descr": "Rivonia Rd &, Grayston Dr, Gauteng, Sandton, 2031, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Michelangelo Hotel,Johannesburg (1)",
        "Lat": -26.1069699,
        "Long": 28.0566358,
        "Descr": "135 West St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Peech Hotel,Johannesburg (1)",
        "Lat": -26.1365329,
        "Long": 28.0588454,
        "Descr": "61 North St, Melrose North, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Johannesburg,Johannesburg (1)",
        "Lat": -26.16454,
        "Long": 28.05939,
        "Descr": "17 4th Ave, Houghton Estate, Johannesburg, 2198, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Saxon Hotel,Johannesburg (1)",
        "Lat": -26.11212,
        "Long": 28.03561,
        "Descr": "36 Saxon Rd, Sandhurst, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali (2)",
        "Lat": 44.9336284,
        "Long": -93.1210248,
        "Descr": "253 Duke St, St Paul, MN 55102, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Marriott,Kigali (2)",
        "Lat": -1.9537135,
        "Long": 30.0621258,
        "Descr": "KN 3 Avenue, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Serena Hotel,Kigali (2)",
        "Lat": -1.9563339,
        "Long": 30.0627322,
        "Descr": "KN 3 Ave, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Retreat by Heaven,Kigali (2)",
        "Lat": -1.9472729,
        "Long": 30.0651165,
        "Descr": "No 5, KN 29 St, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lilongwe (2)",
        "Lat": -13.9883545,
        "Long": 33.778309,
        "Descr": "Area 2, Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 13 Degrees,Lilongwe (2)",
        "Lat": -13.9297681,
        "Long": 33.7966419,
        "Descr": "43/2/58, Mphonongo, Lilongwe, Malawi",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi",
        "Lat": -1.2920659,
        "Long": 36.8219462,
        "Descr": "Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Eka Hotel,Nairobi",
        "Lat": -1.3241578,
        "Long": 36.8445673,
        "Descr": "Eka Hotel, Mombasa Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hemingways Nairobi,Nairobi",
        "Lat": -1.3427104,
        "Long": 36.7053197,
        "Descr": "Karen Mbagathi Ridge, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nairobi Tented Camp,Nairobi",
        "Lat": -1.3632752,
        "Long": 36.7764992,
        "Descr": "Nairobi Tented camp, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ololo Safari Lodge,Nairobi",
        "Lat": -1.3883495,
        "Long": 36.8328425,
        "Descr": "Southern Border of the Nairobi National Park, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Emakoko,Nairobi",
        "Lat": -1.406924,
        "Long": 36.876973,
        "Descr": "The Emakoko, Nairobi National Park, Uhuru Gardens, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Giraffe Manor,Nairobi",
        "Lat": -1.375487,
        "Long": 36.744676,
        "Descr": "Gogo Falls Road, Nairobi, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Panorama Route",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Casterbridge Hollow Boutique Hotel,Panorama Route",
        "Lat": -25.306553,
        "Long": 31.0333826,
        "Descr": "Casterbridge Lifestyle Centre, R40, White River, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oliver's Lodge,Panorama Route",
        "Lat": 43.6608472,
        "Long": -71.4318398,
        "Descr": "92 Powers Rd, Meredith, NH 03253, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Perry's Bridge Hollow,Panorama Route",
        "Lat": -25.0364894,
        "Long": 31.1243171,
        "Descr": "R40, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanamera Lodge,Panorama Route",
        "Lat": -25.0473903,
        "Long": 30.9585527,
        "Descr": "Sabie Road, Hazyview, Kiepersol, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Walkerson's Hotel & Spa,Panorama Route",
        "Lat": 43.5708103,
        "Long": -89.7727407,
        "Descr": "1305 Kalahari Dr, Wisconsin Dells, WI 53965, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Panorama Route (1)",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Casterbridge Hollow Boutique Hotel,Panorama Route (1)",
        "Lat": -25.306553,
        "Long": 31.0333826,
        "Descr": "Casterbridge Lifestyle Centre, R40, White River, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oliver's Lodge,Panorama Route (1)",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Perry's Bridge Hollow,Panorama Route (1)",
        "Lat": -25.0364894,
        "Long": 31.1243171,
        "Descr": "R40, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanamera Lodge,Panorama Route (1)",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Walkerson's Hotel & Spa,Panorama Route (1)",
        "Lat": 43.5708103,
        "Long": -89.7727407,
        "Descr": "1305 Kalahari Dr, Wisconsin Dells, WI 53965, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Whale Route (1)",
        "Lat": 36.1937732,
        "Long": -95.7329257,
        "Descr": "2600 U.S. Rte 66, Catoosa, OK 74015, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Birkenhead House,Whale Route (1)",
        "Lat": -34.409963,
        "Long": 19.280555,
        "Descr": "119 11th St, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Farm 215,Whale Route (1)",
        "Lat": -34.567574,
        "Long": 19.5137787,
        "Descr": "Farm 215 - Hartebeeskloof & Koudeberg, Baardskeerdersbos, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Forest Lodge,Whale Route (1)",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Garden Lodge,Whale Route (1)",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Private Villas,Whale Route (1)",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lekkerwater Beach Lodge,Whale Route (1)",
        "Lat": -34.4502064,
        "Long": 20.6345114,
        "Descr": "De Hoop Marine Protected Area, South Africa, Overberg DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Ocean House,Whale Route (1)",
        "Lat": -34.473385,
        "Long": 20.5163438,
        "Descr": "De Hoop Avenue De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ocean Eleven Guest House,Whale Route (1)",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One Marine Drive Boutique Hotel,Whale Route (1)",
        "Lat": -34.4221363,
        "Long": 19.234668,
        "Descr": "1 Marine Dr, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Schulphoek House,Whale Route (1)",
        "Lat": -34.4269554,
        "Long": 19.2004925,
        "Descr": "181 Piet Retief Cres, Sand Bay, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marine Hotel,Whale Route (1)",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Thatch House,Whale Route (1)",
        "Lat": -34.4064253,
        "Long": 19.3417732,
        "Descr": "R43 Stanford Road, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Windhoek",
        "Lat": -22.5608807,
        "Long": 17.0657549,
        "Descr": "Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Windhoek,Windhoek",
        "Lat": -22.570407,
        "Long": 17.085683,
        "Descr": "Rev Michael Scott St, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Omaanda,Windhoek",
        "Lat": -22.49542,
        "Long": 17.39028,
        "Descr": "Farm No. 78, Rest of Ondekaremba Farm, Windhoek East, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Olive Exclusive,Windhoek",
        "Lat": -22.5534297,
        "Long": 17.0902601,
        "Descr": "22 Promenaden Rd, Windhoek, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Estate,Stellenbosch,Wine Route",
        "Lat": -33.9221089,
        "Long": 18.9237638,
        "Descr": "Helshoogte Rd, Stellenbosch, 7560, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wine Route (1)",
        "Lat": 41.276186,
        "Long": -72.8381229,
        "Descr": "845 W Main St UNIT 7, Branford, CT 06405, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek,Wine Route (1)",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Babylonstoren,Franschhoek,Wine Route (1)",
        "Lat": -33.905848,
        "Long": 19.113083,
        "Descr": "Corner of Main Road and, Cabriere St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek Country House,Franschhoek,Wine Route (1)",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Cle des Montagnes,Franschhoek,Wine Route (1)",
        "Lat": -33.912665,
        "Long": 19.116629,
        "Descr": "13 Daniel Hugo St, Franschhoek, Cape Town, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Residence,Franschhoek,Wine Route (1)",
        "Lat": -33.898411,
        "Long": 19.1222313,
        "Descr": "Elandskloof Road, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Franschhoek Hotel & Spa,Franschhoek,Wine Route (1)",
        "Lat": -33.935809,
        "Long": 19.110409,
        "Descr": "16 Minor Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Quartier Francais,Franschhoek,Wine Route (1)",
        "Lat": -33.9129333,
        "Long": 19.1202526,
        "Descr": "Corner of Berg and, Wilhelmina St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leeu House,Franschhoek,Wine Route (1)",
        "Lat": -33.913255,
        "Long": 19.121227,
        "Descr": "12 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mont Rochelle,Franschhoek,Wine Route (1)",
        "Lat": -33.912819,
        "Long": 19.1110279,
        "Descr": "1499 Dassenberg Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Franschhoek,Franschhoek,Wine Route (1)",
        "Lat": -33.9083276,
        "Long": 19.1170728,
        "Descr": "68 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage at Grande Provence,Franschhoek,Wine Route (1)",
        "Lat": -33.8987481,
        "Long": 19.1036181,
        "Descr": "Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Paarl,Wine Route (1)",
        "Lat": -33.7639,
        "Long": 18.9189,
        "Descr": "Suid-Agter-Paarl Rd, Southern Paarl, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grand Dedale Country House,Paarl,Wine Route (1)",
        "Lat": -33.6269933,
        "Long": 19.0830857,
        "Descr": "Bovlei Rd, Wellington, 7654, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grande Roche,Paarl,Wine Route (1)",
        "Lat": -33.7513889,
        "Long": 18.9591667,
        "Descr": "1 Plantasie St, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Light House Boutique Suites,Paarl,Wine Route (1)",
        "Lat": -33.77236,
        "Long": 18.954887,
        "Descr": "2 Lille, Courtrai, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stellenbosch,Wine Route (1)",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bonne Esperance,Stellenbosch,Wine Route (1)",
        "Lat": -33.9369809,
        "Long": 18.8664217,
        "Descr": "Van Riebeeck St &, Neethling St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Estate,Stellenbosch,Wine Route (1)",
        "Lat": -33.9221089,
        "Long": 18.9237638,
        "Descr": "Helshoogte Rd, Stellenbosch, 7560, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delaire Graff Estate,Stellenbosch,Wine Route (1)",
        "Lat": -33.9202951,
        "Long": 18.9218332,
        "Descr": "Helshoogte Rd, Stellenbosch, 7602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lanzerac Hotel & Spa,Stellenbosch,Wine Route (1)",
        "Lat": -33.938299,
        "Long": 18.894264,
        "Descr": "1 Lanzerac Rd, Jonkershoek, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majeka House,Stellenbosch,Wine Route (1)",
        "Lat": -33.9680216,
        "Long": 18.860642,
        "Descr": "26 Houtkapper St, Paradyskloof, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oude Werf Hotel,Stellenbosch,Wine Route (1)",
        "Lat": -33.9378177,
        "Long": 18.8619977,
        "Descr": "30 Church St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spier Hotel,Stellenbosch,Wine Route (1)",
        "Lat": -33.9735508,
        "Long": 18.7821798,
        "Descr": "R310 Baden Powell Dr, Stellenbosch, 7603, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anjajavy le Lodge,Madagascar",
        "Lat": -14.9914277,
        "Long": 47.2297279,
        "Descr": "Anjajavy L Hotel, Péninsule d'Anjajavy, Anjajavy Village 515, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Tsarabanjina,Madagascar",
        "Lat": -13.028615,
        "Long": 48.54632,
        "Descr": "Tsarabanjina island B.P 380 Helville 2007, 501, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lokanga Boutique Hotel,Madagascar",
        "Lat": -18.9257261,
        "Long": 47.5306729,
        "Descr": "lot VW 115 Ambohimitsimbina, Antananarivo 101, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Maison Gallieni,Madagascar",
        "Lat": -18.9084039,
        "Long": 47.5292373,
        "Descr": "Rue Rainandriamampandry, Tananarive 182, BP, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Miavana,Madagascar",
        "Lat": -12.7961074,
        "Long": 49.8214611,
        "Descr": "Miavana nosy ankao, Madagascar",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Private Seychelles",
        "Lat": 30.1407101,
        "Long": -85.7541707,
        "Descr": "5115 Gulf Dr UNIT 1204, Panama City Beach, FL 32408, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cousine Island,Private Seychelles",
        "Lat": -4.3487802,
        "Long": 55.6482984,
        "Descr": "Cousine Island, PO Box 977, Victoria, Mahe, Cousine, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Denis Private Island Lodge,Private Seychelles",
        "Lat": -3.801795,
        "Long": 55.662857,
        "Descr": "Denis Private Island Denis Island, Denis, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Enchanted Island Resort,Private Seychelles",
        "Lat": -4.6218855,
        "Long": 55.5034253,
        "Descr": "Enchanted Island Resort, Round Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Desroches Island,Private Seychelles",
        "Lat": -5.6972788,
        "Long": 53.6511778,
        "Descr": "Unnamed Road, Desroches Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Seychelles Labriz Resort & Spa,Private Seychelles",
        "Lat": -4.4812865,
        "Long": 55.2483398,
        "Descr": "La Passe, Silhouette, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "North Island,Private Seychelles",
        "Lat": -4.395489,
        "Long": 55.2457288,
        "Descr": "North Island Ile du Nord North Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Six Senses Zil Pasyon,Private Seychelles",
        "Lat": -4.3179723,
        "Long": 55.8692252,
        "Descr": "Felicite, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "2 Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "3 Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "4 Main Page,LANDING PAGES",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe National Park,Botswana",
        "Lat": -18.7305642,
        "Long": 24.4292148,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Game Lodge,Chobe National Park,Botswana",
        "Lat": -17.84083,
        "Long": 25.076058,
        "Descr": "Chobe National Park, Serondela, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Princess,Chobe National Park,Botswana",
        "Lat": -18.7305642,
        "Long": 24.4292148,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Savanna Lodge,Chobe National Park,Botswana",
        "Lat": -18.7305642,
        "Long": 24.4292148,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chobe Under Canvas,Chobe National Park,Botswana",
        "Lat": -18.666616,
        "Long": 24.3905926,
        "Descr": "Chobe National Park, 267, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Valley Lodge,Chobe National Park,Botswana",
        "Lat": -17.852453,
        "Long": 25.24392,
        "Descr": "Lesoma Valley, Chobe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngoma Safari Lodge,Chobe National Park,Botswana",
        "Lat": -17.941432,
        "Long": 24.7092848,
        "Descr": "Ngoma Safari Lodge - Machaba Safaris™, 17.941398, 24.709321 Chobe Forest Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chobe Chilwero,Chobe National Park,Botswana",
        "Lat": -17.827767,
        "Long": 25.137583,
        "Descr": "Chobe National Park, Kasane, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambezi Queen,Chobe National Park,Botswana",
        "Lat": -18.7305642,
        "Long": 24.4292148,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Linyanti,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "DumaTau,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "King's Pool,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.4380621,
        "Long": 23.7069809,
        "Descr": "Ngamiland West Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lagoon Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lebala Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Bush Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Ebony Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Linyanti Tented Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Duma Tau,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.5242381,
        "Long": 23.5784879,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Building - Images to follow,Little Duma Tau,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savuti Camp,Linyanti Wildlife Reserve,Botswana",
        "Lat": -18.2946232,
        "Long": 23.909966,
        "Descr": "Linyanti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Moremi Game Reserve,Botswana",
        "Lat": -19.2386655,
        "Long": 23.3546152,
        "Descr": "Third Bridge Camp, Moremi, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Khwai River Lodge,Moremi Game Reserve,Botswana",
        "Lat": -19.1485425,
        "Long": 23.8001226,
        "Descr": "-19.148322, 23.800381, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Moremi,Moremi Game Reserve,Botswana",
        "Lat": -19.188326,
        "Long": 23.408492,
        "Descr": "Plot 244 Airport Road, Xakanaxa, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Okuti,Moremi Game Reserve,Botswana",
        "Lat": -19.1806824,
        "Long": 23.4104272,
        "Descr": "Okavango Delta, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Xakanaxa,Moremi Game Reserve,Botswana",
        "Lat": -19.1823661,
        "Long": 23.4088182,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hyena Pan Camp,Moremi Game Reserve,Botswana",
        "Lat": -18.9985985,
        "Long": 23.7689546,
        "Descr": "Ngamiland North, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Khwai Bush Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Khwai Leadwood Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.152142,
        "Long": 23.7707102,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Machaba,Moremi Game Reserve,Botswana",
        "Lat": -19.1227858,
        "Long": 23.8132141,
        "Descr": "19.122785, 23.813216 NG/19 Khwai Development Trust, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Mombo,Moremi Game Reserve,Botswana",
        "Lat": -19.2223568,
        "Long": 22.7779066,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machaba Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mma Dinare,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mombo Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.2179449,
        "Long": 22.7850008,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Moremi Crossing,Moremi Game Reserve,Botswana",
        "Lat": -19.5271168,
        "Long": 23.1495068,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pelo Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Qorokwe Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rra Dinare,Moremi Game Reserve,Botswana",
        "Lat": -19.6510095,
        "Long": 22.9058802,
        "Descr": "South-Eastern Okavango, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sable Alley Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.1280182,
        "Long": 23.6613429,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chief's Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.293294,
        "Long": 22.909051,
        "Descr": "Chiefs Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Jackal & Hide,Moremi Game Reserve,Botswana",
        "Lat": -19.1520906,
        "Long": 23.6953918,
        "Descr": "Okavango Delta, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tuludi Camp,Moremi Game Reserve,Botswana",
        "Lat": -19.31693,
        "Long": 22.925959,
        "Descr": "Moremi Game Reserve, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda Game Reserve,Botswana",
        "Lat": -18.6678474,
        "Long": 23.2394452,
        "Descr": "Selinda Game Reserve (Private) NG/16, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda Camp,Selinda Game Reserve,Botswana",
        "Lat": -18.5328932,
        "Long": 23.5220696,
        "Descr": "Ngamiland West, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Selinda Explorers Camp,Selinda Game Reserve,Botswana",
        "Lat": -18.5365932,
        "Long": 23.4857007,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zarafa Camp,Selinda Game Reserve,Botswana",
        "Lat": -18.5894098,
        "Long": 23.5309216,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Kalahari,Botswana",
        "Lat": -22.328474,
        "Long": 24.684866,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Deception Valley Lodge,The Kalahari,Botswana",
        "Lat": -20.953001,
        "Long": 23.649818,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dinaka Camp,The Kalahari,Botswana",
        "Lat": -20.9463391,
        "Long": 23.3433755,
        "Descr": "Haina veld, Ngamiland, North West Botswana, Maun, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kalahari Plains Camp,The Kalahari,Botswana",
        "Lat": -21.3654813,
        "Long": 23.8341122,
        "Descr": "Central Kalahari, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tau Pan Camp,The Kalahari,Botswana",
        "Lat": -21.4502727,
        "Long": 23.4209741,
        "Descr": "Tau Pan Camp, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Savuti,Botswana",
        "Lat": -18.5682622,
        "Long": 24.0670116,
        "Descr": "Savuti, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Savute Elephant Lodge,The Savuti,Botswana",
        "Lat": -18.5693078,
        "Long": 24.0543554,
        "Descr": "Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camp Savuti,The Savuti,Botswana",
        "Lat": -18.564352,
        "Long": 24.061858,
        "Descr": "Paradise Campsite, Savuti, 18°33'51.7\"S 24°03'42.7\"E, Botswana, Selebi Phikwe, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savute Safari Lodge,The Savuti,Botswana",
        "Lat": -18.564308,
        "Long": 24.057397,
        "Descr": "Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savute Under Canvas,The Savuti,Botswana",
        "Lat": -18.569435,
        "Long": 24.071571,
        "Descr": "Ngoma Gate Chobe National Park, Botswana",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu National Reserve,Kenya",
        "Lat": 0.5774645,
        "Long": 37.4915773,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Bedroom Camp,Samburu National Reserve,Kenya",
        "Lat": 0.5895286,
        "Long": 37.6033554,
        "Descr": "Samburu National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Larsen's Tented Camp,Samburu National Reserve,Kenya",
        "Lat": 0.577665,
        "Long": 37.492733,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "SaSaab Camp,Samburu National Reserve,Kenya",
        "Lat": 0.6311773,
        "Long": 37.35051,
        "Descr": "Westgate, Samburu National Reserve, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu Intrepids,Samburu National Reserve,Kenya",
        "Lat": 0.5772821,
        "Long": 37.4924004,
        "Descr": "Isiolo District, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Samburu Sopa Lodge,Samburu National Reserve,Kenya",
        "Lat": 0.6094372,
        "Long": 37.5237687,
        "Descr": "Unnamed street, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sarara Camp,Samburu National Reserve,Kenya",
        "Lat": 1.0093928,
        "Long": 37.3856163,
        "Descr": "295P+Q6 Wamba, Samburu, Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saruni Rhino,Samburu National Reserve,Kenya",
        "Lat": 0.5774645,
        "Long": 37.4915773,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saruni Samburu,Samburu National Reserve,Kenya",
        "Lat": 0.5774645,
        "Long": 37.4915773,
        "Descr": "Kenya",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Etosha National Park,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Andersson's At Ongava,Etosha National Park,Namibia",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Ongava,Etosha National Park,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Bush Camp,Etosha National Park,Namibia",
        "Lat": -18.8127442,
        "Long": 17.1290394,
        "Descr": "Unnamed Road, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Lodge,Etosha National Park,Namibia",
        "Lat": -18.8119583,
        "Long": 17.1195049,
        "Descr": "8 kilometers from the Lindequist Gate of the Etosha Nationalpark, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mushara Outpost,Etosha National Park,Namibia",
        "Lat": -19.058018,
        "Long": 17.190761,
        "Descr": "Etosha National Park, Etosha, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ongava Lodge,Etosha National Park,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ongava Tented Camp,Etosha National Park,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Bush Camp,Etosha National Park,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Etosha Aoba Camp,Etosha National Park,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Tented Camp,Etosha National Park,Namibia",
        "Lat": -18.7307811,
        "Long": 17.0475834,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma The Fort,Etosha National Park,Namibia",
        "Lat": -18.7360687,
        "Long": 17.0427703,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Onguma Tree Top Camp,Etosha National Park,Namibia",
        "Lat": -18.7313095,
        "Long": 17.0466392,
        "Descr": "Onguma Game Reserve Etosha National Park, Tsumeb, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Safarihoek Lodge,Etosha National Park,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Villa Mushara,Etosha National Park,Namibia",
        "Lat": -18.8555909,
        "Long": 16.3293197,
        "Descr": "Etosha National Park, Namibia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Addo Elephant National Park,Eastern Cape,South Africa",
        "Lat": -33.4834289,
        "Long": 25.7505798,
        "Descr": "Addo Elephant National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorah Elephant Camp,Addo Elephant National Park,Eastern Cape,South Africa",
        "Lat": -33.508578,
        "Long": 25.80108,
        "Descr": "ADDO HEIGHTS RD, Addo, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Long Hope Villa,Addo Elephant National Park,Eastern Cape,South Africa",
        "Lat": -33.4834289,
        "Long": 25.7505798,
        "Descr": "Addo Elephant National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Riverbend Lodge,Addo Elephant National Park,Eastern Cape,South Africa",
        "Lat": -33.426058,
        "Long": 25.7533717,
        "Descr": "Elephant National Park, Zuurberg Road, Addo, 6105, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Ecca Lodge,Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.1462395,
        "Long": 26.5250324,
        "Descr": "Kwandwe Private Game Reserve, 6170, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Fort House,Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Great Fish River Lodge,Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.1464645,
        "Long": 26.5250743,
        "Descr": "Kwandwe Private Game Reserve, R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Melton Manor,Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.146984,
        "Long": 26.524956,
        "Descr": "R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kwandwe Uplands Homestead,Kwandwe Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.146984,
        "Long": 26.524956,
        "Descr": "R67 Fort Brown, Grahamstown, 6140, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve in the Eastern Cape, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Bayethe Tented Lodge,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4001998,
        "Long": 26.1843469,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Eagles Crag,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4158603,
        "Long": 26.0916266,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Lobengula,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.3590272,
        "Long": 26.1077361,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Long Lee Manor,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4668045,
        "Long": 26.0503559,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Riverdene Family Lodge,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4907595,
        "Long": 26.0478638,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Sarili,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.5025418,
        "Long": 26.0502919,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shamwari Sindile,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4766871,
        "Long": 26.0375604,
        "Descr": "Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sharmwari Explorer Camp,Shamwari Private Game Reserve,Eastern Cape,South Africa",
        "Lat": -33.4779366,
        "Long": 26.036227,
        "Descr": "Shamwari Private Game Reserve in the Eastern Cape, Shamwari Private Game Reserve, Paterson, 6130, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Private Game Reserve,Kruger,South Africa",
        "Lat": -24.429375,
        "Long": 31.0134571,
        "Descr": "Kapama Private Game Reserve, Kapama Private Game Reserve, Kapama, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Buffalo Camp,Kapama Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4666743,
        "Long": 31.0509923,
        "Descr": "Kapama Buffalo Camp, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Karula,Kapama Private Game Reserve,Kruger,South Africa",
        "Lat": -24.429375,
        "Long": 31.0134571,
        "Descr": "Kapama Private Game Reserve, Kapama Private Game Reserve, Kapama, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama River Lodge,Kapama Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4261553,
        "Long": 31.0288083,
        "Descr": "Kapama River Lodge, Kapama Private Game Reserve, R40, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapama Southern Camp,Kapama Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4552166,
        "Long": 31.0869147,
        "Descr": "Kapama Southern Camp, Kapama Game Reserve R40 Klaserie River #2, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.2564029,
        "Long": 31.2168466,
        "Descr": "The Klaserie Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Africa on Foot Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.2569911,
        "Long": 31.216675,
        "Descr": "Ross Farm, Klaserie Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Boabab Ridge,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.318845,
        "Long": 31.228981,
        "Descr": "Klaserie Private Nature Reserve Hoedspruit, PO Box 1199, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Klaserie Sands River Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.1899507,
        "Long": 31.2609757,
        "Descr": "Greater Kruger Park, Klaserie Private Nature Reserve Greater Kruger, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makumu Game Lodge,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4045799,
        "Long": 31.3506581,
        "Descr": "Farm Dundee 32KU Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Misava Safari Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.160441,
        "Long": 31.1725338,
        "Descr": "Klaserie Private Nature Reserve Limpopo Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nthambo Tree Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.288344,
        "Long": 31.231355,
        "Descr": "Ross Farm, Enkhulu Gate, Argyle Road, Klaserie Private Nature Reserve, Hoedspruit, 1360, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Amani,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.1726637,
        "Long": 31.1989811,
        "Descr": "Northumberland Farm Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Camp George,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.1659685,
        "Long": 31.2025431,
        "Descr": "Simbavati Camp George, Portion 9 of portion 7 Farm Northumberland Registered Division KU31 Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Rishile Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.241788,
        "Long": 31.2233999,
        "Descr": "Tanda Tula Rishilé Camp, Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Under Construction,Tanda Tula Rishile Camp,Klaserie Private Game Reserve,Kruger,South Africa",
        "Lat": -24.241788,
        "Long": 31.2233999,
        "Descr": "Tanda Tula Rishilé Camp, Klaserie Private Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kruger Private Concession,Kruger,South Africa",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamiltons Tented Camp,Kruger Private Concession,Kruger,South Africa",
        "Lat": -24.6237334,
        "Long": 31.6725986,
        "Descr": "Kruger National Park Hamiltons Tented Rd Kruger Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hoyo Hoyo Safari Lodge,Kruger Private Concession,Kruger,South Africa",
        "Lat": 40.7273942,
        "Long": -74.0058612,
        "Descr": "160 Varick St Suite 030-102, New York, NY 10014, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Imbali Safari Lodge,Kruger Private Concession,Kruger,South Africa",
        "Lat": -24.6010456,
        "Long": 31.6298179,
        "Descr": "S145, Kruger National Park, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Narina Lodge,Kruger Private Concession,Kruger,South Africa",
        "Lat": 40.7273942,
        "Long": -74.0058612,
        "Descr": "160 Varick St Suite 030-102, New York, NY 10014, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Tinga Lodge,Kruger Private Concession,Kruger,South Africa",
        "Lat": 40.7273942,
        "Long": -74.0058612,
        "Descr": "160 Varick St Suite 030-102, New York, NY 10014, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pafuri Luxury Tented Camp,Kruger Private Concession,Kruger,South Africa",
        "Lat": -22.4205608,
        "Long": 31.2295341,
        "Descr": "Makuleke Contract Park Northern Kruger National Park, Pafuri, 0973, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Lebombo,Kruger Private Concession,Kruger,South Africa",
        "Lat": 37.7904356,
        "Long": -122.4012663,
        "Descr": "1 Sansome St #3500, San Francisco, CA 94104, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Sweni,Kruger Private Concession,Kruger,South Africa",
        "Lat": 37.7904356,
        "Long": -122.4012663,
        "Descr": "1 Sansome St #3500, San Francisco, CA 94104, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Outpost,Kruger Private Concession,Kruger,South Africa",
        "Lat": -22.44029,
        "Long": 31.08357,
        "Descr": "The Outpost Lodge, in Kruger National Park, Pafuri/Makuleke Region Kruger National Park, 2021, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manyeleti Private Game Reserve,Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Honeyguide Khoka Moya Camp,Manyeleti Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5385477,
        "Long": 31.4005605,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Honeyguide Mantobeni Camp,Manyeleti Private Game Reserve,Kruger,South Africa",
        "Lat": -24.6479532,
        "Long": 31.5262986,
        "Descr": "Honeyguide Mantobeni Camp, Manyeleti Game Reserve, Orpen Gate, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Manor House,Manyeleti Private Game Reserve,Kruger,South Africa",
        "Lat": -23.988384,
        "Long": 31.55474,
        "Descr": "Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Safari Lodge,Manyeleti Private Game Reserve,Kruger,South Africa",
        "Lat": -24.58871,
        "Long": 31.393812,
        "Descr": "1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Arathusa Safari Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7370625,
        "Long": 31.5091875,
        "Descr": "7G75+5M Sabi Sand Game Reserve, Utlha, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chitwa Chitwa,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.733491,
        "Long": 31.558849,
        "Descr": "Chitwa Chitwa Game Lodge, Gowrie Main, Sabi Sands Game Reserve, 1200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini Leadwood Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.770238,
        "Long": 31.394806,
        "Descr": "Sabi Sand Game Reserve, Kruger National Park, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7833911,
        "Long": 31.3806894,
        "Descr": "Sabi Sands Game Reserve, Kruger Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dulini River Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.770342,
        "Long": 31.398953,
        "Descr": "Sabi Sand Game Reserve, Kruger National Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Elephant Plains,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7434619,
        "Long": 31.478254,
        "Descr": "Sabi Sands Game Reserve, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kirkman's Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.934691,
        "Long": 31.559564,
        "Descr": "Sabi Sand Private Game Reserve, Sabie Park, Sabi Sand, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leopard Hills,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7925315,
        "Long": 31.3680768,
        "Descr": "Sabi Sand Game Reserve, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Fish Eagle Villa (Ivory Lodge),Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9400719,
        "Long": 31.5257381,
        "Descr": "Sabi Sand Reserve, Sabie Park, Kruger National Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Hi'Nkweni Villa (Tinga Lodge),Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9400719,
        "Long": 31.5257381,
        "Descr": "Sabi Sand Reserve, Sabie Park, Kruger National Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands Ivory Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9400719,
        "Long": 31.5257381,
        "Descr": "Sabi Sand Reserve, Sabie Park, Kruger National Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Sands River Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9400719,
        "Long": 31.5257381,
        "Descr": "Sabi Sand Reserve, Sabie Park, Kruger National Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Founders Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Pioneer Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Private Granite Suites,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.797097,
        "Long": 31.499004,
        "Descr": "Londolozi Game Reserve, Londolozi, Sabi Sands Game Reserve, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Tree Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Londolozi Varty Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7965139,
        "Long": 31.5008966,
        "Descr": "Londolozi Game Reserve, Londolozi Game Reserve Sparta Farms, Skukuza, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8003764,
        "Long": 31.5408156,
        "Descr": "Mala Mala Game Reserve, Ehlanzeni, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Rattray's Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8003764,
        "Long": 31.5408156,
        "Descr": "Mala Mala Game Reserve, Ehlanzeni, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mala Mala Sable Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8003764,
        "Long": 31.5408156,
        "Descr": "Mala Mala Game Reserve, Ehlanzeni, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nottens Bush Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8944072,
        "Long": 31.5380477,
        "Descr": "Sabi Sand Reserve, Hazyview, Sabie Park, Mpumalanga, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Bush Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Earth Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9456062,
        "Long": 31.4701633,
        "Descr": "Sabi Sabi Private Game Reserve Sabie Game Reserve Greater, Sabie Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Little Bush Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9036283,
        "Long": 31.479694,
        "Descr": "Sabie Park, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sabi Sabi Selati Camp,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8974281,
        "Long": 31.5095516,
        "Descr": "Sabi Sabi Private Game Reserve Sabi Sand Wildtuin, Sabie Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Savanna Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.859425,
        "Long": 31.402015,
        "Descr": "Savanna Private Game Reserve, Sabi Sand Game Reserve, R536, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Silvan Safari Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7534421,
        "Long": 31.4783039,
        "Descr": "Sabi Sand Game Reserve, Kruger, 1200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbambili Game Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7272657,
        "Long": 31.4765256,
        "Descr": "South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Boulders Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.786237,
        "Long": 31.429,
        "Descr": "Sabi Sands Game Reserve, Kruger National Park, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Castleton,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.831821,
        "Long": 31.443259,
        "Descr": "Sabi Sand Game Reserve, Kruger National Park, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Singita Ebony Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.7865144,
        "Long": 31.4258293,
        "Descr": "Sabi Sands Game Reserve, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tengile River Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.9325265,
        "Long": 31.5559094,
        "Descr": "Sabi Sand Private Game Reserve Sabi Sand, Sabie Park, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Cliff Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.927939,
        "Long": 31.52491,
        "Descr": "Sabi Sands Game Reserve, Portion 4, Shaws Gate, shaws gate, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Rock Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ulusaba Safari Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -23.9883848,
        "Long": 31.5547402,
        "Descr": "Kruger National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Umkumbe Safari Lodge,Sabi Sand Private Game Reserve,Kruger,South Africa",
        "Lat": -24.8719535,
        "Long": 31.5452027,
        "Descr": "4, Sabi Sand Game Reserve, Shaws Gate, Sabie Park, Kruger, 1350, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chapungu Tented Camp - Closing Temporarily,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4168518,
        "Long": 31.1541836,
        "Descr": "ThornyBush Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jackalberry Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5062061,
        "Long": 31.1147099,
        "Descr": "The Thornybush Game Reserve, Guernsey Rd, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kubili House,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4701298,
        "Long": 31.1177162,
        "Descr": "Thornybush Private Game Reserve, Greater Kruger National Park, Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Monwana Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.447527,
        "Long": 31.169226,
        "Descr": "Thornybush Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "N'Kaya Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.467775,
        "Long": 31.159973,
        "Descr": "Thornybush Game Reserve, Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Malewane,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5178222,
        "Long": 31.1642341,
        "Descr": "Avoca Road, Thornybush Private Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Saseka Tented Camp,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Serondella Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4533237,
        "Long": 31.1972123,
        "Descr": "Guernsey Rd, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shumbalala Game Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4239298,
        "Long": 31.2318112,
        "Descr": "Shumbalala Game Lodge, Thornybush Nature Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush Game Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush The River Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thornybush Waterside Lodge - Closing Temporarily,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409947,
        "Long": 31.1573937,
        "Descr": "The River Lodge, Thornybush Private Nature Reseve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Waterbuck Lodge,Thornybush Private Game Reserve,Kruger,South Africa",
        "Lat": -24.5409989,
        "Long": 31.103568,
        "Descr": "Thornybush Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.407347,
        "Long": 31.314404,
        "Descr": "Timbavati Game Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kings Camp,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.276071,
        "Long": 31.307427,
        "Descr": "Timbavati Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Makanyi Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.471096,
        "Long": 31.255318,
        "Descr": "Makanyi Private Game Lodge | Luxury Safari South Africa, Kruger Park, Middle Road, Timbavati Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Motswari Safari Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.1837959,
        "Long": 31.3887214,
        "Descr": "Motswari Private Game Reserve Timbavati Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngala Safari Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.381338,
        "Long": 31.321872,
        "Descr": "andBeyond Ngala Private Game Reserve, Timbavati, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ngala Tented Camp,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.4799709,
        "Long": 31.3793106,
        "Descr": "andBeyond Ngala Private Game Reserve, Timbavati, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rock Fig Safari Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.3067454,
        "Long": 31.2910515,
        "Descr": "Timbavati Private Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati Hilltop Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.2246851,
        "Long": 31.356172,
        "Descr": "Simbavati Hilltop Lodge, Timbavati Game Reserve, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Simbavati River Lodge,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.2155653,
        "Long": 31.353416,
        "Descr": "Simbavati River Lodge, 4, Timbavati Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Field Camp,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.3052416,
        "Long": 31.3232445,
        "Descr": "Tanda Tula, Greater Kruger National Park, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanda Tula Safari Camp,Timbavati Private Game Reserve,Kruger,South Africa",
        "Lat": -24.35,
        "Long": 30.966667,
        "Descr": "Timbavati Private Nature Reserve, Hoedspruit, 1380, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe Game Reserve,South Africa",
        "Lat": -24.7604347,
        "Long": 26.2777073,
        "Descr": "Madikwe Game Reserve, 2874 R49, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jaci's Tree Lodge,Madikwe Game Reserve,South Africa",
        "Lat": -24.7604347,
        "Long": 26.2777073,
        "Descr": "Madikwe Game Reserve, 2874 R49, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Jamala Madikwe Royal Safari Lodge,Madikwe Game Reserve,South Africa",
        "Lat": -24.8042109,
        "Long": 26.4049743,
        "Descr": "Jamala Madikwe, Madikwe Game Reserve, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe Hills Game Lodge,Madikwe Game Reserve,South Africa",
        "Lat": -24.724721,
        "Long": 26.347052,
        "Descr": "Madikwe Hills Private Game Lodge Madikwe Game Reserve, 2865, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Madikwe Safari Lodge,Madikwe Game Reserve,South Africa",
        "Lat": -24.7231893,
        "Long": 26.3877945,
        "Descr": "Madikwe Game Reserve, Madikwe, 0116, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Farm House,Madikwe Game Reserve,South Africa",
        "Lat": -24.7604347,
        "Long": 26.2777073,
        "Descr": "Madikwe Game Reserve, 2874 R49, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Owner's House,Madikwe Game Reserve,South Africa",
        "Lat": -24.7604347,
        "Long": 26.2777073,
        "Descr": "Madikwe Game Reserve, 2874 R49, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru River House,Madikwe Game Reserve,South Africa",
        "Lat": -24.7852538,
        "Long": 26.4402503,
        "Descr": "Thabazimbi, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Madikwe,Madikwe Game Reserve,South Africa",
        "Lat": -24.7775898,
        "Long": 26.1854077,
        "Descr": "The Royal Madikwe Safari Lodge, Wonderboom gate Madikwe Game Reserve Madikwe, 2874, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tuningi Safari Lodge,Madikwe Game Reserve,South Africa",
        "Lat": -24.7744814,
        "Long": 26.2103132,
        "Descr": "Madikwe Game Reserve, Madikwe, 2840, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Closed to Oct 2022,Manna Bay,Cape Town,South African Cities & Surrounds,South Africa",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Durban (1),South African Cities & Surrounds,South Africa",
        "Lat": -29.8586804,
        "Long": 31.0218404,
        "Descr": "Durban, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Canelands Beach Club & Spa,Durban (1),South African Cities & Surrounds,South Africa",
        "Lat": -29.5025691,
        "Long": 31.2389144,
        "Descr": "Salt Rock, Dolphin Coast, 4391, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Teremok Marine Lodge,Durban (1),South African Cities & Surrounds,South Africa",
        "Lat": -29.735195,
        "Long": 31.0807851,
        "Descr": "49 Marine Dr, Umhlanga Rocks, Durban, 4320, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oyster Box,Durban (1),South African Cities & Surrounds,South Africa",
        "Lat": -29.7284034,
        "Long": 31.0872415,
        "Descr": "2 Lighthouse Rd, Umhlanga, 4319, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbali Resort,Durban (1),South African Cities & Surrounds,South Africa",
        "Lat": -29.8586804,
        "Long": 31.0218404,
        "Descr": "Durban, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -24.876541,
        "Long": 30.8887795,
        "Descr": "R543, Ehlanzeni, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Casterbridge Hollow Boutique Hotel,Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -25.306553,
        "Long": 31.0333826,
        "Descr": "Casterbridge Lifestyle Centre, R40, White River, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oliver's Lodge,Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.8785182,
        "Long": 18.5758587,
        "Descr": "Panorama, Cape Town, 7500, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Perry's Bridge Hollow,Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -25.0364894,
        "Long": 31.1243171,
        "Descr": "R40, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanamera Lodge,Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -25.0473903,
        "Long": 30.9585527,
        "Descr": "Sabie Road, Hazyview, Kiepersol, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Walkerson's Hotel & Spa,Panorama Route,South African Cities & Surrounds,South Africa",
        "Lat": -25.3699455,
        "Long": 30.1869584,
        "Descr": "10km outside of Dullstroom towards Lydenburg, Dullstroom, 1110, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ocean Eleven Guest House,Whale Route,South African Cities & Surrounds,South Africa",
        "Lat": -34.4236111,
        "Long": 19.2344444,
        "Descr": "11 Westcliff Rd, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek Country House,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage at Grande Provence,Franschhoek,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.8987481,
        "Long": 19.1036181,
        "Descr": "Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Estate,Stellenbosch,Wine Route,South African Cities & Surrounds,South Africa",
        "Lat": -33.9221089,
        "Long": 18.9237638,
        "Descr": "Helshoogte Rd, Stellenbosch, 7560, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Manyara,Tanzania",
        "Lat": -3.6190593,
        "Long": 35.8061779,
        "Descr": "Lake Manyara, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Esacarpment Luxury Lodge,Lake Manyara,Tanzania",
        "Lat": -3.4014466,
        "Long": 35.8049495,
        "Descr": "Lake Manyara National Park, Lake Manyara, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Manyara Tree Lodge,Lake Manyara,Tanzania",
        "Lat": -3.668764,
        "Long": 35.746448,
        "Descr": "Lake Manyara National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Tanganyika,Tanzania",
        "Lat": -6.2555679,
        "Long": 29.5107708,
        "Descr": "Lake Tanganyika",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gombe Lodge,Lake Tanganyika,Tanzania",
        "Lat": -4.8824046,
        "Long": 29.6342101,
        "Descr": "Lumumba Street, Mwanga Road, Kigoma, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Greystroke Mahale,Lake Tanganyika,Tanzania",
        "Lat": -6.138543,
        "Long": 29.7329802,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lupita Island,Lake Tanganyika,Tanzania",
        "Lat": -7.4562155,
        "Long": 30.5599959,
        "Descr": "Lupita Island, Lake Tanganyika, Kipili, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mahale Lodge,Lake Tanganyika,Tanzania",
        "Lat": -6.129117,
        "Long": 29.7318569,
        "Descr": "Mbali Mbali Mahale Lodge, Mahale National Park, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rubondo Island National Park,Tanzania",
        "Lat": -2.301424,
        "Long": 31.860767,
        "Descr": "Rubondo Island Camp, Rubondo Island National Park, Rubondo Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Rubondo Island Camp,Rubondo Island National Park,Tanzania",
        "Lat": -2.301424,
        "Long": 31.860767,
        "Descr": "Rubondo Island Camp, Rubondo Island National Park, Rubondo Island, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kafue National Park,Zambia",
        "Lat": -14.5,
        "Long": 26.1666667,
        "Descr": "Kafue National Park, D769, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Busanga Bush Camp,Kafue National Park,Zambia",
        "Lat": -14.2052545,
        "Long": 25.8037109,
        "Descr": "Kafue, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Shumba Camp,Kafue National Park,Zambia",
        "Lat": -14.219251,
        "Long": 25.8729744,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lower Zambezi National Park,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Baines' River Camp,Lower Zambezi National Park,Zambia",
        "Lat": -15.762731,
        "Long": 29.231741,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chiawa Camp,Lower Zambezi National Park,Zambia",
        "Lat": -15.6835762,
        "Long": 29.4135429,
        "Descr": "Lower Zambezi National Park, Chiawa, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Old Mondoro,Lower Zambezi National Park,Zambia",
        "Lat": -15.6296961,
        "Long": 29.6916473,
        "Descr": "Lower Zambezi National Park, Rufunsa, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Zambezi Lodge,Lower Zambezi National Park,Zambia",
        "Lat": -15.727642,
        "Long": 29.314983,
        "Descr": "Royal Zambezi Lodge, Lower Zambezi River <br />Zambia, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sausage Tree Camp,Lower Zambezi National Park,Zambia",
        "Lat": -15.312277,
        "Long": 29.603549,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time & Tide Chongwe Suites,Lower Zambezi National Park,Zambia",
        "Lat": -15.3122767,
        "Long": 29.6035495,
        "Descr": "Lower Zambezi National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "North Luangwa National Park,Zambia",
        "Lat": -11.9114239,
        "Long": 32.2653887,
        "Descr": "North Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mwaleshi Camp,North Luangwa National Park,Zambia",
        "Lat": -11.990607,
        "Long": 32.317163,
        "Descr": "North Luangwa National Park, Eastern Province, Zambia, North Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Takwela Camp,North Luangwa National Park,Zambia",
        "Lat": -12.0403312,
        "Long": 32.354631,
        "Descr": "Mwaleshi, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bilimungwe Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chamilandu Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikoko Tree Camp,South Luangwa National Park,Zambia",
        "Lat": 42.365002,
        "Long": -71.058003,
        "Descr": "77 N Washington St, Boston, MA 02114, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikunto Safari Lodge,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chindeni Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -13.367288,
        "Long": 31.606818,
        "Descr": "South Luangwa Park, Block 01, South Luangwa Park 51000, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Flatdogs Camp,South Luangwa National Park,Zambia",
        "Lat": -13.1023982,
        "Long": 31.7785433,
        "Descr": "Plot 8755, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Island Bush Camp,South Luangwa National Park,Zambia",
        "Lat": -13.4324436,
        "Long": 31.4742221,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kafunta River Lodge,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kaingo Camp,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kalamu Lagoon Camp,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kapamba Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -13.3827058,
        "Long": 31.5260524,
        "Descr": "Mpika, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kuyenda Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -13.1897,
        "Long": 31.675825,
        "Descr": "South Luangwa National Park, Block KB24, Zambia, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lion Camp,South Luangwa National Park,Zambia",
        "Lat": -12.8846195,
        "Long": 31.9670563,
        "Descr": "Lion Camp, South Luangwa National Park, Luangwa National Park, South, Lundazi, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Luangwa River Camp,South Luangwa National Park,Zambia",
        "Lat": -13.074287,
        "Long": 31.8106597,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mfuwe Lodge,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nkwali Camp,South Luangwa National Park,Zambia",
        "Lat": -13.1162657,
        "Long": 31.7392957,
        "Descr": "Nkwali Camp, Mpika, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nsefu Camp,South Luangwa National Park,Zambia",
        "Lat": -12.9371568,
        "Long": 31.9034699,
        "Descr": "Nsefu Sector Mfuwe ZM Mfuwe ZM, 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Puku Ridge Camp,South Luangwa National Park,Zambia",
        "Lat": -13.1612663,
        "Long": 31.7152779,
        "Descr": "South Luangwa National Park, 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Robin's House,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Chichele Presidential Lodge,South Luangwa National Park,Zambia",
        "Lat": -13.4543605,
        "Long": 31.3298836,
        "Descr": "South Luangwa National Park، Chilongozi 50100, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tafika Camp,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tena Tena,South Luangwa National Park,Zambia",
        "Lat": -12.9971281,
        "Long": 31.9055727,
        "Descr": "Nsefu, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Chinzombo,South Luangwa National Park,Zambia",
        "Lat": -13.1038261,
        "Long": 31.7620069,
        "Descr": "TIME + TIDE CHINZOMBO, Luangwa River South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Kakuli,South Luangwa National Park,Zambia",
        "Lat": -12.9962835,
        "Long": 31.89581,
        "Descr": "Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Luwi,South Luangwa National Park,Zambia",
        "Lat": -12.8993716,
        "Long": 31.7516685,
        "Descr": "TIME + TIDE LUWI CAMP, South, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Mchenja,South Luangwa National Park,Zambia",
        "Lat": -12.9409803,
        "Long": 31.904538,
        "Descr": "TIME + TIDE MCHENJA CAMP, South Luangwa National Park, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Time + Tide Nsolo,South Luangwa National Park,Zambia",
        "Lat": -12.9403535,
        "Long": 31.804567,
        "Descr": "South Luangwa National Park, Luwi River, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zungulila Bushcamp,South Luangwa National Park,Zambia",
        "Lat": -13.351853,
        "Long": 31.514774,
        "Descr": "South Luangwa National Park, Zambia, Mfuwe, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hwange National Park,Zimbabwe",
        "Lat": -19.1240539,
        "Long": 26.5925913,
        "Descr": "Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Davison's Camp,Hwange National Park,Zimbabwe",
        "Lat": -19.0136118,
        "Long": 27.3099999,
        "Descr": "Davison's Camp, Hwange, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Makalolo,Hwange National Park,Zimbabwe",
        "Lat": -19.0677965,
        "Long": 27.250514,
        "Descr": "Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Somalisa Acacia,Hwange National Park,Zimbabwe",
        "Lat": -18.910558,
        "Long": 27.121666,
        "Descr": "Along the Kennedy Vlei Line, Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Somalisa Camp,Hwange National Park,Zimbabwe",
        "Lat": -19.0448106,
        "Long": 27.0499452,
        "Descr": "Somalisa Concession Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Hide Safari Camp,Hwange National Park,Zimbabwe",
        "Lat": -19.1240539,
        "Long": 26.5925913,
        "Descr": "Hwange National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mana Pools National Park,Zimbabwe",
        "Lat": -15.937159,
        "Long": 29.456993,
        "Descr": "Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Chikwenya Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.937159,
        "Long": 29.456993,
        "Descr": "Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "John's Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.937159,
        "Long": 29.456993,
        "Descr": "Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanga Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.908764,
        "Long": 29.263107,
        "Descr": "Kanga Pan, Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Little Ruckomechi,Mana Pools National Park,Zimbabwe",
        "Lat": -15.8500641,
        "Long": 29.1249441,
        "Descr": "Mana Pools National Park,, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nyamatusi Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.6993726,
        "Long": 29.4263824,
        "Descr": "Nyamatusi Camp, Nyamatusi Wilderness Concession Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ruckomechi Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.8500641,
        "Long": 29.1249441,
        "Descr": "Mana Pools National Park,, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sapi Explorers Camp,Mana Pools National Park,Zimbabwe",
        "Lat": -15.8370505,
        "Long": 29.6439563,
        "Descr": "Sapi river, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zambezi Expeditions,Mana Pools National Park,Zimbabwe",
        "Lat": -15.937159,
        "Long": 29.456993,
        "Descr": "Mana Pools National Park, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matusadona National Park & Lake Kariba,Zimbabwe",
        "Lat": -16.746124,
        "Long": 28.670422,
        "Descr": "Matusadona National Park, Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bumi Hills Safari Lodge,Matusadona National Park & Lake Kariba,Zimbabwe",
        "Lat": -16.8091758,
        "Long": 28.3496046,
        "Descr": "Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Changa Safari Camp,Matusadona National Park & Lake Kariba,Zimbabwe",
        "Lat": -16.746124,
        "Long": 28.670422,
        "Descr": "Matusadona National Park, Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Musango Safari Camp,Matusadona National Park & Lake Kariba,Zimbabwe",
        "Lat": -16.8369178,
        "Long": 28.3897561,
        "Descr": "Musango Safari Camp, Island 134 and 135 Lake Kariba, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hamerkop House by Lemala,Arusha",
        "Lat": -3.3950495,
        "Long": 36.7359786,
        "Descr": "Excel E Rd, Arusha, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kili Villa,Arusha",
        "Lat": -3.4244124,
        "Long": 36.8780421,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machweo Wellness Retreat,Arusha",
        "Lat": -3.3807342,
        "Long": 36.7450619,
        "Descr": "Onsea Moivaro Road / Baraa Road Arusha TZ, 1259, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Entebbe & Kampala",
        "Lat": 0.0435913,
        "Long": 32.4417945,
        "Descr": "Entebbe International Airport, 14 Kitaasa Rd, Entebbe, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lake Victoria Serena Resort,Entebbe & Kampala",
        "Lat": 0.2075834,
        "Long": 32.5829484,
        "Descr": "Kigo Rd, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Latitude 0 Degrees,Entebbe & Kampala",
        "Lat": 0.2752666,
        "Long": 32.591189,
        "Descr": "64 & 66 Kyodondo, Mobutu Road, Kampala, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Boma Entebbe,Entebbe & Kampala",
        "Lat": 0.05329,
        "Long": 32.465018,
        "Descr": "Plot 20A Gowers Road, Entebbe, Uganda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Christiana Lodge,Garden Route",
        "Lat": -34.088019,
        "Long": 23.3686895,
        "Descr": "Aquarius Cl, SolarBeach, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emily Moon River Lodge,Garden Route",
        "Lat": -34.01012,
        "Long": 23.3729843,
        "Descr": "1 Rietvlei Rd, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hog Hollow Country Lodge,Garden Route",
        "Lat": -33.9731395,
        "Long": 23.4736603,
        "Descr": "Askop Road, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hunters Country House,Garden Route",
        "Lat": -34.052641,
        "Long": 23.275351,
        "Descr": "Off N2, 10km west of Plettenberg Bay, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanonkop House,Garden Route",
        "Lat": -34.0362538,
        "Long": 23.0220235,
        "Descr": "6 Cuthbert St, Kanonkop, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna Hollow Country Estate,Garden Route",
        "Lat": -34.0355873,
        "Long": 23.017208,
        "Descr": "5 Welbedacht Ln, Kanonkop, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurland,Garden Route",
        "Lat": -33.9449115,
        "Long": 23.4924921,
        "Descr": "Kurland, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leisure Isle Lodge,Garden Route",
        "Lat": -34.0660858,
        "Long": 23.0559406,
        "Descr": "87 Bayswater Rd, Leisure Isle, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "No 5 Boutique Art Hotel,Garden Route",
        "Lat": -33.9847202,
        "Long": 25.6619275,
        "Descr": "5 Brighton Dr, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pezula Resort Hotel & Spa,Garden Route",
        "Lat": -34.0674425,
        "Long": 23.0999206,
        "Descr": "Sparrebosch, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Port Elizabeth,Garden Route",
        "Lat": -33.987245,
        "Long": 25.6711184,
        "Descr": "Marine Dr & 9th Ave Summerstrand Nelson Mandela Bay, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tamodi Lodge & Stables,Garden Route",
        "Lat": -33.986913,
        "Long": 23.45518,
        "Descr": "Pinehaven rd, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Plettenberg,Garden Route",
        "Lat": -34.0574892,
        "Long": 23.3644925,
        "Descr": "Plettenberg Bay, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Rex Hotel,Garden Route",
        "Lat": -34.0391937,
        "Long": 23.045213,
        "Descr": "8 Gray St, Knysna Central, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Turbine Boutique Hotel,Garden Route",
        "Lat": -34.048774,
        "Long": 23.049058,
        "Descr": "36 Sawtooth Lane, Thesen Island, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Windermere,Garden Route",
        "Lat": 45.1011657,
        "Long": -79.5284194,
        "Descr": "2932 Hwy 118w, Port Caling, ON P0B 1J0, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tsala Treetop Lodge,Garden Route",
        "Lat": -34.0521202,
        "Long": 23.2705612,
        "Descr": "Off N2 10km W Of Plettenberg, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Marriott,Kigali",
        "Lat": -1.9537135,
        "Long": 30.0621258,
        "Descr": "KN 3 Avenue, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kigali Serena Hotel,Kigali",
        "Lat": -1.9563339,
        "Long": 30.0627322,
        "Descr": "KN 3 Ave, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Retreat by Heaven,Kigali",
        "Lat": -1.9472729,
        "Long": 30.0651165,
        "Descr": "No 5, KN 29 St, Kigali, Rwanda",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Royal Chundu,Livingstone",
        "Lat": -17.8470867,
        "Long": 25.392696,
        "Descr": "Plot 9003, Nakatindi Road, Katambora, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sanctuary Sussi & Chuma,Livingstone",
        "Lat": -17.840845,
        "Long": 25.771746,
        "Descr": "Mosi-oa-Tunya National Park, Livingstone 00000, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Sindabezi Island Lodge,Livingstone",
        "Lat": -17.835671,
        "Long": 25.7077228,
        "Descr": "Nakatindi Road, Livingstone 10101, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taita Falcon Lodge,Livingstone",
        "Lat": -17.9690889,
        "Long": 25.9178056,
        "Descr": "11km from the Victoria Falls, Livingstone 60012, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The David Livingstone Lodge,Livingstone",
        "Lat": -17.8883056,
        "Long": 25.8442707,
        "Descr": "Riverside Dr, Off Sichango Rd, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Royal Livingstone,Livingstone",
        "Lat": 44.4178919,
        "Long": -79.6956582,
        "Descr": "181 Livingstone St E Unit 6, Barrie, ON L4M 6Z4, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Thorntree River Lodge,Livingstone",
        "Lat": -17.8430953,
        "Long": 25.7549238,
        "Descr": "Mosi-oa-Tunya National Park, Mosi-Oa-Tunya, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Toka Leya,Livingstone",
        "Lat": -17.8555135,
        "Long": 25.7873394,
        "Descr": "Mosi-oa-Tunya National Park, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tongabezi Lodge,Livingstone",
        "Lat": -17.8199844,
        "Long": 25.7047634,
        "Descr": "Livingstone Way, Livingstone, Zambia",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Batonka Lodge,Victoria Falls",
        "Lat": -17.929448,
        "Long": 25.828053,
        "Descr": "Reynard Road, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ilala Lodge,Victoria Falls",
        "Lat": -17.926191,
        "Long": 25.841572,
        "Descr": "A8, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matetsi Victoria Falls,Victoria Falls",
        "Lat": -17.8644008,
        "Long": 25.5003986,
        "Descr": "Matetsi Private Game Reserve, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Stanley & Livingstone Boutique Hotel,Victoria Falls",
        "Lat": -17.9995711,
        "Long": 25.8100543,
        "Descr": "Old Ursula’s Road, Stanley & Livingstone Private Game Reserve, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Elephant Camp,Victoria Falls",
        "Lat": -17.9743439,
        "Long": 25.8456366,
        "Descr": "The Elephant Camp, Victoria Falls National Park, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Victoria Falls Hotel,Victoria Falls",
        "Lat": -17.9308041,
        "Long": 25.8423322,
        "Descr": "1 Mallet Dr, Victoria Falls, Zimbabwe",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Babylonstoren,Wine Route",
        "Lat": -33.8237104,
        "Long": 18.9267014,
        "Descr": "Klapmuts - Simondium Rd, Simondium, Paarl, 7670, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bonne Esperance,Wine Route",
        "Lat": -33.9369809,
        "Long": 18.8664217,
        "Descr": "Van Riebeeck St &, Neethling St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Estate,Wine Route",
        "Lat": -33.9221089,
        "Long": 18.9237638,
        "Descr": "Helshoogte Rd, Stellenbosch, 7560, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delaire Graff Estate,Wine Route",
        "Lat": -33.9202951,
        "Long": 18.9218332,
        "Descr": "Helshoogte Rd, Stellenbosch, 7602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek Country House,Wine Route",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grand Dedale Country House,Wine Route",
        "Lat": -33.6269933,
        "Long": 19.0830857,
        "Descr": "Bovlei Rd, Wellington, 7654, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grande Roche,Wine Route",
        "Lat": 39.0210434,
        "Long": -74.8728253,
        "Descr": "3159 Rte 9 S, Rio Grande, NJ 08242, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Cle des Montagnes,Wine Route",
        "Lat": 45.253152,
        "Long": -72.735317,
        "Descr": "1095 Chemin N, Brigham, QC J2K 4R6, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Residence,Wine Route",
        "Lat": -33.898411,
        "Long": 19.1222313,
        "Descr": "Elandskloof Road, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lanzerac Hotel & Spa,Wine Route",
        "Lat": -33.938299,
        "Long": 18.894264,
        "Descr": "1 Lanzerac Rd, Jonkershoek, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Franschhoek Hotel & Spa,Wine Route",
        "Lat": -33.935809,
        "Long": 19.110409,
        "Descr": "16 Minor Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Quartier Francais,Wine Route",
        "Lat": 38.6296488,
        "Long": -122.8769989,
        "Descr": "1437 Grove St, Healdsburg, CA 95448, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leeu House,Wine Route",
        "Lat": -33.913255,
        "Long": 19.121227,
        "Descr": "12 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Light House Boutique Suites,Wine Route",
        "Lat": -33.77236,
        "Long": 18.954887,
        "Descr": "2 Lille, Courtrai, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majeka House,Wine Route",
        "Lat": -33.9680216,
        "Long": 18.860642,
        "Descr": "26 Houtkapper St, Paradyskloof, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mont Rochelle,Wine Route",
        "Lat": -33.912819,
        "Long": 19.1110279,
        "Descr": "1499 Dassenberg Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oude Werf Hotel,Wine Route",
        "Lat": -33.9378177,
        "Long": 18.8619977,
        "Descr": "30 Church St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Franschhoek,Wine Route",
        "Lat": -33.9083276,
        "Long": 19.1170728,
        "Descr": "68 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage at Grande Provence,Wine Route",
        "Lat": -33.8987481,
        "Long": 19.1036181,
        "Descr": "Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spier Hotel,Wine Route",
        "Lat": -33.9735508,
        "Long": 18.7821798,
        "Descr": "R310 Baden Powell Dr, Stellenbosch, 7603, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Bazaruto Island Resort & Spa,Mozambique",
        "Lat": -21.7048015,
        "Long": 35.4470672,
        "Descr": "Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Medjumbe Island Resort & Spa,Mozambique",
        "Lat": -11.815883,
        "Long": 40.603806,
        "Descr": "Medjumbe Island, Quirimbas Archipelago, Cabo Delgado Province, Medjumbe 2116, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anvil Bay,Mozambique",
        "Lat": -26.3494905,
        "Long": 32.9311351,
        "Descr": "Ponta Chemucane, Machavene 1006, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Benguerra Island,Mozambique",
        "Lat": -21.853401,
        "Long": 35.425307,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Marlin Beach,Mozambique",
        "Lat": -21.853401,
        "Long": 35.425307,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Azura Quilalea Private Island,Mozambique",
        "Lat": -12.491051,
        "Long": 40.600105,
        "Descr": "Quilalea Island Quirimbas Archipelago Cabo Delgado, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Benguerra Island Lodge,Mozambique",
        "Lat": -21.86317,
        "Long": 35.41823,
        "Descr": "Benguerra Island, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Coral Lodge,Mozambique",
        "Lat": -15.0067892,
        "Long": 40.770626,
        "Descr": "Varanda 9, Mossuril, Nampula Varanda, Mossuril, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Diamonds Mequfi Beach Resort,Mozambique",
        "Lat": -13.24512,
        "Long": 40.5559,
        "Descr": "Mecufi Road, Mecufi 3200, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ibo Island Lodge,Mozambique",
        "Lat": -12.3420704,
        "Long": 40.5828972,
        "Descr": "Rua Bela Vista, Ibo island, Quirimbas Archipelago, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Machangulo Beach Lodge,Mozambique",
        "Lat": -26.0837974,
        "Long": 32.9590911,
        "Descr": "Machangulo Peninsula, Inhaca, Prov, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Massinga Beach Lodge,Mozambique",
        "Lat": -23.3503135,
        "Long": 35.4760551,
        "Descr": "Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Santorini Mozambique,Mozambique",
        "Lat": -21.928077,
        "Long": 35.3162073,
        "Descr": "19 cle Outboro, Vilanculos, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "White Pearl Resort,Mozambique",
        "Lat": -26.7086086,
        "Long": 32.9009284,
        "Descr": "Ponta Mamoli, Zitundo, Mozambique",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Anantara Maia Luxury Resort & Spa,Seychelles",
        "Lat": -4.7168018,
        "Long": 55.4793392,
        "Descr": "West Coast Road, Anse Louis, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Banyan Tree Seychelles,Seychelles",
        "Lat": -4.7819306,
        "Long": 55.4974378,
        "Descr": "Anse Intendance Takamaka Mahe Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Ephelia Resort,Seychelles",
        "Lat": -4.658432,
        "Long": 55.4053115,
        "Descr": "Port Launay Rd, Mahé, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Constance Lemuria Resort,Seychelles",
        "Lat": -4.299269,
        "Long": 55.681463,
        "Descr": "Anse Kerlan Praslin Seychelles, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cousine Island,Seychelles",
        "Lat": -4.350728,
        "Long": 55.6474522,
        "Descr": "Cousine, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Denis Private Island Lodge,Seychelles",
        "Lat": -3.801795,
        "Long": 55.662857,
        "Descr": "Denis Private Island Denis Island, Denis, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Enchanted Island Resort,Seychelles",
        "Lat": -4.6218855,
        "Long": 55.5034253,
        "Descr": "Enchanted Island Resort, Round Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Desroches Island,Seychelles",
        "Lat": -5.6972788,
        "Long": 53.6511778,
        "Descr": "Unnamed Road, Desroches Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Resort Seychelles,Seychelles",
        "Lat": -4.7542939,
        "Long": 55.4660072,
        "Descr": "Petite Anse, Mahe Island, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Seychelles Labriz Resort & Spa,Seychelles",
        "Lat": -4.4812865,
        "Long": 55.2483398,
        "Descr": "La Passe, Silhouette, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hilton Seychelles Northolme Resort & Spa,Seychelles",
        "Lat": -4.5922623,
        "Long": 55.4317498,
        "Descr": "N Coast Rd, Mahé, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Domaine de L'Orangeraie,Seychelles",
        "Lat": -4.3451166,
        "Long": 55.8302001,
        "Descr": "La Passe, La Digue, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Domaine de la Reserve,Seychelles",
        "Lat": -4.3096638,
        "Long": 55.7356667,
        "Descr": "-4.310214, 55.736266, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "North Island,Seychelles",
        "Lat": -4.3949648,
        "Long": 55.2452567,
        "Descr": "Ile du Nord, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Raffles Seychelles,Seychelles",
        "Lat": -4.3084819,
        "Long": 55.7183626,
        "Descr": "Anse Takamaka Baie Saint Anne Praslin PRASLIN, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Six Senses Zil Pasyon,Seychelles",
        "Lat": -4.3190832,
        "Long": 55.8670463,
        "Descr": "Felicite, Seychelles",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Baraza Resort & Spa,Zanzibar",
        "Lat": -6.1915383,
        "Long": 39.5351677,
        "Descr": "Bwejuu Beach South East Coast Paje Michamvi Road Dongwe, Zanzibar 2284, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emerson Spice Hotel,Zanzibar",
        "Lat": -6.165917,
        "Long": 39.2026368,
        "Descr": "Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fundu Lagoon,Zanzibar",
        "Lat": -5.2915108,
        "Long": 39.6585569,
        "Descr": "Wambaa, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gold Zanzibar Beach House & Spa,Zanzibar",
        "Lat": -5.7534224,
        "Long": 39.2882085,
        "Descr": "Kendwa, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kilindi Zanzibar,Zanzibar",
        "Lat": -5.7603948,
        "Long": 39.2906036,
        "Descr": "Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Matemwe Retreat,Zanzibar",
        "Lat": -5.843811,
        "Long": 39.35696,
        "Descr": "Matemwe Beach Matemwe TZ, 3275, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Melia Zanzibar,Zanzibar",
        "Lat": -5.953874,
        "Long": 39.3678619,
        "Descr": "Kiwengwa Zanzibar TZ, 00200, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mnemba Island Lodge,Zanzibar",
        "Lat": -5.820408,
        "Long": 39.383625,
        "Descr": "andBeyond Mnemba Island, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Park Hyatt Zanzibar,Zanzibar",
        "Lat": -6.162198,
        "Long": 39.1866153,
        "Descr": "Shangani Street Stone Town Zanzibar TZ, Zanzibar 4255, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Manta Resort,Zanzibar",
        "Lat": -4.882748,
        "Long": 39.679388,
        "Descr": "Psv-zanzibar estate, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Zanzibar,Zanzibar",
        "Lat": -6.4105896,
        "Long": 39.4522668,
        "Descr": "Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tui Blue Bahari Zanzibar,Zanzibar",
        "Lat": -5.9391946,
        "Long": 39.359994,
        "Descr": "Pwani Mchangani Zanzibar TZ, 0000, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tulia Zanzibar Unique Beach Resort,Zanzibar",
        "Lat": -6.0347647,
        "Long": 39.4067497,
        "Descr": "Zanzibar, Pongwe, Pongwe, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zanzibar Serena Hotel,Zanzibar",
        "Lat": -6.1633991,
        "Long": 39.1860502,
        "Descr": "Shangani St, Zanzibar, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zawadi Hotel,Zanzibar",
        "Lat": -6.1674554,
        "Long": 39.5256439,
        "Descr": "South East Coast, Michamvi, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zuri Zanzibar,Zanzibar",
        "Lat": -5.749608,
        "Long": 39.2902422,
        "Descr": "Kendwa Zanzibar, 73107, Tanzania",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Town,South Africa",
        "Lat": -33.9248685,
        "Long": 18.4240553,
        "Descr": "Cape Town, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "21 Nettleton,Cape Town,South Africa",
        "Lat": -33.9351959,
        "Long": 18.3807601,
        "Descr": "19 - 23 Nettleton Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "African Elite,Cape Town,South Africa",
        "Lat": -33.9159549,
        "Long": 18.4178357,
        "Descr": "Shop A21, Cape Quarter, De Waterkant, Dixon St, De Waterkant, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Belmond Mount Nelson Hotel,Cape Town,South Africa",
        "Lat": -33.9320559,
        "Long": 18.4116404,
        "Descr": "76 Orange St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bungalow 52,Cape Town,South Africa",
        "Lat": -33.9406711,
        "Long": 18.3759546,
        "Descr": "52 Fourth Beach Promenade, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camissa House,Cape Town,South Africa",
        "Lat": -33.94727,
        "Long": 18.41707,
        "Descr": "28 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Camps Bay Retreat,Cape Town,South Africa",
        "Lat": -33.9463,
        "Long": 18.3807,
        "Descr": "Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Cadogan,Cape Town,South Africa",
        "Lat": -33.9318158,
        "Long": 18.4082055,
        "Descr": "5 Upper Union St, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape Grace,Cape Town,South Africa",
        "Lat": -33.908713,
        "Long": 18.4205508,
        "Descr": "W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8002, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cape View Clifton,Cape Town,South Africa",
        "Lat": -33.9323918,
        "Long": 18.3780387,
        "Descr": "232 Kloof Rd, Clifton, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Cloud Nine Boutique Hotel,Cape Town,South Africa",
        "Lat": -33.929816,
        "Long": 18.4084645,
        "Descr": "12 Kloof Nek Rd, Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Compass House,Cape Town,South Africa",
        "Lat": -33.9278789,
        "Long": 18.3794975,
        "Descr": "154 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Dock House,Cape Town,South Africa",
        "Lat": -33.9058798,
        "Long": 18.418972,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ellerman House,Cape Town,South Africa",
        "Lat": -33.9290344,
        "Long": 18.3778647,
        "Descr": "180 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Gorgeous George Boutique Hotel,Cape Town,South Africa",
        "Lat": -33.9236057,
        "Long": 18.4200855,
        "Descr": "118 St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kensington Place,Cape Town,South Africa",
        "Lat": -33.9394599,
        "Long": 18.40511,
        "Descr": "38 Kensington Cres, Higgovale Gardens, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Manna Bay,Cape Town,South Africa",
        "Lat": -33.9465955,
        "Long": 18.4140246,
        "Descr": "8 Bridle Rd, Oranjezicht, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "More Quarters,Cape Town,South Africa",
        "Lat": -33.9322299,
        "Long": 18.40814,
        "Descr": "2 Nicol St, Gardens, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Beach Lodge,Cape Town,South Africa",
        "Lat": -34.4756401,
        "Long": 20.5086937,
        "Descr": "De Hoop Avenue, De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Nox Apartments & Villas,Cape Town,South Africa",
        "Lat": -33.9631894,
        "Long": 18.3777178,
        "Descr": "1st Floor, Suite A, 13 Totnes Ave, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "O On Kloof Boutique Hotel,Cape Town,South Africa",
        "Lat": -33.9247948,
        "Long": 18.3828384,
        "Descr": "92 Kloof Rd, Sea Point, Cape Town, 8060, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One & Only Cape Town,Cape Town,South Africa",
        "Lat": -33.9086313,
        "Long": 18.4162905,
        "Descr": "One&Only Cape Town, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "POD Boutique Hotel,Cape Town,South Africa",
        "Lat": -33.9491694,
        "Long": 18.3797583,
        "Descr": "3 Argyle St, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Queen Victoria Hotel,Cape Town,South Africa",
        "Lat": -33.9053947,
        "Long": 18.4173715,
        "Descr": "Moorings 5 & Portswood Ridge, 5 Portswood Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Waterfront,Cape Town,South Africa",
        "Lat": -33.8996146,
        "Long": 18.4120393,
        "Descr": "100 Beach Rd, Granger Bay, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Table Bay Hotel,Cape Town,South Africa",
        "Lat": -33.9028648,
        "Long": 18.4221961,
        "Descr": "Quay, 6 W Quay Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Taj Cape Town,Cape Town,South Africa",
        "Lat": -33.9244744,
        "Long": 18.4197152,
        "Descr": "1 Waal Straat, corner St Georges Mall, Cape Town City Centre, Cape Town, 8000, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Bay Hotel,Cape Town,South Africa",
        "Lat": -33.95254,
        "Long": 18.3782,
        "Descr": "69 Victoria Rd, Camps Bay, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Cape Milner,Cape Town,South Africa",
        "Lat": -33.927595,
        "Long": 18.409109,
        "Descr": "2 Milner Rd, Tamboerskloof, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Clarendon Bantry Bay,Cape Town,South Africa",
        "Lat": -33.9280643,
        "Long": 18.3792721,
        "Descr": "158 Kloof Rd, Bantry Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Constantia,Cape Town,South Africa",
        "Lat": -34.0297494,
        "Long": 18.4398087,
        "Descr": "34 Spaanschemat River Rd, Constantia, Cape Town, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Long Beach,Cape Town,South Africa",
        "Lat": -34.13759,
        "Long": 18.32947,
        "Descr": "1 Kirsten Ave, Kommetjie, Cape Town, 7976, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marly Boutique Hotel,Cape Town,South Africa",
        "Lat": -33.9531211,
        "Long": 18.3775315,
        "Descr": "The Promenade, 201 Victoria Rd, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The One Above,Cape Town,South Africa",
        "Lat": -33.911232,
        "Long": 18.420313,
        "Descr": "1 Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Silo,Cape Town,South Africa",
        "Lat": -33.9084084,
        "Long": 18.4225592,
        "Descr": "Silo Square, Victoria & Alfred Waterfront, Cape Town, 8801, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spa House,Cape Town,South Africa",
        "Lat": -34.0459263,
        "Long": 18.3662281,
        "Descr": "18 Ave Suzanne, Hout Bay, Cape Town, 7872, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tintswalo Atlantic,Cape Town,South Africa",
        "Lat": -34.065147,
        "Long": 18.36796,
        "Descr": "Chapmans Peak Dr, Hout Bay, 7806, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Twelve Apostles Hotel & Spa,Cape Town,South Africa",
        "Lat": -33.9835262,
        "Long": 18.3589679,
        "Descr": "Victoria Street, Camps Bay, Cape Town, 8005, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Victoria & Alfred Hotel,Cape Town,South Africa",
        "Lat": -33.90597,
        "Long": 18.4201001,
        "Descr": "V&A Complex, PierHead, Dock Rd, Victoria & Alfred Waterfront, Cape Town, 8001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Durban,South Africa",
        "Lat": -29.8586804,
        "Long": 31.0218404,
        "Descr": "Durban, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Canelands Beach Club & Spa,Durban,South Africa",
        "Lat": -29.5025691,
        "Long": 31.2389144,
        "Descr": "Salt Rock, Dolphin Coast, 4391, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Teremok Marine Lodge,Durban,South Africa",
        "Lat": -29.735195,
        "Long": 31.0807851,
        "Descr": "49 Marine Dr, Umhlanga Rocks, Durban, 4320, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Oyster Box,Durban,South Africa",
        "Lat": -29.7284034,
        "Long": 31.0872415,
        "Descr": "2 Lighthouse Rd, Umhlanga, 4319, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Zimbali Resort,Durban,South Africa",
        "Lat": -29.8590713,
        "Long": 31.0220286,
        "Descr": "66 Joe Slovo St, Durban Central, Durban, 4001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Garden Route,South Africa",
        "Lat": -33.704229,
        "Long": 22.047637,
        "Descr": "South Cape DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Christiana Lodge,Garden Route,South Africa",
        "Lat": -34.088019,
        "Long": 23.3686895,
        "Descr": "Aquarius Cl, SolarBeach, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Emily Moon River Lodge,Garden Route,South Africa",
        "Lat": -34.01012,
        "Long": 23.3729843,
        "Descr": "1 Rietvlei Rd, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hog Hollow Country Lodge,Garden Route,South Africa",
        "Lat": -33.9731395,
        "Long": 23.4736603,
        "Descr": "Askop Road, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Hunters Country House,Garden Route,South Africa",
        "Lat": -34.052641,
        "Long": 23.275351,
        "Descr": "Off N2, 10km west of Plettenberg Bay, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kanonkop House,Garden Route,South Africa",
        "Lat": -34.0362538,
        "Long": 23.0220235,
        "Descr": "6 Cuthbert St, Kanonkop, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Knysna Hollow Country Estate,Garden Route,South Africa",
        "Lat": -34.0355873,
        "Long": 23.017208,
        "Descr": "5 Welbedacht Ln, Kanonkop, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Kurland,Garden Route,South Africa",
        "Lat": -33.9449115,
        "Long": 23.4924921,
        "Descr": "Kurland, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leisure Isle Lodge,Garden Route,South Africa",
        "Lat": -34.0660858,
        "Long": 23.0559406,
        "Descr": "87 Bayswater Rd, Leisure Isle, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "No 5 Boutique Art Hotel,Garden Route,South Africa",
        "Lat": -33.9847202,
        "Long": 25.6619275,
        "Descr": "5 Brighton Dr, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Pezula Resort Hotel & Spa,Garden Route,South Africa",
        "Lat": -34.0674425,
        "Long": 23.0999206,
        "Descr": "Sparrebosch, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Port Elizabeth,Garden Route,South Africa",
        "Lat": -33.987245,
        "Long": 25.6711184,
        "Descr": "Marine Dr & 9th Ave Summerstrand Nelson Mandela Bay, Summerstrand, Gqeberha, 6001, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tamodi Lodge & Stables,Garden Route,South Africa",
        "Lat": -33.986913,
        "Long": 23.45518,
        "Descr": "Pinehaven rd, The Crags, Plettenberg Bay, 6602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Plettenberg,Garden Route,South Africa",
        "Lat": -34.05381,
        "Long": 23.37756,
        "Descr": "40 Church St, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Rex Hotel,Garden Route,South Africa",
        "Lat": -34.0391937,
        "Long": 23.045213,
        "Descr": "8 Gray St, Knysna Central, Knysna, 6571, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Turbine Boutique Hotel,Garden Route,South Africa",
        "Lat": -34.048774,
        "Long": 23.049058,
        "Descr": "36 Sawtooth Lane, Thesen Island, Knysna, 6570, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Windermere,Garden Route,South Africa",
        "Lat": -33.8341255,
        "Long": 23.4537973,
        "Descr": "Garden Route National Park, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tsala Treetop Lodge,Garden Route,South Africa",
        "Lat": -34.0521202,
        "Long": 23.2705612,
        "Descr": "Off N2 10km W Of Plettenberg, Plettenberg Bay, 6600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Johannesburg,South Africa",
        "Lat": -26.2041028,
        "Long": 28.0473051,
        "Descr": "Johannesburg, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "AtholPlace House & Villa,Johannesburg,South Africa",
        "Lat": -26.1117668,
        "Long": 28.0639915,
        "Descr": "90 Pretoria Ave, Atholl, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "D'Oreale Grande,Johannesburg,South Africa",
        "Lat": -26.1443844,
        "Long": 28.2213325,
        "Descr": "64 Jones Rd, O.R. Tambo, Kempton Park, 1620, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Fairlawns Boutique Hotel & Spa,Johannesburg,South Africa",
        "Lat": -26.0795011,
        "Long": 28.0749836,
        "Descr": "1 Alma Rd, Morningside, Johannesburg, 2052, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Four Seasons Hotel The Westcliff,Johannesburg,South Africa",
        "Lat": -26.1710223,
        "Long": 28.0324839,
        "Descr": "67 Jan Smuts Ave, Westcliff, Johannesburg, 2132, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Intercontinental Hotel,Johannesburg,South Africa",
        "Lat": -26.1068045,
        "Long": 28.0525693,
        "Descr": "Corner of Maude &, 5th St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Michelangelo Towers,Johannesburg,South Africa",
        "Lat": -26.1063997,
        "Long": 28.0542906,
        "Descr": "8 Maude St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mount Grace Hotel & Spa,Johannesburg,South Africa",
        "Lat": -26.1336131,
        "Long": 28.0676215,
        "Descr": "1 Melrose Blvd, Melrose Arch, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Radisson Blu Hotel Sandton,Johannesburg,South Africa",
        "Lat": -26.1041779,
        "Long": 28.0614167,
        "Descr": "Rivonia Rd &, Daisy St, Sandown, Sandton, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Maslow,Johannesburg,South Africa",
        "Lat": -26.0990371,
        "Long": 28.0588493,
        "Descr": "Rivonia Rd &, Grayston Dr, Gauteng, Sandton, 2031, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Michelangelo Hotel,Johannesburg,South Africa",
        "Lat": -26.1069699,
        "Long": 28.0566358,
        "Descr": "135 West St, Sandown, Sandton, 2146, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Peech Hotel,Johannesburg,South Africa",
        "Lat": -26.1365329,
        "Long": 28.0588454,
        "Descr": "61 North St, Melrose North, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Residence Johannesburg,Johannesburg,South Africa",
        "Lat": -26.16454,
        "Long": 28.05939,
        "Descr": "No 17 4th Ave, Houghton Estate, Johannesburg, 2198, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Saxon Hotel,Johannesburg,South Africa",
        "Lat": -26.11212,
        "Long": 28.03561,
        "Descr": "36 Saxon Rd, Sandhurst, Johannesburg, 2196, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Panorama Route,South Africa",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Casterbridge Hollow Boutique Hotel,Panorama Route,South Africa",
        "Lat": -25.306553,
        "Long": 31.0333826,
        "Descr": "Casterbridge Lifestyle Centre, R40, White River, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oliver's Lodge,Panorama Route,South Africa",
        "Lat": 40.1268012,
        "Long": -104.9249965,
        "Descr": "Panorama Ave, Firestone, CO 80504, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Perry's Bridge Hollow,Panorama Route,South Africa",
        "Lat": -25.0364894,
        "Long": 31.1243171,
        "Descr": "R40, Hazyview, 1242, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Tanamera Lodge,Panorama Route,South Africa",
        "Lat": -25.0473903,
        "Long": 30.9585527,
        "Descr": "Sabie Road, Hazyview, Kiepersol, 1260, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Walkerson's Hotel & Spa,Panorama Route,South Africa",
        "Lat": -25.3699455,
        "Long": 30.1869584,
        "Descr": "10km outside of Dullstroom towards Lydenburg, Dullstroom, 1110, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Whale Route,South Africa",
        "Lat": -34.4222368,
        "Long": 20.5455147,
        "Descr": "De Hoop Nature Reserve, Overberg District Municipality, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Birkenhead House,Whale Route,South Africa",
        "Lat": 37.7904356,
        "Long": -122.4012663,
        "Descr": "1 Sansome St #3500, San Francisco, CA 94104, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Farm 215,Whale Route,South Africa",
        "Lat": -34.567574,
        "Long": 19.5137787,
        "Descr": "Farm 215 - Hartebeeskloof & Koudeberg, Baardskeerdersbos, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Forest Lodge,Whale Route,South Africa",
        "Lat": -34.549873,
        "Long": 19.413024,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Garden Lodge,Whale Route,South Africa",
        "Lat": -34.539996,
        "Long": 19.412938,
        "Descr": "Grootbos Private Nature Reserve, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grootbos Private Villas,Whale Route,South Africa",
        "Lat": -34.543243,
        "Long": 19.411929,
        "Descr": "Grootbos Private Nature Reserve™, R43, Gansbaai, 7220, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lekkerwater Beach Lodge,Whale Route,South Africa",
        "Lat": -34.4502064,
        "Long": 20.6345114,
        "Descr": "De Hoop Marine Protected Area, South Africa, Overberg DC, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Morukuru Ocean House,Whale Route,South Africa",
        "Lat": -34.473385,
        "Long": 20.5163438,
        "Descr": "De Hoop Avenue De Hoop Nature Reserve, Swellendam, 6740, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Ocean Eleven Guest House,Whale Route,South Africa",
        "Lat": -34.4236111,
        "Long": 19.2344444,
        "Descr": "11 Westcliff Rd, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "One Marine Drive Boutique Hotel,Whale Route,South Africa",
        "Lat": -34.4221363,
        "Long": 19.234668,
        "Descr": "1 Marine Dr, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Schulphoek House,Whale Route,South Africa",
        "Lat": -34.4269554,
        "Long": 19.2004925,
        "Descr": "181 Piet Retief Cres, Sand Bay, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Marine,Whale Route,South Africa",
        "Lat": 0,
        "Long": 0,
        "Descr": "",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Thatch House,Whale Route,South Africa",
        "Lat": -34.4064253,
        "Long": 19.3417732,
        "Descr": "R43 Stanford Road, Hermanus, 7200, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Wine Route,South Africa",
        "Lat": -33.9378603,
        "Long": 18.8634434,
        "Descr": "47 Church St, Stellenbosch Central, Stellenbosch, 7599, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Babylonstoren,Wine Route,South Africa",
        "Lat": -33.822505,
        "Long": 18.929985,
        "Descr": "Babylonstoren, Klapmuts - Simondium Rd, Simondium, 7670, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Bonne Esperance,Wine Route,South Africa",
        "Lat": -33.9369809,
        "Long": 18.8664217,
        "Descr": "Van Riebeeck St &, Neethling St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Clouds Estate,Wine Route,South Africa",
        "Lat": -33.9221089,
        "Long": 18.9237638,
        "Descr": "Helshoogte Rd, Stellenbosch, 7560, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Delaire Graff Estate,Wine Route,South Africa",
        "Lat": -33.9202951,
        "Long": 18.9218332,
        "Descr": "Helshoogte Rd, Stellenbosch, 7602, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Franschhoek Country House,Wine Route,South Africa",
        "Lat": -33.9001271,
        "Long": 19.1051309,
        "Descr": "Huguenot, Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grand Dedale Country House,Wine Route,South Africa",
        "Lat": -33.6269933,
        "Long": 19.0830857,
        "Descr": "Bovlei Rd, Wellington, 7654, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Grande Roche,Wine Route,South Africa",
        "Lat": 37.207023,
        "Long": -93.293867,
        "Descr": "314 W Walnut St, Springfield, MO 65806, USA",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Cle des Montagnes,Wine Route,South Africa",
        "Lat": -33.912665,
        "Long": 19.116629,
        "Descr": "13 Daniel Hugo St, Franschhoek, Cape Town, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "La Residence,Wine Route,South Africa",
        "Lat": 47.6231973,
        "Long": -70.1434478,
        "Descr": "181 Rue Richelieu, La Malbaie, QC G5A 1X7, Canada",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Lanzerac Hotel & Spa,Wine Route,South Africa",
        "Lat": -33.938299,
        "Long": 18.894264,
        "Descr": "1 Lanzerac Rd, Jonkershoek, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Franschhoek Hotel & Spa,Wine Route,South Africa",
        "Lat": -33.935809,
        "Long": 19.110409,
        "Descr": "16 Minor Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Le Quartier Francais,Wine Route,South Africa",
        "Lat": -33.9129333,
        "Long": 19.1202526,
        "Descr": "Corner of Berg and, Wilhelmina St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Leeu House,Wine Route,South Africa",
        "Lat": -33.913255,
        "Long": 19.121227,
        "Descr": "12 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Light House Boutique Suites,Wine Route,South Africa",
        "Lat": -33.77236,
        "Long": 18.954887,
        "Descr": "2 Lille, Courtrai, Paarl, 7646, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Majeka House,Wine Route,South Africa",
        "Lat": -33.9680216,
        "Long": 18.860642,
        "Descr": "26 Houtkapper St, Paradyskloof, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Mont Rochelle,Wine Route,South Africa",
        "Lat": -33.912819,
        "Long": 19.1110279,
        "Descr": "1499 Dassenberg Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "Oude Werf Hotel,Wine Route,South Africa",
        "Lat": -33.9378177,
        "Long": 18.8619977,
        "Descr": "30 Church St, Stellenbosch Central, Stellenbosch, 7600, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Last Word Franschhoek,Wine Route,South Africa",
        "Lat": -33.9083276,
        "Long": 19.1170728,
        "Descr": "68 Huguenot St, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Owners Cottage at Grande Provence,Wine Route,South Africa",
        "Lat": -33.8987481,
        "Long": 19.1036181,
        "Descr": "Main Rd, Franschhoek, 7690, South Africa",
        "Exists": "",
        "Converted": 1
      },
      {
        "Address": "The Spier Hotel,Wine Route,South Africa",
        "Lat": -33.9735508,
        "Long": 18.7821798,
        "Descr": "R310 Baden Powell Dr, Stellenbosch, 7603, South Africa",
        "Exists": "",
        "Converted": 1
      }
    ];
    var latlong = obj.find(o => o.Address.toLowerCase() === address.toLowerCase());
    return latlong;
  }
}
