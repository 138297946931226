import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';

import { HomeComponent } from './home/home.component';

import { HeaderComponent } from './header/header.component';
import { AreasComponent } from './areas/areas.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PlacesComponent } from './places/places.component';
import { WhyusComponent } from './whyus/whyus.component';
import { InstagramComponent } from './instagram/instagram.component';
import { StepsComponent } from './steps/steps.component';

import { ImagesComponent } from './images/images.component';
import { CardsComponent } from './cards/cards.component';

import { IntersectionComponent } from './intersection/intersection.component';

import { FormsModule } from '@angular/forms';
import { ContactusComponent } from './contactus/contactus.component';
import { ThankYouEnquiryComponent } from './thankyouenquiry/thankyouenquiry.component';
import { CardComponent } from './card/card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ScullyLibModule } from '@scullyio/ng-lib';

import { DestinationsComponent } from './destinations/destinations.component';
import { OurFavouritesComponent } from './our-favourites/our-favourites.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { TopdestinationsComponent } from './topdestinations/topdestinations.component';
import { TripsComponent } from './trips/trips.component';

import { SafePipe } from './service/safepipe.service';
import { HighlightDirective } from './service/highlight.service';


@NgModule({
  declarations: [
    AppComponent,

    MenuComponent,
    FooterComponent,
    SearchComponent,

    HomeComponent,

    HeaderComponent,
    AreasComponent,
    AboutusComponent,
    PlacesComponent,
    WhyusComponent,
    OurFavouritesComponent,

    StepsComponent,
    InstagramComponent,
    IntersectionComponent,

    CardComponent,
    ImagesComponent,
    CardsComponent,
    BreadcrumbComponent,

    ContactusComponent,
    ThankYouEnquiryComponent,
    OurStoryComponent,
    DestinationsComponent,
    TopdestinationsComponent,
    TripsComponent,

    SafePipe,
    HighlightDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ScullyLibModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
