<div id="hiddenDestinations" class="hiddenH3div">
  {{ "Top Destinations" | titlecase }}
</div>
<div>
  <app-header
      [title]="page.card.h1"
      [subtitle]="page.card.h2"
      [image]="page.card?.images[0]"
      [altText]="page.card?.alt1"
    ></app-header>

  <div class="btncontainer t-b20">
    <a href="/contact-us">
      <button aria-label="Get in touch" class="btn">Get in Touch</button>
    </a>
  </div>
  <div class="btncontainer t-b10">
    <button
      aria-label="Down arrow"
      class="btn downarrow"
      onClick="document.getElementById('article').scrollIntoView();"
    ></button>
  </div>

  <span class="container">
    <!-- Article -->
    <span id="article" name="article" class="p1280 abouttext">
      <div class="p100 flexmt mt35 mb-70">
        <div id="divDestinations" class="left">
          <article>
            <span class="abouttext left dark">

              <!--Header-->
              <h3 class="center black">
                {{ (page.card.h3 | titlecase) }}
              </h3>

              <p *ngFor="let line of page.reason; let i = index">
                <!--Experience-->
                <span *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])" class="header black"
                  >{{ utils.getWhyTitle(page.reason[i]) | titlecase }}
                </span>
                <span
                  *ngIf="utils.isAllUppercase(page.reason[i]) || utils.startWithTime(page.reason[i])"
                  class="dark"
                  [ngClass]="{ makebold: utils.startWithUSD(page.reason[i + 1]) }"
                  [innerHTML]="page.reason[i + 1]"
                ></span>

                <!--Experience-->
                <span
                  *ngIf="
                    i == 0 ||
                    (!utils.isAllUppercase(page.reason[i]) &&
                    !utils.startWithTime(page.reason[i]) &&
                      !utils.isAllUppercase(page.reason[i - 1]) &&
                      !utils.startWithTime(page.reason[i - 1]))
                  "
                  class="dark"
                  [ngClass]="{ makebold: utils.startWithUSD(page.reason[i]) }"
                  [innerHTML]="page.reason[i]"
                ></span>
              </p>

            </span>
          </article>
        </div>
      </div>
    </span>
  </span>

  <div class="areacontainer">
    <div class="area">
      <div class="grid-container">
        <div
          class="grid-item item1"
          (mouseover)="scaleImage($event, 0)"
          (mouseout)="scaleImage($event, 0)"
        >
          <a href="/safaris/botswana">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image1"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image1"
                  [ngClass]="{ scaleup: item[0] }"
                  alt="Botswana"
                />
              </picture>
              <div class="h4class">Botswana</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item2"
          (mouseover)="scaleImage($event, 1)"
          (mouseout)="scaleImage($event, 1)"
        >
          <a href="/safaris/south-africa/kruger-national-park">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image2"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image2"
                  [ngClass]="{ scaleup: item[1] }"
                  alt="Kruger National Park"
                />
              </picture>
              <div class="h4class">Kruger National Park</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item3"
          (mouseover)="scaleImage($event, 2)"
          (mouseout)="scaleImage($event, 2)"
        >
          <a href="/safaris/south-africa/kruger-national-park/sabi-sand-private-game-reserve">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image3"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image3"
                  [ngClass]="{ scaleup: item[2] }"
                  alt="Sabi Sand Reserve"
                />
              </picture>
              <div class="h4class">Sabi Sand Reserve</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item4"
          (mouseover)="scaleImage($event, 3)"
          (mouseout)="scaleImage($event, 3)"
        >
          <a href="/safaris/botswana/okavango-delta">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image4"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image4"
                  [ngClass]="{ scaleup: item[3] }"
                  alt="Okavango Delta"
                />
              </picture>
              <div class="h4class">Okavango Delta</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item5"
          (mouseover)="scaleImage($event, 4)"
          (mouseout)="scaleImage($event, 4)"
        >
          <a href="/safaris/zimbabwe/victoria-falls">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image5"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image5"
                  [ngClass]="{ scaleup: item[4] }"
                  alt="Victoria Falls"
                />
              </picture>
              <div class="h4class">Victoria Falls</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item6"
          (mouseover)="scaleImage($event, 5)"
          (mouseout)="scaleImage($event, 5)"
        >
          <a href="/cities/cape-town">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image6"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image6"
                  [ngClass]="{ scaleup: item[5] }"
                  alt="Cape Town"
                />
              </picture>
              <div class="h4class">Cape Town</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item7"
          (mouseover)="scaleImage($event, 6)"
          (mouseout)="scaleImage($event, 6)"
        >
          <a href="/safaris/south-africa">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image7"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image7"
                  [ngClass]="{ scaleup: item[6] }"
                  alt="South Africa"
                />
              </picture>
              <div class="h4class">South Africa</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item8"
          (mouseover)="scaleImage($event, 7)"
          (mouseout)="scaleImage($event, 7)"
        >
          <a href="/safaris/tanzania/serengeti-national-park">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image8"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image8"
                  [ngClass]="{ scaleup: item[7] }"
                  alt="Serengeti"
                />
              </picture>
              <div class="h4class">Serengeti</div>
            </div>
          </a>
        </div>

        <div
          class="grid-item item9"
          (mouseover)="scaleImage($event, 8)"
          (mouseout)="scaleImage($event, 8)"
        >
          <a href="/safaris/namibia">
            <div class="coverimg">
              <picture class="lazy">
                <source
                  [srcset]="image9"
                  media="(min-width: 1px), (min-height: 1px)"
                />
                <img
                  [src]="image9"
                  [ngClass]="{ scaleup: item[8] }"
                  alt="Namibia"
                />
              </picture>
              <div class="h4class">Namibia</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="btncontainer">
    <a href="/destinations">
      <button
        id="topToAll"
        aria-label="All destinations"
        class="btn alldestinations pink"
      >
        All Destinations
      </button>
    </a>
  </div>

  <app-steps id="steps"></app-steps>
  <app-instagram id="instagram"></app-instagram>

  <app-intersection></app-intersection>
</div>
