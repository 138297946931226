<div id="hiddenOurfavourites" class="hiddenH3div">Our Favourite Trips</div>
<div id="divOurfavourites" [ngClass]="{ specialPagesFavourites: isSpecialPage }" class="areacontainer">
  <div class="h3class center adjusth3">Our Favourite Trips</div>
  <div class="area">
    <div class="grid-container">
      <div
        class="grid-item item1"
        (mouseover)="scaleImage($event, 0)"
        (mouseout)="scaleImage($event, 0)"
      >
        <a href="/favourite/1charming">
          <div class="coverimg">
            <picture class="lazy">
              <source
                [srcset]="image1"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="image1"
                [ngClass]="{ scaleup: item[0] }"
                alt="Charming"
              />
            </picture>
            <div class="h4class">Charming</div>
          </div>
        </a>
      </div>

      <div
        class="grid-item item2"
        (mouseover)="scaleImage($event, 1)"
        (mouseout)="scaleImage($event, 1)"
      >
        <a href="/favourite/2stylish">
          <div class="coverimg">
            <picture class="lazy">
              <source
                [srcset]="image2"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="image2"
                [ngClass]="{ scaleup: item[1] }"
                alt="Stylish"
              />
            </picture>
            <div class="h4class">Stylish</div>
          </div>
        </a>
      </div>

      <div
        class="grid-item item3"
        (mouseover)="scaleImage($event, 2)"
        (mouseout)="scaleImage($event, 2)"
      >
        <a href="/favourite/3exclusive">
          <div class="coverimg">
            <picture class="lazy">
              <source
                [srcset]="image3"
                media="(min-width: 1px), (min-height: 1px)"
              />
              <img
                [src]="image3"
                [ngClass]="{ scaleup: item[2] }"
                alt="Exclusive"
              />
            </picture>
            <div class="h4class">Exclusive</div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="btncontainer">
    <a href="/trips">
      <button
        id="ourToMore"
        aria-label="All destinations"
        class="alldestinations btn"
      >
        More Trips
      </button>
    </a>
  </div>
</div>
