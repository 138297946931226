import { Child } from '../entities/child';
import { Parent } from '../entities/parent';
import { GrandParent } from '../entities/grandparent';

export class Card {
  folderid: string;
  foldername: string;
  title: string;
  why: string;
  experience: string;
  stay: string;
  activities: string;
  reason: string;
  when: string;
  images: string[];
  level: number;
  other: string;
  lodges: string;

  //Untamed
  schema: string;
  canon: string;
  meta: string;
  alt8: string;
  alt7: string;
  alt6: string;
  alt5: string;
  alt4: string;
  alt3: string;
  alt2: string;
  alt1: string;
  pageTitle: string;
  h3: string;
  h2: string;
  h1: string;

  url: string;
  related: string;
  keyword: string;
  category: string;
  author: string;
  created: string;
  modified: string;
  html: string;
  youtube: string;

  exclusions: string;
  inclusions: string;
  overview: string;
  summary: string;
  areas: string;
  rate: string;

  children: Child[];
  parent: Parent;
  grandparent: GrandParent;

  constructor() { }
}
