<header>
  <div class="container1">
    <div class="grid">
      <div class="gridspacer"></div>
      <div class="containerlogo">
        <div class="h1class  logo"><a href="/">Africa Unwind</a></div>
      </div>
      <div class="gridspacer"></div>
    </div>

    <a href="/search-site">
      <div class="wrapper">
        <div class="container" [ngClass]="{ active: searchOpen }">
          <div class="icon-items icon-1">
            <i class="fas fa fa-search"></i>
          </div>
        </div>
      </div>
    </a>

    <div class="navigation">
      <nav class="site-nav" [ngClass]="{ 'site-nav--open': hamburgerOpen }">
        <ul>
          <li class="nav-button">
            <span class="button topplan"
              ><a href="/contact-us">Let's Plan</a></span
            >
          </li>

          <li class="li">
            <a href="/safaris"><i class="site-nav--icon"></i>Safaris</a>
          </li>
          <li class="li">
            <a href="/islands"><i class="site-nav--icon"></i>Islands</a>
          </li>
          <li class="li">
            <a href="/cities"><i class="site-nav--icon"></i>Cities</a>
          </li>
          <li class="li mega-drop-down menu-item">
            <a href="/destinations"
              ><i class="site-nav--icon"></i>Destinations
            </a>

            <ul class="submenu">
              <li class="submenu-item">
                <div class="flexrow">
                  <div class="h2class submenu-title">Top Destinations</div>
                </div>
                <ul class="submenu-top">
                  <li class="submenu-top-item">
                    <a href="/safaris/botswana">Botswana</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/south-africa/kruger-national-park"
                      >Kruger National Park</a
                    >
                  </li>
                  <li class="submenu-top-item">
                    <a
                      href="/safaris/south-africa/kruger-national-park/sabi-sand-private-game-reserve"
                      >Sabi Sand Reserve</a
                    >
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/botswana/okavango-delta"
                      >Okavango Delta</a
                    >
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/zimbabwe/victoria-falls"
                      >Victoria Falls</a
                    >
                  </li>
                  <li class="submenu-top-item">
                    <a href="/cities/cape-town">Cape Town</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/south-africa">South Africa</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/tanzania/serengeti-national-park"
                      >Serengeti</a
                    >
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/namibia">Namibia</a>
                  </li>
                </ul>

                <div class="flexrow">
                  <div class="h2class submenu-title-half">Southern Africa</div>
                  <div class="h2class submenu-title-half">Indian Ocean Islands</div>
                </div>
                <ul class="submenu-top">
                  <li class="submenu-top-item">
                    <a href="/safaris/botswana">Botswana</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/mauritius">Mauritius</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/south-africa">South Africa</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/mozambique">Mozambique</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/zimbabwe">Zimbabwe</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/seychelles">Seychelles</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/zambia">Zambia</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/maldives">Maldives</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/namibia">Namibia</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/zanzibar">Zanzibar</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/malawi">Malawi</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/islands/madagascar">Madagascar</a>
                  </li>
                </ul>

                <div class="flexrow">
                  <div class="h2class submenu-title">East Africa</div>
                </div>
                <ul class="submenu-top">
                  <li class="submenu-top-item">
                    <a href="/safaris/tanzania">Tanzania</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/kenya">Kenya</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/rwanda">Rwanda</a>
                  </li>
                  <li class="submenu-top-item">
                    <a href="/safaris/uganda">Uganda</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li class="li listar">
            <div class="star">
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
          </li>

          <li class="li">
            <a href="/our-story"><i class="site-nav--icon"></i>Our Story</a>
          </li>

          <li class="li">
            <a href="/blog"><i class="site-nav--icon"></i>Blog</a>
          </li>
        </ul>
      </nav>
    </div>

    <a class="round-text" href="/contact-us">
      <div class="plan round">
        <span class="button">Let's Plan</span>
      </div></a
    >

    <div
      class="menu-toggle"
      [ngClass]="{ open: hamburgerOpen }"
      (click)="toggleHamburger()"
    >
      <div class="hamburger"></div>
    </div>
  </div>
</header>
