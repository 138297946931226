import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as $ from "jquery";
import { HighlightDirective } from '../service/highlight.service';

function ResizeH1() {
  $(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    //H1 main title
    var mainwidth = $(window).width() - 30;

    //console.log("Main: " + mainwidth);
    //console.log("h1: " + $(".main").find("h1").width());

    //Try an initial change to force the title size
    if ($(".main").find("h1").width() > mainwidth) {
      var currentFontSize = parseInt($(".main").find("h1").css("font-size"));
      //console.log("currentFontSize: " + currentFontSize);
      $(".main").find("h1").css("font-size", currentFontSize - 0.5);

      $(".main").find("highlight").css("font-size", (currentFontSize - 0.5) * 0.5432);
      //console.log("h1: " + $(".main").find("h1").width());
    }

    while ($(".main").find("h1").width() > mainwidth) {
      var factor = $(".main").find("h1").width() / mainwidth;
      var currentFontSize = parseInt($(".main").find("h1").css("font-size"));
      //console.log("factor: " + factor);
      //console.log("currentFontSize: " + currentFontSize);
      $(".main").find("h1").css("font-size", currentFontSize / factor);

      $(".main").find("highlight").css("font-size", currentFontSize / factor);
      //console.log("h1: " + $(".main").find("h1").width());
    };

    var finalfontsize = $(".main").find("h1").css("font-size");
    //console.log("finalfontsize: " + finalfontsize);
    $(".main").find("h1").css("line-height", finalfontsize);

    //$(".main").find("highlight").css("line-height", finalfontsize);

  });
}

function ResizeH2() {
  $(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) return;

    var mainwidth = $(window).width() - 30;
    if ($(".sub")) {
      while ($(".sub").width() > mainwidth) {
        var factor = $(".sub").width() / mainwidth;
        var currentFontSize = parseInt($(".sub").find("h2").css("font-size"));
        $(".sub").find("h2").css("font-size", currentFontSize / factor);
        //$(".sub").find("h2").css("line-height", currentFontSize / factor);
      };
    }

  });
}

function LoadImage() {
  $(function () {
    var viewportWidth = $(window).width();
    var viewportHeight = $(window).height();

    console.log("Load new image: w:" + viewportWidth + " h:" + viewportHeight);
    var imgSource = $("#mainimg").attr("src");
    //console.log(imgSource);

    //Based on intersection values
    var newImageSize = "sm";
    if (viewportWidth >= 900 || viewportHeight >= 510)
      newImageSize = "lg";
    else if (viewportWidth >= 450 || viewportHeight >= 255)
      newImageSize = "md";
    //if (viewportWidth >= 300 || viewportHeight >= 170) "sm"

    var newImage = imgSource.split("-20w.").join("-" + newImageSize + ".");
    //console.log(newImage);
    $("#mainimg").attr("src", newImage);

    $(".grid").css("visibility", "visible");
  });
}

function ApplySafariHack() {
  $(document).ready(function () {
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
      $("#headerapp").addClass('safari');
      var mainwidth = $(window).width();
      if (mainwidth > 800) {
        var currentVal = $(".sub").find("h2").css("margin-top");
        $(".sub").find("h2").css("margin-top", currentVal + 60);
        $(".mainspacer").height(60);
      }
    }
  });
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() presubtitle: string;
  @Input() subtitle: string;
  @Input() image: string;
  @Input() logo: string;
  @Input() altText: string;

  showHeader: boolean;
  isMainPage: boolean;
  isBlogPage: boolean;

  constructor() {

  }

  ngOnInit(): void {
    console.log("Header init");
    this.isMainPage = (this.title == "Africa Unwind");
    this.isBlogPage = (this.title == "Another Travel Blog");
  }

  ngAfterViewInit(): void {
    if (this.title && !this.isMainPage) ResizeH1();
    //if (this.subtitle && !this.isMainPage) ResizeH2();
    ApplySafariHack();
    LoadImage();
  }

  getimage(): string {
    var result = this.image;

    if (result.toLowerCase().indexOf('.webp') > -1) {
      result = this.image.replace('.webp', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.jpg') > -1) {
      result = this.image.replace('.jpg', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.jpeg') > -1) {
      result = this.image.replace('.jpeg', '-20w.webp');
    } else if (result.toLowerCase().indexOf('.png') > -1) {
      result = this.image.replace('.png', '-20w.webp');
    };

    result = result.split('\`').join('%27'); //eg. Jack's camp would load the -200w image on cards...?!

    let re = /\`/gi;
    result = result.replace(re, "\'");

    return result;
  }

  fixTitle(text: string): string {
    if (!text) return;
    return text.split("1920’S").join("1920’s")
    //return text;
  }

  getTitle1(text: string): string {
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.containsZero()) {
      var result = text.substr(0, text.indexOf("0"));
      return result;
    }
    else return text;
  }

  getTitle2(text: string): string {
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.containsZero()) {
      return "0";
    }
    else return "";
  }

  getTitle3(text: string): string {
    if (!text) return;
    text = text.split("1920’S").join("1920’s")
    if (this.containsZero()) {
      return text.substr(text.indexOf("0") + 1);
    }
    else return "";
  }

  fixSubtitle(text: string): string {
    if (!text) return "";
    return text
      .toLowerCase()
      .split("nyerere national park selous game reserve,").join("nyerere national park,")
      .split("matusadona national park lake kariba,").join("matusadona national park,");
  }

  containsZero(): boolean {
    if (!this.title) return;
    return (this.title.indexOf("0") > -1);
  }

  containsLatitude(): boolean {
    if (!this.title) return;
    return (this.title.toLowerCase().indexOf("latitude") > -1);
  }
}
